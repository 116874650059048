import useApi from "@/hooks/useApi";
import WebhookRepositories from "@/utilities/repositories/Webhook";
import { ListResponseData } from "@/utilities/types/requests";
import { EWebhookType, WebhookItem } from "@/utilities/types/Webhook";
import cn from "@/utils/class-names";
import {
  webhookSettingSchema,
  webhookSigninSchema,
} from "@/utils/validators/webhook.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { memo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiCopySimple, PiTrashBold } from "react-icons/pi";
import { Button, Input, Password, Title } from "rizzui";
import { useCopyToClipboard } from "usehooks-ts";

export interface WebhookSmsFormProps {
  refetchData?: () => Promise<ListResponseData<WebhookItem> | undefined>;
  data: WebhookItem[];
  isLoading?: boolean;
}

function WebhookSmsForm({ data, isLoading, refetchData }: WebhookSmsFormProps) {
  const { request: settingWebhook, loading } = useApi({
    request: WebhookRepositories.settingWebhook,
  });

  const [_, copyToClipboard] = useCopyToClipboard();

  function handleCopyToClipboard(value: string) {
    copyToClipboard(value);
    toast.success(<b>{`Copied to clipboard`}</b>);
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    resolver: zodResolver(webhookSigninSchema),
  });

  const secretKey = watch("secretKey");

  const onSubmit = async (values: any) => {
    try {
      const data = values?.url ? [values.url] : [];
      await settingWebhook({ data, type: EWebhookType.SIGNIN });
      toast.success("Setting webhook successfully");

      await refetchData?.();
    } catch (e) {
      toast.error(<b>{`Failed to save webhook settings`}</b>);
    }
  };

  useEffect(() => {
    reset({
      url: data?.[0]?.name,
      secretKey: data?.[0]?.secretKey,
    });
  }, [data]);

  return (
    <div className="mt-4 border-muted mb-2 items-start rounded-lg border p-3 shadow @md:p-5 @xl:p-3">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full col-span-full mb-2 gap-y-4"
      >
        <Title as="h5">Webhook Signin</Title>

        <div className="grid w-full items-start gap-3 md:grid-cols-2 grid-cols-1">
          <Controller
            control={control}
            name={"url"}
            render={({ field: formField, fieldState, formState }) => {
              return (
                <Input
                  label="Url"
                  placeholder="Enter Url to connect to webhook"
                  {...formField}
                  //   defaultValue={field.url}
                  {...formState}
                  error={fieldState.error?.message}
                />
              );
            }}
          />
          <Password
            label="Secret Key"
            placeholder="Secret key"
            inputClassName="text-sm [&_input]:px-2"
            readOnly
            helperText={
              "Secret key will be automatically generated after submit"
            }
            defaultValue={secretKey}
            prefix={
              <PiCopySimple
                onClick={(e) => {
                  handleCopyToClipboard(secretKey);
                }}
                className={cn(
                  "mr-2 h-5 w-5 text-gray-500 cursor-pointer",
                  !secretKey && "pointer-events-none"
                )}
              />
            }
          />
        </div>

        <div className="ml-auto w-full flex flex-col md:flex-row justify-between gap-2">
          {data?.[0]?.id && (
            <Button
              type="button"
              variant="text"
              color="danger"
              className={cn("md:w-fit w-full font-semibold")}
              onClick={onSubmit}
            >
              <PiTrashBold className="me-1 h-[18px] w-[18px]" /> Remove
            </Button>
          )}

          <Button
            disabled={isLoading || loading}
            type="submit"
            className="md:w-fit w-full"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
WebhookSmsForm.displayName = "WebhookSmsForm";

export default memo(WebhookSmsForm);
