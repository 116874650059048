import { Title } from "rizzui";
import ControlledTable from "@/components/controlled-table";
import useApi from "@/hooks/useApi.tsx";
import { IResponseData } from "@/utilities/types/requests.ts";
import { IMessage } from "@/utilities/types/Message.ts";
import MessageRepository from "@/utilities/repositories/Message.ts";
import { useCallback, useMemo } from "react";
import { useColumn } from "@/hooks/use-column.ts";
import { GetColumns } from "@/app/app-shared/message/table/columns.tsx";
import { useTableServer } from "@/hooks/useTableServer.tsx";
import ModalCreateEditMessage from "@/app/app-shared/message/create-edit/create-edit-message.tsx";
import useProfile from "@/hooks/use-profile";
import { Roles } from "@/utilities/types/Users";

export default function MessageTable({ isAgency }: { isAgency?: boolean }) {
  const { profile } = useProfile();
  const { request: deleteMessage } = useApi<IResponseData<IMessage>>({
    request: MessageRepository.deleteMessageByAdmin,
  });

  const { request: deleteMessageAgency } = useApi<IResponseData<IMessage>>({
    request: MessageRepository.deleteMessageByAgency,
    enableToast: true,
  });

  const {
    handleReset,
    tableData,
    currentPage,
    totalItems,
    isLoading,
    handlePaginate,
    pageSize,
    handleChangePageSize,
  } = useTableServer({
    server: {
      request: isAgency
        ? MessageRepository.getMessagesByAgency
        : MessageRepository.getMessagesByAdmin,
    },
  });

  const onDeleteItem = useCallback(
    async (id: string) => {
      if (!isAgency) {
        await deleteMessage(id);
      } else {
        await deleteMessageAgency(id);
      }
      handleReset();
    },
    [handleReset]
  );

  const columns = useMemo(
    () =>
      GetColumns({
        onDeleteItem,
        reset: handleReset,
        isAgency,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDeleteItem, tableData, isAgency]
  );
  const { visibleColumns } = useColumn(columns);

  return (
    <div className="mt-4">
      <div className="relative z-50 mb-4 flex flex-wrap items-center justify-between gap-2.5 @container">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          {profile?.role === Roles.ADMIN ? "All Message" : "WhatsApp Templates"}
        </Title>
        <ModalCreateEditMessage isAgency={isAgency} reset={handleReset} />
      </div>
      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={visibleColumns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
}
