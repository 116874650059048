"use client";

import { PiPlusBold } from "react-icons/pi";
import { useModal } from "@/app/shared/modal-views/use-modal";
import { Button, Tooltip, type ButtonProps } from "rizzui";
import cn from "@/utils/class-names";

interface ModalButtonProps extends ButtonProps {
  label?: string;
  className?: string;
  customSize?: string;
  icon?: React.ReactNode;
  view: React.ReactNode;
  onBackdrop?: () => void;
  canClick?: boolean;
}

export default function ModalButton({
  label = "Add New",
  className,
  customSize = "500px",
  view,
  icon = <PiPlusBold className="me-1.5 h-[17px] w-[17px]" />,
  onBackdrop,
  canClick = true,
  ...rest
}: ModalButtonProps) {
  const { openModal } = useModal();
  if (!canClick) {
    return (
      <Tooltip
        content={"Please update sections before adding promotion banner"}
        placement="top-start"
      >
        <Button
          className={cn(
            "mt-5 w-full text-xs capitalize @lg:w-auto sm:text-sm lg:mt-0",
            className
          )}
          disabled
          {...rest}
        >
          {icon}
          {label}
        </Button>
      </Tooltip>
    );
  }
  return (
    <Button
      className={cn(
        "mt-5 w-full text-xs capitalize @lg:w-auto sm:text-sm lg:mt-0",
        className
      )}
      onClick={() => {
        openModal({
          view,
          customSize,
          onBackdrop,
        });
      }}
      {...rest}
    >
      {icon}
      {label}
    </Button>
  );
}
