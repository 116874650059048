import { request } from "@/utilities/libs/request.ts";
import { IResponseData } from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { TImageAsset } from "../types/Asset";
import { E_ASSETS } from "../enums/Asset";

function uploadImageAsset(data: FormData) {
  return request<IResponseData<TImageAsset>>({
    method: ERequestMethods.POST,
    url: E_ASSETS.IMAGE,
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

const AssetsRepository = Object.freeze({
  uploadImageAsset,
});

export default AssetsRepository;
