"use client";

import DeletePopover from "@/app/shared/delete-popover";
import PencilIcon from "@/components/icons/pencil";
import { HeaderCell } from "@/components/ui/table";
import { UNIT_TRANSACTION_STATUS } from "@/config/constants";
import { EUnitTransactionStatus } from "@/utilities/enums/UnitTransaction";
import { IUnitTransaction } from "@/utilities/types/UnitTransaction";
import { IUnitType } from "@/utilities/types/UnitType";
import { formatDate } from "@/utils/format-date";
import { ActionIcon, Tooltip } from "rizzui";
import { Roles } from "@/utilities/types/Users.ts";

type Columns = {
  data: IUnitTransaction[];
  onDeleteItem: (id: string) => Promise<void>;
  onEditItem: (row: IUnitTransaction) => void;
  role: string;
};

export const getColumns = ({ onDeleteItem, onEditItem, role }: Columns) => [
  {
    title: (
      <HeaderCell
        title="Name"
        align="center"
      />
    ),
    key: "unitName",
    dataIndex: "unitName",
    align: "center",
    render: (value: string | Record<string, string>) => {
      return typeof value === "string" ? value : value?.en;
    },
  },
  {
    title: (
      <HeaderCell
        title="Unit Type"
        align="center"
      />
    ),
    key: "unitType",
    dataIndex: "unitType",
    align: "center",
    render: (value: IUnitType) => {
      return typeof value?.title === "string" ? value?.title : value?.title?.en;
    },
  },
  // {
  //   title: (
  //     <HeaderCell
  //       title="Project"
  //       align="center"
  //     />
  //   ),
  //   dataIndex: "project",
  //   key: "project",
  //   align: "center",
  //   render: (value: IProject) => {
  //     return typeof value?.name === "object" ? value?.name?.en : value?.name;
  //   },
  // },
  {
    title: (
      <HeaderCell
        title="Timestamp"
        align="center"
      />
    ),
    dataIndex: "timestamp",
    key: "timestamp",
    align: "center",
    render: (value: string) => formatDate(new Date(value), "DD/MM/YYYY"),
  },
  {
    title: (
      <HeaderCell
        title="Status"
        align="center"
      />
    ),
    dataIndex: "status",
    key: "status",
    align: "center",
    render: (value: string) =>
      UNIT_TRANSACTION_STATUS?.[value as EUnitTransactionStatus]?.label || "",
  },
  {
    title: (
      <HeaderCell
        className="ps-3"
        title={
          <span className="whitespace-nowrap">
            {role !== "" && role !== Roles.AGENCY && "Actions"}
          </span>
        }
        align="center"
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: IUnitTransaction) => (
      <div className="flex items-center justify-center gap-3 pe-4">
        {role !== "" && role !== Roles.AGENCY && (
          <>
            <Tooltip
              size="sm"
              content={"Edit Unit Transaction"}
              placement="top"
              color="invert"
            >
              <ActionIcon
                size="sm"
                variant="outline"
                onClick={() => onEditItem(row)}
              >
                <PencilIcon className="h-4 w-4" />
              </ActionIcon>
            </Tooltip>
            <DeletePopover
              title={`Delete unit transaction`}
              description={`Are you sure you want to delete this unit transaction?`}
              onDelete={async () => await onDeleteItem(row.id)}
            />
          </>
        )}
      </div>
    ),
  },
];
