import { Button, Title } from "rizzui";
import cn from "@/utils/class-names";
import { FcGoogle } from "react-icons/fc";
import OrSeparation from "@/app/shared/auth-layout/or-separation";
import { siteConfig } from "@/config/site.config";
import { BsFacebook } from "react-icons/bs";
import { Link } from "react-router-dom";
import Logo from "@/components/logo";

export default function AuthWrapperFour({
  children,
  title,
  isSocialLoginActive = false,
  isSignIn = false,
  className = "",
}: {
  children: React.ReactNode;
  title: React.ReactNode;
  isSocialLoginActive?: boolean;
  isSignIn?: boolean;
  className?: string;
}) {
  return (
    <div className="min-h-screen flex flex-col">
      {/*<AuthHeader />*/}
      <div className="flex w-full flex-col justify-center flex-1">
        <div className="flex w-full flex-col justify-center px-5">
          <div
            className={cn(
              "mx-auto w-full my-auto max-w-md md:max-w-lg lg:max-w-xl",
              className
            )}
          >
            <div className="flex flex-col items-center gap-10">
              <Logo className="max-w-[200px]" />
              <Title
                as="h2"
                className="mb-7 text-center text-[28px] font-bold leading-snug md:text-3xl md:!leading-normal lg:mb-10 lg:text-4xl"
              >
                {title}
              </Title>
            </div>
            {isSocialLoginActive && (
              <>
                <div className="flex flex-col gap-4 pb-6 md:flex-row md:gap-6 xl:pb-7">
                  <Button variant="outline" className="h-11 w-full">
                    <FcGoogle className="me-2 h-4 w-4 shrink-0" />
                    <span className="truncate">Signin with Google</span>
                  </Button>
                  <Button variant="outline" className="h-11 w-full">
                    <BsFacebook className="me-2 h-4 w-4 shrink-0 md:h-5 md:w-5" />
                    <span className="truncate">Signin with Facebook</span>
                  </Button>
                </div>
                <OrSeparation
                  title={`Or, Sign ${isSignIn ? "in" : "up"} with your email`}
                  isCenter
                  className="mb-5 2xl:mb-7"
                />
              </>
            )}

            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
