import { Roles } from "@/utilities/types/Users.ts";
import { HeaderCell } from "@/components/ui/table.tsx";
import useProfile from "@/hooks/use-profile.ts";
import DeletePopover from "@/app/shared/delete-popover.tsx";
import ModalCreateEditFloor from "@/app/app-shared/virtual-project/create-edit";
import { VirtualProjectItem } from "@/utilities/types/VirtualProject.ts";
import { IFloorName, IUnitTypeFloor } from "@/utilities/types/Floor.ts";
import { TImageAsset } from "@/utilities/types/Asset";
const ASSET_BASE_URL = import.meta.env.VITE_ASSET_BASE_URL as string;

type Columns = {
  data: VirtualProjectItem[];
  onDeleteItem?: (id: string) => void;
  onHeaderCellClick: (value: string) => void;
  onUpdateFloor?: (id: string) => Promise<void>;
  reset?: () => void;
  role: Roles;
  projectId: string;
};
export const GetColumns = ({
  data,
  onHeaderCellClick,
  onDeleteItem,
  role,
  reset,
  projectId,
}: Columns) => [
  {
    title: (
      <HeaderCell
        title="Logo"
        align="left"
      />
    ),
    dataIndex: "thumbnail",
    key: "thumbnail",
    render: (thumbnail: TImageAsset) => {
      return (
        <>
          {thumbnail ? (
            <img
              width="70"
              height="70"
              src={`${ASSET_BASE_URL}${thumbnail?.urls[0].url}`}
            />
          ) : (
            <p>Empty Thumbnail</p>
          )}
        </>
      );
    },
  },
  {
    title: (
      <HeaderCell
        title="Name"
        sortable
        align="left"
      />
    ),
    onHeaderCell: () => onHeaderCellClick("name"),
    dataIndex: "name",
    key: "name",
    render: (name: IFloorName) => {
      return <>{typeof name === "object" ? name?.en : name}</>;
    },
  },
  {
    title: (
      <HeaderCell
        title="Unit Type"
        sortable
        align="left"
      />
    ),
    onHeaderCell: () => onHeaderCellClick("name"),
    dataIndex: "unitType",
    key: "unitType",
    render: (unitType: IUnitTypeFloor) => {
      return (
        <>
          {typeof unitType?.title === "object"
            ? unitType?.title?.en
            : unitType?.title}
        </>
      );
    },
  },
  {
    title: (
      <HeaderCell
        title="Url"
        align="left"
      />
    ),
    dataIndex: "url",
    key: "url",
    render: (_: string, row: VirtualProjectItem) => (
      <a
        href={row?.url}
        target="_blank"
      >
        {row?.url}
      </a>
    ),
  },
  {
    title: (
      <HeaderCell
        className="ps-3"
        title={<span className="whitespace-nowrap">Actions</span>}
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: VirtualProjectItem) => {
      if (role === Roles.ADMIN) {
        return (
          <ActionAdmin
            onDeleteItem={() => onDeleteItem?.(row.id)}
            row={row}
            projectId={projectId}
            reset={reset}
          />
        );
      }
    },
  },
];

const ActionAdmin = ({
  onDeleteItem,
  row,
  projectId,
  reset,
}: {
  onDeleteItem: () => void;
  reset?: () => void;
  onUpdateFloor?: () => Promise<void>;
  row: VirtualProjectItem;
  projectId: string;
}) => {
  const { profile } = useProfile();
  return (
    <div className="flex items-center justify-center gap-3 pe-4">
      {profile?.role === Roles.ADMIN && (
        <>
          <ModalCreateEditFloor
            row={row}
            projectId={projectId}
            reset={() => typeof reset === "function" && reset()}
          />
          <DeletePopover
            title={`Delete virtual project`}
            description={`Are you sure to delete this virtual project?`}
            onDelete={onDeleteItem}
          />
        </>
      )}
    </div>
  );
};
