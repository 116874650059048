import { useModal } from "@/app/shared/modal-views/use-modal";
import { DatePicker } from "@/components/ui/datepicker";
import { UNIT_TRANSACTION_STATUS } from "@/config/constants";
import useApi from "@/hooks/useApi";
import { EUnitTransactionStatus } from "@/utilities/enums/UnitTransaction";
import UnitTransactionRepository from "@/utilities/repositories/UnitTransaction";
import UnitTypeRepository from "@/utilities/repositories/UnitType";
import { IUnitTransaction } from "@/utilities/types/UnitTransaction";
import { IUnitType } from "@/utilities/types/UnitType";
import { ListResponseData } from "@/utilities/types/requests";
import {
  CreateUnitTransactionInput,
  createUnitTransactionSchema,
} from "@/utils/validators/unit-transactions.schema";
import { createUnitTypeSchema } from "@/utils/validators/unit-type.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiXBold } from "react-icons/pi";
import { ActionIcon, Button, Input, Select, SelectOption, Title } from "rizzui";

export interface CreateEditUnitTransactionProps {
  refetchData?: () => void;
  projectId?: string;
  data?: IUnitTransaction;
}

export default function CreateEditUnitTransaction({
  refetchData,
  projectId = "",
  data,
}: CreateEditUnitTransactionProps) {
  const isUpdate = !!data;
  const title = isUpdate ? "Edit Unit Transaction" : "Create Unit Transaction";
  const form = useForm({
    defaultValues: {
      unitName: "",
      unitTypeId: "",
      projectId: data?.projectId || projectId,
      status: "",
      timestamp: new Date(),
    },
    resolver: zodResolver(createUnitTransactionSchema),
  });

  const { closeModal, isOpen } = useModal();

  const { loading, request: createUnitTransaction } = useApi({
    request: UnitTransactionRepository.createUnitTransaction,
  });
  const { loading: loadingUpdate, request: updateUnitTransaction } = useApi({
    request: UnitTransactionRepository.updateUnitTransaction,
  });

  const { request: fetchUnitTypes, response: unitTypeData } = useApi<
    ListResponseData<IUnitType>
  >({
    request: UnitTypeRepository.getAllUnitType,
  });

  const onSubmit = async (formData: CreateUnitTransactionInput) => {
    try {
      if (isUpdate) {
        await updateUnitTransaction(data.id, {
          ...formData,
          projectId: undefined,
        });
      } else {
        await createUnitTransaction({
          ...formData,
        });
      }
      toast.success("Successfully");
      refetchData?.();
      if (isOpen) {
        closeModal();
      }
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (data) {
      form.reset({
        unitName: data?.unitName || "",
        unitTypeId: data?.unitTypeId || "",
        projectId: data?.projectId || "",
        status: data?.status || "",
        timestamp: new Date(data?.timestamp),
      });
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    fetchUnitTypes({ queryParams: { pagination: { page: 1, limit: 50 } } });
  }, []);

  const unitTypeOptions =
    unitTypeData?.data?.items?.map((item: IUnitType) => ({
      value: item.id,
      label: typeof item?.title === "object" ? item?.title?.en : item?.title,
    })) || [];

  const UNIT_TRANSACTION_OPTIONS = Object.keys(UNIT_TRANSACTION_STATUS).map(
    (e) => ({
      value: e,
      label:
        UNIT_TRANSACTION_STATUS?.[e as EUnitTransactionStatus]?.label || "",
    })
  );

  return (
    <form
      noValidate
      className="grid grid-cols-1 gap-6 p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className="col-span-full flex items-center justify-between">
        <Title
          as="h4"
          className="font-semibold"
        >
          {title}
        </Title>
        {isOpen && (
          <ActionIcon
            size="sm"
            variant="text"
            onClick={closeModal}
          >
            <PiXBold className="h-auto w-5" />
          </ActionIcon>
        )}
      </div>

      <Input
        label="Name"
        size="lg"
        placeholder="Enter name"
        {...form.register("unitName")}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.unitName?.message}
      />

      <Controller
        name="unitTypeId"
        control={form.control}
        render={({ field: { onChange, value, name } }) => (
          <Select
            options={unitTypeOptions}
            value={value}
            onChange={onChange}
            name={name}
            label="Unit Type"
            size="lg"
            className="[&>label>span]:font-medium  col-span-full"
            getOptionValue={(option: SelectOption) => option.value}
            displayValue={(selected: string) =>
              unitTypeOptions?.find((option) => option.value === selected)
                ?.label
            }
            dropdownClassName={"z-[9999] w-full"}
            error={form?.formState?.errors?.[name]?.message}
          />
        )}
      />

      <Controller
        name="timestamp"
        control={form.control}
        render={({ field: { value, onChange } }) => (
          <DatePicker
            inputProps={{
              label: "Timestamp",
              error: form?.formState?.errors?.timestamp?.message as string,
            }}
            placeholderText="Select Date"
            className="[&>label>span]:font-medium  col-span-full"
            selected={value}
            onChange={onChange}
            dateFormat="dd/MM/YYYY"
          />
        )}
      />

      <Controller
        name="status"
        control={form.control}
        render={({ field: { onChange, value, name } }) => (
          <Select
            options={UNIT_TRANSACTION_OPTIONS}
            value={value}
            onChange={onChange}
            name={name}
            label="Status"
            size="lg"
            className="[&>label>span]:font-medium  col-span-full"
            getOptionValue={(option: SelectOption) => option.value}
            displayValue={(selected: string) =>
              UNIT_TRANSACTION_OPTIONS?.find(
                (option) => option.value === selected
              )?.label
            }
            dropdownClassName={"z-[9999] w-full"}
            error={form?.formState?.errors?.[name]?.message}
          />
        )}
      />

      <div className="col-span-full flex items-center justify-end gap-4">
        <Button
          variant="outline"
          onClick={closeModal}
          className="w-auto"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          isLoading={loading || loadingUpdate}
          className="w-auto"
          disabled={!form?.formState?.isDirty}
        >
          {title}
        </Button>
      </div>
    </form>
  );
}
