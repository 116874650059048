import { SelectOption } from "rizzui";

export enum EImportTaskStatus {
  Processing = "processing",
  Success = "success",
  Failed = "failed",
  Pending = "pending",
}

export enum EImportType {
  Project = "project",
  Category = "category",
  UnitType = "unit-type",
  FloorPlan = "floor-plan",
  UnitTransaction = "unit-transaction",
  DirectCommission = "direct-commission",
  Mortgage = "mortgage",
}

export const E_IMPORT_DATA = Object.freeze({
  MAIN: "/import-data",
  PROJECT: "/import-data/project",
  FLOOR_PLAN: "/import-data/floor-plan",
  PROJECT_TYPE: "/import-data/project-type",
  UNIT_TYPE: "/import-data/unit-type",
  UNIT_TRANSACTION_PROJECT: "/import-data/unit-transaction/project",
  UNIT_TRANSACTION: "/import-data/unit-transaction",
  DIRECT_COMMISSION: "/import-data/direct-commission",
  MORTGAGE: "/import-data/mortgage",
  TASK_ID: (id: string = ":id") => `/import-data/${id}`,
});

export const IMPORT_TYPE_LABEL = Object.freeze({
  [EImportType.Category]: "Project type (Category)",
  [EImportType.Project]: "Project Detail",
  [EImportType.UnitType]: "Unit Type",
  [EImportType.FloorPlan]: "Floorplan",
  [EImportType.UnitTransaction]: "Unit Transaction",
  [EImportType.DirectCommission]: "Direct Commission",
  [EImportType.Mortgage]: "Mortgage",
});

export const StatusColors = {
  [EImportTaskStatus.Processing]: "text-sky-600",
  [EImportTaskStatus.Success]: "text-green-700",
  [EImportTaskStatus.Failed]: "text-red-600",
  [EImportTaskStatus.Pending]: "text-black",
};

export const IMPORT_TYPE_OPTIONS: SelectOption[] = [
  { label: "Project type", value: "project-type" },
  { label: "Unit type", value: "unit-type" },
  { label: "Project", value: "project" },
  { label: "Direct commission", value: "direct-commission" },
  // { label: "Floorplan", value: "floor-plan" },
  {
    label: "Unit transaction",
    value: "unit-transaction",
  },
  {
    label: "Unit transaction (with project)",
    value: "unit-transaction/project",
  },
  { label: "Mortgage", value: "mortgage" },
];
