"use client";

import { useEffect, useState } from "react";
import { PiXBold } from "react-icons/pi";
import { Controller, SubmitHandler, UseFormReturn } from "react-hook-form";
import { Form } from "@/components/ui/form";
import { Input, Button, ActionIcon, Title } from "rizzui";
import { useModal } from "@/app/shared/modal-views/use-modal";
import { Path, useNavigate } from "react-router-dom";
import UserRepository from "@/utilities/repositories/Users";
import { IUserData } from "@/utilities/types/Users";
import toast from "react-hot-toast";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import { filterChangedFormFields } from "@/utils/form";
import {
  EditUserSchema,
  editUserSchema,
} from "@/utils/validators/edit-user.schema";
import useProfile from "@/hooks/use-profile";
import SingleImageUpload from "@/components/ui/file-upload/single-image-upload.tsx";
import { backPrev } from "@/utilities/functions";
import DomainInput from "@/components/ui/domain-input";
import SettingsRepository from "@/utilities/repositories/Settings";
import SelectDomain, {
  SelectOptionType,
} from "@/app/app-shared/user/users-table/select-domain.tsx";
import { IDomainDTO, TAgencySetting } from "@/utilities/types/Setting.ts";
export default function EditUser({
  id,
  title,
  backTo,
}: {
  id: string;
  title: string;
  backTo?: string;
}) {
  const { closeModal, isOpen } = useModal();
  const [reset, setReset] = useState<{
    firstName: string;
    lastName: string;
    password: string;
    email: string;
    phone: string;
    photo: { url: string } | null;
    photoId: string;
    measuringId?: string | null;
    domain?: string | null;
  }>({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    phone: "",
    photo: null,
    photoId: "",
    measuringId: "",
  });

  const [mapOptions, setMapOptions] = useState<SelectOptionType[]>([]);
  const [defaultOptions, setDefaultOptions] = useState({});
  const [configID, setConfigID] = useState<IDomainDTO[]>();
  const navigate = useNavigate();

  const refreshPage = () => {
    window.location.reload();
  };

  const { profile, setProfile } = useProfile();

  const isMyProfile = profile?.id === id;

  const [isLoading, setIsLoading] = useState(false);

  const [form, setForm] =
    useState<UseFormReturn<EditUserSchema, any, undefined>>();
  const handleBack = () => {
    if (!backTo) return;
    navigate(backPrev(backTo));
    closeModal?.();
  };

  const { request: findUserDetail, response } = useApi<
    IResponseData<IUserData>
  >({
    request: UserRepository.getUserById,
  });

  const { request: findMeDetails } = useApi<IResponseData<IUserData>>({
    request: UserRepository.getMe,
  });

  const updateUser = async (dataForm: EditUserSchema) => {
    if (!id || !form?.formState.dirtyFields) return;

    const fieldChange = filterChangedFormFields(
      dataForm,
      form?.formState.dirtyFields
    );

    const { domain, ...userData } = fieldChange;

    try {
      setIsLoading(true);
      await UserRepository.updateUser(id, {
        ...userData,
      });

      if (domain && response?.data?.config?.id) {
        await SettingsRepository.createDomain({
          configId: response?.data?.config?.id,
          name: domain || "",
          primary: true,
        });
      }
      toast.success(`Successfully`);
      setTimeout(() => {
        setIsLoading(false);

        if (backTo) {
          setReset({
            firstName: "",
            lastName: "",
            password: "",
            email: "",
            phone: "",
            photo: null,
            photoId: "",
          });
        }

        if (isMyProfile && profile) {
          setProfile({
            ...profile,
            ...form.getValues(),
          } as IUserData);
        }

        handleBack();
      }, 600);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
      setIsLoading(false);
    }
  };

  const updateMe = async (dataForm: EditUserSchema) => {
    try {
      setIsLoading(true);
      const dataSubmit = {
        email: dataForm.email,
        firstName: dataForm.firstName,
        lastName: dataForm.lastName,
        photoId: null,
        measuringId: dataForm.measuringId,
      };
      if (dataForm.photo) {
        // @ts-ignore
        dataSubmit.photoId = dataForm.photo.id;
      }
      const response = await UserRepository.updateMe({
        ...dataSubmit,
      });
      toast.success(`Successfully`);
      setIsLoading(false);

      if (backTo) {
        setReset({
          firstName: "",
          lastName: "",
          password: "",
          email: "",
          phone: "",
          photo: null,
          photoId: "",
        });
      }

      if (isMyProfile && profile) {
        const profileData = {
          ...profile,
          ...response?.data,
        };
        setProfile({
          ...profileData,
        });
      }

      handleBack();
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
      setIsLoading(false);
    }
  };
  const onSubmit: SubmitHandler<EditUserSchema> = (data) => {
    if (!isMyProfile) {
      updateUser(data);
    } else if (isMyProfile) {
      updateMe(data);
    }
  };

  const setOptionsSelect = (config: TAgencySetting) => {
    if (config) {
      const primaryDomain = config?.domains?.find((item) => !!item.primary);
      primaryDomain &&
        setDefaultOptions({
          primary: primaryDomain.primary,
          value: primaryDomain.id,
          label: `${primaryDomain.name}`,
          configID: primaryDomain.configId,
        });
      if (config?.domains) {
        setConfigID(config.domains);
        const mapOptionsArr = config.domains.map((item) => {
          return {
            primary: item.primary,
            value: item.id,
            label: item.name,
            configID: item.configId,
          };
        });
        setMapOptions(mapOptionsArr);
      }
    }
  };

  useEffect(() => {
    if (!id) return;
    if (isMyProfile) {
      findMeDetails().then((res) => {
        if (!res?.data) return;
        const { firstName, lastName, email, photo, photoId, config } = res.data;
        let photoArgs = {
          width: 0,
          url: "",
        };
        if (photo?.urls?.[0]) {
          photoArgs = photo?.urls?.[0];
        }
        if (config) setOptionsSelect(config);

        // @ts-ignore
        setReset({
          firstName,
          lastName,
          password: "",
          email,
          phone: "",
          photo: { url: photoArgs?.url || "" },
          photoId: photoId || "",
        });
      });
    } else {
      findUserDetail(id).then((res) => {
        if (!res?.data) return;
        const { firstName, lastName, email, measuringId, config } = res.data;

        if (config) setOptionsSelect(config);

        setReset({
          firstName,
          lastName,
          password: "",
          email,
          phone: "",
          photo: null,
          photoId: "",
          measuringId,
        });
      });
    }
  }, []);

  return (
    <Form<EditUserSchema>
      resetValues={reset}
      onSubmit={onSubmit}
      validationSchema={editUserSchema}
      onInitForm={(methods) => {
        setForm(methods);
      }}
      className="grid grid-cols-1 h-full gap-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
    >
      {({
        register,
        control,
        watch,
        setError,
        formState: { errors, dirtyFields },
      }) => {
        return (
          <div className="col-span-full flex flex-col gap-4">
            <div className="mt-4 flex items-center justify-between">
              <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
                {title}
              </Title>
              {isOpen && (
                <ActionIcon size="sm" variant="text" onClick={closeModal}>
                  <PiXBold className="h-auto w-5" />
                </ActionIcon>
              )}
            </div>
            <div className="flex-1 flex-col gap-3 flex">
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState, formState }) => (
                  <Input
                    label="Email"
                    size="lg"
                    placeholder="Enter user's Email Address"
                    className="col-span-full"
                    {...field}
                    {...formState}
                    value={field.value}
                    error={fieldState.error?.message}
                    disabled
                  />
                )}
              />

              <Input
                label="First Name"
                size="lg"
                placeholder="Enter user's first name"
                {...register("firstName")}
                className="[&>label>span]:font-medium "
                error={errors.firstName?.message}
              />
              <Input
                label="Last Name"
                size="lg"
                placeholder="Enter user's last name"
                {...register("lastName")}
                className="[&>label>span]:font-medium "
                error={errors.lastName?.message}
              />

              {!isMyProfile && mapOptions.length > 0 && (
                <Input
                  label="Measuring ID"
                  size="lg"
                  placeholder="G-XXXX"
                  {...register("measuringId")}
                  className="[&>label>span]:font-medium "
                  error={errors.lastName?.message}
                />
              )}

              {!isMyProfile && (
                <>
                  <p>Custom Domain</p>
                  <SelectDomain
                    options={mapOptions}
                    defaultOptions={defaultOptions}
                    configID={configID}
                    reset={() => refreshPage()}
                  />
                </>
              )}

              {isMyProfile && (
                <Controller
                  control={control}
                  name="photo"
                  render={({ field: { onChange } }) => (
                    <SingleImageUpload
                      imageUrl={watch("photo")?.url || ""}
                      title="Avatar"
                      setValue={onChange}
                      uploaderText="Upload Avatar photo"
                      uploadContainerClass="w-full h-fit md:w-1/3"
                      imageContainerClassName="rounded-full w-[120px] h-[120px] p-0"
                      imageClassName="rounded-full w-[120px] h-[120px] p-0 object-cover"
                      toolbarContainerClassName="top-[20px] end-[10px] [&>div]:-translate-x-[10px]"
                      dropZoneClassName="w-[120px] h-[120px] rounded-full"
                      uploadDropzoneContainerClass="w-fit"
                    />
                  )}
                />
              )}
            </div>

            <div className="sticky bottom-0  py-4 px-2 -mx-4 border-t border-gray-200 bg-white z-10 @lg:gap-4 @xl:grid @xl:auto-cols-max @xl:grid-flow-col col-span-full flex items-center justify-end gap-4">
              {backTo && (
                <Button
                  variant="outline"
                  onClick={handleBack}
                  className="w-full @xl:w-auto"
                >
                  Cancel
                </Button>
              )}
              <Button
                type="submit"
                isLoading={isLoading}
                disabled={!form?.formState.isDirty}
                className="w-full @xl:w-auto"
              >
                {title}
              </Button>
            </div>
          </div>
        );
      }}
    </Form>
  );
}
