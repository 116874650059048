import { request } from "@/utilities/libs/request.ts";
import { ListResponseData } from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { E_ANALYTICS } from "../enums/Analytic";
import {
  ITopProjectAnalytic,
  ITopRangePriceAnalytic,
  IUnitTypeAnalytic,
} from "../types/Analytic";

function getUnitTypeAnalytics(startDate: string, endDate: string) {
  const query = {
    startDate,
    endDate,
  };

  return request<ListResponseData<IUnitTypeAnalytic>>({
    method: ERequestMethods.GET,
    url: E_ANALYTICS.UNIT_TYPE,
    params: query,
  });
}

function getTopRangePriceAnalytics(startDate: string, endDate: string) {
  const query = {
    startDate,
    endDate,
  };

  return request<ListResponseData<ITopRangePriceAnalytic>>({
    method: ERequestMethods.GET,
    url: E_ANALYTICS.TOP_RANGE_PRICE,
    params: query,
  });
}

function getProjectAnalytics(startDate: string, endDate: string) {
  const query = {
    startDate,
    endDate,
    sortBy: "entriesCount",
    sort: "DESC",
  };

  return request<ListResponseData<ITopProjectAnalytic>>({
    method: ERequestMethods.GET,
    url: E_ANALYTICS.TOP_PROJECT,
    params: query,
  });
}

function getUnitTypeAnalyticsAgent(startDate: string, endDate: string) {
  const query = {
    startDate,
    endDate,
  };

  return request<ListResponseData<IUnitTypeAnalytic>>({
    method: ERequestMethods.GET,
    url: E_ANALYTICS.UNIT_TYPE_AGENT,
    params: query,
  });
}

function getProjectAnalyticsAgent(startDate: string, endDate: string) {
  const query = {
    startDate,
    endDate,
    sortBy: "entriesCount",
    sort: "DESC",
  };

  return request<ListResponseData<ITopProjectAnalytic>>({
    method: ERequestMethods.GET,
    url: E_ANALYTICS.TOP_PROJECT_AGENT,
    params: query,
  });
}

const AnalyticsRepository = Object.freeze({
  getUnitTypeAnalytics,
  getTopRangePriceAnalytics,
  getProjectAnalytics,
  getUnitTypeAnalyticsAgent,
  getProjectAnalyticsAgent,
});

export default AnalyticsRepository;
