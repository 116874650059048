import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import {
  IUnitTransaction,
  IUnitTransactionDTO,
} from "../types/UnitTransaction";
import { E_UNIT_TRANSACTION } from "../enums/UnitTransaction";

function getAllUnitTransactions({ queryParams }: IRequestArgs) {
  const query = {
    projectSlug: queryParams?.query?.filter?.[0]?.projectSlug || "",
    sortBy: "createdAt",
    sort: "DESC",
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<IUnitTransaction>>({
    method: ERequestMethods.GET,
    url: E_UNIT_TRANSACTION.MAIN,
    params: query,
  });
}

function deleteUnitTransaction(id: string) {
  return request<IResponseData<IUnitTransaction>>({
    method: ERequestMethods.DELETE,
    url: E_UNIT_TRANSACTION.UNIT_TRANSACTION_ID(id),
  });
}

function createUnitTransaction(data: IUnitTransactionDTO) {
  return request<IResponseData<IUnitTransaction>>({
    method: ERequestMethods.POST,
    url: E_UNIT_TRANSACTION.MAIN,
    data,
  });
}

function updateUnitTransaction(id: string, data: Partial<IUnitTransactionDTO>) {
  return request<IResponseData<IUnitTransaction>>({
    method: ERequestMethods.PATCH,
    url: E_UNIT_TRANSACTION.UNIT_TRANSACTION_ID(id),
    data,
  });
}

const UnitTransactionRepository = Object.freeze({
  getAllUnitTransactions,
  deleteUnitTransaction,
  createUnitTransaction,
  updateUnitTransaction,
});

export default UnitTransactionRepository;
