import { SortableList } from "@/components/dnd-sortable/dnd-sortable-list";
import { IProjectLocale } from "@/utilities/types/Project";
import cn from "@/utils/class-names";
import * as React from "react";
import { Empty } from "rizzui";
import { IoMdClose } from "react-icons/io";
import { SelectedProject } from "@/utilities/types/SectionSettings";
export interface OrderProjectProps {
  projectSelecteds: SelectedProject[]; // TODO: type this
  isUpdating: boolean;
  maxNumOfProject: number;
  onRemoveSelected: (project: IProjectLocale) => void;
  handleOrderProject(values: { item: SelectedProject; newIndex: number }): void;
}

function OrderProject({
  projectSelecteds,
  onRemoveSelected,
  handleOrderProject,
  isUpdating,
  maxNumOfProject,
}: OrderProjectProps) {
  return (
    <SortableList<SelectedProject>
      items={projectSelecteds}
      onChange={handleOrderProject}
    >
      <div className="max-h-[500px] h-full overflow-auto">
        {projectSelecteds.length !== 0 ? (
          projectSelecteds.map((item, index) => (
            <React.Fragment key={"sortable-menu" + index}>
              <SortableList.Item
                className="list-none p-2 border-b border-slate-300 last:border-none"
                id={item.id}
              >
                <div
                  className="relative flex items-center justify-between gap-4"
                  key={item.id}
                >
                  <div className="flex items-center gap-4">
                    {index < 8 && (
                      <div
                        className={cn(
                          "bg-primary text-white flex justify-center items-center size-8 rounded-full",
                          index + 1 > maxNumOfProject && "opacity-50"
                        )}
                      >
                        <span className="h-auto text-base font-semibold">
                          {index + 1}
                        </span>
                      </div>
                    )}
                    <SortableList.DragHandle
                      className={cn(
                        "cursor-grab  h-5 w-5  text-gray-900 transition-all [&>svg]:h-[20px] [&>svg]:w-[20px]",
                        isUpdating && "select-none pointer-events-none"
                      )}
                    />

                    <p>
                      {" "}
                      {typeof item?.project?.name === "object"
                        ? item?.project?.name?.en
                        : item?.project?.name}
                    </p>
                  </div>
                  <button
                    disabled={isUpdating}
                    onClick={() => onRemoveSelected(item?.project)}
                  >
                    <IoMdClose className="w-5 h-5" />
                  </button>
                </div>
              </SortableList.Item>
            </React.Fragment>
          ))
        ) : (
          <Empty
            alignment="center"
            text="No projects selected"
            textClassName="mt-2 text-gray-400"
          />
        )}
      </div>
    </SortableList>
  );
}

export default React.memo(OrderProject);
