import { Title } from "rizzui";
import cn from "@/utils/class-names";
import { ForwardedRef, forwardRef } from "react";

const widgetCardClasses = {
  base: "border border-muted bg-gray-0 p-5 dark:bg-gray-50 lg:p-7",
  rounded: {
    sm: "rounded-sm",
    DEFAULT: "rounded-lg",
    lg: "rounded-xl",
    xl: "rounded-2xl",
  },
};

type WidgetCardTypes = {
  title: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
  rounded?: keyof typeof widgetCardClasses.rounded;
  headerClassName?: string;
  titleClassName?: string;
  actionClassName?: string;
  headingClassName?: string;
  descriptionClassName?: string;
  className?: string;
};

function WidgetCard(
  {
    title,
    action,
    description,
    rounded = "DEFAULT",
    className,
    headerClassName,
    actionClassName,
    titleClassName,
    headingClassName,
    descriptionClassName,
    children,
  }: React.PropsWithChildren<WidgetCardTypes>,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      className={cn(
        widgetCardClasses.base,
        widgetCardClasses.rounded[rounded],
        className
      )}
      ref={ref}
    >
      <div
        className={cn(
          action &&
            "flex md:flex-row gap-2 flex-col items-start justify-between",
          headerClassName
        )}
      >
        <div
          className={cn(
            "flex md:flex-col gap-2 w-full justify-between md:flex-start md:w-fit flex-row",
            headingClassName
          )}
        >
          <Title
            as="h3"
            className={cn(
              "text-base font-semibold truncate max-w-full sm:text-lg",
              titleClassName
            )}
          >
            {title}
          </Title>
          {description && (
            <div className={descriptionClassName}>
              <Title
                as="h4"
                className="font-semibold whitespace-nowrap md:text-xl text-md"
              >
                {description}
              </Title>
            </div>
          )}
        </div>
        {action && <div className={actionClassName}>{action}</div>}
      </div>
      {children}
    </div>
  );
}

export default forwardRef(WidgetCard);
WidgetCard.displayName = "WidgetCard";
