import { Enum } from "./Enum";

export const E_LEAD_SCORE_TYPE = Object.freeze({
  HOT: "hot",
  WARM: "warm",
  COLD: "cold",
  AGENT_DUPLICATE: "AgentDuplicate",
});

export type E_LEAD_SCORE_TYPE = Enum<typeof E_LEAD_SCORE_TYPE>;
