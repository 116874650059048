import { z } from "zod";

// form zod validation schema
export const importDataSchema = z
  .object({
    file: z.any().refine((file) => !!file, `Required`),
    importType: z.object({
      label: z.string(),
      value: z.enum([
        "project-type",
        "unit-type",
        "project",
        "direct-commission",
        "floor-plan",
        "unit-transaction",
        "unit-transaction/project",
        "mortgage",
      ]),
    }),
    project: z
      .object({
        label: z.string(),
        value: z.string(),
      })
      .optional(),
  })
  .refine(
    (value) =>
      value.importType?.value !== "unit-transaction/project" ||
      !!value.project?.value,
    { path: ["project"], message: "Project is required" }
  );

// generate form types from zod validation schema
export type ImportDataSchema = z.infer<typeof importDataSchema>;
