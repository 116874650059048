import { ESectionLayout } from "@/utilities/enums/SectionLayouts";
import React, { forwardRef, memo, useEffect, useRef, useState } from "react";
import { Button } from "rizzui";

interface IPreviewParams {
  name?: string;
  layout: ESectionLayout;
  background: string;
  sectionId: string;
  projectCount?: number;
}

interface IframeLayoutPreviewProps {
  previewParams: IPreviewParams;
  baseUrl: string;
}

const IframeLayoutPreview = forwardRef<
  HTMLIFrameElement,
  IframeLayoutPreviewProps
>(({ previewParams, baseUrl }, ref) => {
  const [iframeUrl, setIframeUrl] = useState<string>(baseUrl);
  const [urlError, setUrlError] = useState<string>("");
  const [iframeMinHeight, setIframeMinHeight] = useState<number>(300);
  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const updateIframeUrl = (queryParams: IPreviewParams) => {
    if (!isValidUrl(baseUrl)) {
      setUrlError("Invalid URL");
      return;
    }

    const url = new URL(baseUrl);
    const params = new URLSearchParams();

    Object.entries(queryParams).forEach(([key, value]) => {
      params.append(key, value);
    });

    url.search = params.toString();
    setIframeUrl(url.toString());
    setUrlError(""); // Clear any previous errors
  };

  useEffect(() => {
    updateIframeUrl(previewParams);
  }, [previewParams, baseUrl]);

  const handleClick = (url: string) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    // TODO: Create a service to handle message from iframe
    const handleMessage = (event: MessageEvent) => {
      // Check the message type and origin (optional)

      if (event.data.type === "onGetElementRect") {
        setIframeMinHeight(event.data?.elementRect?.height);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <div
      className={
        "flex flex-col gap-2 items-center justify-center w-full h-full relative min-h-[500px]"
      }
    >
      {urlError && <p style={{ color: "red" }}>{urlError}</p>}
      <iframe
        src={iframeUrl}
        width="100%"
        height="100%"
        title="Layout Preview"
        allow="fullscreen"
        ref={ref}
        style={{ minHeight: iframeMinHeight + 50 }}
      />
      <Button variant="outline" onClick={() => handleClick(iframeUrl)}>
        Preview
      </Button>
    </div>
  );
});

IframeLayoutPreview.displayName = "IframeLayoutPreview";

export default memo(IframeLayoutPreview);
