"use client";

import React from "react";
import dynamic from "next/dynamic";
import isEmpty from "lodash/isEmpty";
import Table, { type TableProps } from "@/components/ui/table";
import { Title, Loader } from "rizzui";
import cn from "@/utils/class-names";
import type { TableFilterProps } from "@/components/controlled-table/table-filter";
import type { TablePaginationProps } from "@/components/controlled-table/table-pagination";
import { ColumnsType } from "rc-table";
import { useMedia } from "@/hooks/use-media";
const TableFilter = dynamic(
  () => import("@/components/controlled-table/table-filter"),
  { ssr: false }
);
const TablePagination = dynamic(
  () => import("@/components/controlled-table/table-pagination"),
  { ssr: false }
);

type ControlledTableProps<T> = Omit<TableProps, "columns"> & {
  isLoading?: boolean;
  showLoadingText?: boolean;
  filterElement?: React.ReactElement;
  filterOptions?: TableFilterProps;
  paginatorOptions?: TablePaginationProps;
  tableFooter?: React.ReactNode;
  className?: string;
  onRow?: (record: T, rowIndex: number) => void;
  paginatorClassName?: string;
  columns: ColumnsType<T>;
};

export default function ControlledTable<T>({
  isLoading,
  filterElement,
  filterOptions,
  paginatorOptions,
  tableFooter,
  showLoadingText,
  paginatorClassName,
  onRow,
  className,
  ...tableProps
}: ControlledTableProps<T>) {
  const isMobile = useMedia("(max-width: 768px)", false);

  return (
    <>
      {!isEmpty(filterOptions) && (
        <TableFilter {...filterOptions}>{filterElement}</TableFilter>
      )}
      <div className="relative">
        {isLoading && (
          <div className="grid z-50 absolute bg-black/[0.05] pointer-events-none h-full left-0  w-full flex-grow place-content-center items-center justify-center">
            <Loader variant="spinner" size="xl" />
            {showLoadingText ? (
              <Title as="h6" className="-me-2 mt-4 font-medium text-gray-500">
                Loading...
              </Title>
            ) : null}
          </div>
        )}
        <Table
          scroll={{ x: isMobile ? 1200 : "100%" }}
          rowKey={(record) => record.id}
          onRow={onRow}
          className={cn(className)}
          {...tableProps}
        />

        {tableFooter ? tableFooter : null}
      </div>
      {!isEmpty(paginatorOptions) && (
        <TablePagination
          paginatorClassName={paginatorClassName}
          {...paginatorOptions}
        />
      )}
    </>
  );
}
