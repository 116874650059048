import useProfile from "@/hooks/use-profile";
import ProjectRepository from "@/utilities/repositories/Project";

import { Roles } from "@/utilities/types/Users";
import { useEffect, useState, forwardRef } from "react";
import { Input, Select, SelectOption, SelectProps } from "rizzui";

type Props = Omit<SelectProps<SelectOption>, "options"> & {
  value?: SelectOption;
  user?: string;
};

const ProjectSelect = forwardRef<HTMLDivElement, Props>(
  ({ onChange, user, value = "", ...props }, ref) => {
    // Default value for "value"
    const [options, setOptions] = useState<Array<SelectOption>>([]);
    const { profile } = useProfile();
    const [search, setSearch] = useState("");

    let agentId = profile?.role !== Roles.ADMIN ? profile?.id : undefined;
    agentId = user ? user : agentId;

    useEffect(() => {
      const fetchOptions = async () => {
        let totalPage = 1;
        let currPage = 1;
        let arr: SelectOption[] = [];

        do {
          const res = agentId
            ? await ProjectRepository.getAgentProject({
                queryParams: {
                  pagination: {
                    limit: 50,
                    page: currPage,
                  },
                },
              })
            : await ProjectRepository.getProject({
                queryParams: {
                  pagination: {
                    limit: 50,
                    page: currPage,
                  },
                },
              });

          currPage = res.data.currentPage + 1;
          totalPage = res.data.totalPage;
          arr = arr.concat(
            res.data.items.map((item) => ({
              label:
                typeof item.name === "string"
                  ? item.name
                  : item.name?.en || "Unknown",
              value: item.id,
            }))
          );
        } while (currPage <= totalPage);

        setOptions(arr);
      };

      fetchOptions();
    }, [agentId]);

    const renderSearchInput = (option: SelectOption) => {
      if (option.value !== "search") {
        return option.label;
      }
      return (
        <div className="w-full p-2">
          <Input
            type="search"
            placeholder="Search by name project"
            value={search}
            onClear={() => setSearch("")}
            onChange={(event) => setSearch(event.target.value)}
            rounded="lg"
            clearable
            className="w-full"
          />
        </div>
      );
    };

    return (
      <div ref={ref}>
        {" "}
        {/* Attach the ref to the wrapper div */}
        <Select
          {...props}
          options={[
            { label: "Search", value: "search", disabled: true },
            ...options
              .filter((option) =>
                option.label.toLowerCase().includes(search.toLowerCase())
              )
              .sort((a, b) => {
                return a.label.localeCompare(b.label);
              }),
          ]}
          onChange={(e) => {
            onChange?.(e);
            setSearch("");
          }}
          optionClassName="first:bg-white first:p-0 first:sticky first:top-[-6px] first:[&>div]:w-full"
          getOptionDisplayValue={renderSearchInput}
          getOptionValue={(option) => option}
          displayValue={(selected: SelectOption) =>
            options?.find((r) => r.value === selected?.value)?.label ??
            props.placeholder
          }
          value={value}
        />
      </div>
    );
  }
);

ProjectSelect.displayName = "ProjectSelect";

export default ProjectSelect;
