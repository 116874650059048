import { EMarketSegment, ETenure } from "../types/Project";
import { Enum } from "./Enum";

export const E_PROJECTS = Object.freeze({
  MAIN: "/project",
  PROJECT_ID: (id: string) => `/project/${id}`,
  USER_PROJECT: "/user-project",
  USER_PROJECT_MANY: "/user-project/many",
  USER_PROJECT_ID: (id: string) => `/user-project/${id}`,
});

export type E_PROJECTS = Enum<typeof E_PROJECTS>;

type EnumOptions<T> = {
  label: string;
  value: T;
};

export const marketSegmentOptions: EnumOptions<EMarketSegment>[] = [
  {
    value: EMarketSegment.RCR,
    label: "RCR",
  },
  {
    value: EMarketSegment.OCR,
    label: "OCR",
  },
  {
    value: EMarketSegment.CCR,
    label: "CCR",
  },
];

export const tenureOptions: EnumOptions<ETenure>[] = [
  {
    value: ETenure.Freehold,
    label: "Freehold",
  },
  {
    value: ETenure.Yr99,
    label: "99-yr",
  },
  {
    value: ETenure.Yr101,
    label: "101-yr",
  },
  {
    value: ETenure.Yr999,
    label: "999-yr",
  },
];
