import React, { ChangeEvent, useEffect, useState } from "react";
import { Checkbox, CheckboxProps } from "rizzui";
interface CustomCheckbox extends CheckboxProps {
  defaultChecked: boolean;
  onChangeValue: (
    e: ChangeEvent<HTMLInputElement>,
    onSuccess: () => void
  ) => void;
}
const CustomCheckbox: React.FC<CustomCheckbox> = ({
  defaultChecked,
  disabled,
  onChangeValue,
  ...rest
}) => {
  const [checked, setChecked] = useState<boolean>(defaultChecked);
  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);
  return (
    <Checkbox
      {...rest}
      className="m-1"
      rounded="sm"
      checked={checked}
      disabled={disabled}
      onChange={(e) => {
        onChangeValue(e, () => setChecked(!checked));
      }}
    />
  );
};

export default CustomCheckbox;
