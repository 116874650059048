// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { ELangCode } from "@/utilities/enums/Language";
import { LANGUAGE_KEY } from "@/config/constants";
import en from "@/locales/en/translation.json";
import zht from "@/locales/en/translation.json";
import zhs from "@/locales/en/translation.json";

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: "customDetector",
  lookup() {
    return localStorage.getItem(LANGUAGE_KEY) || ELangCode["EN"];
  },
  cacheUserLanguage(lng) {
    localStorage.setItem(LANGUAGE_KEY, lng);
  },
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      zhs: {
        translation: zhs,
      },
      zht: {
        translation: zht,
      },
    },
    fallbackLng: "en",
    lng: "en", // default language
    debug: true,
    detection: {
      order: ["customDetector", "navigator"],
      caches: ["localStorage"],
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
