import useApi from "@/hooks/useApi";

import {
  EmailTemplateInput,
  emailTemplateSchema,
} from "@/utils/validators/email-template.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Input, Title } from "rizzui";
import { EMAIL_TEMPLATE_FIELDS } from "./configs";
import EmailTemplateRepository from "@/utilities/repositories/EmailTemplate";
import QuillEditor from "@/components/ui/quille-editor/quill-editor";
import { EEmailTemplateKeys } from "@/utilities/enums/EmailTemplate";

interface ICreateEditEmailTemplateProps {
  templateKey: EEmailTemplateKeys;
}

export default function CreateEditEmailTemplate({
  templateKey,
}: ICreateEditEmailTemplateProps) {
  const title = `Edit Email Template`;
  const form = useForm({
    defaultValues: {
      body: "",
      subject: "",
      name: templateKey,
    },
    resolver: zodResolver(emailTemplateSchema),
  });

  const { loading: updating, request: updateEmailTemplate } = useApi({
    request: EmailTemplateRepository.updateEmailTemplate,
  });

  const { loading: creating, request: createEmailTemplate } = useApi({
    request: EmailTemplateRepository.createEmailTemplate,
  });

  const {
    loading: loading,
    request: getEmailTemplate,
    response,
  } = useApi({
    request: EmailTemplateRepository.getEmailTemplate,
  });

  const onSubmit = async (formData: EmailTemplateInput) => {
    const templateByKey = response?.data?.find(
      (item) => item.name === templateKey
    );

    try {
      if (templateByKey?.id) {
        await updateEmailTemplate(
          {
            ...formData,
          },
          templateByKey?.id
        );
      } else {
        await createEmailTemplate({
          ...formData,
          name: templateKey,
        });
      }
      toast.success("Successfully");
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getEmailTemplate({
      name: templateKey,
    }).then((res) => {
      const template = res?.data?.[0];
      if (template) {
        form.reset({
          ...template,
        });
      }
    });
  }, []);

  return (
    <div className="mt-4">
      <div className="@container px-6 col-span-full flex items-center justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          {title}
        </Title>
      </div>
      <form
        noValidate
        className="grid grid-cols-1 gap-6 px-6 py-4 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Input
          label="Subject"
          placeholder="Subject"
          // value={row?.name?.en || ''}
          {...form.register("subject")}
          error={form?.formState?.errors.subject?.message}
          className="col-span-full"
        />
        <Controller
          name="body"
          control={form.control}
          render={({ field, fieldState, formState }) => (
            <QuillEditor
              value={field?.value}
              onChange={field?.onChange}
              label="Message"
              className="col-span-full [&_.ql-editor]:min-h-[200px]"
              labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
              error={form?.formState?.errors?.body?.message}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  // ["blockquote", "code-block"],
                  ["link"],
                ],
              }}
            />
          )}
        />

        <div className="col-span-full">
          <div className="flex flex-col gap-2">
            <div className="rizzui-textarea-label block text-[16px] font-bold">
              Note:
            </div>
            <div className="rizzui-textarea-label text-sm font-medium">
              Please enclose the field names in double curly bracket syntax{" "}
              {"{{ }}"}. The code will only recognize the following fields on
              this UI. For example:
              <strong> {"{{name}}"}</strong>
            </div>

            {Object.entries(EMAIL_TEMPLATE_FIELDS).map(
              ([field, value]: [string, string]) => {
                return (
                  <div className="flex gap-2">
                    <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                      {value}
                    </div>
                    <div className="rizzui-textarea-label text-sm font-medium">
                      {field}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>

        <div className="col-span-full flex items-center justify-end gap-4">
          <Button
            type="submit"
            isLoading={loading || updating || creating}
            className="md:w-auto w-full"
            disabled={!form?.formState?.isDirty}
          >
            {title}
          </Button>
        </div>
      </form>
    </div>
  );
}
