import { OTPInput } from "input-otp";
import { FakeDash, Slot } from "../otp-input";
import { Controller, useForm } from "react-hook-form";
import { Button, Text } from "rizzui";
import { PiArrowRightBold } from "react-icons/pi";
import { LuAlarmClock } from "react-icons/lu";
import { useLocation } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";
import { STORAGE_KEYS } from "@/config/auth";
import { useCountdown } from "@/hooks/use-countdown";
import { useAuthHelper } from "./helper";
import cn from "@/utils/class-names";
import { useState } from "react";

export interface AuthVerifyOtpFormProps {}

export default function AuthVerifyOtpForm({}: AuthVerifyOtpFormProps) {
  const form = useForm<{ otp: string }>();
  const [otpExpires, setOtpExpires] = useSessionStorage<null | string>(
    STORAGE_KEYS.OTP_EXPIRED,
    null
  );

  const [countReSendOtp, setCountReSendOtp] = useState(0);

  const { sendOtp, verifyOtpLogin, isLoading } = useAuthHelper();

  const { state } = useLocation();

  const timer = useCountdown(otpExpires || "");

  const onSubmit = async (values: { otp: string }) => {
    try {
      await verifyOtpLogin({
        otp: values.otp,
        email: state?.data?.email,
      });
      setOtpExpires(null);
    } catch (error) {}
  };

  const isDisabled = (form.watch("otp")?.length || 0) < 6 || isLoading;

  // Enable resend button only when timer reaches 00:00
  const disabledResend =
    !(timer?.minutes === 0 && timer?.seconds === 0) || isLoading;

  return (
    <form
      className="flex flex-col text-center md:max-w-md mx-auto justify-center gap-6"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Controller
        name="otp"
        control={form.control}
        render={({ field }) => {
          return (
            <OTPInput
              maxLength={6}
              containerClassName="group w-full flex-1 justify-center flex items-center has-[:disabled]:opacity-30"
              render={({ slots }) => (
                <>
                  <div className="flex">
                    {slots.slice(0, 3).map((slot, idx) => (
                      <Slot key={idx} {...slot} />
                    ))}
                  </div>

                  <FakeDash />

                  <div className="flex">
                    {slots.slice(3).map((slot, idx) => (
                      <Slot key={idx} {...slot} />
                    ))}
                  </div>
                </>
              )}
              onChange={field.onChange}
            />
          );
        }}
      />

      <div className="flex flex-col justify-center">
        {timer && (
          <div className="flex flex-row gap-x-2 items-center justify-center">
            <LuAlarmClock size={20} />
            <Text className="min-w-[40px]" as="p">
              {timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes}:
              {timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}
            </Text>
          </div>
        )}
        <Text className="text-center leading-loose text-gray-500">
          Didn’t receive the code?
          <span className={cn(disabledResend && "cursor-not-allowed")}>
            <Button
              onClick={() => {
                if (!disabledResend) {
                  setCountReSendOtp(countReSendOtp + 1);
                  sendOtp(state.data);
                }
              }}
              type="button"
              as="button"
              variant="text"
              className={cn(
                "font-semibold underline text-gray-700 transition-colors bg-transparent hover:text-blue pl-1",
                disabledResend && "pointer-events-none opacity-50"
              )}
              disabled={disabledResend} // Disable button when timer is running
            >
              Resend OTP
            </Button>
          </span>
        </Text>
      </div>

      <Button disabled={isDisabled} className="w-full" type="submit" size="md">
        <span>Verify OTP</span>
        <PiArrowRightBold className="ms-2 mt-0.5 h-5 w-5" />
      </Button>
    </form>
  );
}
