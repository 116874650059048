import AuthWrapperFour from "@/app/shared/auth-layout/auth-wrapper-four";
import ForgetPasswordForm from "@/components/auth/forget-password-form";

function ForgetPassword() {
    return (
        <AuthWrapperFour title={<></>}>
            <ForgetPasswordForm />
        </AuthWrapperFour>


    );
}

export default ForgetPassword;