import { isGoogleMapsIframe } from "@/utils/string";
import { z } from "zod";

// form zod validation schema
export const createProjectSchema = z.object({
  name: z.string().min(1, { message: "This field is required" }),
  area: z.number().or(z.string()).optional(),
  tenure: z.string().optional(),
  description: z.string().optional(),
  address: z.string().optional(),
  categoryId: z.string().optional(),
  developerId: z.string().optional(),
  tenureEffectFrom: z.date().optional(),
  expectedTop: z.date().optional(),
  locationId: z.string().optional(),
  marketSegment: z.string().optional(),
  pictures: z
    .array(
      z.object({
        id: z.string().min(1, { message: "This field is required" }),
        size: z.number().or(z.string()).optional(),
        url: z.string().min(1, { message: "This field is required" }),
        name: z.string().optional(),
        type: z.string().min(1, { message: "This field is required" }),
      })
    )
    .optional(),
  medias: z
    .array(
      z.object({
        id: z.string().min(1, { message: "This field is required" }),
        size: z.number().or(z.string()).optional(),
        url: z.string().min(1, { message: "This field is required" }),
        name: z.string().optional(),
        type: z.string().min(1, { message: "This field is required" }),
      })
    )
    .optional(),
  amenityHtml: z.string().optional(),
  facilityHtml: z.string().optional(),
  directCommission: z.number().or(z.string()).optional(),
  googleMapUrl: z.string().optional(),
});

// generate form types from zod validation schema
export type CreateProjectInput = z.infer<typeof createProjectSchema>;
