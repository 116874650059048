import { convertUrl } from "@/utilities/functions";
import { useMemo, useState } from "react";

export interface ImageAppProps extends React.HTMLProps<HTMLImageElement> {
  inApp?: boolean;
  fallback?: string;
  fallbackComponent?: JSX.Element;
}

export default function ImageApp({
  inApp,
  src,
  alt,
  fallback,
  fallbackComponent,
  ...rest
}: ImageAppProps) {
  const [isError, setIsError] = useState(false);

  const url = useMemo(() => {
    if (inApp) return src;
    setIsError(false);
    const newUrl = convertUrl(src);
    if (!newUrl) {
      setIsError(true);
    }
    return newUrl;
  }, [src]);

  if (isError && fallbackComponent) {
    return fallbackComponent;
  }

  return (
    <img
      src={url}
      alt={alt}
      onError={(e) => {
        if (fallback) {
          e.currentTarget.src = fallback;
        }
        setIsError(true);
      }}
      {...rest}
    />
  );
}
