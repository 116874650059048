("use client");

import { useCallback, useMemo } from "react";
import { useColumn } from "@/hooks/use-column";
import ControlledTable from "@/components/controlled-table";
import { useTableServer } from "@/hooks/useTableServer";
import { getColumns } from "./columns";
import { IUserPreference } from "@/utilities/types/UserPreference";
import UserPreferenceRepository from "@/utilities/repositories/UserPreference";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import FilterElement from "./filter-element";

export default function UserPreferencesTable() {
  const { request: removeUserPreference } = useApi<
    IResponseData<IUserPreference>
  >({
    request: UserPreferenceRepository.deleteUserPreference,
    enableToast: true,
  });

  const {
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handlePaginate,
    pageSize,
    handleChangePageSize,
    handleSearch,
    searchTerm,
  } = useTableServer<IUserPreference>({
    server: {
      request: UserPreferenceRepository.getAllUserPreference,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
  });

  const onDeleteItem = useCallback(
    async (id: string) => {
      await removeUserPreference(id);
      handlePaginate(currentPage); // Recall API
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlePaginate, currentPage]
  );

  const columns = useMemo(
    () =>
      getColumns({
        data: tableData,
        onDeleteItem,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(tableData), onDeleteItem]
  );

  const { visibleColumns } = useColumn(columns);
  //TODO: Uncomment filter and test when BE already filter
  return (
    <div className="mt-4">
      <FilterElement
        onSearch={handleSearch}
        searchTerm={searchTerm}
      />

      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={visibleColumns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
}
