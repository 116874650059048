import { ERequestMethods } from "../enums/RequestMethod";
import { E_SETTINGS } from "../enums/Settings";
import { request } from "../libs/request";
import {
  SectionSettingDTO,
  SelectedProject,
  SelectedProjectDTO,
} from "../types/SectionSettings";
import { ISectionConfig } from "../types/Setting";
import { IResponseData, ListResponseData } from "../types/requests";

function getAllSections() {
  return request<ListResponseData<ISectionConfig>>({
    method: ERequestMethods.GET,
    url: E_SETTINGS.SECTION_CONFIG,
    params: {
      limit: 50,
      page: 1,
    },
  });
}

function addSection(data: SectionSettingDTO) {
  return request<IResponseData<ISectionConfig>>({
    method: ERequestMethods.POST,
    url: E_SETTINGS.SECTION_CONFIG,
    data,
  });
}

function updateManySection(data: Partial<SectionSettingDTO>[]) {
  return request<IResponseData<ISectionConfig>>({
    method: ERequestMethods.POST,
    url: E_SETTINGS.SECTION_CONFIG + "/many",
    data: {
      lang: "en", // TODO: multi languages update,
      data,
    },
  });
}

function updateSection({ id, ...restData }: Partial<SectionSettingDTO>) {
  return request<IResponseData<ISectionConfig>>({
    method: ERequestMethods.PATCH,
    url: E_SETTINGS.SECTION_CONFIG + `/${id}`,
    data: restData,
  });
}

function getSelectedProject(sectionId: string) {
  return request<ListResponseData<SelectedProject>>({
    method: ERequestMethods.GET,
    url: E_SETTINGS.SECTION_CONFIG_ID(sectionId) + "/projects",
    params: {
      limit: 50,
      page: 1,
    },
  });
}

function addOrUpdateManyProject(sectionId: string, data: SelectedProjectDTO[]) {
  return request<IResponseData<SelectedProject[]>>({
    method: ERequestMethods.POST,
    url: E_SETTINGS.SECTION_CONFIG_ID(sectionId) + "/projects",
    data: {
      data,
    },
  });
}

function removeSelectedProject(
  sectionId: string,
  data: {
    projectId: string; // For update only,
  }
) {
  return request<IResponseData<SelectedProject>>({
    method: ERequestMethods.DELETE,
    url: E_SETTINGS.SECTION_CONFIG_ID(sectionId) + "/projects",
    data,
  });
}

function removeSection(sectionId: string) {
  return request<IResponseData<ISectionConfig>>({
    method: ERequestMethods.DELETE,
    url: E_SETTINGS.SECTION_CONFIG_ID(sectionId),
  });
}

function onArrangeSection({
  id,
  ...restData
}: {
  id: string;
  newPosition: number;
  beforeId: number;
}) {
  return request<IResponseData<ISectionConfig>>({
    method: ERequestMethods.PATCH,
    url: `${E_SETTINGS.SECTION_CONFIG}/change-weight/${id}`,
    data: restData,
  });
}

function onArrangeProject({
  id,
  sectionId,
  ...restData
}: {
  id: string;
  sectionId: string;
  newPosition: number;
  beforeId?: number;
}) {
  return request<IResponseData<ISectionConfig>>({
    method: ERequestMethods.PATCH,
    url: `${E_SETTINGS.SECTION_CONFIG}/${sectionId}/project/change-weight/${id}`,
    data: restData,
  });
}

const SectionSettingRepository = Object.freeze({
  getAllSections,
  addSection,
  updateSection,
  updateManySection,
  getSelectedProject,
  addOrUpdateManyProject,
  removeSelectedProject,
  removeSection,
  onArrangeSection,
  onArrangeProject,
});

export default SectionSettingRepository;
