import { z } from "zod";

export const SectionConfigSchema = z.object({
  id: z.string().optional(),
  layout: z.string().min(1, { message: "This field is required" }),
  name: z.string().optional(),
  weight: z.number().optional(),
  html: z.coerce.string().optional(),
  background: z.string().min(1, { message: "This field is required" }),
  // .refine(
  //   (value) => {
  //     return (
  //       /^#(?:[0-9a-fA-F]{3}){1,2}(?:[0-9a-fA-F]{2})?$/.test(value) ||
  //       namedColors[value.toLowerCase()]
  //     );
  //   },
  //   {
  //     message:
  //       "Must be a valid hex color (#RRGGBBAA or #RGBA), or named colors",
  //   }
  // ),
  visible: z.boolean().optional(),
  weightTimestamp: z.string().optional(),
  ctaTitle: z.coerce.string().optional(),
  ctaDescription: z.coerce.string().optional(),
  ctaButtonTitle: z.coerce.string().optional(),
  photo: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .optional(),
});

export const FieldArraySectionConfigSchema = z.array(SectionConfigSchema);

// generate form types from zod validation schema

export type SectionConfigInput = z.infer<typeof SectionConfigSchema>;
export type FieldArraySectionConfigInput = z.infer<
  typeof FieldArraySectionConfigSchema
>;
