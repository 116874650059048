import { useMemo, createRef } from "react";

interface TableDataItem {
  id: string;
  [key: string]: any;
}

const useRowRefs = (tableData: TableDataItem[]) => {
  const rowRefs = useMemo(() => {
    return tableData.reduce(
      (acc, item) => {
        acc[item.id] = createRef<HTMLDivElement>();
        return acc;
      },
      {} as { [key: string]: React.RefObject<HTMLDivElement> }
    );
  }, [tableData]);

  return rowRefs;
};

export default useRowRefs;
