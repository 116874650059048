import ControlledTable from "@/components/controlled-table";
import { useTableServer } from "@/hooks/useTableServer";
import UserRepository from "@/utilities/repositories/Users";
import { useCallback, useMemo } from "react";
import { getColumns } from "./columns";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import { IUserDataResponse } from "@/utilities/types/Users";
import FilterElement from "./filter-element";
import toast from "react-hot-toast";
import AuthRepository from "@/utilities/repositories/Auths";
import { SignInResponse } from "@/utilities/types/Auths";
import { useAuthHelper } from "@/components/auth/helper";

export default function UserTable() {
  const { request: deleteUser } = useApi<IResponseData<IUserDataResponse>>({
    request: UserRepository.deleteUser,
    enableToast: true,
  });

  const { request: signInAgent } = useApi<IResponseData<SignInResponse>>({
    request: AuthRepository.adminSignInAgent,
    enableToast: true,
  });

  const onHeaderCellClick = (value: string) => ({
    onClick: () => {
      handleSort(value);
    },
  });

  const { refreshAuth } = useAuthHelper();

  const {
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handlePaginate,
    pageSize,
    handleChangePageSize,
    searchTerm,
    handleSearch,
    sortConfig,
    handleSort,
    handleReset,
    refresh,
  } = useTableServer({
    server: {
      request: UserRepository.getUsers,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
  });

  const onDeleteItem = useCallback(
    async (id: string) => {
      await deleteUser(id);
      handleReset(); // Recall API
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [handleReset]
  );

  const onLoginAccountAgent = async (email: string) => {
    try {
      const response = await signInAgent({
        email,
      });

      if (response?.data) {
        refreshAuth(response?.data);
        window.location.reload();
      }
    } catch (error: any) {
      toast.error(error?.message || "Login failed");
    }
  };

  const columns = useMemo(
    () =>
      getColumns({
        data: tableData,
        sortConfig,
        onHeaderCellClick,
        onDeleteItem,
        onLoginAccountAgent,
        handleReset,
        refresh,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onHeaderCellClick,
      sortConfig.key,
      sortConfig.direction,
      onDeleteItem,
      tableData,
      onLoginAccountAgent,
    ]
  );

  return (
    <div className="mt-4">
      <FilterElement
        onSearch={handleSearch}
        searchTerm={searchTerm}
        variant="users"
      />
      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={columns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
}
