import EditUser from "@/app/shared/roles-permissions/edit-user";
import { routes } from "@/config/routes";
import { backPrev } from "@/utilities/functions";
import { useNavigate, useParams } from "react-router-dom";

export default function EditUserPage() {
  const { id } = useParams();

  const navigate = useNavigate();

  if (!id) {
    navigate(backPrev(routes.user.listing));
    return <></>;
  }

  return <EditUser id={id} title="Edit user" backTo={routes.user.listing} />;
}
