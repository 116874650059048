import { IMessage } from "@/utilities/types/Message.ts";
import { HeaderCell } from "@/components/ui/table.tsx";
import DeletePopover from "@/app/shared/delete-popover.tsx";
import ModalCreateEditMessage from "@/app/app-shared/message/create-edit/create-edit-message.tsx";
import { Textarea } from "rizzui";

type Columns = {
  onDeleteItem?: (id: string) => void;
  onUpdateItem?: (id: string) => Promise<void>;
  reset?: () => void;
  isAgency?: boolean;
};

export const GetColumns = ({ onDeleteItem, reset, isAgency }: Columns) => [
  {
    title: <HeaderCell title="Subject" sortable align="left" />,
    dataIndex: "subject",
    key: "subject",
    render: (subject: IMessage) => {
      return <>{subject}</>;
    },
  },
  {
    title: <HeaderCell title="Content" sortable align="left" />,
    dataIndex: "content",
    key: "content",
    render: (content: IMessage) => {
      let convert = JSON.stringify(content)
      convert = convert.replaceAll("\\n","</br>")
      return <p className={'whitespace-pre-line'} dangerouslySetInnerHTML={{__html:convert}}/>;
    },
  },
  {
    title: (
      <HeaderCell
        className="ps-3"
        title={<span className="whitespace-nowrap">Actions</span>}
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: IMessage) => {
      return (
        <ActionAdmin
          onDeleteItem={() => onDeleteItem?.(row.id)}
          row={row}
          reset={reset}
          isAgency={isAgency}
        />
      );
    },
  },
];

const ActionAdmin = ({
  onDeleteItem,
  row,
  reset,
  isAgency,
}: {
  onDeleteItem: () => void;
  reset?: () => void;
  row: IMessage;
  isAgency?: boolean;
}) => {
  return (
    <>
      <div className={"flex items-center justify-center gap-2"}>
        <ModalCreateEditMessage
          row={row}
          reset={reset}
          isAgency={isAgency}
          canEdit={(isAgency && !row?.isAdmin) || !isAgency}
        />
        <DeletePopover
          title={`Delete message template`}
          description={`Are you sure to delete message template?`}
          onDelete={onDeleteItem}
        />
      </div>
    </>
  );
};
