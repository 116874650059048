import { request } from "@/utilities/libs/request.ts";
import { IResponseData } from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { ISiteContent, ISiteContentDTO } from "../types/SiteContent";
import { E_SITE_CONTENT, ESiteContentType } from "../enums/SiteContent";

function getAllSiteContent() {
  return request<IResponseData<ISiteContent[]>>({
    method: ERequestMethods.GET,
    url: E_SITE_CONTENT.MAIN,
  });
}

function getSiteContentById(id: string) {
  return request<IResponseData<ISiteContent>>({
    method: ERequestMethods.GET,
    url: E_SITE_CONTENT.SITE_CONTENT_ID(id),
  });
}

function deleteSiteContent(id: string) {
  return request<IResponseData<ISiteContent>>({
    method: ERequestMethods.DELETE,
    url: E_SITE_CONTENT.SITE_CONTENT_ID(id),
  });
}

function createSiteContent(data: ISiteContentDTO) {
  return request<IResponseData<ISiteContent>>({
    method: ERequestMethods.POST,
    url: E_SITE_CONTENT.MAIN,
    data,
  });
}

function updateSiteContent(id: string, data: ISiteContentDTO) {
  return request<IResponseData<ISiteContent>>({
    method: ERequestMethods.PATCH,
    url: E_SITE_CONTENT.SITE_CONTENT_ID(id),
    data,
  });
}

function adminGetSiteContentBySiteType(type: ESiteContentType) {
  return request<IResponseData<ISiteContent>>({
    method: ERequestMethods.GET,
    url: E_SITE_CONTENT.ADMIN + `/${type}`,
  });
}

function adminCreateSiteContent(data: ISiteContentDTO) {
  return request<IResponseData<ISiteContent>>({
    method: ERequestMethods.POST,
    url: E_SITE_CONTENT.ADMIN,
    data,
  });
}

function adminUpdateSiteContentDefault(id: string, data: ISiteContentDTO) {
  return request<IResponseData<ISiteContent>>({
    method: ERequestMethods.PATCH,
    url: E_SITE_CONTENT.ADMIN_SITE_CONTENT_ID(id),
    data,
  });
}

const SiteContentRepository = Object.freeze({
  getAllSiteContent,
  deleteSiteContent,
  createSiteContent,
  updateSiteContent,
  getSiteContentById,
  adminCreateSiteContent,
  adminGetSiteContentBySiteType,
  adminUpdateSiteContentDefault,
});

export default SiteContentRepository;
