import { z } from "zod";

// form zod validation schema
export const createUnitTransactionSchema = z.object({
  unitName: z.string().min(1, "This field is required"),
  unitTypeId: z.string().min(1, "This field is required"),
  projectId: z.string().min(1, "This field is required"),
  status: z.string().min(1, "This field is required"),
  timestamp: z.date().refine((value) => value !== null, "Please select a date"),
});

// generate form types from zod validation schema
export type CreateUnitTransactionInput = z.infer<
  typeof createUnitTransactionSchema
>;
