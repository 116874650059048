import { useModal } from "@/app/shared/modal-views/use-modal";
import { DatePicker } from "@/components/ui/datepicker";
import useApi from "@/hooks/useApi";
import AmenityRepository from "@/utilities/repositories/Amenity";
import { IAmenity } from "@/utilities/types/Amenity";
import {
  CreateAmenityInput,
  createAmenitySchema,
} from "@/utils/validators/amenity.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiXBold } from "react-icons/pi";
import { ActionIcon, Button, Input, Title } from "rizzui";

export interface CreateEditEmenityProps {
  refetchData?: () => void;
  projectId?: string;
  data?: IAmenity;
}

export default function CreateEditAmenity({
  refetchData,
  projectId = "",
  data,
}: CreateEditEmenityProps) {
  const isUpdate = !!data;
  const title = isUpdate ? "Edit Amenity" : "Create Amenity";
  const form = useForm({
    defaultValues: {
      name: "",
      type: "",
      projectId: projectId || "",
      distance: 0,
      duration: 0,
    },
    resolver: zodResolver(createAmenitySchema),
  });

  const { closeModal, isOpen } = useModal();

  const { loading, request: createUnitTransaction } = useApi({
    request: AmenityRepository.createAmenity,
  });
  const { loading: loadingUpdate, request: updateUnitTransaction } = useApi({
    request: AmenityRepository.updateAmenity,
  });

  const onSubmit = async (formData: CreateAmenityInput) => {
    try {
      if (isUpdate) {
        await updateUnitTransaction(data.id, {
          ...formData,
          projectId: undefined,
        });
      } else {
        await createUnitTransaction({
          ...formData,
        });
      }
      toast.success("Successfully");
      refetchData?.();
      if (isOpen) {
        closeModal();
      }
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (data) {
      form.reset({
        name: typeof data?.name === "object" ? data?.name?.en : data?.name,
        type: typeof data?.type === "object" ? data?.type?.en : data?.type,
        projectId: data?.projectId || projectId || "",
        distance: data?.distance || 0,
        duration: data?.duration || 0,
      });
    }
  }, [JSON.stringify(data)]);

  return (
    <form
      noValidate
      className="grid grid-cols-1 gap-6 p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className="col-span-full flex items-center justify-between">
        <Title
          as="h4"
          className="font-semibold"
        >
          {title}
        </Title>
        {isOpen && (
          <ActionIcon
            size="sm"
            variant="text"
            onClick={closeModal}
          >
            <PiXBold className="h-auto w-5" />
          </ActionIcon>
        )}
      </div>

      <Input
        label="Name"
        size="lg"
        placeholder="Enter name"
        {...form.register("name")}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.name?.message}
      />
      <Input
        label="Type"
        size="lg"
        placeholder="Enter type"
        {...form.register("type")}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.type?.message}
      />
      <Input
        label="Distance"
        size="lg"
        placeholder="Enter distance"
        {...form.register("distance")}
        type="number"
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.distance?.message}
      />
      <Input
        label="Duration"
        size="lg"
        placeholder="Enter duration"
        {...form.register("duration")}
        type="number"
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.duration?.message}
      />

      <div className="col-span-full flex items-center justify-end gap-4">
        <Button
          variant="outline"
          onClick={closeModal}
          className="w-auto"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          isLoading={loading || loadingUpdate}
          className="w-auto"
          disabled={!form?.formState?.isDirty}
        >
          {title}
        </Button>
      </div>
    </form>
  );
}
