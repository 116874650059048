// import "./utilities/libs/sentry.ts";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import "./index.css";
import { ThemeProvider } from "./app/shared/theme-provider.tsx";
import { Toaster } from "react-hot-toast";
import GlobalDrawer from "./app/shared/drawer-views/container.tsx";
import GlobalModal from "./app/shared/modal-views/container.tsx";
import createStore from "react-auth-kit/createStore";
import AuthProvider from "react-auth-kit";
import { IUserData } from "@/utilities/types/Users.ts";
import { AUTH_KIT } from "./config/auth.ts";
import "./locales/i18n";
import { AdvanceFeaturesProvider } from "./utilities/providers/AdvanceFeaturesProvider.tsx";

const store = createStore<IUserData>({
  authName: AUTH_KIT.NAME,
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AdvanceFeaturesProvider>
      <AuthProvider store={store}>
        <BrowserRouter>
          <ThemeProvider>
            <App />

            <Toaster containerStyle={{ zIndex: 9999999999 }} />
            <GlobalDrawer />
            <GlobalModal />
          </ThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </AdvanceFeaturesProvider>
  </React.StrictMode>
);
