"use client";

import Link from "next/link";
import HamburgerButton from "@/layouts/hamburger-button";
import SearchWidget from "@/components/search/search";
import Logo from "@/components/logo";
import StickyHeader from "@/layouts/sticky-header";
import { CarbonDrawerSidebar } from "./carbon-drawer-sidebar";
import HeaderMenuRight from "../header-menu-right";
import LanguageSwitcher from "@/components/language-switcher";

export default function Header() {
  return (
    <StickyHeader className="z-[990] 2xl:py-5 3xl:px-8 4xl:px-10 w-full py-4 md:py-0 xl:hidden">
      <div className="flex items-center justify-between flex-1 w-full">
        <HamburgerButton
          view={
            <CarbonDrawerSidebar className="static h-full w-full 2xl:w-full" />
          }
        />
        <div className="flex items-center flex-1 justify-center">
          <Link
            href={"/"}
            aria-label="Site Logo"
            className="me-4 w-fit h-fit shrink-0 text-gray-800 hover:text-gray-900 lg:me-5"
          >
            <Logo />
          </Link>

          {/* <SearchWidget /> */}
          {/* <LanguageSwitcher /> */}
        </div>
      </div>

      {/* <HeaderMenuRight /> */}
    </StickyHeader>
  );
}
