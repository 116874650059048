import { SOCIAL_TYPES } from "@/config/constants";
import { ESocialLinks } from "@/utilities/enums/Social";
import { Controller, useFieldArray } from "react-hook-form";
import { PiPlusBold, PiTrashBold } from "react-icons/pi";
import { Button, Input, Select, SelectOption } from "rizzui";

interface ISocialLinkFieldProps {
  title: string;
  name: string;
  control: any;
  register: any;
  errors: any;
}

export const SocialLinkField: React.FC<ISocialLinkFieldProps> = ({
  title,
  name,
  control,
  register,
  errors,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const SOCIAL_TYPE_OPTIONS: SelectOption[] = Object.keys(SOCIAL_TYPES).map(
    (e) => ({
      value: e as string,
      label: (SOCIAL_TYPES?.[e as ESocialLinks]?.["label"] || "") as string,
    })
  );
  return (
    <div className="flex flex-col col-span-full">
      {title && <h6 className="text-foreground md:text-base mb-3">{title}</h6>}
      <div className="grid grid-cols-1 gap-2 w-full">
        {fields.map((field: any, index) => {
          return (
            <div
              key={field.id}
              className="border-muted mb-2 items-start rounded-lg border p-2 shadow @md:p-5 @xl:p-3"
            >
              <div className="grid w-full items-start gap-3 @md:grid-cols-3 @lg:gap-4 @xl:grid-cols-3 @2xl:gap-2 @4xl:grid-cols-3">
                <Input
                  label="Url"
                  placeholder="Enter url"
                  {...register(`${name}.${index}.url`)}
                  defaultValue={field.name}
                  error={errors?.[name]?.[index]?.url?.message}
                />
                <Controller
                  name={`${name}.${index}.type`}
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <Select
                      options={SOCIAL_TYPE_OPTIONS}
                      value={value}
                      onChange={onChange}
                      name={name}
                      label="Social type"
                      getOptionValue={(option: SelectOption) => option.value}
                      displayValue={(selected: string) =>
                        SOCIAL_TYPE_OPTIONS?.find(
                          (option) => option.value === selected
                        )?.label
                      }
                      dropdownClassName={"z-[9999]"}
                      error={errors?.[name]?.[index]?.type?.message}
                    />
                  )}
                />
                <Input
                  label="Label"
                  placeholder="Enter label"
                  {...register(`${name}.${index}.label`)}
                  defaultValue={field.value}
                  error={errors?.[name]?.[index]?.label?.message}
                />
              </div>
              <Button
                variant="text"
                color="danger"
                onClick={() => remove(index)}
                className="-mx-2 -mb-1 ms-auto mt-5 h-auto px-2 py-1 font-semibold"
              >
                <PiTrashBold className="me-1 h-[18px] w-[18px]" /> Remove
              </Button>
            </div>
          );
        })}
        <div className="flex w-full flex-col items-start justify-between @4xl:flex-row">
          <Button
            onClick={() => {
              append({ url: "", type: "", label: "" });
            }}
            variant="flat"
            className="w-full @4xl:mb-0 @4xl:mt-0 @4xl:w-auto"
          >
            <PiPlusBold className="me-1.5 h-4 w-4" /> Add Item
          </Button>
        </div>
      </div>
      {errors?.[name]?.[0]?.root?.message && (
        <p className="text-red text-[13px] mt-1.5 rizzui-input-error-text">
          {errors?.[name]?.[0]?.root?.message}
        </p>
      )}
    </div>
  );
};
