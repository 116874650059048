import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { ILandingPage, ILandingPageDataForm } from "../types/LandingPage";
import { LANDINGPAGES } from "../enums/LandingPages";

function getLandingPages({ queryParams }: IRequestArgs) {
  const query = {
    filter: queryParams?.query?.filter,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<ILandingPage>>({
    method: ERequestMethods.GET,
    url: LANDINGPAGES.GET,
    params: query,
  });
}

function getLandingPagesByProject({ queryParams }: IRequestArgs) {
  const query = {
    ...queryParams?.pagination,
  };
  return request<ListResponseData<ILandingPage>>({
    method: ERequestMethods.GET,
    url:
      LANDINGPAGES.GET_BY_PROJECT +
      `/${queryParams?.query?.filter[0].projectId}`,
    params: query,
  });
}

function createLandingPage(data: ILandingPageDataForm) {
  return request<IResponseData<ILandingPage>>({
    method: ERequestMethods.POST,
    url: LANDINGPAGES.CREATE,
    data,
  });
}

function updateLandingPage(id: string, data: ILandingPageDataForm) {
  return request<IResponseData<ILandingPage>>({
    method: ERequestMethods.PATCH,
    url: LANDINGPAGES.UPDATE(id),
    data,
  });
}

function removeLandingPage(id: string) {
  return request<IResponseData<ILandingPage>>({
    method: ERequestMethods.DELETE,
    url: LANDINGPAGES.REMOVE(id),
  });
}

const LandingPagesRepository = Object.freeze({
  getLandingPages,
  getLandingPagesByProject,
  createLandingPage,
  updateLandingPage,
  removeLandingPage,
});

export default LandingPagesRepository;
