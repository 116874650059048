import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  ActionIcon,
  Input,
  Tooltip,
  Select,
  SelectOption,
} from "rizzui";
import PencilIcon from "@/components/icons/pencil.tsx";
import useApi from "@/hooks/useApi.tsx";
import { IResponseData } from "@/utilities/types/requests.ts";
import { IFloor, IFloorDataForm, Photo } from "@/utilities/types/Floor.ts";
import FloorPlansRepository from "@/utilities/repositories/FloorPlans.ts";
import { useForm } from "react-hook-form";
import { IUnitTypeInAll } from "@/utilities/types/UnitType.ts";
import UnitTypeRepository from "@/utilities/repositories/UnitType.ts";
import { editFloorPlanSchema } from "@/utilities/validators/floor-plan.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import SingleImageUpload from "@/components/ui/file-upload/single-image-upload.tsx";
import UploadRepository from "@/utilities/repositories/Upload.ts";
import { TImageAsset } from "@/utilities/types/Asset.ts";
import { routes } from "@/config/routes.ts";
import { Link } from "react-router-dom";
import { backPrev } from "@/utilities/functions";
import useProfile from "@/hooks/use-profile";
import { Roles } from "@/utilities/types/Users";

export interface ModalEditProps {
  reset?: () => void;
  row?: IFloor;
  projectId: string;
}

export default function ModalCreateEditFloor({
  row,
  projectId,
  reset,
}: ModalEditProps) {
  const { profile } = useProfile();
  const form = useForm({
    defaultValues: {
      projectId,
      name: row?.name?.en || "",
      area: row?.area || "",
      minPrice: row?.minPrice || 0,
      maxPrice: row?.maxPrice || 0,
      totalUnits: row?.totalUnits || 0,
      availableUnits: row?.availableUnits || 0,
      unitTypeId: row?.unitTypeId || "",
      photoId: row?.photoId || "",
    },
    resolver: zodResolver(editFloorPlanSchema),
  });
  const [modalState, setModalState] = useState(false);
  const [photo, setPhoto] = useState<Photo>({
    id: row?.photoId || "",
    url: row?.photo?.urls?.[0]?.url || "",
  });

  const [displayOption, setDisplayOption] = useState<string | number>(
    form.getValues("unitTypeId")
  );
  const isUpdate: boolean = !!row?.id;
  const title = row?.id ? "Update" : "Create";

  const { loading: loadingUpdate, request: updateFloorPlan } = useApi({
    request: FloorPlansRepository.UpdateFloor,
  });

  const { request: getUploadById, response: photoUpload } = useApi<
    IResponseData<TImageAsset>
  >({
    request: UploadRepository.getUploadById,
  });

  const { request: createFloorPlan } = useApi({
    request: FloorPlansRepository.createFloor,
  });

  const { request: getUnitTypeList, response: unitTypesData } = useApi<
    IResponseData<IUnitTypeInAll[]>
  >({
    request: UnitTypeRepository.getAllUnitMain,
  });
  useEffect(() => {
    if (modalState) {
      Promise.all([getUnitTypeList(), getUploadById(photo.id)]).then(() => {
        if (photoUpload?.data.urls[0]?.url) {
          setPhoto({
            ...photo,
            url: photoUpload?.data?.urls[0]?.url,
          });
        }
      });
    }
    form.reset({
      name: row?.name?.en || "",
      area: row?.area || "",
      minPrice: row?.minPrice || 0,
      maxPrice: row?.maxPrice || 0,
      totalUnits: row?.totalUnits || 0,
      availableUnits: row?.availableUnits || 0,
      unitTypeId: row?.unitTypeId || "",
      photoId: row?.photoId || "",
    });
  }, [modalState]);

  const unitTypeOptions: SelectOption[] =
    unitTypesData?.data.map((unitTypeItem: IUnitTypeInAll) => ({
      value: unitTypeItem.id,
      label: unitTypeItem.title,
    })) || ([] as SelectOption[]);

  const onSubmit = async (formData: IFloorDataForm) => {
    const dataToCreateOrUpdate = {
      ...formData,
      projectId,
      photoId: photo.id,
    };
    try {
      if (isUpdate) {
        await updateFloorPlan(row?.id, dataToCreateOrUpdate);
      } else {
        await createFloorPlan(dataToCreateOrUpdate);
      }
      reset?.();
      toast.success("Successfully");
      setModalState(false);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  // Function to sanitize input and allow only numbers
  const sanitizeInput = (input: string) => {
    return input.replace(/\D/g, "").replace(/,/g, ""); // Remove any non-numeric characters
  };

  return (
    <>
      {isUpdate ? (
        <Tooltip
          size="sm"
          content={"Edit Floorplan"}
          placement="top"
          color="invert"
        >
          <ActionIcon
            size="sm"
            variant="outline"
            onClick={() => setModalState(true)}
          >
            <PencilIcon className="h-4 w-4" />
          </ActionIcon>
        </Tooltip>
      ) : (
        <>
          <div className={"flex justify-end items-center gap-2 mb-4"}>
            <Link
              to={backPrev(routes.project.listing)}
              className={"mr-2 ml-auto"}
            >
              <span
                className={
                  "px-4 py-2 inline-block border border-solid border-black rounded-md"
                }
              >
                Back
              </span>
            </Link>

            {profile?.role === Roles.ADMIN && (
              <Button
                onClick={() => {
                  setModalState(true);
                }}
                className="w-auto mr-0 block"
              >
                Add New
              </Button>
            )}
          </div>
        </>
      )}

      <Modal
        containerClassName="p-6"
        className="[&_.pointer-events-none]:overflow-visible"
        size={"lg"}
        isOpen={modalState}
        onClose={() => setModalState(false)}
      >
        <form
          className={"gap-4 grid grid-cols-2"}
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Input
            label="Name"
            placeholder="Name of the Floorplan"
            // value={row?.name?.en || ''}
            {...form.register("name")}
            error={form?.formState?.errors.name?.message}
          />
          <Input
            label="Size"
            placeholder="0.00"
            min={0.0}
            type={"number"}
            // value={row?.area || ''}
            {...form.register("area")}
            error={form?.formState?.errors.area?.message}
          />
          <Input
            label="Min Price"
            placeholder="Min Price"
            min={0.0}
            // value={row?.minPrice || ''}
            type={"text"}
            {...form.register("minPrice")}
            onChange={(e) => {
              const targetValue = sanitizeInput(e.target.value);
              form.setValue("minPrice", Number(targetValue), {
                shouldDirty: true,
              });
            }}
            error={form?.formState?.errors.minPrice?.message}
          />
          <Input
            label="Max Price"
            placeholder="Max Price"
            min={0.0}
            // value={row?.maxPrice || ''}
            type={"text"}
            {...form.register("maxPrice")}
            onChange={(e) => {
              const targetValue = sanitizeInput(e.target.value);
              form.setValue("maxPrice", Number(targetValue), {
                shouldDirty: true,
              });
            }}
            error={form?.formState?.errors.maxPrice?.message}
          />
          {/* <Input
            label="Total Unit"
            placeholder="Total Unit"
            min={0.0}
            // value={row?.maxPrice || ''}
            type={"number"}
            {...form.register("totalUnits")}
            error={form?.formState?.errors.totalUnits?.message}
          />
          <Input
            label="Available Unit"
            placeholder="Available Unit"
            min={0.0}
            // value={row?.maxPrice || ''}
            type={"number"}
            {...form.register("availableUnits")}
            error={form?.formState?.errors.availableUnits?.message}
          /> */}
          <Select
            className={"col-span-2"}
            dropdownClassName="!z-0"
            options={unitTypeOptions}
            value={displayOption}
            onChange={(value: string) => {
              if (isUpdate) {
                form.setValue("unitTypeId", value, { shouldDirty: true });
              } else {
                form.setValue("unitTypeId", value);
              }
              setDisplayOption(value);
            }}
            label="Unit Type"
            inPortal={false}
            name={"unitTypeId"}
            error={form?.formState?.errors.unitTypeId?.message}
            getOptionValue={(option) => option.value}
            displayValue={(selected: string) => {
              const selectedOption = unitTypeOptions?.find(
                (option) => option.value === selected
              );
              return selectedOption?.label;
            }}
          />
          <SingleImageUpload
            imageUrl={photo.url || ""}
            title="Photo"
            setValue={setPhoto}
            uploaderText="Upload photo"
            uploadContainerClass="w-full h-full md:w-1/3 col-span-2"
          />
          {/*<UploadZone*/}
          {/*    name="photoId"*/}
          {/*    className="col-span-full"*/}
          {/*    getValues={form.getValues}*/}
          {/*    setValue={form.setValue}*/}
          {/*    inputProps={{*/}
          {/*        multiple: false,*/}
          {/*        accept: "image/*",*/}
          {/*    }}*/}
          {/*/>*/}

          <div className="col-span-full flex items-center justify-end gap-4">
            <Button
              variant="outline"
              onClick={() => {
                setModalState(false);
              }}
              className="md:w-auto w-1/2"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={loadingUpdate}
              className="md:w-auto w-1/2"
              disabled={!form?.formState?.isDirty}
            >
              {title}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
