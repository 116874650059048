import useApi from "@/hooks/useApi";

import {
  EmailTemplateInput,
  emailTemplateSchema,
} from "@/utils/validators/email-template.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Title } from "rizzui";
import { EMAIL_TEMPLATE_FIELDS } from "./configs";
import QuillEditor from "@/components/ui/quille-editor/quill-editor";
import SiteContentRepository from "@/utilities/repositories/SiteContent";
import { ESiteContentType } from "@/utilities/enums/SiteContent";
import { createSiteContentSchema } from "@/utilities/validators/site-content";

interface ICreateEditPrivacyPolicyTemplateProps {}

export default function CreateEditPrivacyPolicyTemplate({}: ICreateEditPrivacyPolicyTemplateProps) {
  const title = `Privacy Policy`;
  const form = useForm({
    defaultValues: {
      content: "",
      title: "",
    },
    resolver: zodResolver(createSiteContentSchema),
  });

  const { loading: updating, request: updatePrivacyPolicy } = useApi({
    request: SiteContentRepository.adminUpdateSiteContentDefault,
  });

  const { loading: creating, request: createPrivacyPolicy } = useApi({
    request: SiteContentRepository.adminCreateSiteContent,
  });

  const {
    loading: loading,
    request: getPrivacyPolicy,
    response,
  } = useApi({
    request: SiteContentRepository.adminGetSiteContentBySiteType,
  });

  const onSubmit = async (formData: { content: string }) => {
    try {
      if (!!response?.data?.id) {
        await updatePrivacyPolicy(response?.data?.id, {
          ...formData,
          title: "Privacy Policy",
          // type: ESiteContentType.PRIVACT_POLICY,
        });
      } else {
        await createPrivacyPolicy({
          ...formData,
          title: "Privacy Policy",
          type: ESiteContentType.PRIVACT_POLICY,
        });
      }

      toast.success("Successfully");
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getPrivacyPolicy(ESiteContentType.PRIVACT_POLICY).then((response) => {
      form.reset({
        title: response?.data?.title,
        content: response?.data?.content,
      });
    });
  }, []);

  return (
    <div className="mt-4">
      <div className="@container px-6 col-span-full flex items-center justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          {title}
        </Title>
      </div>
      <form
        noValidate
        className="grid grid-cols-1 gap-6 px-6 py-4 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {/* <Input
          label="Title"
          placeholder="Title"
          // value={row?.name?.en || ''}
          {...form.register("title")}
          error={form?.formState?.errors.title?.message}
          className="col-span-full"
          readOnly
        /> */}
        <Controller
          name="content"
          control={form.control}
          render={({ field, fieldState, formState }) => (
            <QuillEditor
              value={field?.value}
              onChange={field?.onChange}
              label="Content"
              className="col-span-full [&_.ql-editor]:min-h-[200px]"
              labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
              error={form?.formState?.errors?.content?.message}
            />
          )}
        />

        <div className="col-span-full">
          <div className="flex flex-col gap-2">
            <div className="rizzui-textarea-label block text-[16px] font-bold">
              Note:
            </div>
            <div className="rizzui-textarea-label text-sm font-medium">
              Please enclose the field names in double curly bracket syntax{" "}
              {"{{ }}"}. The code will only recognize the following fields on
              this UI. For example:
              <strong> {"{{name}}"}</strong>
            </div>

            {Object.entries(EMAIL_TEMPLATE_FIELDS).map(
              ([field, value]: [string, string]) => {
                return (
                  <div className="flex gap-2">
                    <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                      {value}
                    </div>
                    <div className="rizzui-textarea-label text-sm font-medium">
                      {field}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>

        <div className="col-span-full flex items-center justify-end gap-4">
          <Button
            type="submit"
            isLoading={loading || updating || creating}
            className="md:w-auto w-full"
            disabled={!form?.formState?.isDirty}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}
