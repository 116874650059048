import CreateEditDisclaimer from "@/app/app-shared/disclaimer/create-edit-disclaimer";
import { EDisclaimerKeys } from "@/utilities/enums/Disclaimer";

export default function DisclaimerListingPage() {
  return (
    <div className="flex flex-col gap-1">
      <CreateEditDisclaimer disclaimerKey={EDisclaimerKeys.Disclaimer} />
      <CreateEditDisclaimer disclaimerKey={EDisclaimerKeys.FooterDisclaimer} />
      <CreateEditDisclaimer disclaimerKey={EDisclaimerKeys.FormNotice} />
    </div>
  );
}
