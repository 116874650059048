"use client";

import { Controller, SubmitHandler } from "react-hook-form";
import { Form } from "@/components/ui/form";
import { useState } from "react";
import { Button, Input, Textarea, Title } from "rizzui";
import { TAgencySettingCreate } from "@/utilities/types/Setting";
import SettingsRepository from "@/utilities/repositories/Settings";
import toast from "react-hot-toast";
import {
  CreateAgencySettingSchema,
  createAgencySettingSchema,
} from "@/utils/validators/create-agency-setting";
import { useModal } from "@/app/shared/modal-views/use-modal";
import cn from "@/utils/class-names";
import DomainInput from "@/components/ui/domain-input";
import { SUFFIX_DOMAIN } from "@/config/constants";

export default function CreateAgencySetting({
  isModalView,
  onRefetch,
}: {
  userId: string;
  isModalView: boolean;
  onRefetch: () => void;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const DEFAULT_VALUES = {
    domain: "",
    siteTitle: "",
    siteDescription: "",
  };
  const [reset, setReset] = useState<CreateAgencySettingSchema>(DEFAULT_VALUES);
  const { closeModal } = useModal();

  const onSubmit: SubmitHandler<CreateAgencySettingSchema> = async (
    formData
  ) => {
    const dataToCreate: TAgencySettingCreate = {
      domain: `${formData?.domain || ""}${SUFFIX_DOMAIN}`,
      siteTitle: formData?.siteTitle || "",
      siteDescription: formData?.siteDescription || "",
    };
    try {
      setIsLoading(true);
      await SettingsRepository.createAgencySetting(dataToCreate);
      toast.success(`Created agent setting successfully`);
      setIsLoading(false);
      onRefetch();
      closeModal();
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
      setIsLoading(false);
    }
  };

  return (
    <Form<CreateAgencySettingSchema>
      resetValues={reset}
      onSubmit={onSubmit}
      validationSchema={createAgencySettingSchema}
      className="grid grid-cols-1 p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
    >
      {({ control, setError, formState: { isDirty } }) => {
        return (
          <>
            <div className="relative z-50 mb-4 flex flex-wrap items-center justify-between gap-2.5 @container">
              <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
                Website Settings
              </Title>
            </div>
            <div className="col-span-full">
              <div className="flex flex-col gap-6">
                <Controller
                  control={control}
                  name="domain"
                  render={({ field, fieldState, formState }) => (
                    <DomainInput
                      label="Domain"
                      size="lg"
                      placeholder="Enter your domain"
                      className="col-span-full p-0"
                      {...field}
                      {...formState}
                      value={field.value}
                      error={fieldState.error?.message}
                      suffix={SUFFIX_DOMAIN}
                      inputClassName="[&>input]:pe-0"
                      setError={(errorStr: string) =>
                        setError("domain", {
                          message: errorStr,
                        })
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="siteTitle"
                  render={({ field, fieldState, formState }) => (
                    <Input
                      label="Site title"
                      size="lg"
                      placeholder="Enter your site title"
                      className="col-span-full"
                      {...field}
                      {...formState}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="siteDescription"
                  render={({ field, fieldState, formState }) => (
                    <Textarea
                      label="Site description"
                      size="lg"
                      placeholder="Enter your site description"
                      className="col-span-full"
                      {...field}
                      {...formState}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </div>
            </div>

            <div
              className={cn(
                "col-span-full flex items-center justify-end gap-4 bg-white p-2",
                isModalView
                  ? ""
                  : "sticky bottom-0 left-0 right-0 z-[99] -mb-8 "
              )}
            >
              <Button
                type="submit"
                isLoading={isLoading}
                className="w-full @xl:w-auto"
                disabled={!isDirty}
              >
                Create
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}
