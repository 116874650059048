import useApi from "@/hooks/useApi";
import WebhookRepositories from "@/utilities/repositories/Webhook";
import { ListResponseData } from "@/utilities/types/requests";
import { EWebhookType, WebhookItem } from "@/utilities/types/Webhook";
import cn from "@/utils/class-names";
import { webhookSettingSchema } from "@/utils/validators/webhook.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { memo, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiCopySimple, PiPlusBold, PiTrashBold } from "react-icons/pi";
import { useCopyToClipboard } from "react-use";
import { Button, Input, Password, Title } from "rizzui";

export interface WebhookSubmitFormProps {
  refetchData?: () => Promise<ListResponseData<WebhookItem> | undefined>;
  data: WebhookItem[];
  isLoading?: boolean;
}

function WebhookSubmitForm({
  data,
  refetchData,
  isLoading,
}: WebhookSubmitFormProps) {
  const { request: settingWebhook, loading } = useApi({
    request: WebhookRepositories.settingWebhook,
  });

  const [_, copyToClipboard] = useCopyToClipboard();

  function handleCopyToClipboard(value: string) {
    copyToClipboard(value);
    toast.success(<b>{`Copied to clipboard`}</b>);
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: zodResolver(webhookSettingSchema),
  });

  const { fields, remove, append } = useFieldArray({
    control: control,
    name: "webhook",
  });

  const resetForm = (data: WebhookItem[]) => {
    const webhookFieldsConvert = data.map((field) => ({
      url: field.name,
      secretKey: field.secretKey,
      id: field.id,
    }));
    reset({
      webhook: webhookFieldsConvert,
    });
  };

  const onSubmit = async (values: any) => {
    try {
      const data = values?.webhook?.map((item: any) => item.url);

      await settingWebhook({ data, type: EWebhookType.SUBMIT_FORM });
      toast.success("Setting webhook successfully");

      await refetchData?.();
    } catch (e) {
      toast.error(<b>{`Failed to save webhook settings`}</b>);
    }
  };

  useEffect(() => {
    resetForm(data);
  }, [data]);

  useEffect(() => {
    const webhookRootMessage = errors?.webhook?.root?.message as string;
    if (webhookRootMessage) {
      toast.error(webhookRootMessage);
    }
  }, [errors]);
  return (
    <div className="md:mt-4 mt-2 border-muted mb-2 items-start rounded-lg border p-3 shadow @md:p-5 @xl:p-3">
      <div className="mb-2 w-full flex items-center justify-between">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col w-full col-span-full gap-y-4"
        >
          <Title as="h5">Webhook Submit Form</Title>

          <div className="grid grid-cols-1 gap-2 w-full">
            {fields.map((field: any, index) => {
              return (
                <div
                  key={field.id}
                  className="border-muted mb-2 items-start rounded-lg border p-2 shadow @md:p-5 @xl:p-3"
                >
                  <div className="grid w-full items-start gap-3 grid-cols-2">
                    <Controller
                      control={control}
                      name={`webhook.${index}.url`}
                      render={({ field: formField, fieldState, formState }) => {
                        return (
                          <Input
                            label="Url"
                            placeholder="Enter Url to connect to webhook"
                            {...formField}
                            defaultValue={field.url}
                            {...formState}
                            error={fieldState.error?.message}
                            disabled={!!field.secretKey}
                            className="col-span-full md:col-span-1"
                          />
                        );
                      }}
                    />
                    <Password
                      label="Secret Key"
                      placeholder="Secret key"
                      inputClassName="text-sm [&_input]:px-2"
                      readOnly
                      helperText={
                        !field.secretKey &&
                        "Secret key will be automatically generated after submit"
                      }
                      defaultValue={field.secretKey}
                      prefix={
                        <PiCopySimple
                          onClick={(e) => {
                            handleCopyToClipboard(field?.secretKey);
                          }}
                          className={cn(
                            "mr-2 h-5 w-5 text-gray-500 cursor-pointer",
                            !field.secretKey && "pointer-events-none"
                          )}
                        />
                      }
                      className="col-span-full md:col-span-1"
                    />
                  </div>
                  <Button
                    variant="text"
                    color="danger"
                    onClick={() => remove(index)}
                    className={cn(
                      "-mx-2 -mb-1 ms-auto mt-5 h-auto px-2 py-1 font-semibold"
                    )}
                  >
                    <PiTrashBold className="me-1 h-[18px] w-[18px]" /> Remove
                  </Button>
                </div>
              );
            })}
            <div className="flex w-full flex-col items-start justify-between @4xl:flex-row">
              <Button
                onClick={() => {
                  append({ url: "" });
                }}
                variant="flat"
                className="w-full @4xl:mb-0 @4xl:mt-0 @4xl:w-auto"
              >
                <PiPlusBold className="me-1.5 h-4 w-4" /> Add Item
              </Button>
            </div>
          </div>
          <Button
            disabled={isLoading || loading}
            type="submit"
            className="ml-auto md:w-auto w-full"
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
}
WebhookSubmitForm.displayName = "WebhookSubmitForm";
export default memo(WebhookSubmitForm);
