import { CDN_URL } from "@/config/constants";
import QueryString from "qs";
import { To } from "react-router-dom";
export function validUrl(url: string) {
  if (!url) return url;
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}
export function convertUrl(url?: string) {
  if (!url) return url;
  const isValid = validUrl(url);
  if (isValid) {
    // The url is include cdn url right now
    return url;
  } else {
    // The url is not include cdn url
    return `${CDN_URL}${url}`;
  }
}

export function getParamsCurrentPage(
  extraParams?: Record<string, any>
): string {
  const searchParamsParse = QueryString.parse(location?.search.slice(1));
  const queryParams = {
    backTo: {
      pathname: location.pathname,
      ...searchParamsParse,
      rowKey: extraParams?.rowKey,
    },
    ...extraParams,
  };

  return QueryString.stringify(queryParams);
}

interface IBackTo {
  pathname?: string;
  page?: number;
  limit?: number;
  search?: string;
  rowKey?: string;
  backTo?: IBackTo;
}
export function backPrev(fallback?: string): To {
  const searchParamsParse: {
    backTo?: IBackTo;
  } = QueryString.parse(location?.search.slice(1));

  return {
    pathname: searchParamsParse?.backTo?.pathname || fallback,
    search: QueryString.stringify({
      page: searchParamsParse?.backTo?.page || 1,
      limit: searchParamsParse?.backTo?.limit || 10,
      rowKey: searchParamsParse?.backTo?.rowKey || "",
      search: searchParamsParse?.backTo?.search || "",
      backTo: { ...searchParamsParse?.backTo?.backTo },
    }),
  };
}
