import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { E_UNIT_TYPE } from "../enums/UnitType";
import {IUnitType, IUnitTypeDTO, IUnitTypeInAll} from "../types/UnitType";


function getAllUnitMain(){
  return request<IResponseData<IUnitTypeInAll[]>>({
    method: ERequestMethods.GET,
    url: E_UNIT_TYPE.MAIN
  });
}

function getAllUnitType({ queryParams }: IRequestArgs) {
  const query = {
    filter: queryParams?.query?.filter,
    sortBy: "createdAt",
    sort: "DESC",
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<IUnitType>>({
    method: ERequestMethods.GET,
    url: E_UNIT_TYPE.LISTING,
    params: query,
  });
}

function deleteUnitType(id: string) {
  return request<IResponseData<IUnitType>>({
    method: ERequestMethods.DELETE,
    url: E_UNIT_TYPE.UNIT_TYPE_ID(id),
  });
}

function createUnitType(data: IUnitTypeDTO) {
  return request<IResponseData<IUnitType>>({
    method: ERequestMethods.POST,
    url: E_UNIT_TYPE.MAIN,
    data,
  });
}

function updateUnitType(id: string, data: Partial<IUnitTypeDTO>) {
  return request<IResponseData<IUnitType>>({
    method: ERequestMethods.PATCH,
    url: E_UNIT_TYPE.UNIT_TYPE_ID(id),
    data,
  });
}

const UnitTypeRepository = Object.freeze({
  getAllUnitType,
  deleteUnitType,
  createUnitType,
  updateUnitType,
  getAllUnitMain
});

export default UnitTypeRepository;
