"use client";

import WidgetCard from "@/components/cards/widget-card";
import { CustomTooltip } from "@/components/charts/custom-tooltip";
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import { useMedia } from "@/hooks/use-media";
import SimpleBar from "@/components/ui/simplebar";
import { Title } from "rizzui";
import cn from "@/utils/class-names";
import { DatePicker } from "@/components/ui/datepicker";
import { useEffect, useMemo, useRef, useState } from "react";
import useApi from "@/hooks/useApi";
import AnalyticsRepository from "@/utilities/repositories/Analytic";
import { ITopProjectAnalytic } from "@/utilities/types/Analytic";
import { ListResponseData } from "@/utilities/types/requests";
import dayjs from "dayjs";
import { useInterval } from "usehooks-ts";
import { Skeleton } from "@/components/ui/skeleton";
import useProfile from "@/hooks/use-profile";
import { Roles } from "@/utilities/types/Users";

export interface ITopProjectsChartData {
  name: string;
  amount: number;
}

export default function UnitTransactionChart({
  className,
}: {
  className?: string;
}) {
  const isTablet = useMedia("(max-width: 800px)", false);

  const [chartData, setChartData] = useState<ITopProjectsChartData[]>([]);
  const { profile } = useProfile();

  const currentRole = profile?.role;

  const [startDate, setStartDate] = useState<Date | null>(
    dayjs().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [currentCount, setCurrentCount] = useState<number>(0);

  const titleChart = useRef<number>(0);
  const {
    request: getProjectAnalytics,
    response: topProjectsAnalyticResponse,
    loading,
  } = useApi<ListResponseData<ITopProjectAnalytic>>({
    request: !currentRole
      ? null
      : currentRole === Roles.ADMIN
        ? AnalyticsRepository.getProjectAnalytics
        : AnalyticsRepository.getProjectAnalyticsAgent,
  });

  const [index, setIndex] = useState(0);

  useInterval(() => {
    setIndex(index + 1);
  }, 5000);

  useEffect(() => {
    if (startDate?.toISOString() && endDate?.toISOString()) {
      getProjectAnalytics(startDate.toISOString(), endDate.toISOString());
    }
  }, [startDate?.toISOString(), endDate?.toISOString(), index, currentRole]);

  useEffect(() => {
    if (topProjectsAnalyticResponse?.data) {
      setChartData(
        topProjectsAnalyticResponse?.data?.items?.map((item) => ({
          name: item?.name || "",
          amount: item?.unitsSoldCount,
        })) || []
      );

      const count =
        topProjectsAnalyticResponse?.data?.totalCount?.unitsSoldCount || 0;
      if (titleChart.current !== count) {
        titleChart.current = count;
      }
    }
  }, [topProjectsAnalyticResponse?.data]);

  const chartDescription = useMemo(() => {
    const count = topProjectsAnalyticResponse?.data?.totalCount?.unitsSoldCount;

    let number = count ?? currentCount;

    if (!count || count === currentCount) {
      number = currentCount;
    } else {
      setCurrentCount(count);
    }

    if (number > 1) {
      return `${number} transactions`;
    } else {
      return `${!number ? 0 : number} transaction`;
    }
  }, [
    currentCount,
    topProjectsAnalyticResponse?.data?.totalCount?.unitsSoldCount,
  ]);

  const handleChange = ([newStartDate, newEndDate]: [
    Date | null,
    Date | null,
  ]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };
  return (
    <WidgetCard
      title={`General Trend:
        New Launch Transactions`}
      titleClassName="text-gray-700 font-bold md:text-2xl text-xl font-inter w-fit whitespace-pre-line md:whitespace-normal"
      headingClassName="flex-col md:flex-col"
      description={chartDescription}
      actionClassName="flex-1 flex w-full md:w-[280px] justify-end"
      action={
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          selectsRange
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd MMM, yyyy"
          placeholderText="Select date"
          popperPlacement="bottom-end"
          inputProps={{
            variant: "text",
            inputClassName: "p-0 px-1 h-auto [&_input]:text-ellipsis",
          }}
          className="rounded border [&_.rizzui-input-container]:px-3 [&_.rizzui-input-container]:py-1.5 w-full md:w-[280px]"
        />
      }
      className={cn("min-h-[28rem]", className)}
    >
      <SimpleBar>
        <div className="h-[27.3rem] w-full pt-9 @lg:pt-8">
          <ResponsiveContainer
            width="100%"
            height="100%"
            {...(isTablet && { minWidth: "500px" })}
          >
            <BarChart
              data={chartData}
              margin={{
                left: -17,
                top: 27,
              }}
              className="[&_.recharts-tooltip-cursor]:fill-opacity-20 dark:[&_.recharts-tooltip-cursor]:fill-opacity-10 [&_.recharts-cartesian-axis-tick-value]:fill-gray-500 [&_.recharts-cartesian-axis.xAxis]:translate-y-2 [&_.recharts-cartesian-axis.yAxis]:-translate-y-3 rtl:[&_.recharts-cartesian-axis.yAxis]:-translate-x-12 [&_path.recharts-rectangle]:!stroke-none"
            >
              <defs>
                <linearGradient id="bar" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#3CBA92" stopOpacity={1} />
                  <stop offset="95%" stopColor="#0BA360" stopOpacity={1} />
                </linearGradient>
              </defs>
              <CartesianGrid
                vertical={false}
                strokeOpacity={0.435}
                strokeDasharray="8 10"
              />
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                tickFormatter={(label: any) => {
                  const limit = 10; // put your maximum character
                  if (label?.toString()?.length < limit) return label;
                  return `${label?.toString()?.substring(0, limit)}...`;
                }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(label) => `${label}`}
              />
              <Tooltip
                content={
                  <CustomTooltip className="[&_.chart-tooltip-item:last-child]:hidden" />
                }
                cursor={false}
              />
              <Bar
                dataKey="amount"
                fill="#3872FA"
                barSize={36}
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </SimpleBar>
    </WidgetCard>
  );
}

function CustomizedDot(props: any) {
  const { cx, cy } = props;
  return (
    <svg
      x={cx - 10}
      y={cy - 7}
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7.03906"
        cy="7"
        r="5.5"
        fill="#3872FA"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
}
