import cn from "@/utils/class-names";
import React, { memo, useEffect, useRef } from "react";
import { useCountUp } from "react-countup";

import "./styles.css";
import { leadScore } from "@/utilities/functions/lead";

interface IPaymentSummaryProps {
  totalInitial: number;
}
const ChartPie: React.FC<IPaymentSummaryProps> = ({ totalInitial = 0 }) => {
  const countUpRef = useRef<HTMLSpanElement>(null);
  const total = totalInitial;
  const { start } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: total,
    delay: 0,
    duration: 2,
  });
  useEffect(() => {
    start();
  }, [start, total]);

  return (
    <div
      className="pie animate rounded-full mx-auto flex flex-col items-center justify-center gap-1"
      style={
        {
          "--p": total,
          "--c": leadScore(total)?.color,
          "--w": "250px",
        } as React.CSSProperties
      }
    >
      <div
        className="absolute w-full h-full rounded-full top-0 left-0 opacity-10"
        style={{ backgroundColor: leadScore(total || 0)?.color }}
      />
      <p
        className={cn(
          "whitespace-pre-wrap break-words text-center font-bold text-6xl",
          "max-w-[250px]"
        )}
        style={{ color: leadScore(total)?.color }}
      >
        <span ref={countUpRef}>{countUpRef?.current?.innerText || 0}</span>
      </p>
    </div>
  );
};

export default memo(ChartPie);
