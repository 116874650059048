import { z } from "zod";

// form zod validation schema
export const createUnitTypeSchema = z.object({
  title: z.string().min(1, "This field is required"),
  bedRoomCount: z
    .number()
    .min(0, "This field is numberic")
    .or(
      z
        .string()
        .refine((value) => !isNaN(Number(value)), "This field is numberic")
    ),
  hasStudyRoom: z.boolean().optional(),
  isPremium: z.boolean().optional(),
  isDeluxe: z.boolean().optional(),
  isPrivateLift: z.boolean().optional(),
  isCompact: z.boolean().optional(),
  isCompactPlus: z.boolean().optional(),
  isDuplex: z.boolean().optional(),
  isPenthouse: z.boolean().optional(),
  isUtility: z.boolean().optional(),
  hasGuest: z.boolean().optional(),
});

// generate form types from zod validation schema
export type CreateUnitTypeInput = z.infer<typeof createUnitTypeSchema>;
