import { Enum } from "./Enum";

export const UNITS = Object.freeze({
  GET: `/unit`,
  CREATE: "/unit",
  DETAIL: (id: string) => `/unit/${id}`,
  UPDATE: (id: string) => `/unit/${id}`,
  REMOVE: (id: string) => `/unit/${id}`,
});

export type E_UNITS = Enum<typeof UNITS>;
