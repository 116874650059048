import ColorPickerPopover from "@/app/shared/colorpicker-popover";
import { useModal } from "@/app/shared/modal-views/use-modal";
import useApi from "@/hooks/useApi";
import {
  ESectionLayout,
  SectionLayouts,
} from "@/utilities/enums/SectionLayouts";

import QuillEditor from "@/components/ui/quille-editor/quill-editor";
import SectionSettingRepository from "@/utilities/repositories/SectionSetting";
import {
  SectionConfigInput,
  SectionConfigSchema,
} from "@/utilities/validators/section-config";
import { zodResolver } from "@hookform/resolvers/zod";
import { useId } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, Select, SelectOption } from "rizzui";
import OptionItemLayout from "./OptionItemLayout";
import VisibleToggle from "@/components/visible-toggle";

export interface SectionConfigFormProps {
  weight?: number;
  refetchData: () => Promise<void>;
}

const DEFAULT_WEIGHT = 1;

export default function SectionConfigForm({
  refetchData,
  weight = DEFAULT_WEIGHT,
}: SectionConfigFormProps) {
  const { closeModal } = useModal();

  const formId = useId();

  const {
    control,
    watch,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
  } = useForm<SectionConfigInput>({
    resolver: zodResolver(SectionConfigSchema),
    defaultValues: {
      background: "transparent",
      visible: true,
    },
  });

  const bgValue = watch("background");

  const options: SelectOption[] = Object.keys(SectionLayouts).map(
    (key: string) => ({
      value: key,
      label: SectionLayouts[key as ESectionLayout]?.name,
      preview: SectionLayouts[key as ESectionLayout]?.preview,
    })
  );

  const { request: create, loading } = useApi({
    request: SectionSettingRepository.addSection,
    enableToast: true,
  });

  const onSubmit = async (data: SectionConfigInput) => {
    try {
      await create({
        ...data,
        name: data?.name || "",
        weight,
      });
      await refetchData();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      key={formId}
      noValidate
      // onSubmit={handleSubmit(onSubmit)}
      className="grid w-full items-start gap-3 @md:grid-cols-3 @lg:gap-4 @xl:grid-cols-3 @2xl:gap-2 @4xl:grid-cols-3"
    >
      <Controller
        control={control}
        name="name"
        render={({ field: { onChange, value } }) => (
          <QuillEditor
            value={value}
            onChange={onChange}
            label="Heading"
            className="col-span-full [&_.ql-editor]:min-h-[100px] [&_.ql-editor]:break-word-v2"
            labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
            error={errors?.name?.message as string}
            toolbar={[
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["bold", "italic", "underline"], // toggled buttons
              [{ color: [] }], // dropdown with defaults from theme
              [{ align: [] }],
            ]}
          />
        )}
      />
      <Controller
        name={"layout"}
        control={control}
        render={({ field: { name, onChange, value } }) => (
          <Select
            options={options}
            value={value}
            onChange={onChange}
            name={name}
            label="Layout"
            getOptionValue={(option: SelectOption) => option.value}
            displayValue={(selected: string) =>
              options?.find((option) => option.value === selected)?.label
            }
            getOptionDisplayValue={(option) => {
              return <OptionItemLayout option={option} />;
            }}
            dropdownClassName={"z-modal [&_li>div]:w-full"}
            error={errors.layout?.message as string}
          />
        )}
      />
      <Controller
        control={control}
        name="background"
        render={({ field: { onChange, value } }) => {
          return (
            <ColorPickerPopover
              value={value || ""}
              onChange={(value: string) => {
                onChange(value);
              }}
              className="w-[64px] h-[32px] rounded-[6px]"
            />
          );
        }}
      />
      {watch(`layout`) === ESectionLayout.SectionHTMLContent && (
        <Controller
          control={control}
          name={"html"}
          render={({ field: { onChange, value } }) => (
            <QuillEditor
              value={value}
              onChange={onChange}
              label="HTML"
              className="[&>.ql-container_.ql-editor]:min-h-[100px]"
              labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
              error={errors.html?.message as string}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name={`visible`}
        render={({ field: { value } }) => (
          <VisibleToggle
            value={!!value}
            onChange={(value: boolean) =>
              setValue(`visible`, value, {
                shouldDirty: true,
                shouldValidate: true,
              })
            }
          />
        )}
      />
      <div className="col-span-full flex items-center justify-end gap-4">
        <Button
          variant="outline"
          onClick={closeModal}
          className="w-auto"
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={handleSubmit(onSubmit)}
          isLoading={loading}
          className="w-auto"
          disabled={!isDirty}
        >
          Create
        </Button>
      </div>
    </form>
  );
}
