import { LEAD_SCORE_TYPES } from "@/config/constants";
import { E_LEAD_SCORE_TYPE } from "../enums/LeadScoreTypes";

export const leadScore = (score: number) => {
  if (score > 80) return LEAD_SCORE_TYPES?.[E_LEAD_SCORE_TYPE?.HOT];
  if (score > 49) return LEAD_SCORE_TYPES?.[E_LEAD_SCORE_TYPE?.WARM];
  if (score > 1) return LEAD_SCORE_TYPES?.[E_LEAD_SCORE_TYPE?.COLD];
  return LEAD_SCORE_TYPES?.[E_LEAD_SCORE_TYPE?.AGENT_DUPLICATE]; // Score of 1, default case
};

export function formatDateInfo(dateInfo: string): string[] {
  return dateInfo
    .split(" - ") // Split by " - "
    .map((item) => item.trim()); // Trim whitespace from each item
}

export function formatUnitTypes(unitTypes: string): string[] {
  return unitTypes
    ?.replace("Unit types selected: ", "") // Remove the initial label
    ?.split(",") // Split by commas
    ?.map((item) => item?.trim()) // Trim whitespace from each item
    ?.sort((a, b) => a.localeCompare(b)); // Sort alphabetically
}

export function formatInterestedIn(unitTypes: string): string[] {
  return unitTypes
    ?.replace("Checked interests: ", "") // Remove the initial label
    ?.split(",") // Split by commas
    ?.map((item) => item?.trim()) // Trim whitespace from each item
    ?.sort((a, b) => a.localeCompare(b)); // Sort alphabetically
}
