"use client";

import DeletePopover from "@/app/shared/delete-popover";
import PencilIcon from "@/components/icons/pencil";
import { HeaderCell } from "@/components/ui/table";
import { TImageAsset } from "@/utilities/types/Asset";
import { IDeveloper } from "@/utilities/types/Developer";
import { ActionIcon, Tooltip } from "rizzui";
const ASSET_BASE_URL = import.meta.env.VITE_ASSET_BASE_URL as string;
type Columns = {
  data: IDeveloper[];
  onDeleteItem: (id: string) => Promise<void>;
  onEditItem: (row: IDeveloper) => void;
};

export const getColumns = ({ onDeleteItem, onEditItem }: Columns) => [
  {
    title: (
      <HeaderCell
        title="Logo"
        align="center"
      />
    ),
    key: "logo",
    dataIndex: "logo",
    align: "center",
    render: (value: TImageAsset) => {
      return value?.urls?.[0]?.url ? (
        <img
          className="w-[90px] mx-auto aspect-video"
          src={`${ASSET_BASE_URL}${value?.urls?.[0]?.url}`}
          loading="lazy"
        />
      ) : (
        <></>
      );
    },
  },
  {
    title: (
      <HeaderCell
        title="Name"
        align="center"
      />
    ),
    key: "name",
    dataIndex: "name",
    align: "center",
    render: (value: string | Record<string, string>) => {
      return typeof value === "string" ? value : value?.en;
    },
  },
  {
    title: (
      <HeaderCell
        title="Description"
        align="center"
      />
    ),
    dataIndex: "description",
    key: "description",
    align: "center",
    render: (value: string | Record<string, string>) => {
      return typeof value === "string" ? value : value?.en;
    },
  },
  {
    title: (
      <HeaderCell
        className="ps-3"
        title={<span className="whitespace-nowrap">Actions</span>}
        align="center"
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: IDeveloper) => (
      <div className="flex items-center justify-end gap-3 pe-4">
        <Tooltip
          size="sm"
          content={"Edit Developer"}
          placement="top"
          color="invert"
        >
          <ActionIcon
            size="sm"
            variant="outline"
            onClick={() => onEditItem(row)}
          >
            <PencilIcon className="h-4 w-4" />
          </ActionIcon>
        </Tooltip>
        <DeletePopover
          title={`Delete developer`}
          description={`Are you sure you want to delete this developer?`}
          onDelete={async () => await onDeleteItem(row.id)}
        />
      </div>
    ),
  },
];
