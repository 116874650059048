import ColorPickerPopover from "@/app/shared/colorpicker-popover";
import QuillEditor from "@/components/ui/quille-editor/quill-editor";
import {
  ESectionLayout,
  SectionLayouts,
} from "@/utilities/enums/SectionLayouts";
import { FieldArraySectionConfigInput } from "@/utilities/validators/section-config";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input, Select, SelectOption } from "rizzui";
import OptionItemLayout from "./OptionItemLayout";
import VisibleToggle from "@/components/visible-toggle";
import PromotionBannerTable from "../../promotion-banner/table";
import SingleImageUpload from "@/components/ui/file-upload/single-image-upload";
import { ISectionConfig } from "@/utilities/types/Setting";

export interface UpdateSectionConfigFormProps {
  indexForm: number;
  sectionId: string;
  sectionConfigs?: ISectionConfig[];
}

function UpdateSectionConfigForm({
  indexForm,
  sectionId,
  sectionConfigs,
}: UpdateSectionConfigFormProps) {
  const { control, watch, setValue, getFieldState } =
    useFormContext<FieldArraySectionConfigInput>();
  const options: SelectOption[] = Object.keys(SectionLayouts).map(
    (key: string) => ({
      value: key,
      label: SectionLayouts[key as ESectionLayout]?.name,
      preview: SectionLayouts[key as ESectionLayout]?.preview,
    })
  );

  return (
    <div className="grid  w-full items-start gap-3 grid-cols-1 lg:gap-4 xl:grid-cols-3 2xl:gap-2 4xl:grid-cols-3">
      <Controller
        control={control}
        name={`${indexForm}.name`}
        render={({ field: { onChange, value } }) => {
          return (
            <QuillEditor
              value={value}
              onChange={onChange}
              label="Heading"
              className="col-span-full [&_.ql-editor]:min-h-[50px]"
              labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
              error={getFieldState(`${indexForm}.name`).error?.message}
            />
          );
        }}
      />
      <Controller
        name={`${indexForm}.layout`}
        control={control}
        render={({ field: { name, onChange, value } }) => (
          <Select
            options={options}
            value={value}
            onChange={onChange}
            name={`${indexForm}.layout`}
            label="Layout"
            getOptionValue={(option: SelectOption) => option.value}
            displayValue={(selected: string) =>
              options?.find((option) => option.value === selected)?.label
            }
            getOptionDisplayValue={(option) => {
              return <OptionItemLayout option={option} />;
            }}
            dropdownClassName={"z-modal [&_li>div]:w-full"}
            error={
              getFieldState(`${indexForm}.layout`).error?.message as string
            }
          />
        )}
      />
      <Controller
        control={control}
        name={`${indexForm}.background`}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <div>
                <p
                  className={
                    "block text-sm mb-1.5 font-medium rizzui-input-labelw"
                  }
                >
                  Background
                </p>
                <ColorPickerPopover
                  value={value || ""}
                  onChange={(value: string) => {
                    onChange(value);
                  }}
                  className="w-full h-10 rounded-[6px] border border-muted"
                />
              </div>
            </>
          );
        }}
      />

      {watch(`${indexForm}.layout`) === ESectionLayout.SectionPromotionBanner &&
        !!sectionId && (
          <div className="col-span-full">
            <PromotionBannerTable
              sectionId={sectionId}
              canAdd={
                sectionConfigs?.[indexForm]?.layout ===
                ESectionLayout.SectionPromotionBanner
              }
            />
          </div>
        )}
      {watch(`${indexForm}.layout`) === ESectionLayout.SectionCTA && (
        <>
          <Controller
            control={control}
            name={`${indexForm}.photo`}
            render={({ field: { onChange } }) => (
              <SingleImageUpload
                imageUrl={watch(`${indexForm}.photo`)?.url || ""}
                title="CTA Background"
                setValue={onChange}
                uploaderText="Upload cta background"
              />
            )}
          />
          <Controller
            control={control}
            name={`${indexForm}.ctaTitle`}
            render={({ field: { onChange, value }, fieldState, formState }) => (
              <Input
                label="CTA Title"
                size="lg"
                placeholder="Enter cta title"
                className="col-span-full"
                {...formState}
                value={value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name={`${indexForm}.ctaDescription`}
            render={({ field: { onChange, value }, fieldState, formState }) => (
              <Input
                label="CTA Description"
                size="lg"
                placeholder="Enter cta description"
                className="col-span-full"
                {...formState}
                value={value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name={`${indexForm}.ctaButtonTitle`}
            render={({ field: { onChange, value }, fieldState, formState }) => (
              <Input
                label="CTA Button Title"
                size="lg"
                placeholder="Enter cta button title"
                className="col-span-full"
                {...formState}
                value={value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </>
      )}
      {watch(`${indexForm}.layout`) === ESectionLayout.SectionHTMLContent && (
        <Controller
          control={control}
          name={`${indexForm}.html`}
          render={({ field: { onChange, value } }) => (
            <QuillEditor
              value={value}
              onChange={onChange}
              label="HTML"
              className="col-span-full [&_.ql-editor]:min-h-[100px]"
              labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5 [&>.ql-container_.ql-editor]:min-h-[150px]"
              error={getFieldState(`${indexForm}.html`).error?.message}
            />
          )}
        />
      )}
    </div>
  );
}

export default memo(UpdateSectionConfigForm);
