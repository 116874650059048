import React from "react";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

interface IVisibleToggleProps {
  value: boolean;
  visibleTitle?: string;
  invisibleTitle?: string;
  onChange: (value: boolean) => void;
}
const VisibleToggle: React.FC<IVisibleToggleProps> = ({
  value,
  visibleTitle = "Show section",
  invisibleTitle = "Hide section",
  onChange,
}) => {
  return (
    <div
      className="flex gap-1 items-center justify-center cursor-pointer select-none"
      onClick={(e) => {
        e.stopPropagation();
        onChange(!value);
      }}
    >
      {value ? (
        <>
          <HiOutlineEye className="w-6 h-6" />
          <p className="rizzui-input-label block text-sm font-medium">
            {visibleTitle}
          </p>
        </>
      ) : (
        <>
          <HiOutlineEyeOff className="w-6 h-6" />
          <p className="rizzui-input-label block text-sm font-medium">
            {invisibleTitle}
          </p>
        </>
      )}
    </div>
  );
};

export default VisibleToggle;
