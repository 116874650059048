import { useModal } from "@/app/shared/modal-views/use-modal";
import { formatDate } from "@/utils/format-date";
import { PiMailboxLight, PiUserCircleLight, PiXBold } from "react-icons/pi";
import { ActionIcon, Title, Text, cn } from "rizzui";
import { IImportDataTask } from "@/utilities/types/ImportData";
import {
  EImportTaskStatus,
  IMPORT_TYPE_LABEL,
  StatusColors,
} from "@/utilities/enums/ImportData";

export default function TaskDetail({ data }: { data?: IImportDataTask }) {
  const { closeModal } = useModal();

  return (
    <div className="block">
      <div className="flex items-center justify-between border-b border-gray-200 p-5 md:p-7">
        <Title
          as="h3"
          className="font-lexend text-xl font-semibold md:text-2xl"
        >
          Import Task Details
        </Title>
        <ActionIcon
          size="sm"
          variant="text"
          onClick={closeModal}
          className="p-0 text-gray-500 hover:!text-gray-900"
        >
          <PiXBold className="h-5 w-5" />
        </ActionIcon>
      </div>
      <div className="p-5 md:px-7 md:pb-7 md:pt-6 grid grid-cols-2 gap-y-4 gap-x-6">
        <div className="border border-gray-200 rounded-lg p-4 col-span-full">
          <ul className="space-y-4 text-xs sm:text-sm">
            <li className="flex items-center">
              <PiUserCircleLight className="me-2  w-5 shrink-0 text-xl" />
              Imported By:
              <span className="ps-2 font-medium text-gray-1000">
                {data?.importedBy.firstName + " " + data?.importedBy.lastName ||
                  "N/A"}
              </span>
            </li>

            <li className="flex items-center">
              <PiMailboxLight className="me-2  w-5 shrink-0 text-xl" />
              Email:
              <span className="ps-2 font-medium text-gray-1000">
                {data?.importedBy?.email || "N/A"}
              </span>
            </li>
          </ul>
        </div>
        <div className="border border-gray-200 rounded-lg p-4 col-span-full">
          <ul className="space-y-4 text-xs sm:text-sm">
            <li className="flex items-center">
              Filename:
              <span className="ps-2 font-medium text-gray-1000">
                {data?.filename}
              </span>
            </li>
            <li className="flex items-center">
              Import Type:
              <span className="ps-2 font-medium text-gray-1000">
                {data?.type
                  ? IMPORT_TYPE_LABEL[data?.type] || data?.type
                  : "N/A"}
              </span>
            </li>

            <li className="flex items-center">
              Imported At:
              <span className="ps-2 font-medium text-gray-1000">
                {data
                  ? formatDate(data.createdAt, "MMM DD, YYYY hh:mm:ss A Z")
                  : "N/A"}
              </span>
            </li>
            <li className="flex items-center">
              Last Updated At:
              <span className="ps-2 font-medium text-gray-1000">
                {data
                  ? formatDate(data.updatedAt, "MMM DD, YYYY hh:mm:ss A Z")
                  : "N/A"}
              </span>
            </li>
          </ul>
        </div>
        <div className="border border-gray-200 rounded-lg p-4 col-span-full">
          <ul className="space-y-4 text-xs sm:text-sm">
            <li className="flex items-center">
              Status:
              <span className="ps-2">
                {data?.status ? (
                  <Text
                    className={cn([
                      "font-semibold",
                      "uppercase",
                      StatusColors[data?.status],
                    ])}
                  >
                    {data?.status}
                  </Text>
                ) : (
                  "N/A"
                )}
              </span>
            </li>
            {data?.status === EImportTaskStatus.Failed ? (
              <li className="flex items-center">
                Failure Reason:
                <span className="ps-2 font-medium text-gray-1000">
                  {data.failureReason || "N/A"}
                </span>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
}
