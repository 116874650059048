import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { IFloor, IFloorDataForm } from "@/utilities/types/Floor.ts";
import { FLOORS } from "@/utilities/enums/FloorPlan";

function getFloor({ queryParams }: IRequestArgs) {
  const query = {
    projectId: queryParams?.query?.filter[0].projectId,
    ...queryParams?.pagination,
  };
  return request<ListResponseData<IFloor>>({
    method: ERequestMethods.GET,
    url: FLOORS.GET,
    params: query,
  });
}

function createFloor(data: IFloorDataForm) {
  return request<IResponseData<IFloor>>({
    method: ERequestMethods.POST,
    url: FLOORS.CREATE,
    data,
  });
}

function UpdateFloor(id: string, data: IFloorDataForm) {
  return request<IResponseData<IFloor>>({
    method: ERequestMethods.PATCH,
    url: FLOORS.UPDATE(id),
    data,
  });
}

function RemoveFloor(id: string) {
  return request<IResponseData<IFloor>>({
    method: ERequestMethods.DELETE,
    url: FLOORS.REMOVE(id),
  });
}

function GetFloorDetails(id: string) {
  return request<IResponseData<IFloor>>({
    method: ERequestMethods.GET,
    url: FLOORS.DETAIL(id),
  });
}

const FloorPlansRepository = Object.freeze({
  getFloor,
  createFloor,
  UpdateFloor,
  RemoveFloor,
  GetFloorDetails,
});

export default FloorPlansRepository;
