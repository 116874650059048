import EnquiryEntriesTable from "@/app/app-shared/enquiry-entrties/table";
import { PiFolderUser, PiUserCheck } from "react-icons/pi";
import { Tab, Title } from "rizzui";

export default function EnquiryEntriesPage() {
  return (
    // <div className="mt-6">
    //   <div className="relative z-50 mb-4 flex flex-wrap items-center justify-between gap-2.5 @container ">
    //     <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
    //       All Leads
    //     </Title>
    //   </div>
    //   <Tab>
    //     <Tab.List>
    //       <Tab.ListItem>
    //         <PiUserCheck className="h-4 w-4" />
    //         Leads
    //       </Tab.ListItem>
    //       <Tab.ListItem>
    //         <PiFolderUser className="h-4 w-4" /> Appointment entries
    //       </Tab.ListItem>
    //     </Tab.List>
    //     <Tab.Panels>
    //       <Tab.Panel>
    //         <EnquiryEntriesTable />
    //       </Tab.Panel>
    //       <Tab.Panel>
    //         <EnquiryEntriesTable />
    //       </Tab.Panel>
    //     </Tab.Panels>
    //   </Tab>
    // </div>
    <EnquiryEntriesTable />
  );
}
