import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { IAmenity, IAmenityDTO } from "../types/Amenity";
import { E_AMENITY } from "../enums/Amenity";

function getAllAmenity({ queryParams }: IRequestArgs) {
  const query = {
    projectId: queryParams?.query?.filter?.[0]?.projectId || "",
    sortBy: "createdAt",
    sort: "DESC",
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<IAmenity>>({
    method: ERequestMethods.GET,
    url: E_AMENITY.MAIN,
    params: query,
  });
}

function deleteAmenity(id: string) {
  return request<IResponseData<IAmenity>>({
    method: ERequestMethods.DELETE,
    url: E_AMENITY.AMENITY_ID(id),
  });
}

function createAmenity(data: IAmenityDTO) {
  return request<IResponseData<IAmenity>>({
    method: ERequestMethods.POST,
    url: E_AMENITY.MAIN,
    data,
  });
}

function updateAmenity(id: string, data: Partial<IAmenityDTO>) {
  return request<IResponseData<IAmenity>>({
    method: ERequestMethods.PATCH,
    url: E_AMENITY.AMENITY_ID(id),
    data,
  });
}

const AmenityRepository = Object.freeze({
  getAllAmenity,
  deleteAmenity,
  createAmenity,
  updateAmenity,
});

export default AmenityRepository;
