import { Enum } from "./Enum";

export const E_ANALYTICS = Object.freeze({
  UNIT_TYPE: "/analytic/unit-type-stats",
  TOP_RANGE_PRICE: "/analytic/price-range-stats",
  TOP_PROJECT: "/analytic/project-stats",

  UNIT_TYPE_AGENT: "/analytic/unit-type-stats/contact-sales-submission",
  TOP_PROJECT_AGENT: "/analytic/project-stats/contact-sales-submission",
});

export type E_ANALYTICS = Enum<typeof E_ANALYTICS>;
