import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import {
  VirtualProjectDTO,
  VirtualProjectItem,
} from "@/utilities/types/VirtualProject.ts";
import { request } from "@/utilities/libs/request.ts";
import { E_VirtualProject } from "@/utilities/enums/VirtualProject.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";

function getVirtualProjects({ queryParams }: IRequestArgs) {
  const query = {
    projectId:
      queryParams?.query?.projectId || queryParams?.query?.filter[0].projectId,
  };

  return request<ListResponseData<VirtualProjectItem>>({
    method: ERequestMethods.GET,
    params: query,
    url: E_VirtualProject.MAIN,
  });
}
function createVirtualProject(data: VirtualProjectDTO) {
  return request<IResponseData<VirtualProjectItem>>({
    method: ERequestMethods.POST,
    url: E_VirtualProject.MAIN,
    data,
  });
}

function updateVirtualProject(id: string, data: VirtualProjectDTO) {
  return request<ListResponseData<VirtualProjectItem>>({
    method: ERequestMethods.PATCH,
    url: E_VirtualProject.UD(id),
    data,
  });
}

function deleteVirtualProject(id: string) {
  return request<IResponseData<VirtualProjectItem>>({
    method: ERequestMethods.DELETE,
    url: E_VirtualProject.UD(id),
  });
}

const VirtualProjectRepository = Object.freeze({
  getVirtualProjects,
  updateVirtualProject,
  deleteVirtualProject,
  createVirtualProject,
});

export default VirtualProjectRepository;
