import { useModal } from "@/app/shared/modal-views/use-modal";
import useApi from "@/hooks/useApi";
import LocationRepository from "@/utilities/repositories/Location";
import { ILocation } from "@/utilities/types/Location";
import {
  CreateLocationInput,
  createLocationSchema,
} from "@/utils/validators/location.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiXBold } from "react-icons/pi";
import { ActionIcon, Button, Input, Title } from "rizzui";

export interface CreateEditLocationProps {
  refetchData?: () => void;
  data?: ILocation;
}

export default function CreateEditLocation({
  refetchData,
  data,
}: CreateEditLocationProps) {
  const isUpdate = !!data;
  const title = isUpdate ? "Edit Location" : "Create Location";
  const form = useForm({
    defaultValues: data,
    resolver: zodResolver(createLocationSchema),
  });
  const { closeModal, isOpen } = useModal();

  const { loading, request: createLocation } = useApi({
    request: LocationRepository.createLocation,
  });
  const { loading: loadingUpdate, request: updateLocation } = useApi({
    request: LocationRepository.updateLocation,
  });

  const onSubmit = async (formData: CreateLocationInput) => {
    try {
      if (isUpdate) {
        await updateLocation(data.id, formData);
      } else {
        await createLocation(formData);
      }
      toast.success("Successfully");
      refetchData?.();
      if (isOpen) {
        closeModal();
      }
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  return (
    <form
      noValidate
      className="grid grid-cols-1 gap-6 p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className="col-span-full flex items-center justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          {title}
        </Title>
        {isOpen && (
          <ActionIcon size="sm" variant="text" onClick={closeModal}>
            <PiXBold className="h-auto w-5" />
          </ActionIcon>
        )}
      </div>

      <Input
        label="Location Name"
        size="lg"
        placeholder="Enter location name"
        {...form.register("name")}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.name?.message}
      />

      <div className="col-span-full flex items-center justify-end gap-4">
        <Button
          variant="outline"
          onClick={closeModal}
          className="md:w-auto w-1/2"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          isLoading={loading || loadingUpdate}
          className="md:w-auto w-1/2"
          disabled={!form?.formState?.isDirty}
        >
          {title}
        </Button>
      </div>
    </form>
  );
}
