import { SOCIAL_TYPES } from "@/config/constants";
import { ESocialLinks } from "@/utilities/enums/Social";
import cn from "@/utils/class-names";
import { Controller, useFieldArray } from "react-hook-form";
import { PiPlusBold, PiTrashBold } from "react-icons/pi";
import { Button, Input, Select, SelectOption } from "rizzui";

interface IMultipleEmailsFieldProps {
  name: string;
  control: any;
  register: any;
  errors: any;
  agencyDefaultEmail: string;
}

export const MultipleEmailsField: React.FC<IMultipleEmailsFieldProps> = ({
  name,
  control,
  register,
  errors,
  agencyDefaultEmail,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <div className="flex flex-col col-span-full">
      <div className="grid grid-cols-1 gap-2 w-full">
        {fields.map((field: any, index) => {
          const isDefaultEmail = field.email === agencyDefaultEmail;
          return (
            <div
              key={field.id}
              className="border-muted mb-2 items-start rounded-lg border p-2 shadow @md:p-5 @xl:p-3"
            >
              <div className="grid w-full items-start gap-3 grid-cols-1">
                <Input
                  label="Email"
                  placeholder="Enter email address"
                  {...register(`${name}.${index}.email`)}
                  defaultValue={field.name}
                  error={errors?.[name]?.[index]?.email?.message}
                  disabled={isDefaultEmail}
                />
              </div>
              <Button
                variant="text"
                color="danger"
                onClick={() => remove(index)}
                disabled={isDefaultEmail}
                className={cn(
                  "-mx-2 -mb-1 ms-auto mt-5 h-auto px-2 py-1 font-semibold",
                  isDefaultEmail && "pointer-events-none"
                )}
              >
                <PiTrashBold className="me-1 h-[18px] w-[18px]" /> Remove
              </Button>
            </div>
          );
        })}
        <div className="flex w-full flex-col items-start justify-between @4xl:flex-row">
          <Button
            onClick={() => {
              append({ url: "", type: "", label: "" });
            }}
            variant="flat"
            className="w-full @4xl:mb-0 @4xl:mt-0 @4xl:w-auto"
          >
            <PiPlusBold className="me-1.5 h-4 w-4" /> Add Item
          </Button>
        </div>
      </div>
      {errors?.[name]?.[0]?.root?.message && (
        <p className="text-red text-[13px] mt-1.5 rizzui-input-error-text">
          {errors?.[name]?.[0]?.root?.message}
        </p>
      )}
    </div>
  );
};
