"use client";

import WidgetCard from "@/components/cards/widget-card";
import { DatePicker } from "@/components/ui/datepicker";
import { Title } from "rizzui";
import cn from "@/utils/class-names";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from "recharts";
import useApi from "@/hooks/useApi";
import { ListResponseData } from "@/utilities/types/requests";
import { IUnitTypeAnalytic } from "@/utilities/types/Analytic";
import AnalyticsRepository from "@/utilities/repositories/Analytic";
import dayjs from "dayjs";
import { useInterval } from "usehooks-ts";
import { Skeleton } from "@/components/ui/skeleton";
import useProfile from "@/hooks/use-profile";
import { Roles } from "@/utilities/types/Users";

const COLORS = [
  "#FF6384", // Red
  "#36A2EB", // Blue
  "#FFCE56", // Yellow
  "#4BC0C0", // Teal
  "#9966FF", // Purple
  "#FF9F40", // Orange
  "#C9CBCF", // Grey
  "#FFCD56", // Light Yellow
  "#A5DFDF", // Light Teal
  "#FF99C8", // Pink
];

interface IUnitTypeChartData {
  name: string;
  value: number;
}
const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, midAngle } =
    props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius - 100) * cos;
  const sy = cy + (outerRadius - 100) * sin;
  return (
    <Sector
      cx={sx}
      cy={sy}
      cornerRadius={5}
      innerRadius={50}
      outerRadius={120}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={props.fill}
    />
  );
};

export default function UnitType({ className }: { className?: string }) {
  const [activeIndex, setActiveIndex] = useState(1);
  const [startDate, setStartDate] = useState<Date | null>(
    dayjs().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [chartData, setChartData] = useState<IUnitTypeChartData[]>([]);
  const [currentCount, setCurrentCount] = useState<number>(0);

  const { profile } = useProfile();

  const currentRole = profile?.role;

  const {
    request: getUnitTypeAnalytics,
    response: unitTypeAnalyticResponse,
    loading,
  } = useApi<ListResponseData<IUnitTypeAnalytic>>({
    request: !currentRole
      ? null
      : currentRole === Roles.ADMIN
        ? AnalyticsRepository.getUnitTypeAnalytics
        : AnalyticsRepository.getUnitTypeAnalyticsAgent,
  });
  const titleChart = useRef<number>(0);

  const onMouseOver = useCallback((_: any, index: number) => {
    setActiveIndex(index);
  }, []);
  const onMouseLeave = useCallback(() => {
    setActiveIndex(0);
  }, []);

  const totalValue =
    chartData?.reduce((pre, cur) => pre + (cur?.value ?? 0), 0) || 0;

  const [index, setIndex] = useState(0);

  useInterval(() => {
    setIndex(index + 1);
  }, 5000);

  useEffect(() => {
    if (startDate?.toISOString() && endDate?.toISOString()) {
      getUnitTypeAnalytics(startDate.toISOString(), endDate.toISOString());
    }
  }, [startDate?.toISOString(), endDate?.toISOString(), index, currentRole]);

  useEffect(() => {
    if (unitTypeAnalyticResponse?.data) {
      setChartData(
        unitTypeAnalyticResponse?.data?.items?.map((item) => ({
          name: item?.title || item?.unitTypeTitle || "",
          value: item?.entriesCount,
        })) || []
      );

      const count =
        unitTypeAnalyticResponse?.data?.totalCount?.entriesCount || 0;
      if (titleChart.current !== count) {
        titleChart.current = count;
      }
    }
  }, [unitTypeAnalyticResponse?.data]);
  const handleChange = ([newStartDate, newEndDate]: [
    Date | null,
    Date | null,
  ]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const chartDescription = useMemo(() => {
    const count = unitTypeAnalyticResponse?.data?.totalCount?.entriesCount;

    let number = count ?? currentCount;

    if (!count || count === currentCount) {
      number = currentCount;
    } else {
      setCurrentCount(count);
    }

    if (number > 1) {
      return `${number} leads`;
    } else {
      return `${!number ? 0 : number} lead`;
    }
  }, [currentCount, unitTypeAnalyticResponse?.data?.totalCount?.entriesCount]);

  return (
    <WidgetCard
      title="Top Unit Type"
      titleClassName="text-gray-700 font-bold md:text-2xl text-xl font-inter w-fit"
      description={chartDescription}
      className={cn("@container", className)}
      actionClassName="flex-1 flex w-full md:w-[280px] justify-end"
      action={
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          selectsRange
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd MMM, yyyy"
          placeholderText="Select date"
          popperPlacement="bottom-end"
          inputProps={{
            variant: "text",
            inputClassName: "p-0 px-1 h-auto [&_input]:text-ellipsis",
          }}
          className="rounded border [&_.rizzui-input-container]:px-3 [&_.rizzui-input-container]:py-1.5 w-full md:w-[280px]"
        />
      }
    >
      <div className="flex flex-col overflow-visible gap-6">
        <div className="relative h-[300px] w-full after:absolute after:inset-1/2 after:h-24 after:w-24 after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full after:border after:border-dashed after:border-gray-300 @sm:py-3 !py-0">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart className="[&_.recharts-layer:focus]:outline-none [&_.recharts-sector:focus]:outline-none dark:[&_.recharts-text.recharts-label]:first-of-type:fill-white">
              <Pie
                activeIndex={activeIndex}
                data={chartData}
                cornerRadius={5}
                innerRadius={70}
                outerRadius={120}
                paddingAngle={6}
                stroke="rgba(0,0,0,0)"
                dataKey="value"
                activeShape={renderActiveShape}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
              >
                {chartData.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="grid grid-cols-1 gap-4 gap-x-6 @[24rem]:grid-cols-2 @[28rem]:mx-auto">
          {chartData?.map((item, index) => (
            <Detail
              key={`unit-type-${index}`}
              color={COLORS?.[index]}
              value={Number(((item?.value / totalValue) * 100).toFixed(2))}
              text={item?.name}
            />
          ))}
        </div>
      </div>
    </WidgetCard>
  );
}

function Detail({
  color,
  value,
  text,
}: {
  color: string;
  value: number;
  text: string;
}) {
  return (
    <div className="flex justify-between items-center gap-2">
      <div className=" col-span-3 flex items-center justify-start gap-1.5 flex-1">
        <span
          style={{ background: color }}
          className="block h-2.5 w-2.5 rounded-full"
        />
        <p className="text-gray-500 flex-1">{text}</p>
      </div>
      <span
        style={{ borderColor: color }}
        className="rounded-full border-2 px-2 py-0.5 font-bold text-gray-700 h-fit w-[70px] text-center"
      >
        {value}%
      </span>
    </div>
  );
}
