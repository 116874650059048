import { useModal } from "@/app/shared/modal-views/use-modal";
import SingleImageUpload from "@/components/ui/file-upload/single-image-upload";
import useApi from "@/hooks/useApi";
import DeveloperRepository from "@/utilities/repositories/Developer";
import { IDeveloper } from "@/utilities/types/Developer";
import {
  CreateDeveloperInput,
  createDeveloperSchema,
} from "@/utils/validators/developer-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiXBold } from "react-icons/pi";
import { ActionIcon, Button, Input, Textarea, Title } from "rizzui";

export interface CreateEditDeveloperProps {
  refetchData?: () => void;
  data?: IDeveloper;
}

export default function CreateEditDeveloper({
  refetchData,
  data,
}: CreateEditDeveloperProps) {
  const isUpdate = !!data;
  const title = isUpdate ? "Edit Developer" : "Create Developer";
  const form = useForm({
    defaultValues: {
      name: "",
      description: "",
      logo: { id: "", url: "" },
    },
    resolver: zodResolver(createDeveloperSchema),
  });

  const { closeModal, isOpen } = useModal();

  const { loading, request: createDeveloper } = useApi({
    request: DeveloperRepository.createDeveloper,
  });
  const { loading: loadingUpdate, request: updateDeveloper } = useApi({
    request: DeveloperRepository.updateDeveloper,
  });

  const onSubmit = async (formData: CreateDeveloperInput) => {
    const dataToCreateOrUpdate = {
      name: formData?.name || "",
      logoId: formData?.logo?.id || "",
      description: formData?.description || "",
    };
    try {
      if (isUpdate) {
        await updateDeveloper(data.id, dataToCreateOrUpdate);
      } else {
        await createDeveloper(dataToCreateOrUpdate);
      }
      toast.success("Successfully");
      refetchData?.();
      if (isOpen) {
        closeModal();
      }
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    form.reset({
      name: typeof data?.name === "string" ? data?.name : data?.name?.en,
      description:
        typeof data?.description === "string"
          ? data?.description
          : data?.description?.en,
      logo: { id: data?.logoId || "", url: data?.logo?.urls?.[0]?.url || "" },
    });
  }, [JSON.stringify(data)]);

  return (
    <form
      noValidate
      className="grid grid-cols-1 gap-6 p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className="col-span-full flex items-center justify-between">
        <Title as="h4" className="font-semibold">
          {title}
        </Title>
        {isOpen && (
          <ActionIcon size="sm" variant="text" onClick={closeModal}>
            <PiXBold className="h-auto w-5" />
          </ActionIcon>
        )}
      </div>
      <div className="[&>label>span]:font-medium  col-span-full">
        <Controller
          control={form.control}
          name="logo"
          render={({ field: { onChange } }) => (
            <SingleImageUpload
              imageUrl={form.watch("logo")?.url || ""}
              title="Logo"
              setValue={onChange}
              uploaderText="Upload logo"
              uploadContainerClass="w-full h-full md:w-1/3"
              error={form?.formState?.errors?.logo?.id?.message}
            />
          )}
        />
      </div>
      <Input
        label="Name"
        size="lg"
        placeholder="Enter name"
        {...form.register("name")}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.name?.message}
      />
      <Textarea
        label="Description"
        size="lg"
        placeholder="Enter description"
        {...form.register("description")}
        rows={3}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.description?.message}
      />
      <div className="col-span-full flex items-center justify-end gap-4">
        <Button
          variant="outline"
          onClick={closeModal}
          className="md:w-auto w-1/2"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          isLoading={loading || loadingUpdate}
          className="md:w-auto w-1/2"
          disabled={!form?.formState?.isDirty}
        >
          {title}
        </Button>
      </div>
    </form>
  );
}
