import { Enum } from "./Enum";

export const E_SETTINGS = Object.freeze({
  AGENCY: "/user-config/my-config",
  UPDATE_AGENCY: "/user-config/:id",
  CREATE_AGENCY: "/user-config",
  CONFIG_LOCATION: "/user-config-location",
  CONFIG_LOCATION_ID: (id: string = ":id") => `/user-config-location/${id}`,
  SECTION_CONFIG: "/section",
  SECTION_CONFIG_ID: (id: string = ":id") => `/section/${id}`,
  CHECK_DOMAIN: "/domains/check",
  UPDATE_DOMAIN: (id: string = ":id") => `/domains/${id}`,
  CREATE_DOMAIN: `/domains`,
  GENERATE_UNIQUE_DOMAIN: `/user-project-landing-page/unique-domain`,
});

export type E_SETTINGS = Enum<typeof E_SETTINGS>;
