import LoginActivityTable from "@/app/app-shared/login-activity/table";

export interface LoginActivityPageProps {}

export default function LoginActivityPage(props: LoginActivityPageProps) {
  return (
    <div className="mt-4">
      <LoginActivityTable />
    </div>
  );
}
