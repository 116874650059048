import { request } from "@/utilities/libs/request.ts";
import { IResponseData, ListResponseData } from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import {
  IDomainDTO,
  ISectionConfig,
  TAgencySetting,
  TAgencySettingCreate,
  TAgencySettingUpdate,
  TDomain,
} from "../types/Setting";
import { E_SETTINGS } from "../enums/Settings";

function getAgencySetting() {
  return request<IResponseData<TAgencySetting>>({
    method: ERequestMethods.GET,
    url: E_SETTINGS.AGENCY,
  });
}

function checkAvailableDomain(domain: string, configId?: string) {
  return request<
    IResponseData<{
      data: null;
      message: string;
      statusCode: number;
      success: boolean;
    }>
  >({
    method: ERequestMethods.GET,
    url: `${E_SETTINGS.CHECK_DOMAIN}`,
    params: { domainName: domain, configId },
  });
}

function updateAgencySetting(id: string, data: TAgencySettingUpdate) {
  return request<IResponseData<TAgencySetting>>({
    method: ERequestMethods.PATCH,
    url: E_SETTINGS.UPDATE_AGENCY.replace(":id", id),
    data,
  });
}

function createAgencySetting(data: TAgencySettingCreate) {
  return request<IResponseData<TAgencySetting>>({
    method: ERequestMethods.POST,
    url: E_SETTINGS.CREATE_AGENCY,
    data,
  });
}

function getAllConfigLocations() {
  return request<ListResponseData<ISectionConfig>>({
    method: ERequestMethods.GET,
    url: E_SETTINGS.CONFIG_LOCATION,
    params: {
      limit: 50,
      page: 1,
    },
  });
}

function addConfigLocation(data: any) {
  return request<IResponseData<ISectionConfig>>({
    method: ERequestMethods.POST,
    url: E_SETTINGS.CONFIG_LOCATION,
    data,
  });
}

function updateConfigLocation(id: string, data: any) {
  return request<IResponseData<ISectionConfig>>({
    method: ERequestMethods.PATCH,
    url: E_SETTINGS.CONFIG_LOCATION_ID(id),
    data,
  });
}

function updateDomain(id: string, data: any) {
  return request<IResponseData<TDomain>>({
    method: ERequestMethods.PATCH,
    url: E_SETTINGS.UPDATE_DOMAIN(id),
    data,
  });
}

function createDomain(data: IDomainDTO) {
  return request<IResponseData<TDomain>>({
    method: ERequestMethods.POST,
    url: E_SETTINGS.CREATE_DOMAIN,
    data,
  });
}

function generateUniqeDomain(projectId: string) {
  return request<IResponseData<string>>({
    method: ERequestMethods.POST,
    url: E_SETTINGS.GENERATE_UNIQUE_DOMAIN,
    data: { projectId },
  });
}

const SettingsRepository = Object.freeze({
  getAgencySetting,
  updateAgencySetting,
  createAgencySetting,
  getAllConfigLocations,
  addConfigLocation,
  updateConfigLocation,
  checkAvailableDomain,
  updateDomain,
  createDomain,
  generateUniqeDomain,
});

export default SettingsRepository;
