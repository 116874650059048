import { z } from "zod";

export const landingPageSchema = z.object({
  domain: z.string().optional(),
  name: z.string().min(1, { message: "This field is required" }),
  project: z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .optional(),
  user: z.string().optional(),
});

export type TLandingPageSchemaForm = z.infer<typeof landingPageSchema>;
