import { z } from "zod";

export const emailTemplateSchema = z.object({
  subject: z.string().min(1, { message: "This field is required!" }),
  body: z.string().min(1, { message: "This field is required!" }),
  name: z.string().optional(),
});

// generate form types from zod validation schema
export type EmailTemplateInput = z.infer<typeof emailTemplateSchema>;
