import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { E_ENQUIRY } from "../enums/EnquiryEntries";
import { IEnquiryEntry, IEnquirySubmissionLog } from "../types/EnquiryEntry";

function getList({ queryParams }: IRequestArgs) {
  let query = {
    sort: queryParams?.query?.sort,
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  queryParams?.query?.filter?.forEach((item) => {
    query = {
      ...query,
      ...item,
    };
  });

  return request<ListResponseData<IEnquiryEntry>>({
    method: ERequestMethods.GET,
    url: E_ENQUIRY.MAIN,
    params: query,
  });
}

function getListByAgency({ queryParams }: IRequestArgs) {
  let query = {
    sort: queryParams?.query?.sort,
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  queryParams?.query?.filter?.forEach((item) => {
    query = {
      ...query,
      ...item,
    };
  });

  return request<ListResponseData<IEnquiryEntry>>({
    method: ERequestMethods.GET,
    url: E_ENQUIRY.MAIN + "/by-agency",
    params: query,
  });
}

function getById(id: string) {
  return request<IResponseData<IEnquiryEntry>>({
    method: ERequestMethods.GET,
    url: E_ENQUIRY.DETAIL.replace(":id", id),
  });
}

function deleteItem(id: string) {
  return request<IResponseData<IEnquiryEntry>>({
    method: ERequestMethods.DELETE,
    url: E_ENQUIRY.DETAIL.replace(":id", id),
  });
}

function getLogsById(id: string) {
  return request<ListResponseData<IEnquirySubmissionLog>>({
    method: ERequestMethods.GET,
    url: E_ENQUIRY.LOGS.replace(":id", id),
  });
}

const EnquiryEntriesRepository = Object.freeze({
  getList,
  getById,
  deleteItem,
  getListByAgency,
  getLogsById,
});

export default EnquiryEntriesRepository;
