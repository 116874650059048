import React, { useState } from "react";
import { Controller } from "react-hook-form";
import ColorPickerPopover from "@/app/shared/colorpicker-popover"; // Update the import path
import { IColorScheme } from "@/utilities/types/ColorScheme.ts";
import { IColors } from "@/utilities/types/Color.ts";
import ColorSchemaPreview from "@/components/ui/color-scheme-preview.tsx";
import transformColorVariable from "@/utilities/functions/transform-color-variable.ts";

interface ColorSchemaFieldProps {
  title: string;
  name: string;
  control: any;
  register: any;
  errors: any;
  colors: IColors;
  colorScheme: IColorScheme;
  previewUrl: string;
}

const isValidColor = (color: string) => {
  const s = new Option().style;
  s.color = color;
  return s.color !== "";
};

export const ColorSchemaField: React.FC<ColorSchemaFieldProps> = ({
  title,
  name,
  control,
  errors,
  colors,
  colorScheme,
  previewUrl,
}) => {
  const [schemeColors, setSchemeColors] = useState<IColors>(colors);

  const handleColorChange = (key: string, value: string) => {
    setSchemeColors((prevColors) => ({
      ...prevColors,
      [key]: value,
    }));
  };

  return (
    <div className="flex md:flex-row flex-col rounded-lg bg-muted p-6 gap-6">
      <div className="grow p-0 md:p-6 flex items-center justify-center rounded-lg bg-white">
        <ColorSchemaPreview
          colors={schemeColors}
          baseUrl={`https://${previewUrl}${colorScheme?.slug}`}
        />
      </div>
      <div className="shrink-0 flex flex-col gap-3">
        <p className="font-bold text-2xl">{title}</p>
        {Object.keys(colors).map((colorKey) => {
          const colorValue = colors[colorKey];
          return (
            <div key={colorKey} className="">
              <p className="rizzui-input-label block text-sm mb-1.5 font-medium text-muted-foreground">
                {transformColorVariable(colorKey, title)}
              </p>

              <Controller
                control={control}
                name={`${name}.${colorKey}`}
                defaultValue={colorValue}
                rules={{
                  validate: {
                    isValidColor: (value) =>
                      isValidColor(value) || "Invalid color value",
                  },
                }}
                render={({ field }) => (
                  <div className="relative h-full flex flex-col">
                    <ColorPickerPopover
                      value={field.value || ""}
                      onChange={(value: string) => {
                        handleColorChange(colorKey, value);
                        field.onChange(value);
                      }}
                      className="w-full h-[42px] flex-1"
                    />
                    {errors[name] && errors[name][colorKey] && (
                      <p className="text-red text-[13px] mt-1.5 rizzui-input-error-text">
                        {errors[name][colorKey].message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
