import { useModal } from "@/app/shared/modal-views/use-modal";
import useApi from "@/hooks/useApi";
import UnitTypeRepository from "@/utilities/repositories/UnitType";
import { IUnitType } from "@/utilities/types/UnitType";
import {
  CreateUnitTypeInput,
  createUnitTypeSchema,
} from "@/utils/validators/unit-type.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiXBold } from "react-icons/pi";
import { ActionIcon, Button, Checkbox, Input, Title } from "rizzui";

export interface CreateEditUnitTypeProps {
  refetchData?: () => void;
  data?: IUnitType;
}

export default function CreateEditUnitType({
  refetchData,
  data,
}: CreateEditUnitTypeProps) {
  const isUpdate = !!data;
  const title = isUpdate ? "Edit Unit Type" : "Create Unit Type";
  const form = useForm({
    defaultValues: {
      title: "",
      bedRoomCount: 0,
      hasStudyRoom: false,
      isPremium: false,
      isDeluxe: false,
      isPrivateLift: false,
      isCompact: false,
      isCompactPlus: false,
      isDuplex: false,
      isPenthouse: false,
      isUtility: false,
      hasGuest: false,
    },
    resolver: zodResolver(createUnitTypeSchema),
  });

  const { closeModal, isOpen } = useModal();

  const { loading, request: createUnitType } = useApi({
    request: UnitTypeRepository.createUnitType,
  });
  const { loading: loadingUpdate, request: updateUnitType } = useApi({
    request: UnitTypeRepository.updateUnitType,
  });

  const onSubmit = async (formData: CreateUnitTypeInput) => {
    const dataToCreateOrUpdate = {
      ...formData,
      bedRoomCount: Number(formData?.bedRoomCount) || 0,
    };
    try {
      if (isUpdate) {
        await updateUnitType(data.id, dataToCreateOrUpdate);
      } else {
        await createUnitType(dataToCreateOrUpdate);
      }
      toast.success("Successfully");
      refetchData?.();
      if (isOpen) {
        closeModal();
      }
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (data) {
      form.reset({
        title: typeof data?.title === "string" ? data?.title : data?.title?.en,
        bedRoomCount: data?.bedRoomCount || 0,
        hasStudyRoom: !!data?.hasStudyRoom,
        isPremium: !!data?.isPremium,
      });
    }
  }, [JSON.stringify(data)]);

  return (
    <form
      noValidate
      className="grid grid-cols-1 gap-6 p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className="col-span-full flex items-center justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          {title}
        </Title>
        {isOpen && (
          <ActionIcon size="sm" variant="text" onClick={closeModal}>
            <PiXBold className="h-auto w-5" />
          </ActionIcon>
        )}
      </div>

      <Input
        label="Name"
        size="lg"
        placeholder="Enter name"
        {...form.register("title")}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.title?.message}
      />

      <Input
        label="Number of bedrooms"
        size="lg"
        placeholder="Enter number of bedrooms"
        {...form.register("bedRoomCount")}
        type="number"
        min={0}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.bedRoomCount?.message}
      />

      <Checkbox
        {...form.register("hasStudyRoom")}
        label="Has study room"
        className="[&>label>span]:font-medium max-w-max"
        error={form?.formState?.errors?.hasStudyRoom?.message}
      />

      <Checkbox
        {...form.register("isPremium")}
        label="Is premium"
        className="[&>label>span]:font-medium max-w-max"
        error={form?.formState?.errors?.isPremium?.message}
      />

      <Checkbox
        {...form.register("isDeluxe")}
        label="Is Deluxe"
        className="[&>label>span]:font-medium max-w-max"
        error={form?.formState?.errors?.isDeluxe?.message}
      />

      <Checkbox
        {...form.register("isPrivateLift")}
        label="Is Private Lift"
        className="[&>label>span]:font-medium max-w-max"
        error={form?.formState?.errors?.isPrivateLift?.message}
      />

      <Checkbox
        {...form.register("isCompact")}
        label="Is Compact"
        className="[&>label>span]:font-medium max-w-max"
        error={form?.formState?.errors?.isCompact?.message}
      />

      <Checkbox
        {...form.register("isCompactPlus")}
        label="Is Compact+"
        className="[&>label>span]:font-medium max-w-max"
        error={form?.formState?.errors?.isCompactPlus?.message}
      />

      <Checkbox
        {...form.register("isPenthouse")}
        label="Is Penthouse"
        className="[&>label>span]:font-medium max-w-max"
        error={form?.formState?.errors?.isPenthouse?.message}
      />

      <Checkbox
        {...form.register("isUtility")}
        label="Is Utility"
        className="[&>label>span]:font-medium max-w-max"
        error={form?.formState?.errors?.isUtility?.message}
      />

      <Checkbox
        {...form.register("hasGuest")}
        label="Has Guest"
        className="[&>label>span]:font-medium max-w-max"
        error={form?.formState?.errors?.hasGuest?.message}
      />

      <div className="col-span-full flex items-center justify-end gap-4">
        <Button
          variant="outline"
          onClick={closeModal}
          className="md:w-auto w-1/2"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          isLoading={loading || loadingUpdate}
          className="md:w-auto w-1/2"
          disabled={!form?.formState?.isDirty}
        >
          {title}
        </Button>
      </div>
    </form>
  );
}
