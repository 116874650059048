import {Enum} from "@/utilities/enums/Enum.ts";


export const E_MESSAGE = Object.freeze({
    MAIN:"/template",
    MAIN_BY_AGENCY:"/template/by-agency",
    UD:(id: string) => `/template/${id}`,
    UD_BY_AGENCY:(id:string)=> `/template/${id}/by-agency`,
    GET_BY_ADMIN:"/template/by-admin"
})

export type E_MESSAGE = Enum<typeof E_MESSAGE>;
