import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { IMortgage, MortgageDTO } from "@/utilities/types/Mortgage.ts";
import { MORTGAGE } from "@/utilities/enums/Mortgage.ts";

function getMortgageList({ queryParams }: IRequestArgs) {
  const query = {
    sortBy: "createdAt",
    sort: "DESC",
    ...queryParams?.pagination,
  };
  return request<ListResponseData<IMortgage>>({
    method: ERequestMethods.GET,
    url: MORTGAGE.LIST,
    params: query,
  });
}

function createMortgage(data: MortgageDTO) {
  return request<IResponseData<IMortgage>>({
    method: ERequestMethods.POST,
    url: MORTGAGE.CREATE,
    data,
  });
}

function updateMortgage(id: string, data: MortgageDTO) {
  return request<IResponseData<IMortgage>>({
    method: ERequestMethods.PATCH,
    url: MORTGAGE.UD(id),
    data,
  });
}

function deleteMortgage(id: string, data: MortgageDTO) {
  return request<IResponseData<IMortgage>>({
    method: ERequestMethods.DELETE,
    url: MORTGAGE.UD(id),
  });
}

const MortgageRepository = Object.freeze({
  getMortgageList,
  createMortgage,
  updateMortgage,
  deleteMortgage,
});

export default MortgageRepository;
