import { Enum } from "./Enum";

export const LANDINGPAGES = Object.freeze({
  GET: `/user-project-landing-page`,
  GET_BY_PROJECT: `/user-project-landing-page/get-by-project`,
  CREATE: "/user-project-landing-page",
  DETAIL: (id: string) => `/user-project-landing-page/get-by-domain/${id}`,
  UPDATE: (id: string) => `/user-project-landing-page/${id}`,
  REMOVE: (id: string) => `/user-project-landing-page/${id}`,
});

export type E_LANDINGPAGES = Enum<typeof LANDINGPAGES>;
