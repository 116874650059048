import { ERequestMethods } from "../enums/RequestMethod";
import { request } from "../libs/request";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "../types/requests";
import { IImportDataTask } from "../types/ImportData";
import { E_IMPORT_DATA } from "../enums/ImportData";

function getTaskList({ queryParams }: IRequestArgs) {
  const key = Object.keys(queryParams?.query?.sort ?? {})?.[0];
  const filter: Record<string, unknown> = {};

  queryParams?.query?.filter?.forEach((f) => Object.assign(filter, f));

  const query = {
    ...filter,
    ...queryParams?.pagination,
    sort: key ? queryParams?.query?.sort[key] : undefined,
    sortBy: key,
  };
  return request<ListResponseData<IImportDataTask>>({
    method: ERequestMethods.GET,
    url: E_IMPORT_DATA.MAIN,
    params: query,
  });
}

function postImportProject(data: { file: File }) {
  const formData = new FormData();
  formData.set("file", data.file);

  return request<IResponseData<IImportDataTask>>({
    method: ERequestMethods.POST,
    url: E_IMPORT_DATA.PROJECT,
    data: formData,
  });
}

function postImportProjectType(data: { file: File }) {
  const formData = new FormData();
  formData.set("file", data.file);

  return request<IResponseData<IImportDataTask>>({
    method: ERequestMethods.POST,
    url: E_IMPORT_DATA.PROJECT_TYPE,
    data: formData,
  });
}

function postImportFloorPlan(data: { file: File }) {
  const formData = new FormData();
  formData.set("file", data.file);

  return request<IResponseData<IImportDataTask>>({
    method: ERequestMethods.POST,
    url: E_IMPORT_DATA.FLOOR_PLAN,
    data: formData,
  });
}

function postImportUnitType(data: { file: File }) {
  const formData = new FormData();
  formData.set("file", data.file);

  return request<IResponseData<IImportDataTask>>({
    method: ERequestMethods.POST,
    url: E_IMPORT_DATA.UNIT_TYPE,
    data: formData,
  });
}

function postImportUnitTransaction(data: { file: File }) {
  const formData = new FormData();
  formData.set("file", data.file);

  return request<IResponseData<IImportDataTask>>({
    method: ERequestMethods.POST,
    url: E_IMPORT_DATA.UNIT_TRANSACTION,
    data: formData,
  });
}

function postImportUnitTransactionProject(data: {
  file: File;
  projectId: string;
}) {
  const formData = new FormData();
  formData.set("file", data.file);
  formData.set("projectId", data.projectId);

  return request<IResponseData<IImportDataTask>>({
    method: ERequestMethods.POST,
    url: E_IMPORT_DATA.UNIT_TRANSACTION,
    data: formData,
  });
}

function postImportDirectCommission(data: { file: File }) {
  const formData = new FormData();
  formData.set("file", data.file);

  return request<IResponseData<IImportDataTask>>({
    method: ERequestMethods.POST,
    url: E_IMPORT_DATA.DIRECT_COMMISSION,
    data: formData,
  });
}

function postImportMortgage(data: { file: File }) {
  const formData = new FormData();
  formData.set("file", data.file);

  return request<IResponseData<IImportDataTask>>({
    method: ERequestMethods.POST,
    url: E_IMPORT_DATA.MORTGAGE,
    data: formData,
  });
}

const ImportDataRepository = Object.freeze({
  getTaskList,
  postImportProject,
  postImportProjectType,
  postImportUnitType,
  postImportUnitTransaction,
  postImportUnitTransactionProject,
  postImportFloorPlan,
  postImportDirectCommission,
  postImportMortgage,
});

export default ImportDataRepository;
