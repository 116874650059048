import { Enum } from './Enum';

export const ERequestMethods = Object.freeze({
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
});

export type ERequestMethods = Enum<typeof ERequestMethods>;