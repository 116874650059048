import { Enum } from "./Enum";

export const E_EMAIL_TEMPLATE = Object.freeze({
  MAIN: "/email-template",
});

export type E_EMAIL_TEMPLATE = Enum<typeof E_EMAIL_TEMPLATE>;

export enum EEmailTemplateKeys {
  WELCOME_PROJECT = "welcome-to-project",
}
