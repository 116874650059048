import { E_FEATURE } from "../enums/AdvanceFeature";
import { ERequestMethods } from "../enums/RequestMethod";
import { request } from "../libs/request";
import {
  DynamicLayoutModeDTO,
  IFeature,
  IUserFeature,
  UserFeatureDTO,
} from "../types/AdvanceFeature";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "../types/requests";

function getFeaturesInApp({ queryParams }: IRequestArgs) {
  return request<ListResponseData<IFeature>>({
    method: ERequestMethods.GET,
    url: E_FEATURE.MAIN,
    params: queryParams,
  });
}

function getUserFeaturesById(userId: string) {
  return request<IResponseData<IUserFeature[]>>({
    method: ERequestMethods.GET,
    url: E_FEATURE.USER_FEATURE + `/user/${userId}`,
  });
}

function createOrUpdateUserFeature(data: UserFeatureDTO) {
  return request({
    method: ERequestMethods.POST,
    url: E_FEATURE.USER_FEATURE,
    data,
  });
}

function updateDynamicLayoutMode(
  userFeatureId: string,
  data: DynamicLayoutModeDTO
) {
  return request({
    method: ERequestMethods.PATCH,
    url: E_FEATURE.USER_FEATURE + `/${userFeatureId}`,
    data,
  });
}

const AdvanceFeatureRepository = Object.freeze({
  getFeaturesInApp,
  createOrUpdateUserFeature,
  getUserFeaturesById,
  updateDynamicLayoutMode,
});

export default AdvanceFeatureRepository;
