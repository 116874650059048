import FinancialDashboard from "@/app/app-shared/dashboard";
// import useProfile from "@/hooks/use-profile";
// import { findAccessiblePath } from "@/utilities/functions";
// import { Navigate } from "react-router-dom";

function Home() {
  // const { profile } = useProfile();
  // const defaultPath = findAccessiblePath(profile?.role);
  //return <Navigate to={defaultPath || "/"} replace />; // Defaut page here

  return <FinancialDashboard />;
}

export default Home;
