import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import {
  IProjectCategory,
  IProjectCategoryDTO,
} from "../types/ProjectCategory";
import { E_PROJECT_CATEGORY } from "../enums/ProjectCategory";

function getAllProjectCategory({ queryParams }: IRequestArgs) {
  const query = {
    filter: queryParams?.query?.filter,
    sortBy: "createdAt",
    sort: "DESC",
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<IProjectCategory>>({
    method: ERequestMethods.GET,
    url: E_PROJECT_CATEGORY.LISTING,
    params: query,
  });
}

function deleteProjectCategory(id: string) {
  return request<IResponseData<IProjectCategory>>({
    method: ERequestMethods.DELETE,
    url: E_PROJECT_CATEGORY.PROJECT_CATEGORY_ID(id),
  });
}

function createProjectCategory(data: IProjectCategoryDTO) {
  return request<IResponseData<IProjectCategory>>({
    method: ERequestMethods.POST,
    url: E_PROJECT_CATEGORY.MAIN,
    data,
  });
}

function updateProjectCategory(id: string, data: Partial<IProjectCategoryDTO>) {
  return request<IResponseData<IProjectCategory>>({
    method: ERequestMethods.PATCH,
    url: E_PROJECT_CATEGORY.PROJECT_CATEGORY_ID(id),
    data,
  });
}

const ProjectCategoryRepository = Object.freeze({
  getAllProjectCategory,
  deleteProjectCategory,
  createProjectCategory,
  updateProjectCategory,
});

export default ProjectCategoryRepository;
