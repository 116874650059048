import React, { useState, useEffect } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { Popover } from "react-tiny-popover";
import cn from "@/utils/class-names";

interface ColorPickerPopoverProps {
  value: string;
  onChange: (color: string) => void;
  className?: string;
}

const ColorPickerPopover: React.FC<ColorPickerPopoverProps> = ({
  value,
  onChange,
  className,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState<string>(value);

  useEffect(() => {
    setColor(value);
  }, [value]);

  const handleChange = (color: ColorResult) => {
    const { r, g, b, a } = color.rgb;
    const rgbaValue = `rgba(${r}, ${g}, ${b}, ${a})`;
    setColor(rgbaValue);
    onChange(rgbaValue);
  };

  const getColorFormat = (color: string) => {
    if (color.startsWith("rgba")) return color;
    if (color.length === 9) {
      // Handle hex with alpha (e.g., #FFFFFF40)
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      const a = parseInt(color.slice(7, 9), 16) / 255;
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    }
    return color;
  };

  return (
    <div className={cn(className, "leading-none")}>
      <Popover
        isOpen={showPicker}
        positions={["top", "right", "left", "bottom"]} // preferred positions by priority
        content={
          <div className="custom-sketch-picker">
            <SketchPicker
              color={getColorFormat(color)}
              onChange={handleChange}
            />
          </div>
        }
        containerClassName="z-[1000]"
        onClickOutside={() => setShowPicker(false)}
      >
        <button
          type="button"
          className="w-full h-10 rounded"
          style={{ backgroundColor: color }}
          onClick={() => setShowPicker(!showPicker)}
        />
      </Popover>
    </div>
  );
};

export default ColorPickerPopover;
