"use client";

import {useEffect, useState} from "react";
import { PiXBold } from "react-icons/pi";
import { Controller, SubmitHandler } from "react-hook-form";
import { Form } from "@/components/ui/form";
import { Input, Button, ActionIcon, Title, Password } from "rizzui";
import {
  CreateUserInput,
  createUserSchema,
} from "@/utils/validators/create-user.schema";
import { useModal } from "@/app/shared/modal-views/use-modal";
import { useNavigate } from "react-router-dom";
import UserRepository from "@/utilities/repositories/Users";
import { Roles } from "@/utilities/types/Users";
import toast from "react-hot-toast";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
export default function CreateUser() {
  const { closeModal, isOpen } = useModal();
  const [reset, setReset] = useState({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loadState,setLoadState] = useState(false)
  const handleBack = () => {
    navigate(-1);
    closeModal?.();
  };

  const authHeader = useAuthHeader();

  const createUser = async (dataForm: CreateUserInput) => {
    if (!authHeader) return;
    try {
      setIsLoading(true);
      await UserRepository.createUser({
        ...dataForm,
        role: Roles.AGENCY,
      });
      toast.success(`User created successfully`);
      setTimeout(() => {
        setIsLoading(false);
        setReset({
          firstName: "",
          lastName: "",
          password: "",
          email: "",
          phone: "",
        });
        handleBack();
      }, 600);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
      setIsLoading(false);
    }
  };
  const onSubmit: SubmitHandler<CreateUserInput> = (data) => {
    createUser(data);
  };

  const navigate = useNavigate();
  useEffect(() => {
    setReset({
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      phone: "",
    })
    console.log("refreshed")
    setLoadState(false)
  }, [loadState]);
  useEffect(()=>{
    setLoadState(true)
  },[])
  return (
    <Form<CreateUserInput>
      resetValues={reset}
      onSubmit={onSubmit}
      validationSchema={createUserSchema}
      className="grid grid-cols-1 gap-6 p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900 form-create-user"
    >
      {({ register, control, watch, formState: { errors } }) => {
        return (
          <>
            <div className="col-span-full flex items-center justify-between">
              <Title as="h4" className="font-semibold">
                Add a new User
              </Title>
              {isOpen && (
                <ActionIcon size="sm" variant="text" onClick={closeModal}>
                  <PiXBold className="h-auto w-5" />
                </ActionIcon>
              )}
            </div>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState, formState }) => (
                <Input
                  label="Email"
                  size="lg"
                  placeholder="Enter user's Email Address"
                  className="col-span-full"
                  {...field}
                  {...formState}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )}
            />
            <Password
              label="Password"
              placeholder="Enter your password"
              size="lg"
              className="col-span-full"
              inputClassName="text-sm"
              {...register("password")}
              error={errors.password?.message}
            />
            <Input
              label="First Name"
              size="lg"
              placeholder="Enter user's first name"
              {...register("firstName")}
              className="[&>label>span]:font-medium "
              error={errors.firstName?.message}
            />
            <Input
              label="Last Name"
              size="lg"
              placeholder="Enter user's last name"
              {...register("lastName")}
              className="[&>label>span]:font-medium "
              error={errors.lastName?.message}
            />
            {/* <Controller
              name={"phone"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <PhoneNumber
                  className="col-span-full"
                  size="lg"
                  label="Phone Number"
                  country="vn"
                  value={value}
                  onChange={onChange}
                  // @ts-ignore
                  error={errors?.phone?.message as string}
                />
              )}
            /> */}
            <div className="col-span-full flex items-center justify-end gap-4">
              <Button
                variant="outline"
                onClick={handleBack}
                className="w-full @xl:w-auto"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                isLoading={isLoading}
                className="w-full @xl:w-auto"
              >
                Create User
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}
