import { Title, Text, ActionIcon, Button, Popover } from "rizzui";
import cn from "@/utils/class-names";
import { ReactNode } from "react";

type CustomPopoverProps = {
  title: ReactNode;
  description: string;
  onAccept?: () => Promise<any>;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
};

export default function CustomPopover({
  title,
  description,
  onAccept,
  disabled,
  children,
  className,
}: CustomPopoverProps) {
  return (
    <Popover placement="left">
      <Popover.Trigger>
        <ActionIcon
          size="sm"
          variant="outline"
          disabled={disabled}
          className={cn(
            "cursor-pointer hover:!border-gray-900 hover:text-gray-700",
            disabled && "cursor-not-allowed pointer-events-none",
            className
          )}
        >
          {children}
        </ActionIcon>
      </Popover.Trigger>
      <Popover.Content className="z-10">
        {({ setOpen }) => (
          <div className="w-56 pb-2 pt-1 text-left rtl:text-right">
            <Title
              as="h6"
              className="mb-0.5 flex items-start text-sm text-gray-700 sm:items-center"
            >
              {title}
            </Title>
            <Text className="mb-2 leading-relaxed text-gray-500">
              {description}
            </Text>
            <div className="flex items-center justify-end">
              <Button
                size="sm"
                className="me-1.5 h-7"
                type="submit"
                onClick={async () => {
                  try {
                    await onAccept?.();
                  } finally {
                    setOpen(false);
                  }
                }}
              >
                Yes
              </Button>
              <Button
                size="sm"
                variant="outline"
                className="h-7"
                onClick={() => setOpen(false)}
              >
                No
              </Button>
            </div>
          </div>
        )}
      </Popover.Content>
    </Popover>
  );
}
