import UnitTransactionChart from "./unit-transaction";
import UnitType from "./unit-type";
import TopProjectEntries from "./top-project-entries";

export default function FinancialDashboard() {
  return (
    <div className="grid grid-cols-6 gap-6 @container mt-4">
      <TopProjectEntries className="col-span-full @[59rem]:col-span-3 @[90rem]:col-span-3" />
      <UnitType className="col-span-full @[59rem]:col-span-3 @[90rem]:col-span-3" />
      {/* <TopProjects className="col-span-full @[59rem]:col-span-3 @[90rem]:col-span-3" /> */}
      <UnitTransactionChart className="col-span-full" />
      {/* <TopRangePricesChart className="col-span-full" /> */}
    </div>
  );
}
