import { z } from "zod";
import { messages } from "@/config/messages";
import { validateEmail } from "@/utils/validators/common-rules";

// form zod validation schema
export const editUserSchema = z.object({
  firstName: z.string().min(1, { message: messages.firstNameRequired }),
  lastName: z.string().min(1, { message: messages.lastNameRequired }),
  email: validateEmail,
  photo: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),

  measuringId: z.string().nullable().optional(),

  domain: z
    .string()
    .refine(
      (value) =>
        /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}?$/.test(`${value}`),
      "This is domain field"
    )
    .optional(),
});

// generate form types from zod validation schema
export type EditUserSchema = z.infer<typeof editUserSchema>;
