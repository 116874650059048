import { useTableServer } from "@/hooks/useTableServer.tsx";
import VirtualProjectRepository from "@/utilities/repositories/VirtualProjects.ts";
import { Link, useParams } from "react-router-dom";
import useApi from "@/hooks/useApi.tsx";
import { IResponseData } from "@/utilities/types/requests.ts";
import { VirtualProjectItem } from "@/utilities/types/VirtualProject.ts";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { Roles } from "@/utilities/types/Users.ts";
import { GetColumns } from "@/app/app-shared/virtual-project/table/columns.tsx";
import { useColumn } from "@/hooks/use-column.ts";
import ModalCreateEditFloor from "@/app/app-shared/virtual-project/create-edit";
import ControlledTable from "@/components/controlled-table";
import useProfile from "@/hooks/use-profile.ts";
import { Title } from "rizzui";
import { backPrev } from "@/utilities/functions";
import { routes } from "@/config/routes";
export default function VirtualProjectTable() {
  const { id: projectId } = useParams<{ id: string }>();
  const { profile } = useProfile();
  const onHeaderCellClick = (value: string) => ({
    onClick: () => {
      handleSort(value);
    },
  });
  const { request: createVirtual } = useApi<IResponseData<VirtualProjectItem>>({
    request: VirtualProjectRepository.createVirtualProject,
    enableToast: true,
  });

  const { request: deleteVirtualProject } = useApi<
    IResponseData<VirtualProjectItem>
  >({
    request: VirtualProjectRepository.deleteVirtualProject,
    enableToast: true,
  });

  const {
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handlePaginate,
    pageSize,
    handleChangePageSize,
    handleSort,
    handleReset,
  } = useTableServer({
    server: {
      request: VirtualProjectRepository.getVirtualProjects,
    },
    initialFilterState: {
      projectId,
    },
  });
  const onAddItem = useCallback(async () => {
    await createVirtual()
      .then(() => {
        handleReset(); // Recall API
      })
      .catch((err: any) => {
        toast.error(err?.message || "Something went wrong");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleReset]);

  const onDeleteItem = useCallback(
    async (id: string) => {
      await deleteVirtualProject(id);
      handleReset(); // Recall API
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [handleReset]
  );
  const columns = useMemo(
    () =>
      GetColumns({
        data: tableData,
        onDeleteItem,
        onHeaderCellClick,
        projectId: projectId ?? "",
        reset: handleReset,
        role: Roles.ADMIN,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onHeaderCellClick, onDeleteItem, onAddItem, tableData]
  );
  const { visibleColumns } = useColumn(columns);

  return (
    <div className={"col-span-full"}>
      <div className="mb-4 w-full flex items-center justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl mr-auto">
          Virtual Projects
        </Title>
        <div className="flex items-center justify-center ml-auto gap-2">
          <Link
            to={backPrev(`${routes.project.listing}`)}
            className={"mr-2 ml-auto"}
          >
            <span
              className={
                "px-4 py-2 inline-block border border-solid border-black rounded-md"
              }
            >
              Back
            </span>
          </Link>
          {projectId && profile?.role !== Roles.AGENCY && (
            <ModalCreateEditFloor reset={handleReset} projectId={projectId} />
          )}
        </div>
      </div>
      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={visibleColumns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
}
