import { useAdvanceFeatures } from "@/hooks/use-advance-features";
import { EFeatureMode } from "@/utilities/types/AdvanceFeature";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Switch } from "rizzui";

export interface DynamicLayoutProps {
  isSuperAdmin?: boolean;
  name: string;
}

// !IMPORTANT: Please ensure component imported inside FormProvider (react-hook-form)

export default function DynamicLayout({
  isSuperAdmin,
  name,
}: DynamicLayoutProps) {
  const { control, setValue } = useFormContext();
  const { dynamicLayoutMode } = useAdvanceFeatures();

  useEffect(() => {
    if (dynamicLayoutMode) {
      setValue("dynamicLayout.dynamicLayoutMode", dynamicLayoutMode);
    }
  }, [dynamicLayoutMode]);

  return (
    <div className="grid grid-cols-1 gap-2 w-full">
      <div className="grid w-full items-start gap-32grid-cols-1">
        <h6 className="text-foreground md:text-base">Dynamic Layout</h6>
      </div>
      <div className="grid w-full items-start gap-3 grid-cols-1">
        {isSuperAdmin && (
          <Controller
            control={control}
            name={`${name}.enable`}
            render={({ field, fieldState, formState }) => (
              <Switch
                label="Enable"
                size="lg"
                className=" w-auto"
                {...field}
                {...formState}
                checked={field.value || ""}
                error={fieldState.error?.message}
              />
            )}
          />
        )}
        {!isSuperAdmin && (
          <Controller
            control={control}
            name={`${name}.dynamicLayoutMode`}
            render={({ field, fieldState }) => {
              // Convert the enum value to boolean for the Switch component
              const isAdvance = field.value === EFeatureMode.Advance;

              // Function to handle switch toggle
              const handleToggle = () => {
                // Toggle between 'Normal' and 'Advance' based on current state
                field.onChange(
                  field.value === EFeatureMode.Normal
                    ? EFeatureMode.Advance
                    : EFeatureMode.Normal
                );
              };

              return (
                <Switch
                  label="Advance Mode"
                  size="lg"
                  className="w-auto"
                  onChange={handleToggle}
                  checked={isAdvance} // Set checked based on enum value
                  // Removed {...formState} as it's not directly applicable to Switch component
                  error={fieldState.error?.message}
                />
              );
            }}
          />
        )}
      </div>
    </div>
  );
}
