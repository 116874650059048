import { z } from "zod";

// form zod validation schema
export const createProjectCategorySchema = z.object({
  name: z.string().min(1, "This field is required"),
  type: z.string().optional(),
  shortname: z.string().min(1, "This field is required"),
});

// generate form types from zod validation schema
export type CreateProjectCategoryInput = z.infer<
  typeof createProjectCategorySchema
>;
