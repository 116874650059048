import { useState, useEffect, useMemo, SetStateAction } from "react";
import useApi, { UseApiProps } from "./useApi";
import { ListResponseData } from "@/utilities/types/requests";
import { DEFAULT_LIMIT } from "@/config/pagination";
import { useUpdateEffect } from "react-use";
import { isEmpty, map } from "lodash";
import { useDebounce } from "ahooks";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import { removeUndefinedAndNullNestedObject } from "@/utils/form";
interface AnyObject {
  [key: string]: any;
}
export type DefaultSortKey = "createdAt" | "updatedAt";
export interface SortConfig<K extends string> {
  key: K | DefaultSortKey;
  direction: "asc" | "desc";
}

export interface UseTableServer<T> {
  server: UseApiProps<ListResponseData<T>>;
  initialFilterState?: Record<string, any>;
  searchBy?: (searchTerm: string) => AnyObject;
  pushSearchParams?: boolean;
}

export function useTableServer<T extends AnyObject>({
  server,
  initialFilterState,
  searchBy,
  pushSearchParams = true,
}: UseTableServer<T>) {
  const { request, response, loading } = useApi<ListResponseData<T>>({
    ...server,
    reValidate: false,
  });

  const location = useLocation();

  const navigate = useNavigate();

  const defaultSearchParams = useMemo(() => {
    if (!pushSearchParams) return null;

    const searchParams = qs.parse(location?.search.slice(1));

    if (!searchParams?.limit || !searchParams.page) return null;

    return {
      ...searchParams,
      limit: +searchParams.limit,
      page: +searchParams.page,
    };
  }, []);

  /*
   * Handle row selection
   */
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const handleRowSelect = (recordKey: string) => {
    const selectedKeys = [...selectedRowKeys];
    if (selectedKeys.includes(recordKey)) {
      setSelectedRowKeys(selectedKeys.filter((key) => key !== recordKey));
    } else {
      setSelectedRowKeys([...selectedKeys, recordKey]);
    }
  };
  const handleSelectAll = () => {
    if (selectedRowKeys.length === response?.data?.total) {
      setSelectedRowKeys([]);
    } else {
      setSelectedRowKeys(
        response?.data?.items?.map((record) => record.id) || []
      );
    }
  };

  /*
   * Handle sorting
   */
  const [sortConfig, setSortConfig] = useState<SortConfig<any>>({
    key: "createdAt",
    direction: "desc",
  });

  function handleSort(key: string) {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  }

  /*
   * Handle pagination
   */
  const [currentPage, setCurrentPage] = useState(
    defaultSearchParams?.page ?? 1
  );
  const [pageSize, setPageSize] = useState(
    defaultSearchParams?.limit ?? DEFAULT_LIMIT
  );

  const pushSearchParamsUrl = (
    page: number,
    limit: number,
    search?: string
  ) => {
    if (!pushSearchParams) return;
    const searchParams = qs.parse(location?.search.slice(1));

    const query = qs.stringify(
      {
        ...searchParams,
        search,
        page,
        limit,
      },
      { encode: false }
    );
    navigate(
      {
        pathname: location.pathname,
        search: query,
      },
      {
        state: location?.state,
        replace: true,
      }
    );
  };

  function refresh() {
    const searchQuery = searchBy?.(debounceSearch.trim());
    const filterQueries = map(filters, (value, key) => ({
      [key]: value,
    }));

    request({
      queryParams: {
        pagination: {
          limit: pageSize,
          page: currentPage,
        },
        query: {
          search: {
            ...searchQuery,
          },
          filter: filterQueries,
          sort: {
            [sortConfig.key]: sortConfig.direction.toUpperCase(),
          },
        },
      },
    });
  }

  function handlePaginate(pageNumber: number) {
    setCurrentPage(pageNumber);
    const searchQuery = searchBy?.(debounceSearch.trim());
    const filterQueries = map(filters, (value, key) => ({
      [key]: value,
    }));

    request({
      queryParams: {
        pagination: {
          limit: pageSize,
          page: pageNumber,
        },
        query: {
          search: {
            ...searchQuery,
          },
          filter: filterQueries,
          sort: {
            [sortConfig.key]: sortConfig.direction.toUpperCase(),
          },
        },
      },
    });

    pushSearchParamsUrl(pageNumber, pageSize, searchTerm);
  }

  function handleChangePageSize(limit: SetStateAction<number>) {
    setPageSize(limit);
  }

  /*
   * Handle Filters and searching
   */
  const [searchTerm, setSearchTerm] = useState("");

  const debounceSearch = useDebounce(searchTerm, { wait: 500 });
  const [filters, setFilters] = useState<Record<string, any>>(
    initialFilterState || {}
  );
  function updateFilter(columnId: string, filterValue?: string | any[]) {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnId]: filterValue,
    }));
  }

  /*
   * Handle searching
   */
  function handleSearch(searchValue: string) {
    setSearchTerm(searchValue);
    pushSearchParamsUrl(currentPage, pageSize, searchValue);
  }

  /*
   * Reset search and filters
   */
  function handleReset() {
    const newFilter = {
      ...initialFilterState,
    };
    setFilters({ ...newFilter });
  }

  /*
   * Set isFiltered and final filtered data
   */
  const isFiltered = !isEmpty(
    removeUndefinedAndNullNestedObject(filters || {})
  );

  /*
   * Go to first page when data is filtered and searched
   */
  useUpdateEffect(() => {
    handlePaginate(1);
  }, [filters, debounceSearch, pageSize, sortConfig]);

  useEffect(() => {
    if (initialFilterState) setFilters(initialFilterState);
  }, [JSON.stringify(initialFilterState)]);

  useEffect(() => {
    const filterQueries = map(filters, (value, key) => ({
      [key]: value,
    }));

    request({
      queryParams: {
        pagination: {
          limit: pageSize,
          page: currentPage,
        },
        query: {
          sort: {
            [sortConfig.key as string]: sortConfig.direction.toUpperCase(),
          },
          filter: filterQueries,
        },
      },
    });
    pushSearchParamsUrl(currentPage, pageSize, searchTerm);
  }, []);
  // useTable returns
  return {
    isLoading: loading,
    isFiltered,
    tableData: response?.data?.items || [],
    // pagination
    currentPage,
    handlePaginate,
    pageSize,
    handleChangePageSize,
    totalItems: response?.data?.total || 0,
    // sorting
    sortConfig,
    handleSort,
    // row selection
    selectedRowKeys,
    setSelectedRowKeys,
    handleRowSelect,
    handleSelectAll,
    // searching
    searchTerm,
    handleSearch,
    // filters
    filters,
    updateFilter,
    handleReset,
    refresh,
    response,
  };
}
