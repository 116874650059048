import { objectToFormData } from "@/utils/form";
import { ERequestMethods } from "../enums/RequestMethod";
import { E_UPLOADS } from "../enums/Upload";
import { request } from "../libs/request";
import { IUploadResponse } from "../types/Upload";
import { IResponseData } from "../types/requests";
export const sizes = [992, 1366, 1920];

function uploadImages(file: File) {
  const body = {
    image: file,
    sizes,
  };

  const formDataBody = objectToFormData(body);

  return request<IResponseData<IUploadResponse>>({
    method: ERequestMethods.POST,
    url: E_UPLOADS.IMAGE,
    data: formDataBody,
  });
}

function uploadVideo(file: File) {
  const body = {
    video: file,
  };

  const formDataBody = objectToFormData(body);

  return request<IResponseData<IUploadResponse>>({
    method: ERequestMethods.POST,
    url: E_UPLOADS.VIDEO,
    data: formDataBody,
  });
}

function getUploadById(id: string) {
  return request<IResponseData<any>>({
    method: ERequestMethods.GET,
    url: E_UPLOADS.MAIN(id),
  });
}

const UploadRepository = Object.freeze({
  uploadImages,
  uploadVideo,
  getUploadById,
});

export default UploadRepository;
