"use client";

import CustomPopover from "@/app/shared/custom-popover";
import DeletePopover from "@/app/shared/delete-popover";
import PencilIcon from "@/components/icons/pencil";
import { HeaderCell } from "@/components/ui/table";
import { routes } from "@/config/routes";
import useProfile from "@/hooks/use-profile";
import { SortConfig } from "@/hooks/useTableServer";
// import { IDeveloper } from "@/utilities/types/Developer";
import { ILocale } from "@/utilities/types/Locale";
import { IProject, IProjectLocale } from "@/utilities/types/Project";
import { Roles } from "@/utilities/types/Users";
import { formatDate } from "@/utils/format-date";
import { BiMinusCircle, BiPlusCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import { ActionIcon, Button, Checkbox, Tooltip } from "rizzui";
import { HiMiniReceiptPercent } from "react-icons/hi2";
import EyeIcon from "@/components/icons/eye.tsx";
import { FaArrowsToEye } from "react-icons/fa6";
import { GiAccordion, GiCrystalEye, GiFloorHatch } from "react-icons/gi";
import CustomCheckbox from "@/components/ui/custom-checkbox";
import { getParamsCurrentPage } from "@/utilities/functions";
import { BsXSquare } from "react-icons/bs";
import { IUserFeature } from "@/utilities/types/AdvanceFeature";
import isEmpty from "lodash/isEmpty";

type PropertySortKey =
  | "name"
  | "address"
  | "location"
  | "expectedTop"
  | "tenure"
  | "directCommission";

type Columns = {
  data: IProjectLocale[] | IProject[];
  sortConfig?: SortConfig<PropertySortKey>;
  onDeleteItem?: (id: string) => void;
  onHeaderCellClick: (value: string) => void;
  onRemoveProject?: (id: string) => void;
  onAddProject?: (id: string) => Promise<void>;
  onEditProject?: (
    id: string | undefined,
    data: {
      upcomingLaunch?: boolean;
      promotion?: boolean;
      featured?: boolean;
    },
    onSuccess: () => void
  ) => Promise<void>;
  onUpdateUserProjectAgent?: (
    id: string | undefined,
    data: {
      upcomingLaunch?: boolean;
      promotion?: boolean;
      featured?: boolean;
    },
    onSuccess: () => void
  ) => Promise<void>;
  currentUser?: Roles;
  role: Roles;
  landingPageFeature: IUserFeature | undefined;
};

export const getColumns = ({
  sortConfig,
  onDeleteItem,
  onHeaderCellClick,
  onRemoveProject,
  onAddProject,
  onEditProject,
  onUpdateUserProjectAgent,
  role,
  currentUser,
  landingPageFeature,
}: Columns) => {
  const COLUMNS = [
    {
      title: (
        <HeaderCell
          title="Name"
          sortable
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "name"
          }
          align="center"
        />
      ),
      onHeaderCell: () => onHeaderCellClick("name"),
      dataIndex: "name",
      key: "name",
      render: (field: ILocale, row: IProject) => (
        <>
          <p>{typeof row?.name === "object" ? row?.name?.en : row?.name}</p>
          {/* {row.floorPlanCount === 0 && (
            <small className={"text-xs text-red italic"}>
              Floorplan will be available soon
            </small>
          )} */}
        </>
      ),
    },

    {
      title: (
        <HeaderCell
          title="Location"
          sortable
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "location"
          }
          align="center"
        />
      ),
      onHeaderCell: () => onHeaderCellClick("location"),
      dataIndex: "location",
      key: "location",
      align: "center",
      render: (location: any) => {
        const _location =
          typeof location?.name === "string"
            ? location?.name
            : location?.name?.en;
        return <>{_location}</>;
      },
    },
    {
      title: (
        <HeaderCell
          title="Address"
          sortable
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "address"
          }
          align="center"
        />
      ),
      onHeaderCell: () => onHeaderCellClick("address"),
      dataIndex: "address",
      key: "address",
      align: "center",
      render: (value: string | ILocale, row: IProject) => {
        const latLng = row?.coordinates?.split(",");
        const address = typeof value === "string" ? value : value?.en;
        return latLng ? (
          <Tooltip
            size="sm"
            content={"View on map"}
            placement="top"
            color="invert"
          >
            <Link
              target={latLng && "_blank"}
              to={
                latLng &&
                `https://www.google.com/maps/place/${latLng?.[0]},${latLng?.[1]}`
              }
              className="text-blue-500"
            >
              {address}
            </Link>
          </Tooltip>
        ) : (
          <>{address}</>
        );
      },
    },
    {
      title: (
        <HeaderCell
          title="TOP"
          sortable
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "expectedTop"
          }
          align="center"
        />
      ),
      onHeaderCell: () => onHeaderCellClick("expectedTop"),
      dataIndex: "expectedTop",
      key: "expectedTop",
      align: "center",
      render: (value: Date) => formatDate(value, "MMM YYYY") || "TBC",
    },
    {
      title: (
        <HeaderCell
          title="Tenure"
          sortable
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "tenure"
          }
          align="center"
        />
      ),
      onHeaderCell: () => onHeaderCellClick("tenure"),
      dataIndex: "tenure",
      key: "tenure",
      align: "center",
    },
    {
      title: (
        <HeaderCell
          title="Direct Commission"
          sortable
          ascending={
            sortConfig?.direction === "asc" &&
            sortConfig?.key === "directCommission"
          }
          align="center"
        />
      ),
      onHeaderCell: () => onHeaderCellClick("directCommission"),
      dataIndex: "directCommission",
      key: "directCommission",
      align: "center",
      render: (value: number, row: IProject) =>
        value
          ? `${row?.isCommissionUpTo ? "Up to " : "Up to "}${(value || 0).toFixed(2)}%`
          : "-",
    },
    {
      title: "Upcoming Project",
      dataIndex: "upcoming",
      key: "upcoming",
      align: "center",
      render: (_: string, row: IProject) => {
        const isCheckedByAdmin = !!row?.upcomingLaunch;
        const userChecked = !!row?.userProjects?.[0]?.upcomingLaunch;
        return (
          <div className={"flex justify-center"}>
            <Tooltip
              size="sm"
              content={"Set by Admin"}
              placement="top"
              color="invert"
            >
              <div>
                <CustomCheckbox
                  className="m-1"
                  rounded="sm"
                  defaultChecked={!!row?.upcomingLaunch}
                  disabled={currentUser !== Roles.ADMIN}
                  onChangeValue={(e, onSuccess) => {
                    if (onEditProject) {
                      const data = {
                        upcomingLaunch: e.target.checked,
                      };
                      onEditProject(row?.id, data, onSuccess);
                    }
                  }}
                />
              </div>
            </Tooltip>
            {role === Roles.AGENCY && (
              <Tooltip
                size="sm"
                content={"Override"}
                placement="top"
                color="invert"
              >
                <div>
                  {isCheckedByAdmin && userChecked ? (
                    <button
                      onClick={(e) => {
                        if (onUpdateUserProjectAgent) {
                          const data = {
                            upcomingLaunch: !userChecked,
                          };
                          onUpdateUserProjectAgent(row?.id, data, () => {});
                        }
                      }}
                      className="size-8  items-center justify-center flex"
                    >
                      <BsXSquare className="size-[25px] text-rose-500 bg-rose-100 border-transparent" />
                    </button>
                  ) : (
                    <CustomCheckbox
                      className="m-1"
                      rounded="sm"
                      defaultChecked={!!row?.userProjects?.[0]?.upcomingLaunch}
                      onChangeValue={(e, onSuccess) => {
                        if (onUpdateUserProjectAgent) {
                          const data = {
                            upcomingLaunch: e.target.checked,
                          };
                          onUpdateUserProjectAgent(row?.id, data, onSuccess);
                        }
                      }}
                    />
                  )}
                </div>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Promotion Project",
      dataIndex: "promotion",
      key: "promotion",
      align: "center",
      render: (_: string, row: IProject) => {
        const isCheckedByAdmin = !!row?.promotion;
        const isUserChecked = !!row?.userProjects?.[0]?.promotion;
        return (
          <div className={"flex justify-center"}>
            <Tooltip
              size="sm"
              content={"Set by Admin"}
              placement="top"
              color="invert"
            >
              <div>
                <CustomCheckbox
                  className="m-1"
                  rounded="sm"
                  defaultChecked={!!row?.promotion}
                  disabled={currentUser !== Roles.ADMIN}
                  onChangeValue={(e, onSuccess) => {
                    if (onEditProject) {
                      const data = {
                        promotion: e.target.checked,
                      };
                      onEditProject(row?.id, data, onSuccess);
                    }
                  }}
                />
              </div>
            </Tooltip>
            {role === Roles.AGENCY && (
              <Tooltip
                size="sm"
                content={"Override"}
                placement="top"
                color="invert"
              >
                <div>
                  {isCheckedByAdmin && isUserChecked ? (
                    <button
                      onClick={(e) => {
                        if (onUpdateUserProjectAgent) {
                          const data = {
                            promotion: !isUserChecked,
                          };
                          onUpdateUserProjectAgent(row?.id, data, () => {});
                        }
                      }}
                      className="size-8  items-center justify-center flex"
                    >
                      <BsXSquare className="size-[25px] text-rose-500 bg-rose-100 border-transparent" />
                    </button>
                  ) : (
                    <CustomCheckbox
                      className="m-1"
                      rounded="sm"
                      defaultChecked={!!row?.userProjects?.[0]?.promotion}
                      onChangeValue={(e, onSuccess) => {
                        if (onUpdateUserProjectAgent) {
                          const data = {
                            promotion: e.target.checked,
                          };
                          onUpdateUserProjectAgent(row?.id, data, onSuccess);
                        }
                      }}
                    />
                  )}
                </div>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Featured Project",
      dataIndex: "featured",
      key: "featured",
      align: "center",
      render: (_: string, row: IProject) => {
        const isCheckedByAdmin = !!row?.featured;
        const isUserChecked = !!row?.userProjects?.[0]?.featured;
        return (
          <div className={"flex justify-center"}>
            <Tooltip
              size="sm"
              content={"Set by Admin"}
              placement="top"
              color="invert"
            >
              <div>
                <CustomCheckbox
                  className="m-1"
                  rounded="sm"
                  defaultChecked={!!row?.featured}
                  disabled={currentUser !== Roles.ADMIN}
                  onChangeValue={(e, onSuccess) => {
                    if (onEditProject) {
                      const data = {
                        featured: e.target.checked,
                      };
                      onEditProject(row?.id, data, onSuccess);
                    }
                  }}
                />
              </div>
            </Tooltip>
            {role === Roles.AGENCY && (
              <Tooltip
                size="sm"
                content={"Override"}
                placement="top"
                color="invert"
              >
                <div>
                  {isCheckedByAdmin && isUserChecked ? (
                    <button
                      onClick={(e) => {
                        if (onUpdateUserProjectAgent) {
                          const data = {
                            featured: !isUserChecked,
                          };
                          onUpdateUserProjectAgent(row?.id, data, () => {});
                        }
                      }}
                      className="size-8  items-center justify-center flex"
                    >
                      <BsXSquare className="size-[25px] text-rose-500 bg-rose-100 border-transparent" />
                    </button>
                  ) : (
                    <CustomCheckbox
                      className="m-1"
                      rounded="sm"
                      defaultChecked={!!row?.userProjects?.[0]?.featured}
                      onChangeValue={(e, onSuccess) => {
                        if (onUpdateUserProjectAgent) {
                          const data = {
                            featured: e.target.checked,
                          };
                          onUpdateUserProjectAgent(row?.id, data, onSuccess);
                        }
                      }}
                    />
                  )}
                </div>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <HeaderCell
          className="ps-3"
          title={<span className="whitespace-nowrap">Actions</span>}
        />
      ),
      dataIndex: "action",
      key: "action",
      width: 120,
      render: (_: string, row: IProject) => {
        if (role === Roles.ADMIN) {
          return (
            <ActionAdmin
              onDeleteItem={() => onDeleteItem?.(row.id)}
              onAddProject={async () => await onAddProject?.(row.id)}
              onRemoveProject={async () => await onRemoveProject?.(row.id)}
              isProjectExist={!!row.userProject}
              landingPageFeature={landingPageFeature}
              row={row}
            />
          );
        }
        if (role === Roles.AGENCY) {
          return (
            <ActionByAgent
              id={row.id}
              isProjectExist={!!row.userProjects}
              onRemoveProject={() => onRemoveProject?.(row.id)}
              landingPageFeature={landingPageFeature}
            />
          );
        }
      },
    },
  ];

  // return role === Roles.AGENCY
  //   ? COLUMNS
  //   : COLUMNS.filter((column) => column?.key !== "featured");

  return COLUMNS;
};

const ActionAdmin = ({
  onDeleteItem,
  onAddProject,
  onRemoveProject,
  isProjectExist,
  landingPageFeature,
  row,
}: {
  onDeleteItem: () => void;
  onAddProject?: () => Promise<void>;
  onRemoveProject: () => Promise<void>;
  isProjectExist: boolean;
  landingPageFeature: IUserFeature | undefined;
  row: IProject;
}) => {
  const { profile } = useProfile();
  return (
    <div className="flex items-center justify-center gap-3 pe-4 admin-action">
      {profile?.role === Roles.AGENCY &&
        (isProjectExist ? (
          <>
            <Tooltip
              size="sm"
              content={"View Project"}
              placement="top"
              color="invert"
            >
              <Link
                to={{
                  pathname: routes.project.edit(row.id),
                  search: getParamsCurrentPage({ rowKey: row?.id }),
                }}
              >
                <ActionIcon size="sm" variant="outline">
                  <EyeIcon className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>
            <Tooltip
              size="sm"
              content={"View Unit Transactions"}
              placement="top"
              color="invert"
            >
              <Link
                to={{
                  pathname: `${routes.unitTransaction.listing(row?.id || "")}`,
                  search: getParamsCurrentPage(
                    row?.id ? { rowKey: row?.id } : {}
                  ),
                }}
              >
                <ActionIcon size="sm" variant="outline">
                  <FaArrowsToEye className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>
            <Tooltip
              size="sm"
              content={"View Virtual"}
              placement="top"
              color="invert"
            >
              <Link
                to={{
                  pathname: routes.virtual.listing(row.id),
                  search: getParamsCurrentPage({ rowKey: row?.id }),
                }}
              >
                <ActionIcon size="sm" variant="outline">
                  <HiMiniReceiptPercent className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>
            <Tooltip
              size="sm"
              content={"View Amenities"}
              placement="top"
              color="invert"
            >
              <Link
                to={{
                  pathname: `${routes.amenity.listing(row.id || "")}`,
                  search: getParamsCurrentPage({ rowKey: row?.id }),
                }}
              >
                <ActionIcon size="sm" variant="outline">
                  <GiCrystalEye className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>
            <Tooltip
              size="sm"
              content={"View Floorplans"}
              placement="top"
              color="invert"
            >
              <Link
                to={{
                  pathname: `${routes.floorPlan.listing(row.id || "")}`,
                  search: getParamsCurrentPage(
                    row?.id ? { rowKey: row?.id } : {}
                  ),
                }}
              >
                <ActionIcon size="sm" variant="outline">
                  <GiFloorHatch className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>
            {!isEmpty(landingPageFeature) && (
              <Tooltip
                size="sm"
                content={"View Landing Page"}
                placement="top"
                color="invert"
              >
                <Link
                  to={{
                    pathname: routes.projectLandingPages.listing(row.id),
                    search: getParamsCurrentPage({ rowKey: row?.id }),
                  }}
                >
                  <ActionIcon size="sm" variant="outline">
                    <GiAccordion className="h-4 w-4" />
                  </ActionIcon>
                </Link>
              </Tooltip>
            )}

            <CustomPopover
              title={`Remove Project`}
              description={`Are you sure you want to remove this project from your listing?`}
              onAccept={onRemoveProject}
            >
              <Tooltip
                size="sm"
                content={"Remove Project"}
                placement="top"
                color="invert"
              >
                <ActionIcon size="sm" variant="text">
                  <BiMinusCircle className="h-4 w-4" />
                </ActionIcon>
              </Tooltip>
            </CustomPopover>
          </>
        ) : (
          <>
            <Tooltip
              size="sm"
              content={"View Project"}
              placement="top"
              color="invert"
            >
              <Link
                to={{
                  pathname: routes.project.edit(row.id),
                  search: getParamsCurrentPage({ rowKey: row?.id }),
                }}
              >
                <ActionIcon size="sm" variant="outline">
                  <EyeIcon className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>
            <Tooltip
              size="sm"
              content={"View Unit Transactions"}
              placement="top"
              color="invert"
            >
              <Link
                to={{
                  pathname: `${routes.unitTransaction.listing(row?.id || "")}`,
                  search: getParamsCurrentPage(
                    row?.id ? { rowKey: row?.id } : {}
                  ),
                }}
              >
                <ActionIcon size="sm" variant="outline">
                  <FaArrowsToEye className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>
            <Tooltip
              size="sm"
              content={"View Virtual"}
              placement="top"
              color="invert"
            >
              <Link
                to={{
                  pathname: routes.virtual.listing(row.id),
                  search: getParamsCurrentPage({ rowKey: row?.id }),
                }}
              >
                <ActionIcon size="sm" variant="outline">
                  <HiMiniReceiptPercent className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>
            <Tooltip
              size="sm"
              content={"View Amenities"}
              placement="top"
              color="invert"
            >
              <Link
                to={{
                  pathname: `${routes.amenity.listing(row.id || "")}`,
                  search: getParamsCurrentPage(
                    row?.id ? { rowKey: row?.id } : {}
                  ),
                }}
              >
                <ActionIcon size="sm" variant="outline">
                  <GiCrystalEye className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>
            <Tooltip
              size="sm"
              content={"View Floorplans"}
              placement="top"
              color="invert"
            >
              <Link to={`${routes.floorPlan.listing(row?.id || "")}`}>
                <ActionIcon size="sm" variant="outline">
                  <GiFloorHatch className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>
            {/* <Tooltip
              size="sm"
              content={"View Landing Page"}
              placement="top"
              color="invert"
            >
              <Link to={`${routes.projectLandingPages.listing(row?.id || "")}`}>
                <ActionIcon size="sm" variant="outline">
                  <GiAccordion className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip> */}
            <Button
              className={
                "bg-transparent hover:bg-transparent hover:text-black text-black border-muted hover:border-black p-0 w-7 h-7 rounded"
              }
              rounded="sm"
              onClick={onAddProject}
            >
              <Tooltip
                size="sm"
                content={"Add Project"}
                placement="top"
                color="invert"
              >
                <ActionIcon
                  size="sm"
                  variant="text"
                  className={"hover:text-black border-muted p-0"}
                >
                  <BiPlusCircle className="h-4 w-4 border-muted" />
                </ActionIcon>
              </Tooltip>
            </Button>
          </>
        ))}
      {profile?.role === Roles.ADMIN && (
        <>
          <Tooltip
            size="sm"
            content={"Edit Project"}
            placement="top"
            color="invert"
          >
            <Link
              to={{
                pathname: routes.project.edit(row.id),
                search: getParamsCurrentPage({ rowKey: row?.id }),
              }}
            >
              <ActionIcon size="sm" variant="outline">
                <PencilIcon className="h-4 w-4" />
              </ActionIcon>
            </Link>
          </Tooltip>
          <Tooltip
            size="sm"
            content={"Unit transactions"}
            placement="top"
            color="invert"
          >
            <Link
              to={{
                pathname: `${routes.unitTransaction.listing(row?.id || "")}`,
                search: getParamsCurrentPage(
                  row?.id ? { rowKey: row?.id } : {}
                ),
              }}
            >
              <ActionIcon size="sm" variant="outline">
                <FaArrowsToEye className="h-4 w-4" />
              </ActionIcon>
            </Link>
          </Tooltip>
          <Tooltip size="sm" content={"Virtual"} placement="top" color="invert">
            <Link
              to={{
                pathname: routes.virtual.listing(row.id),
                search: getParamsCurrentPage({ rowKey: row?.id }),
              }}
            >
              <ActionIcon size="sm" variant="outline">
                <HiMiniReceiptPercent className="h-4 w-4" />
              </ActionIcon>
            </Link>
          </Tooltip>
          <Tooltip
            size="sm"
            content={"Amenities"}
            placement="top"
            color="invert"
          >
            <Link
              to={{
                pathname: `${routes.amenity.listing(row.id || "")}`,
                search: getParamsCurrentPage(
                  row?.id ? { rowKey: row?.id } : {}
                ),
              }}
            >
              <ActionIcon size="sm" variant="outline">
                <GiCrystalEye className="h-4 w-4" />
              </ActionIcon>
            </Link>
          </Tooltip>
          <Tooltip
            size="sm"
            content={"Floorplans"}
            placement="top"
            color="invert"
          >
            <Link
              to={{
                pathname: `${routes.floorPlan.listing(row.id || "")}`,
                search: getParamsCurrentPage(
                  row?.id ? { rowKey: row?.id } : {}
                ),
              }}
            >
              <ActionIcon size="sm" variant="outline">
                <GiFloorHatch className="h-4 w-4" />
              </ActionIcon>
            </Link>
          </Tooltip>
          <Tooltip
            size="sm"
            content={"Landing Pages"}
            placement="top"
            color="invert"
          >
            <Link
              to={{
                pathname: routes.projectLandingPages.listing(row.id),
                search: getParamsCurrentPage({ rowKey: row?.id }),
              }}
            >
              <ActionIcon size="sm" variant="outline">
                <GiAccordion className="h-4 w-4" />
              </ActionIcon>
            </Link>
          </Tooltip>
          <DeletePopover
            title={`Delete Project`}
            description={`Are you sure you want to delete this project?`}
            onDelete={onDeleteItem}
          />
        </>
      )}
    </div>
  );
};

const ActionByAgent = ({
  onRemoveProject,
  id,
  isProjectExist,
  landingPageFeature,
}: {
  onRemoveProject: () => void;
  isProjectExist: boolean;
  id: string;
  landingPageFeature: IUserFeature | undefined;
}) => {
  const queryBack = getParamsCurrentPage({
    "added-by": isProjectExist ? "agency" : undefined,
  });

  return (
    <div className="flex items-center justify-end gap-3 pe-4 agent-action">
      <Tooltip
        size="sm"
        content={"View Project"}
        placement="top"
        color="invert"
      >
        <Link
          to={{
            pathname: routes.project.edit(id),
            // search: queryBack,
            search: getParamsCurrentPage({ rowKey: id }),
          }}
        >
          <ActionIcon size="sm" variant="outline">
            <EyeIcon className="h-4 w-4" />
          </ActionIcon>
        </Link>
      </Tooltip>
      <Tooltip
        size="sm"
        content={"View Unit Transactions"}
        placement="top"
        color="invert"
      >
        <Link
          to={{
            pathname: `${routes.unitTransaction.listing(id || "")}`,
            search: getParamsCurrentPage(),
          }}
        >
          <ActionIcon size="sm" variant="outline">
            <FaArrowsToEye className="h-4 w-4" />
          </ActionIcon>
        </Link>
      </Tooltip>
      <Tooltip size="sm" content={"Virtual"} placement="top" color="invert">
        <Link
          to={{
            pathname: routes.virtual.listing(id),
            search: getParamsCurrentPage(),
          }}
        >
          <ActionIcon size="sm" variant="outline">
            <HiMiniReceiptPercent className="h-4 w-4" />
          </ActionIcon>
        </Link>
      </Tooltip>
      <Tooltip
        size="sm"
        content={"View Amenities"}
        placement="top"
        color="invert"
      >
        <Link
          to={{
            pathname: `${routes.amenity.listing(id || "")}`,
            search: getParamsCurrentPage(),
          }}
        >
          <ActionIcon size="sm" variant="outline">
            <GiCrystalEye className="h-4 w-4" />
          </ActionIcon>
        </Link>
      </Tooltip>
      <Tooltip
        size="sm"
        content={"View Floorplans"}
        placement="top"
        color="invert"
      >
        <Link
          to={{
            pathname: `${routes.floorPlan.listing(id || "")}`,
            search: getParamsCurrentPage(),
          }}
        >
          <ActionIcon size="sm" variant="outline">
            <GiFloorHatch className="h-4 w-4" />
          </ActionIcon>
        </Link>
      </Tooltip>
      {!isEmpty(landingPageFeature) && (
        <Tooltip
          size="sm"
          content={"Landing Pages"}
          placement="top"
          color="invert"
        >
          <Link
            to={{
              pathname: routes.projectLandingPages.listing(id),
              search: getParamsCurrentPage({ rowKey: id }),
            }}
          >
            <ActionIcon size="sm" variant="outline">
              <GiAccordion className="h-4 w-4" />
            </ActionIcon>
          </Link>
        </Tooltip>
      )}
      <Tooltip
        size="sm"
        content={"Remove Project"}
        placement="top"
        color="invert"
      >
        <DeletePopover
          title={`Remove Project`}
          description={`Are you sure you want to remove this project from your listing?`}
          onDelete={onRemoveProject}
        />
      </Tooltip>
    </div>
  );
};
