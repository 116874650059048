import dayjs from "dayjs";
import { z } from "zod";

export const createMortgageSchema = z.object({
  bankName: z.string().min(1, { message: "This field is required" }),
  mortgageType: z
    .number()
    .min(1, { message: "This field is required" })
    .or(z.string().min(1, { message: "This field is required" })),
  firstYearRate: z
    .number()
    .min(0, "This field is numberic")
    .or(
      z
        .string()
        .refine((value) => !isNaN(Number(value)), "This field is numberic")
    ),
  firstYearValue: z.string().min(1, { message: "This field is required" }),
  secondYearRate: z
    .number()
    .min(0, "This field is numberic")
    .or(
      z
        .string()
        .refine((value) => !isNaN(Number(value)), "This field is numberic")
    ),
  secondYearValue: z.string().min(1, { message: "This field is required" }),
  threeYearRate: z
    .number()
    .min(0, "This field is numberic")
    .or(
      z
        .string()
        .refine((value) => !isNaN(Number(value)), "This field is numberic")
    ),
  threeYearValue: z.string().min(1, { message: "This field is required" }),
  thereAfterRate: z
    .number()
    .min(0, "This field is numberic")
    .or(
      z
        .string()
        .refine((value) => !isNaN(Number(value)), "This field is numberic")
    ),
  thereAfterValue: z.string().min(1, { message: "This field is required" }),
  applyDate: z.string().refine((value) => dayjs(value).isValid()),
  bankLogo: z
    .object({
      id: z.string().min(1, "This field is required"),
      url: z.string().optional(),
    })
    .nullable(),
  package: z.string().optional(),
  remarks: z.string().optional(),
});

export type MortgageSchemaInput = z.infer<typeof createMortgageSchema>;
