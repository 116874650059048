import ControlledTable from "@/components/controlled-table";
import { useEffect, useMemo } from "react";
import { getColumns } from "./columns";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import { ILocation } from "@/utilities/types/Location";
import LocationRepository from "@/utilities/repositories/Location";
import { Title } from "rizzui";
import ModalButton from "@/app/shared/modal-button";
import CreateEditLocation from "../create-edit/create-edit-location";
import { useModal } from "@/app/shared/modal-views/use-modal";

export default function LocationTable() {
  const { request: deleteLocation } = useApi<IResponseData<ILocation>>({
    request: LocationRepository.deleteLocation,
    enableToast: true,
  });

  const {
    request: fetchData,
    response,
    loading: isLoading,
  } = useApi({
    request: LocationRepository.getAllLocation,
  });

  const tableData = response?.data || [];

  const { openModal } = useModal();
  const onDeleteItem = async (id: string) => {
    await deleteLocation(id);
    await fetchData();
  };

  const onEditItem = (row: ILocation) => {
    openModal({
      view: <CreateEditLocation refetchData={fetchData} data={row} />,
      customSize: "500px",
    });
  };

  const columns = useMemo(
    () =>
      getColumns({
        data: tableData,
        onDeleteItem,
        onEditItem,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData]
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="mb-4 w-full flex items-center justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          All Districts
        </Title>

        <ModalButton
          view={<CreateEditLocation refetchData={fetchData} />}
          label="Add Location"
          customSize="500px"
          className="mt-0 w-auto"
        />
      </div>

      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={columns}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </>
  );
}
