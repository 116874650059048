"use client";

import PencilIcon from "@/components/icons/pencil";
import { HeaderCell } from "@/components/ui/table";
import { ActionIcon, Tooltip } from "rizzui";
import { ISiteContent } from "@/utilities/types/SiteContent";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { routes } from "@/config/routes";

export const getColumns = () => [
  {
    title: (
      <HeaderCell
        title="Title"
        align="center"
      />
    ),
    dataIndex: "title",
    key: "title",
    align: "center",
    render: (field: string) => <>{field}</>,
  },
  {
    title: (
      <HeaderCell
        title="Content Type"
        align="center"
      />
    ),
    dataIndex: "contentType",
    key: "contentType",
    align: "center",
    render: (field: string) => <>{field}</>,
  },
  {
    title: (
      <HeaderCell
        title="Created At"
        align="center"
      />
    ),
    dataIndex: "createdAt",
    key: "createdAt",
    align: "center",
    render: (_: string, row: ISiteContent) => {
      return dayjs(row.createdAt).format("MMMM DD, YYYY");
    },
  },
  {
    title: (
      <HeaderCell
        title=""
        className="ps-3"
        align="center"
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: ISiteContent) => (
      <div className="flex items-center justify-center gap-3 pe-4">
        <Tooltip
          size="sm"
          content={"Edit Site Content"}
          placement="top"
          color="invert"
        >
          <Link to={routes.siteContent.edit(row.id)}>
            <ActionIcon
              size="sm"
              variant="outline"
              onClick={() => {}}
            >
              <PencilIcon className="h-4 w-4" />
            </ActionIcon>
          </Link>
        </Tooltip>
        {/* <DeletePopover
          title={`Delete site content`}
          description={`Are you sure you want to delete this site content?`}
          onDelete={async () => onDeleteItem(row.id)}
        /> */}
      </div>
    ),
  },
];
