import {request} from "@/utilities/libs/request.ts";
import {IResponseData} from "@/utilities/types/requests.ts";
import {ERequestMethods} from "@/utilities/enums/RequestMethod.ts";
import {ILocation, ILocationDTO} from "../types/Location";
import {E_LOCATION} from "../enums/Location";

function getAllLocation() {
  return request<IResponseData<ILocation[]>>({
    method: ERequestMethods.GET,
    url: "/location?sort[name]=ASC",
  });
}

function deleteLocation(id: string) {
  return request<IResponseData<ILocation>>({
    method: ERequestMethods.DELETE,
    url: E_LOCATION.LOCATION_ID(id),
  });
}

function createLocation(data: ILocationDTO) {
  return request<IResponseData<ILocation>>({
    method: ERequestMethods.POST,
    url: E_LOCATION.MAIN,
    data,
  });
}

function updateLocation(id: string, data: Partial<ILocationDTO>) {
  return request<IResponseData<ILocation>>({
    method: ERequestMethods.PATCH,
    url: E_LOCATION.LOCATION_ID(id),
    data,
  });
}

const LocationRepository = Object.freeze({
  getAllLocation,
  deleteLocation,
  createLocation,
  updateLocation,
});

export default LocationRepository;
