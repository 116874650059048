import CreateEditPrivacyPolicyTemplate from "@/app/app-shared/privacy-policy/create-edit-privacy-policy-template";

export interface PrivacyPolicyPageProps {}

export default function PrivacyPolicyPage({}: PrivacyPolicyPageProps) {
  return (
    <div>
      <CreateEditPrivacyPolicyTemplate />
    </div>
  );
}
