import { Text } from "rizzui";
import cn from "@/utils/class-names";
import UploadIcon from "@/components/shape/upload";
import React, { ChangeEvent, useEffect, useState } from "react";
import { PiPencilBold, PiTrashBold } from "react-icons/pi";
import AssetsRepository from "@/utilities/repositories/Asset";
import toast from "react-hot-toast";
const ASSET_BASE_URL = import.meta.env.VITE_ASSET_BASE_URL as string;

interface ISingleImageUploadProps {
  title: string;
  imageUrl: string;
  uploadContainerClass?: string;
  imageClassName?: string;
  imageContainerClassName?: string;
  toolbarContainerClassName?: string;
  dropZoneClassName?: string;
  uploadDropzoneContainerClass?: string;
  uploaderText?: React.ReactNode;
  error?: string;

  setValue: (value: { id: string; url: string }) => void;
}

export default function SingleImageUpload({
  title,
  imageUrl,
  uploadContainerClass = "",
  imageClassName,
  imageContainerClassName,
  dropZoneClassName,
  uploaderText = <>Upload Logo Recommended size 100x100 </>,
  setValue,
  error,
  toolbarContainerClassName,
  uploadDropzoneContainerClass = "",
}: ISingleImageUploadProps) {
  const inputRef = React.createRef<HTMLInputElement>();
  const [file, setFile] = useState<string>("");
  const [isUploading, setIsUploading] = useState<boolean>(false);

  function handleFileSelect(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("sizes[]", "1920");
      formData.append("sizes[]", "1366");
      formData.append("sizes[]", "992");
      setIsUploading(true);
      AssetsRepository.uploadImageAsset(formData)
        .then((res) => {
          setIsUploading(false);
          if (res?.data?.id) {
            setValue({
              id: res?.data?.id || "",
              url: res?.data?.urls?.[0]?.url || "",
            });
            setFile(res?.data?.urls?.[0]?.url || "");
          }
        })
        .catch((err) => {
          toast.error(err?.message || "Something went wrong");
          setIsUploading(false);
        });
    };
    reader.readAsDataURL(file);
  }

  function handleClearFile() {
    setFile("");
    setValue({
      id: "",
      url: "",
    });
  }

  useEffect(() => {
    setFile(imageUrl);
  }, [imageUrl]);

  return (
    <div className={cn("flex flex-col", uploadContainerClass)}>
      {title && (
        <p className="text-sm mb-1.5 font-medium text-gray-900">{title}</p>
      )}
      <div
        className={cn(
          "flex-1 h-[calc(100%-20px)] relative",
          uploadDropzoneContainerClass
        )}
      >
        <label
          className={cn(
            "flex cursor-pointer flex-col items-center justify-center rounded-md border-[1.8px] p-2 hover:border-primary dark:border-gray-700 dark:hover:border-primary",
            file && "hidden",
            dropZoneClassName
          )}
        >
          <UploadIcon className="mx-auto size-12 -translate-x-1" />
          <Text className="text-center text-xs">{uploaderText}</Text>

          <input
            type="file"
            ref={inputRef}
            accept="image/*"
            className="hidden"
            onChange={handleFileSelect}
          />
        </label>

        {/* preview  */}
        {file && (
          <div
            className={cn(
              "group relative flex justify-center rounded-md border-[1px] p-2 hover:border-muted dark:hover:border-gray-700 h-full bg-gray-100",
              imageContainerClassName
            )}
          >
            <img
              src={`${ASSET_BASE_URL}${file}`}
              alt="logo"
              className={cn("w-full object-contain h-full", imageClassName)}
            />
            <div
              className={cn(
                "absolute end-0 top-0 hidden translate-x-full group-hover:block z-10",
                toolbarContainerClassName
              )}
            >
              <div className="-translate-x-[30px] overflow-hidden rounded-md border bg-white shadow-[0px_1px_4px_rgba(0,0,0,0.16)] dark:border-muted/20">
                <ActionButton onClick={() => inputRef.current?.click()}>
                  <PiPencilBold className="size-4" />
                </ActionButton>
                <ActionButton onClick={handleClearFile}>
                  <PiTrashBold className="size-4 text-red-dark dark:text-red" />
                </ActionButton>
              </div>
            </div>
          </div>
        )}
        {isUploading && (
          <div className="absolute w-full h-full left-0 top-0 bg-white opacity-50 z-10 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                opacity="0.25"
              />
              <path
                fill="currentColor"
                d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
              >
                <animateTransform
                  attributeName="transform"
                  dur="0.75s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path>
            </svg>
          </div>
        )}
      </div>
      {error && (
        <p className="text-red text-[13px] mt-1.5 rizzui-input-error-text">
          {error}
        </p>
      )}
    </div>
  );
}

function ActionButton({
  onClick,
  children,
  className,
}: {
  className?: string;
  onClick: () => void;
  children: React.ReactNode;
}) {
  return (
    <button
      type="button"
      className={cn(
        "flex size-7 items-center justify-center text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800",
        className
      )}
      onClick={onClick}
    >
      {children}
      <span className="sr-only">icon</span>
    </button>
  );
}
