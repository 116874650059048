"use client";

import DeletePopover from "@/app/shared/delete-popover";
import PencilIcon from "@/components/icons/pencil";
import { HeaderCell } from "@/components/ui/table";
import { IUnitType } from "@/utilities/types/UnitType";
import { ActionIcon, Tooltip } from "rizzui";

type Columns = {
  data: IUnitType[];
  onDeleteItem: (id: string) => Promise<void>;
  onEditItem: (row: IUnitType) => void;
};

export const getColumns = ({ onDeleteItem, onEditItem }: Columns) => [
  {
    title: (
      <HeaderCell
        title="Name"
        align="center"
      />
    ),
    key: "title",
    dataIndex: "title",
    align: "center",
    render: (value: string | Record<string, string>) => {
      return typeof value === "string" ? value : value?.en;
    },
  },
  {
    title: (
      <HeaderCell
        title="bed rooms"
        align="center"
      />
    ),
    key: "bedRoomCount",
    dataIndex: "bedRoomCount",
    align: "center",
  },
  {
    title: (
      <HeaderCell
        title="Room Study"
        align="center"
      />
    ),
    dataIndex: "hasStudyRoom",
    key: "hasStudyRoom",
    align: "center",
    render: (hasStudyRoom: boolean) => {
      return hasStudyRoom ? "Yes" : "No";
    },
  },
  {
    title: (
      <HeaderCell
        title="Premium"
        align="center"
      />
    ),
    dataIndex: "isPremium",
    key: "isPremium",
    align: "center",
    render: (isPremium: boolean) => {
      return isPremium ? "Yes" : "No";
    },
  },
  {
    title: (
      <HeaderCell
        title="Slug"
        align="center"
      />
    ),
    dataIndex: "slug",
    key: "slug",
    align: "center",
  },
  {
    title: (
      <HeaderCell
        className="ps-3"
        title={<span className="whitespace-nowrap">Actions</span>}
        align="center"
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: IUnitType) => (
      <div className="flex items-center justify-end gap-3 pe-4">
        <Tooltip
          size="sm"
          content={"Edit Unit Type"}
          placement="top"
          color="invert"
        >
          <ActionIcon
            size="sm"
            variant="outline"
            onClick={() => onEditItem(row)}
          >
            <PencilIcon className="h-4 w-4" />
          </ActionIcon>
        </Tooltip>
        <DeletePopover
          title={`Delete unit type`}
          description={`Are you sure you want to delete this unit type?`}
          onDelete={async () => await onDeleteItem(row.id)}
        />
      </div>
    ),
  },
];
