import { ENQUIRY_LOG_TYPES, LEAD_FULL_SCORE } from "@/config/constants";
import { SkeletonSubmissionLogs } from "@/data/skeleton-lead-logs";
import { E_ENQUIRY_LOG_TYPE } from "@/utilities/enums/EnquiryLogTypes";
import {
  formatDateInfo,
  formatInterestedIn,
  leadScore,
} from "@/utilities/functions/lead";
import { IEnquirySubmissionLog } from "@/utilities/types/EnquiryEntry";
import { ListResponseData } from "@/utilities/types/requests";
import cn from "@/utils/class-names";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { RxChevronDown } from "react-icons/rx";
import { Accordion, Progressbar, Title } from "rizzui";
import { formatUnitTypes } from "../utilities/functions/lead";

export interface ProgressBarProps {
  enquiryLogsResponse: ListResponseData<IEnquirySubmissionLog> | null;
}

function ScoreDetails({ enquiryLogsResponse }: ProgressBarProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const increment = 1; // Increment per second

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval); // Stop the interval when progress reaches 100%
          return 100;
        }
        return prevProgress + increment; // Increment progress
      });
    }, 15); // Update every second

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {progress < 100 ? (
        <div style={{ width: "100%", padding: "20px" }}>
          <Progressbar
            value={progress}
            color={
              [
                "primary",
                "secondary",
                "danger",
                "info",
                "success",
                "warning",
              ].includes(
                leadScore(enquiryLogsResponse?.data?.score || 0)
                  ?.rizzuiColor as string
              )
                ? (leadScore(enquiryLogsResponse?.data?.score || 0)
                    ?.rizzuiColor as
                    | "primary"
                    | "secondary"
                    | "danger"
                    | "info"
                    | "success"
                    | "warning")
                : "primary"
            }
          />
        </div>
      ) : isEmpty(enquiryLogsResponse?.data?.submissionLogs) ? (
        SkeletonSubmissionLogs?.submissionLogs?.map((item) => (
          <Accordion
            key={item.id}
            defaultOpen={false}
            className="mx-8 my-2 border-b last-of-type:border-b-0"
          >
            <Accordion.Header>
              {({ open }) => (
                <div className="flex w-full cursor-pointer items-center justify-between text-left rtl:text-right">
                  <div className="flex items-center gap-4 pb-2">
                    <div
                      className={cn(
                        "flex items-center justify-center w-8 h-8 rounded-full"
                      )}
                      style={{
                        backgroundColor: leadScore(
                          item?.point === 0 ? item?.point : 0
                        )?.color,
                        opacity:
                          (Number(item?.point) + 30) / LEAD_FULL_SCORE || 1,
                      }}
                    >
                      <Title
                        as="h3"
                        className="font-lexend text-white font-medium text-lg"
                      >
                        {item.point}
                      </Title>
                    </div>
                    <div className="grid gap-1">
                      <Title
                        as="h3"
                        className="font-lexend font-medium text-base md:text-lg"
                      >
                        {ENQUIRY_LOG_TYPES?.[item?.type]?.label}
                      </Title>
                    </div>
                  </div>
                  <RxChevronDown
                    className={cn(
                      "h-5 w-5 mb-2 -rotate-90 transform transition-transform duration-300",
                      open && "-rotate-0"
                    )}
                    style={{
                      color: leadScore(SkeletonSubmissionLogs?.score || 0)
                        ?.color,
                    }}
                  />
                </div>
              )}
            </Accordion.Header>
            <Accordion.Body className="ml-10 pt-1 pb-2">
              {item.type === E_ENQUIRY_LOG_TYPE.UNIT_TYPE && (
                <ul>
                  {formatUnitTypes(item.reason)?.map((type, index) => (
                    <li key={index}>{type}</li>
                  ))}
                </ul>
              )}
              {item.type !== E_ENQUIRY_LOG_TYPE.UNIT_TYPE && item.reason}
            </Accordion.Body>
          </Accordion>
        ))
      ) : (
        enquiryLogsResponse?.data?.submissionLogs?.map((item) => (
          <Accordion
            key={item.id}
            defaultOpen={false}
            className="mx-8 my-2 border-b last-of-type:border-b-0"
          >
            <Accordion.Header>
              {({ open }) => (
                <div className="flex w-full cursor-pointer items-center justify-between text-left rtl:text-right">
                  <div className="flex items-center gap-4 pb-2">
                    <div
                      className={cn(
                        "flex items-center justify-center w-8 h-8 rounded-full"
                      )}
                      style={{
                        backgroundColor: leadScore(
                          item?.point === 0
                            ? item?.point
                            : enquiryLogsResponse?.data?.score || 0
                        )?.color,
                        opacity:
                          (Number(item?.point) + 30) / LEAD_FULL_SCORE || 1,
                      }}
                    >
                      <Title
                        as="h3"
                        className="font-lexend text-white font-medium text-lg"
                      >
                        {item.point}
                      </Title>
                    </div>
                    <div className="grid gap-1">
                      <Title
                        as="h3"
                        className="font-lexend font-medium text-base md:text-lg"
                      >
                        {ENQUIRY_LOG_TYPES?.[item.type].label}
                      </Title>
                    </div>
                  </div>
                  <RxChevronDown
                    className={cn(
                      "h-5 mb-2 w-5 -rotate-90 transform transition-transform duration-300",
                      open && "-rotate-0"
                    )}
                    style={{
                      color: leadScore(enquiryLogsResponse?.data?.score || 0)
                        ?.color,
                    }}
                  />
                </div>
              )}
            </Accordion.Header>
            <Accordion.Body className="ml-10 pt-1 pb-2">
              {item.type === E_ENQUIRY_LOG_TYPE.APPOINTMENT_DATE && (
                <div>
                  {formatDateInfo(item.reason)?.map((info, index) => (
                    <p key={index}>{info}</p>
                  ))}
                </div>
              )}
              {item.type === E_ENQUIRY_LOG_TYPE.INTERESTED && (
                <ul>
                  {formatInterestedIn(item.reason)?.map((type, index) => (
                    <li key={index}>{type}</li>
                  ))}
                </ul>
              )}
              {item.type === E_ENQUIRY_LOG_TYPE.UNIT_TYPE && (
                <ul>
                  {formatUnitTypes(item.reason)?.map((type, index) => (
                    <li key={index}>{type}</li>
                  ))}
                </ul>
              )}
              {item.type !== E_ENQUIRY_LOG_TYPE.INTERESTED &&
                item.type !== E_ENQUIRY_LOG_TYPE.UNIT_TYPE &&
                item.type !== E_ENQUIRY_LOG_TYPE.APPOINTMENT_DATE &&
                item.reason}
            </Accordion.Body>
          </Accordion>
        ))
      )}
    </>
  );
}

export default ScoreDetails;
