"use client";

import DeletePopover from "@/app/shared/delete-popover";
import PencilIcon from "@/components/icons/pencil";
import { HeaderCell } from "@/components/ui/table";
import { ILocation } from "@/utilities/types/Location";
import { ActionIcon, Tooltip } from "rizzui";

type Columns = {
  data: ILocation[];
  onDeleteItem: (id: string) => Promise<void>;
  onEditItem: (row: ILocation) => void;
};

export const getColumns = ({ onDeleteItem, onEditItem }: Columns) => [
  {
    title: (
      <HeaderCell
        title="Location Name"
        align="center"
      />
    ),
    key: "name",
    dataIndex: "name",
    align: "center",
  },

  {
    title: (
      <HeaderCell
        title="Slug"
        align="center"
      />
    ),
    dataIndex: "slug",
    key: "slug",
    align: "center",
  },

  {
    title: (
      <HeaderCell
        className="ps-3"
        title={<span className="whitespace-nowrap">Actions</span>}
        align="center"
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: ILocation) => (
      <div className="flex items-center justify-end gap-3 pe-4">
        <Tooltip
          size="sm"
          content={"Edit Location"}
          placement="top"
          color="invert"
        >
          <ActionIcon
            size="sm"
            variant="outline"
            onClick={() => onEditItem(row)}
          >
            <PencilIcon className="h-4 w-4" />
          </ActionIcon>
        </Tooltip>
        <DeletePopover
          title={`Delete location`}
          description={`Are you sure you want to delete this location?`}
          onDelete={async () => await onDeleteItem(row.id)}
        />
      </div>
    ),
  },
];
