import { Enum } from "./Enum";

export const E_USER_PREFERENCE = Object.freeze({
  MAIN: "/user-preference",
  LISTING: "/user-preference/by-agency",
  USER_PREFERENCE_ID: (id: string = ":id") => `/user-preference/${id}`,
});

export type E_USER_PREFERENCE = Enum<typeof E_USER_PREFERENCE>;

export enum EUserReferenceType {
  LOCATION = "location",
  UNIT_TYPE = "unit_type",
  RANGE = "range",
}
