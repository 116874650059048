import ImageApp from "@/components/ui/image-app";
import useApi from "@/hooks/useApi";
import ProjectRepository from "@/utilities/repositories/Project";

import {
  ESectionLayout,
  SECTION_PROJECT_ORDER_PREVIEW,
  SectionLayouts,
} from "@/utilities/enums/SectionLayouts";
import SectionSettingRepository from "@/utilities/repositories/SectionSetting";
import { IProjectLocale } from "@/utilities/types/Project";
import { SelectedProject } from "@/utilities/types/SectionSettings";
import { FieldArraySectionConfigInput } from "@/utilities/validators/section-config";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import OrderProject from "./order-project";
import SelectProject from "./select-project";
import IframeLayoutPreview from "./iframe-layout-preview";
import { useAtom } from "jotai";
import { atomHandleSelectLocation, atomHanleLocationOption } from "../store";
import _ from "lodash";

export interface ProjectSettingProps {
  sectionId: string;
  locationId?: string;
  indexForm: number;
  previewUrl?: string;
}

export default function ProjectSetting({
  sectionId,
  locationId,
  indexForm,
  previewUrl,
}: ProjectSettingProps) {
  // TODO: maybe use storage to manage
  const [selectedProjects, setSelectedProjects] = useState<SelectedProject[]>(
    []
  );

  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [selectedLocations, onSelectLocation] = useAtom(
    atomHandleSelectLocation
  );

  const [locationOptions] = useAtom(atomHanleLocationOption);

  const sectionSeclectedLocation = selectedLocations[sectionId];

  const iframeRef = useRef<any>(null);

  const reloadIframe = () => {
    if (iframeRef && typeof iframeRef !== "function" && iframeRef.current) {
      iframeRef.current.src = iframeRef.current.src; // This will reload the iframe
    }
  };

  const [searchProjects, setSearchProjects] = useState<IProjectLocale[]>([]);

  const { request: fetchProjects, loading } = useApi({
    request: ProjectRepository.getAgentProject,
  });

  const { watch, formState } = useFormContext<FieldArraySectionConfigInput>();

  const selectedLayout = watch(`${indexForm}.layout`);

  const PREVIEW_IMAGE = SECTION_PROJECT_ORDER_PREVIEW?.[selectedLayout] || "";

  const { request: fetchSelectedProject, response: selectedProjectResponse } =
    useApi({
      request: SectionSettingRepository.getSelectedProject,
    });

  const { request: removeProject, loading: loadingRemove } = useApi({
    request: SectionSettingRepository.removeSelectedProject,
  });

  const { request: addOrUpdateManyProject, loading: isUpdating } = useApi({
    request: SectionSettingRepository.addOrUpdateManyProject,
  });

  const { request: onArrangeProject } = useApi({
    request: SectionSettingRepository.onArrangeProject,
  });

  const onFetchProject = (search: string, locationIds?: string[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {};

    if (search) {
      query.search = search;
    }

    if (locationIds) {
      query.filter = [
        {
          locationIds,
        },
      ];
    }

    fetchProjects({
      queryParams: {
        pagination: {
          page: 1,
          limit: 50,
        },
        query,
      },
    }).then((res) => {
      const convertedData =
        res?.data?.items.map((item) => ({
          ...item,
        })) || [];
      setSearchProjects(convertedData);
    });
  };

  const onFetchSelectedProject = async () => {
    fetchSelectedProject(sectionId).then((res) => {
      const data = res?.data?.items || [];

      setSelectedProjects(data);

      // Get locationIds from selected projects
      const uniqueLocationSelected = _.uniqBy(
        [
          ...data.map((item) => item?.project?.location?.id),
          ...(sectionSeclectedLocation || []),
          locationId || "",
        ].filter((location) => location),
        function (location) {
          return location;
        }
      );

      // Set selected location  store
      onSelectLocation({
        [sectionId]: uniqueLocationSelected,
      });
    });
  };

  useEffect(() => {
    onFetchSelectedProject();
  }, []);

  useEffect(() => {
    // If section has locationId that mean  default section
    const selectedLocationIds = locationId
      ? [locationId]
      : sectionSeclectedLocation?.map((item) => item as string);
    onFetchProject(searchKeyword, selectedLocationIds);
  }, [sectionSeclectedLocation, searchKeyword]);

  const onRemoveProject = async (project: IProjectLocale) => {
    try {
      await removeProject(sectionId, {
        projectId: project.id,
      });
      await onFetchSelectedProject();
      toast.success(`Updated successfully`);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  const onAddProject = async (project: IProjectLocale) => {
    if (searchProjects.length === 0 || !selectedProjectResponse) return;

    let lastWeight = 0;
    if (selectedProjectResponse.data.total !== 0) {
      lastWeight =
        selectedProjects.length - 1 < selectedProjectResponse.data.total
          ? selectedProjects[selectedProjects.length - 1].weight
          : selectedProjectResponse.data.total;
    }

    try {
      await addOrUpdateManyProject(sectionId, [
        {
          projectId: project.id,
          weight: lastWeight + 1,
        },
      ]);
      await onFetchSelectedProject();
      toast.success(`Updated successfully`);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  const onSelect = async ({
    project,
    checked,
  }: {
    project: IProjectLocale;
    checked: boolean;
  }) => {
    if (checked) {
      await onAddProject(project);
    } else {
      await onRemoveProject(project);
    }
  };

  const handleOrderProject = async ({
    item,
    newIndex,
  }: {
    item: SelectedProject;
    newIndex: number;
  }) => {
    // NOTE: weight start from 1 and index start from 0
    const newWeight = newIndex + 1;
    const oldIndex = selectedProjects?.findIndex(
      (selectedProject) => selectedProject?.id === item?.id
    );
    if (oldIndex === -1 || oldIndex === newIndex) return;

    setSelectedProjects((prev) => {
      const temp = Array.from(prev);
      const [movedItem] = temp.splice(oldIndex, 1);
      temp.splice(newIndex, 0, movedItem);
      const getBeforeItem = temp?.[newIndex - 1]?.id;

      const dataUpdate = {
        id: item.id,
        sectionId,
        newPosition: newWeight,
        beforeId: getBeforeItem,
      };

      onArrangeProject(dataUpdate).then(() => {
        reloadIframe();
      });

      return temp;
    });
  };

  const onAddProjectsWithLocation = async () => {
    if (!selectedProjectResponse) return;
    if (locationId) return; // Is section default
    if (sectionSeclectedLocation.length !== 1) return; // Section has more than one location

    if (selectedProjectResponse.data.total > 0 || !searchProjects.length)
      return; // Location has  project selected

    const payloadAddManyProject = searchProjects.map((pj, index) => ({
      projectId: pj.id,
      weight: index + 1, // because index start from 0
    }));

    try {
      addOrUpdateManyProject(sectionId, payloadAddManyProject);
      // await onFetchSelectedProject();
    } catch (err: any) {
      toast.error(err?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (formState.isSubmitting) {
      // TODO: refactor this function to call at onSubmit
      onAddProjectsWithLocation().then(() => {});
    }
  }, [formState.isSubmitting]);

  return (
    <div className="my-4">
      <div className="grid lg:grid-cols-[500px_1fr] grid-cols-1 gap-2">
        <div className="flex flex-col gap-8 col-span-full">
          {selectedProjects?.length ? (
            <IframeLayoutPreview
              baseUrl={`https://${previewUrl}/layouts/preview`}
              previewParams={{
                background: watch(`${indexForm}.background`),
                name: watch(`${indexForm}.name`),
                layout: selectedLayout as ESectionLayout,
                sectionId,
                projectCount: selectedProjects?.length,
              }}
              ref={iframeRef}
            />
          ) : (
            // Preview layout if section hasn't any projects selected
            <ImageApp inApp src={PREVIEW_IMAGE} />
          )}
        </div>
        <SelectProject
          onSearch={(search) => {
            setSearchKeyword(search);
          }}
          isSectionDefault={!!locationId}
          projects={searchProjects}
          loading={loading}
          selectedProjects={selectedProjects}
          onSelect={(project, checked) => {
            onSelect({ project, checked });
          }}
          sectionId={sectionId}
          options={locationOptions.map((option) => ({
            ...option,
            disabled: selectedProjects.some(
              (item) => item.project.location.id === option.value
            ),
          }))}
        />
        <OrderProject
          onRemoveSelected={(project) => onRemoveProject(project)}
          projectSelecteds={selectedProjects || []}
          handleOrderProject={handleOrderProject}
          isUpdating={isUpdating || loadingRemove}
          maxNumOfProject={
            SectionLayouts?.[selectedLayout as ESectionLayout]
              ?.maxNumOfProject || 0
          }
        />
      </div>
    </div>
  );
}
