import { useModal } from "@/app/shared/modal-views/use-modal";
import ProjectSelect from "@/components/project-select";
import { Form } from "@/components/ui/form";
import { IMPORT_TYPE_OPTIONS } from "@/utilities/enums/ImportData";
import ImportDataRepository from "@/utilities/repositories/ImportData";
import { importDataSchema } from "@/utils/validators/import-data.scheme";
import { useState } from "react";
import { Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { Title, cn, Button, FileInput, Select, SelectOption } from "rizzui";

interface IImportFormValue {
  file?: File;
  importType?: SelectOption;
  project?: SelectOption;
}

type Props = {
  onSuccess?: () => void;
};

export default function ImportForm({ onSuccess }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [needSelectProject, setNeedSelectProject] = useState(false);
  const { closeModal } = useModal();

  return (
    <Form<IImportFormValue>
      validationSchema={importDataSchema}
      onSubmit={(values, e) => {
        const file = e?.target?.file?.files?.[0] as File | undefined;
        Promise.resolve()
          .then(() => {
            if (!file) {
              throw new Error("File is required");
            }
            setIsLoading(true);
            switch (values.importType?.value) {
              case "project": {
                return ImportDataRepository.postImportProject({
                  file,
                });
              }
              case "project-type": {
                return ImportDataRepository.postImportProjectType({
                  file,
                });
              }
              case "unit-type": {
                return ImportDataRepository.postImportUnitType({
                  file,
                });
              }
              case "floor-plan": {
                return ImportDataRepository.postImportFloorPlan({
                  file,
                });
              }
              case "unit-transaction": {
                return ImportDataRepository.postImportUnitTransaction({
                  file,
                });
              }
              case "unit-transaction/project": {
                if (!values.project) {
                  throw new Error("Project is required");
                }
                return ImportDataRepository.postImportUnitTransactionProject({
                  file,
                  projectId: values.project!.value.toString(),
                });
              }
              case "direct-commission": {
                return ImportDataRepository.postImportDirectCommission({
                  file,
                });
              }
              case "mortgage": {
                return ImportDataRepository.postImportMortgage({
                  file,
                });
              }
            }
          })
          .then(() => {
            closeModal();
            toast.success("A new import task has been created");
            onSuccess?.();
          })
          .catch((err) => {
            toast.error(err.message ?? err?.data?.message ?? err.toString());
            setIsLoading(false);
          });
      }}
      className="grid grid-cols-1 gap-6 p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
    >
      {({ control, watch, formState: { errors } }) => {
        watch((values) => {
          const newValue =
            values.importType?.value === "unit-transaction/project";

          if (needSelectProject !== newValue) {
            setNeedSelectProject(newValue);
          }
        });

        return (
          <>
            <>
              <div className="col-span-full flex justify-between items-center">
                <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
                  Import Data
                </Title>
              </div>
              <div className="col-span-full">
                <div className="flex flex-col gap-6 relative">
                  <Controller
                    control={control}
                    name="importType"
                    render={({ field, fieldState, formState }) => (
                      <Select
                        options={IMPORT_TYPE_OPTIONS}
                        label="Import type"
                        placeholder="Select import type"
                        dropdownClassName="z-[99999999]"
                        className="h-16 relative"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="file"
                    render={({ field, fieldState, formState }) => (
                      <FileInput
                        label="File"
                        size="lg"
                        className="col-span-full"
                        accept=".csv"
                        {...field}
                        {...formState}
                        value={field?.value as any}
                        error={fieldState.error?.message}
                      />
                    )}
                  />

                  {needSelectProject ? (
                    <Controller
                      control={control}
                      name="project"
                      render={({ field, fieldState, formState }) => (
                        <ProjectSelect
                          label="Project"
                          size="lg"
                          placeholder="Select Project"
                          className="col-span-full"
                          dropdownClassName="z-[99999999]"
                          {...field}
                          {...formState}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  ) : null}
                </div>
              </div>

              <div
                className={cn(
                  "col-span-full flex items-center justify-end gap-4 bg-white p-2"
                )}
              >
                <Button
                  type="button"
                  disabled={isLoading}
                  className="w-full @xl:w-auto"
                  variant="outline"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  className="w-full @xl:w-auto"
                >
                  Import
                </Button>
              </div>
            </>
          </>
        );
      }}
    </Form>
  );
}
