import { routes } from "@/config/routes";
import AddNewButton from "@/app/shared/add-new-button";
import UserTable from "@/app/app-shared/user/users-table";

const Users = () => {
  return <UserList />;
};

const UserList = () => {
  return (
    <div>
      <UserTable />
    </div>
  );
};

export default Users;
