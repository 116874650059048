import { z } from "zod";

// form zod validation schema
export const createDeveloperSchema = z.object({
  name: z.string().min(1, "This field is required"),
  description: z.string().optional(),
  logo: z
    .object({
      id: z.string().min(1, "This field is required"),
      url: z.string().optional(),
    })
    .nullable(),
});

// generate form types from zod validation schema
export type CreateDeveloperInput = z.infer<typeof createDeveloperSchema>;
