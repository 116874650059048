import { useModal } from "@/app/shared/modal-views/use-modal";
import useApi from "@/hooks/useApi";
import DisclaimerRepository from "@/utilities/repositories/Disclaimer";
import { useEffect } from "react";
import { PiXBold } from "react-icons/pi";
import { ActionIcon, Button, Title } from "rizzui";

export default function DisclaimerNotification() {
  const { request: getDisClaimer, response: disclaimerResponse } = useApi({
    request: DisclaimerRepository.getDisClaimer,
  });
  const { isOpen, closeModal } = useModal();
  useEffect(() => {
    getDisClaimer();
  }, []);
  return (
    <div className="p-6 flex flex-col gap-6">
      <div className="col-span-full flex items-center justify-between">
        <Title
          as="h4"
          className="font-semibold"
        >
          Disclaimer
        </Title>
      </div>
      <div className="flex flex-col gap-6">
        <div
          dangerouslySetInnerHTML={{
            __html: disclaimerResponse?.data?.value || "",
          }}
        />
        {isOpen && (
          <Button
            color="danger"
            className="w-[150px] mx-auto"
            onClick={() => closeModal()}
          >
            Click to Close
          </Button>
        )}
      </div>
    </div>
  );
}
