import { carbonMenuItems } from "@/layouts/carbon/carbon-menu-items";
import { Roles } from "../types/Users";

export function findAccessiblePath(currentRole?: Roles): string | null {
  if (!currentRole) return null;
  const accessibleTab = carbonMenuItems.find((item) =>
    item.permissions?.includes(currentRole)
  );
  if (!accessibleTab || !accessibleTab?.href) return null;

  return accessibleTab.href;
}
