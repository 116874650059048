import { Enum } from "./Enum";

export const E_UNIT_TRANSACTION = Object.freeze({
  MAIN: "/unit-transaction",
  UNIT_TRANSACTION_ID: (id: string = ":id") => `/unit-transaction/${id}`,
});

export type E_UNIT_TRANSACTION = Enum<typeof E_UNIT_TRANSACTION>;

export enum EUnitTransactionStatus {
  SOLD = "sold",
  AVAILABLE = "available",
}
