"use client";

import { useEffect, useState } from "react";
import { Button, Text, Input, Password } from "rizzui";
import { SubmitHandler } from "react-hook-form";
import { Form } from "@/components/ui/form";
import { routes } from "@/config/routes";
import {
  resetPasswordSchema,
  ResetPasswordSchema,
  submitEmailSchema,
  SubmitEmailSchema,
} from "@/utils/validators/reset-password.schema";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AuthRepository from "@/utilities/repositories/Auths.ts";
import { EStatusCode } from "@/utilities/enums/StatusCodes.ts";
import toast from "react-hot-toast";
import useApi from "@/hooks/useApi.tsx";

const BASE_URL = import.meta.env.VITE_BASE_URL as string;

const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
};

export default function ForgetPasswordForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [startReset, setStartReset] = useState<boolean>(false);
  const [reset, setReset] = useState({
    email: "",
  });
  const navigate = useNavigate();

  const { request: VerifyTokenReset } = useApi({
    request: AuthRepository.verifyToken,
  });

  const onSubmit: SubmitHandler<SubmitEmailSchema> = async (data) => {
    const dataSubmit = {
      ...data,
      callbackURL: `${BASE_URL}${routes.auth.forgotPassword}`,
    };
    const response = await AuthRepository.forgotPassword(dataSubmit);
    if (response.statusCode === EStatusCode.Created) {
      toast.success(`${response.message}, Please check your email`);
    } else if (response.statusCode === EStatusCode.NotFound) {
      toast.error(`${response.message}`);
    }
    // setReset(initialValues);
  };

  const onSubmitReset: SubmitHandler<ResetPasswordSchema> = async (data) => {
    const dataSubmit = {
      ...data,
      token: searchParams.get("token"),
    };
    const response = await AuthRepository.resetPassword(dataSubmit);
    if (response.statusCode === EStatusCode.Created) {
      toast.success(`${response.message}`);
      navigate(routes.auth.signIn);
    }
  };

  useEffect(() => {
    if (searchParams && searchParams.get("token")) {
      const queryParams = {
        token: searchParams.get("token"),
      };
      VerifyTokenReset({
        queryParams,
      }).then((rsp) => {
        if (rsp && rsp.statusCode !== EStatusCode.OK) {
          toast.error(`${rsp.message}`);
        } else {
          setStartReset(true);
        }
      });
    }
  }, []);

  return (
    <>
      {!startReset ? (
        <Form<SubmitEmailSchema>
          validationSchema={submitEmailSchema}
          resetValues={reset}
          onSubmit={onSubmit}
          useFormProps={{
            mode: "onChange",
            defaultValues: initialValues,
          }}
          className="pt-1.5"
        >
          {({ register, formState: { errors } }) => (
            <div className="space-y-6">
              <Input
                type="email"
                size="lg"
                label="Email"
                placeholder="Enter your email"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                {...register("email")}
                error={errors.email?.message}
              />
              <Button className="mt-2 w-full" type="submit" size="lg">
                Reset Password
              </Button>
            </div>
          )}
        </Form>
      ) : (
        <Form<ResetPasswordSchema>
          validationSchema={resetPasswordSchema}
          resetValues={reset}
          onSubmit={onSubmitReset}
          useFormProps={{
            mode: "onChange",
            defaultValues: initialValues,
          }}
          className="pt-1.5"
        >
          {({ register, formState: { errors } }) => (
            <div className="space-y-6">
              <Password
                label="Password"
                placeholder="Enter your password"
                size="lg"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                {...register("password")}
                error={errors.password?.message}
              />
              <Button className="mt-2 w-full" type="submit" size="lg">
                Reset Password
              </Button>
            </div>
          )}
        </Form>
      )}

      <Text className="mt-6 text-center leading-loose text-gray-500 lg:mt-8 lg:text-start">
        Don’t reset password?{" "}
        <Link
          to={routes.auth.signIn}
          className="font-semibold text-gray-700 transition-colors hover:text-blue"
        >
          Sign In
        </Link>
      </Text>
    </>
  );
}
