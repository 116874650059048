"use client";

import SectionConfig from "@/app/app-shared/homepage-layout";
import { useModal } from "@/app/shared/modal-views/use-modal";
import useProfile from "@/hooks/use-profile";
import useApi from "@/hooks/useApi";
import SettingsRepository from "@/utilities/repositories/Settings";
import { TAgencySetting, TDomain } from "@/utilities/types/Setting";
import { IResponseData } from "@/utilities/types/requests";
import { Suspense, useEffect, useMemo } from "react";
import CreateAgencySetting from "../settings/CreateAgencySetting";

export default function LayoutPage() {
  const { profile } = useProfile();
  const { openModal } = useModal();

  const onOpenCreateAgencySettingModal = () =>
    openModal({
      view: (
        <CreateAgencySetting
          isModalView
          userId={profile?.id || ""}
          onRefetch={onFetchAgencySetting}
        />
      ),
      customSize: "900px",
    });

  const { request: getAgencySetting, response: agencySettingData } = useApi<
    IResponseData<TAgencySetting>
  >({
    request: SettingsRepository.getAgencySetting,
  });

  const onFetchAgencySetting = () =>
    getAgencySetting()
      .then((res) => {
        if (!res?.data) return;
      })
      .catch(() => onOpenCreateAgencySettingModal());

  useEffect(() => {
    onFetchAgencySetting();
  }, []);

  const previewUrl = useMemo(() => {
    const domain = agencySettingData?.data?.domains?.find(
      (domain: TDomain) => !!domain?.primary
    );
    if (domain) {
      return (domain as TDomain)?.name || "";
    }
    return "";
  }, [agencySettingData?.data?.domains]);

  return (
    <div>
      <Suspense>
        <SectionConfig previewUrl={previewUrl} />
      </Suspense>
    </div>
  );
}
