import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { IUserPreference } from "../types/UserPreference";
import { E_USER_PREFERENCE } from "../enums/UserPreference";

function getAllUserPreference({ queryParams }: IRequestArgs) {
  const query = {
    filter: queryParams?.query?.filter,
    sort: queryParams?.query?.sort,
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<IUserPreference>>({
    method: ERequestMethods.GET,
    url: E_USER_PREFERENCE.LISTING,
    params: query,
  });
}

function deleteUserPreference(id: string) {
  return request<IResponseData<IUserPreference>>({
    method: ERequestMethods.DELETE,
    url: E_USER_PREFERENCE.USER_PREFERENCE_ID(id),
  });
}

const UserPreferenceRepository = Object.freeze({
  getAllUserPreference,
  deleteUserPreference,
});

export default UserPreferenceRepository;
