/* eslint-disable no-duplicate-imports */
import type { UniqueIdentifier } from "@dnd-kit/core";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  rectSwappingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import type { ReactNode } from "react";
import { DragHandle, SortableItem } from "./dnd-sortable-item";
import { SortableOverlay } from "./dnd-sortable-overly";

interface BaseItem {
  id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
  items: T[];
  onChange(values: { item: T; newIndex: number }): void;
  children?: ReactNode;
}

export function SortableList<T extends BaseItem>({
  items,
  onChange,
  children,
}: Props<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={() => {}}
      onDragEnd={(event) => {
        const item = items.find((i) => i.id === event.active.id);
        const newIndex = event?.over?.data?.current?.sortable?.index;
        if (!item) return;
        onChange({ item, newIndex });
      }}
      onDragCancel={() => {}}
    >
      <SortableContext
        strategy={rectSwappingStrategy}
        items={items}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
SortableList.Overlay = SortableOverlay;
