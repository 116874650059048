export const SECTIONS = {
  LEFT_WITH_GALLERY: require("./project-left-with-gallery.png"),
  GALLERY_THREE: require("./gallery-three.png"),
  TOP_WITH_GALLERY: require("./project-top-with-gallery.png"),
  SWIFT_CARDS: require("./swift-card.png"),
  SCROLL_GALLERY: require("./project-with-scroll-gallery.png"),
  SLIDE_CARDS: require("./slide-cards.png"),
  GALLERY_SIX: require("./gallery-six.png"),
  BANNER_PROMOTION: require("./banner-promotion.png"),
  FEATURE_LAUNCHES: require("./feature_launches.png"),

  //PREVIEW

  LEFT_WITH_GALLERY_PREVIEW: require("./project-left-with-gallery-preview.png"),
  GALLERY_THREE_PREVIEW: require("./gallery-three-preview.png"),
  TOP_WITH_GALLERY_PREVIEW: require("./project-top-with-gallery-preview.png"),
  SWIFT_CARDS_PREVIEW: require("./swift-card-preview.png"),
  SCROLL_GALLERY_PREVIEW: require("./project-with-scroll-gallery-preview.png"),
  SLIDE_CARDS_PREVIEW: require("./slide-cards-preview.png"),
  GALLERY_SIX_PREVIEW: require("./gallery-six-preview.png"),
  // BANNER_PROMOTION_PREVIEW: require("./banner-promotion-preview.png"),
  FEATURE_LAUNCHES_PREVIEW: require("./feature_launches_preview.png"),
};
