import useApi from "@/hooks/useApi.tsx";
import { IResponseData } from "@/utilities/types/requests.ts";
import { IMortgage } from "@/utilities/types/Mortgage.ts";
import MortgageRepository from "@/utilities/repositories/Mortgage.ts";
import { useTableServer } from "@/hooks/useTableServer.tsx";
import ControlledTable from "@/components/controlled-table";
import { useCallback, useMemo } from "react";
import { GetColumns } from "@/app/app-shared/mortgage/table/columns.tsx";
import { Roles } from "@/utilities/types/Users.ts";
import { useColumn } from "@/hooks/use-column.ts";
import ModalCreateEditMortgage from "@/app/app-shared/mortgage/create-edit/create-edit-mortgage";
import { Title } from "rizzui";
import ModalButton from "@/app/shared/modal-button";
import CreateEditMortgage from "@/app/app-shared/mortgage/create-edit/create-edit-mortgage";
import { useModal } from "@/app/shared/modal-views/use-modal";

const MortgageTable = () => {
  const { request: deleteMortgage } = useApi<IResponseData<IMortgage>>({
    request: MortgageRepository.deleteMortgage,
    enableToast: true,
  });

  const {
    handleReset,
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handleSort,
    handlePaginate,
    pageSize,
    handleChangePageSize,
  } = useTableServer({
    server: {
      request: MortgageRepository.getMortgageList,
    },
  });

  const onHeaderCellClick = (value: string) => ({
    onClick: () => {
      handleSort(value);
    },
  });

  const { openModal } = useModal();

  const onEditItem = (row: IMortgage) => {
    openModal({
      view: (
        <CreateEditMortgage
          refetchData={() => handlePaginate(currentPage)}
          row={row}
        />
      ),
      customSize: "600px",
    });
  };

  const onDeleteItem = useCallback(
    async (id: string) => {
      await deleteMortgage(id);
      handleReset();
    },
    [handleReset]
  );

  const columns = useMemo(
    () =>
      GetColumns({
        data: tableData,
        onDeleteItem,
        onHeaderCellClick,
        reset: handleReset,
        role: Roles.ADMIN,
        onEditItem,
      }),
    [onHeaderCellClick, onDeleteItem, tableData, onEditItem]
  );

  const { visibleColumns } = useColumn(columns);

  return (
    <>
      <div className="mt-4">
        <div className="mb-4 w-full flex items-center justify-between">
          <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
            Mortgage
          </Title>

          <ModalButton
            view={<ModalCreateEditMortgage refetchData={handleReset} />}
            label="Add mortgage"
            customSize="600px"
            className="mt-0 w-auto"
          />
        </div>
        <ControlledTable
          variant="modern"
          data={tableData}
          isLoading={isLoading}
          showLoadingText={true}
          // @ts-ignore
          columns={visibleColumns}
          paginatorOptions={{
            pageSize,
            setPageSize: handleChangePageSize,
            total: totalItems,
            current: currentPage,
            onChange: (page: number) => handlePaginate(page),
          }}
          className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
        />
      </div>
    </>
  );
};

export default MortgageTable;
