import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import {
  IProjectDTO,
  IProject,
  IProjectDetail,
  IProjectLocale,
  AddManyUserProjectDTO,
} from "../types/Project";
import { E_PROJECTS } from "../enums/Project";

function getProject({ queryParams }: IRequestArgs) {
  const query = {
    filter: queryParams?.query?.filter,
    sort: queryParams?.query?.sort,
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<IProject>>({
    method: ERequestMethods.GET,
    url: E_PROJECTS.MAIN,
    params: query,
  });
}

function getAgentProject({ queryParams }: IRequestArgs) {
  const locationIds = queryParams?.query?.filter?.[0]?.locationIds;

  const query = {
    sort: queryParams?.query?.sort,
    search: queryParams?.query?.search,
    locationIds: locationIds,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<IProjectLocale>>({
    method: ERequestMethods.GET,
    url: E_PROJECTS.USER_PROJECT,
    params: query,
  });
}

function deleteProject(id: string) {
  return request<IResponseData<IProject>>({
    method: ERequestMethods.DELETE,
    url: E_PROJECTS.PROJECT_ID(id),
  });
}

function createProject(data: IProjectDTO) {
  return request<IResponseData<IProject>>({
    method: ERequestMethods.POST,
    url: E_PROJECTS.MAIN,
    data,
  });
}

function getProjectById(id: string) {
  return request<IResponseData<IProjectDetail>>({
    method: ERequestMethods.GET,
    url: E_PROJECTS.PROJECT_ID(id),
  });
}

function removeUserProject(id: string) {
  return request<IResponseData<IProject>>({
    method: ERequestMethods.DELETE,
    url: E_PROJECTS.USER_PROJECT_ID(id),
  });
}

function addUserProject(id: string) {
  return request<IResponseData<IProject>>({
    method: ERequestMethods.POST,
    url: E_PROJECTS.USER_PROJECT,
    data: {
      projectId: id,
    },
  });
}

function updateUserProject(id: string, data: Partial<IProjectDTO>) {
  return request<IResponseData<IProject>>({
    method: ERequestMethods.PATCH,
    url: E_PROJECTS.USER_PROJECT_ID(id),
    data,
  });
}

function addManyUserProject(dataToAdd: AddManyUserProjectDTO[]) {
  return request<IResponseData<IProject>>({
    method: ERequestMethods.POST,
    url: E_PROJECTS.USER_PROJECT_MANY,
    data: { data: dataToAdd },
  });
}

function updateProject(id: string, data: Partial<IProjectDTO>) {
  return request<IResponseData<IProjectDetail>>({
    method: ERequestMethods.PATCH,
    url: E_PROJECTS.PROJECT_ID(id),
    data,
  });
}

function getProjectOfLastesPromotion() {
  return request<ListResponseData<IProject>>({
    method: ERequestMethods.GET,
    url: `${E_PROJECTS.USER_PROJECT}?filter[0][promotion]=true`,
    params: {
      limit: 50,
      page: 1,
    },
  });
}

function addAllProjects() {
  return request<IResponseData<IProject>>({
    method: ERequestMethods.POST,
    url: `${E_PROJECTS.USER_PROJECT}/add-all-projects`,
  });
}
const ProjectRepository = Object.freeze({
  getProject,
  getAgentProject,
  deleteProject,
  createProject,
  getProjectById,
  removeUserProject,
  addUserProject,
  updateProject,
  getProjectOfLastesPromotion,
  addManyUserProject,
  updateUserProject,
  addAllProjects,
});

export default ProjectRepository;
