import { Enum } from "./Enum";

export const E_SITE_CONTENT = Object.freeze({
  MAIN: "/site-content",
  SITE_CONTENT_ID: (id: string = ":id") => `/site-content/${id}`,
  ADMIN: "site-content/admin",
  ADMIN_SITE_CONTENT_ID: (id: string = ":id") => `/site-content/admin/${id}`,
});

export type E_SITE_CONTENT = Enum<typeof E_SITE_CONTENT>;

export enum ESiteContentType {
  ABOUT_US = "about-us",
  CONTACT_US = "contact-us",
  PARTNER_US = "partner-us",
  PRIVACT_POLICY = "privacy-policy",
  RCR = "rcr",
  CCR = "ccr",
  OCR = "ocr",
}
