import { z } from "zod";

// form zod validation schema
export const createPromotionBannerSchema = z.object({
  name: z.string().min(1, "This field is required"),
  url: z.string(),
  description: z.string().optional(),
  photo: z
    .object({
      id: z.string().min(1, "This field is required"),
      url: z.string().optional(),
    })
    .nullable(),
});

// generate form types from zod validation schema
export type CreatePromotionBannerInput = z.infer<
  typeof createPromotionBannerSchema
>;
