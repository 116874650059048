import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { IMessage, IMessageDTO } from "@/utilities/types/Message.ts";
import { request } from "@/utilities/libs/request.ts";

import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { E_MESSAGE } from "@/utilities/enums/Message.ts";

function getMessagesByAdmin({ queryParams }: IRequestArgs) {
  const query = {
    sortBy: "createdAt",
    sort: "DESC",
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<IMessage>>({
    method: ERequestMethods.GET,
    url: E_MESSAGE.GET_BY_ADMIN,
    params: query,
  });
}
function getMessagesByAgency({ queryParams }: IRequestArgs) {
  const query = {
    sortBy: "createdAt",
    sort: "DESC",
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<IMessage>>({
    method: ERequestMethods.GET,
    url: E_MESSAGE.MAIN_BY_AGENCY,
    params: query,
  });
}

function getAllMessage() {
  const query = {
    sortBy: "createdAt",
    sort: "DESC",
  };
  return request<ListResponseData<IMessage>>({
    method: ERequestMethods.GET,
    url: E_MESSAGE.MAIN_BY_AGENCY,
    params: query,
  });
}

function createMessageByAdmin(data: IMessageDTO) {
  return request<IResponseData<IMessage>>({
    method: ERequestMethods.POST,
    url: E_MESSAGE.MAIN,
    data,
  });
}

function createMessageByAgent(data: IMessageDTO) {
  return request<IResponseData<IMessage>>({
    method: ERequestMethods.POST,
    url: E_MESSAGE.MAIN_BY_AGENCY,
    data,
  });
}

function updateMessageByAdmin(id: string, data: IMessageDTO) {
  return request<IResponseData<IMessage>>({
    method: ERequestMethods.PATCH,
    url: E_MESSAGE.UD(id),
    data,
  });
}

function updateMessageByAgency(id: string, data: IMessageDTO) {
  return request<IResponseData<IMessage>>({
    method: ERequestMethods.PATCH,
    url: E_MESSAGE.UD_BY_AGENCY(id),
    data,
  });
}

function deleteMessageByAdmin(id: string) {
  return request<IResponseData<IMessage>>({
    method: ERequestMethods.DELETE,
    url: E_MESSAGE.UD(id),
  });
}

function deleteMessageByAgency(id: string) {
  return request<IResponseData<IMessage>>({
    method: ERequestMethods.DELETE,
    url: E_MESSAGE.UD_BY_AGENCY(id),
  });
}

function getDetailMessage(id: string) {
  return request<IResponseData<IMessage>>({
    method: ERequestMethods.GET,
    url: E_MESSAGE.UD(id),
  });
}

const MessageRepository = Object.freeze({
  getMessagesByAdmin,
  createMessageByAdmin,
  createMessageByAgent,
  updateMessageByAdmin,
  updateMessageByAgency,
  deleteMessageByAdmin,
  deleteMessageByAgency,
  getMessagesByAgency,
  getDetailMessage,
  getAllMessage,
});

export default MessageRepository;
