("use client");

import { useCallback, useEffect, useMemo } from "react";
import { useColumn } from "@/hooks/use-column";
import ControlledTable from "@/components/controlled-table";
import { useTableServer } from "@/hooks/useTableServer";
import { getColumns } from "./columns";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import { useModal } from "@/app/shared/modal-views/use-modal";
import { Title } from "rizzui";
import ModalButton from "@/app/shared/modal-button";
import CreateEditDeveloper from "../create-edit/create-edit-promotion-banner";
import PromotionBannerRepository from "@/utilities/repositories/PromotionBanner";
import { IPromotionBaner } from "@/utilities/types/PromotionBanner";
import CreateEditPromotionBanner from "../create-edit/create-edit-promotion-banner";
import cn from "@/utils/class-names";

interface IPromotionBannerTableProps {
  sectionId: string;
  canAdd?: boolean;
}

export default function PromotionBannerTable({
  sectionId,
  canAdd = false,
}: IPromotionBannerTableProps) {
  const { request: removePromotionBanner } = useApi<
    IResponseData<IPromotionBaner>
  >({
    request: PromotionBannerRepository.deletePromotionBanner,
    enableToast: true,
  });
  const {
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handlePaginate,
    pageSize,
    handleReset,
    handleChangePageSize,
    updateFilter,
    sortConfig,
    handleSort,
  } = useTableServer<IPromotionBaner>({
    server: {
      request: sectionId
        ? PromotionBannerRepository.getAllPromotionBanner
        : null,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
    initialFilterState: { sectionId },
  });

  const onDeleteItem = useCallback(
    async (id: string) => {
      await removePromotionBanner(sectionId, id);
      handlePaginate(currentPage); // Recall API
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlePaginate, currentPage]
  );
  const { openModal } = useModal();
  const onEditItem = (row: IPromotionBaner) => {
    openModal({
      view: (
        <CreateEditPromotionBanner
          refetchData={() => handlePaginate(currentPage)}
          data={row}
        />
      ),
      customSize: "500px",
    });
  };

  const onHeaderCellClick = (value: string) => ({
    onClick: () => {
      handleSort(value);
    },
  });

  const columns = useMemo(
    () =>
      getColumns({
        data: tableData,
        onEditItem,
        onDeleteItem,
        sortConfig,
        onHeaderCellClick,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onEditItem,
      onDeleteItem,
      JSON.stringify(tableData),
      sortConfig.key,
      sortConfig.direction,
      onHeaderCellClick,
    ]
  );

  const { visibleColumns } = useColumn(columns);

  useEffect(() => {
    if (sectionId) {
      updateFilter("sectionId", sectionId);
    }
  }, [sectionId]);
  //TODO: Uncomment filter and test when BE already filter
  return (
    <>
      <div className="mb-4 w-full flex items-center justify-between">
        <Title
          as="h5"
          className="rizzui-input-label block text-sm mb-1.5 font-medium text-muted-foreground"
        >
          Promotion Banner
        </Title>

        <ModalButton
          view={
            <CreateEditDeveloper
              refetchData={handleReset}
              sectionId={sectionId}
            />
          }
          label="Add"
          customSize="500px"
          className={cn("mt-0 w-auto")}
          canClick={canAdd}
        />
      </div>

      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={visibleColumns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </>
  );
}
