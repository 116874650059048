import WebhookSmsForm from "@/app/app-shared/webhook/webhook-sms-form";
import WebhookSubmitForm from "@/app/app-shared/webhook/webhook-submit-form";
import useApi from "@/hooks/useApi";
import WebhookRepositories from "@/utilities/repositories/Webhook";
import { ListResponseData } from "@/utilities/types/requests";
import { EWebhookType, WebhookItem } from "@/utilities/types/Webhook";
import { useEffect } from "react";

export interface WebhookSettingPageProps {}

export default function WebhookSettingPage({}: WebhookSettingPageProps) {
  const {
    request: fetchData,
    refetch,
    loading: isLoading,
    response,
  } = useApi<ListResponseData<WebhookItem>>({
    request: WebhookRepositories.getWebhookSettings,
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex flex-col divide-y">
      <div className="py-2">
        <WebhookSmsForm
          refetchData={refetch}
          data={
            response?.data?.items.filter(
              (item) => item.type === EWebhookType.SIGNIN
            ) || []
          }
          isLoading={isLoading}
        />
      </div>

      <div className="py-2">
        <WebhookSubmitForm
          refetchData={refetch}
          data={
            response?.data?.items.filter(
              (item) => item.type === EWebhookType.SUBMIT_FORM
            ) || []
          }
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
