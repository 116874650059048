"use client";

import { createRef, use, useCallback, useEffect, useMemo } from "react";
import dynamic from "next/dynamic";
import { useColumn } from "@/hooks/use-column";
import ControlledTable from "@/components/controlled-table";
import { useTableServer } from "@/hooks/useTableServer";
import { getColumns } from "./columns";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import { IProject } from "@/utilities/types/Project";
import ProjectRepository from "@/utilities/repositories/Project";
import { Roles } from "@/utilities/types/Users";
import toast from "react-hot-toast";
import DisclaimerNotification from "../../disclaimer/disclaimer-notifcation";
import { useModal } from "@/app/shared/modal-views/use-modal";
import useProfile from "@/hooks/use-profile";
import { PROJECT_MODAL_SHOWN_KEY } from "@/config/constants";
import CustomPopover from "@/app/shared/custom-popover";
import { PiPlusBold } from "react-icons/pi";
import SettingsRepository from "@/utilities/repositories/Settings";
import { TAgencySetting } from "@/utilities/types/Setting";
import CreateAgencySetting from "@/page/settings/CreateAgencySetting";
import { useSearchParams } from "react-router-dom";
import { useAdvanceFeatures } from "@/hooks/use-advance-features";

const FilterElement = dynamic(
  () => import("@/app/app-shared/project/project-table/filter-element"),
  { ssr: false }
);

export default function ProjectTable() {
  const onHeaderCellClick = (value: string) => ({
    onClick: () => {
      handleSort(value);
    },
  });

  const { profile } = useProfile();

  const { landingPageFeature } = useAdvanceFeatures();

  const { request: deleteProject } = useApi<IResponseData<IProject>>({
    request: ProjectRepository.deleteProject,
    enableToast: true,
  });

  const { request: addProject } = useApi<IResponseData<IProject>>({
    request: ProjectRepository.addUserProject,
    enableToast: true,
  });

  const { request: addAllProjects } = useApi<IResponseData<IProject>>({
    request: ProjectRepository.addAllProjects,
    enableToast: true,
  });

  const { request: removeUserProject } = useApi<IResponseData<IProject>>({
    request: ProjectRepository.removeUserProject,
    enableToast: true,
  });

  const { request: editProject } = useApi({
    request: ProjectRepository.updateProject,
    enableToast: true,
  });

  const [searchParams] = useSearchParams();
  const rowKey = searchParams?.get("rowKey");
  const initialSearch = searchParams?.get("search") || "";

  const {
    isLoading,
    isFiltered,
    tableData,
    currentPage,
    totalItems,
    pageSize,
    handleChangePageSize,
    filters,
    updateFilter,
    searchTerm,
    handleSearch,
    sortConfig,
    handleSort,
    handleReset,
    handlePaginate,
    refresh,
  } = useTableServer({
    server: {
      request: ProjectRepository.getProject,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
  });

  useEffect(() => {
    if (initialSearch) {
      handleSearch(initialSearch);
    }
  }, []);

  const onDeleteItem = async (id: string) => {
    deleteProject(id)
      .then(() => {
        handlePaginate(currentPage); // Recall API
      })
      .catch((err: any) => {
        toast.error(err?.message || "Something went wrong");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onRemoveProject = async (id: string) => {
    removeUserProject(id)
      .then(() => {
        handlePaginate(currentPage); // Recall API
      })
      .catch((err: any) => {
        toast.error(err?.message || "Something went wrong");
      });
  };

  const onAddProject = async (id: string) => {
    addProject(id)
      .then(() => {
        handlePaginate(currentPage); // Recall API
      })
      .catch((err: any) => {
        toast.error(err?.message || "Something went wrong");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const { request: getAgencySetting } = useApi<IResponseData<TAgencySetting>>({
    request: SettingsRepository.getAgencySetting,
  });

  const onFetchAgencySetting = async () => {
    getAgencySetting()
      .then(async (res) => {
        if (!res?.data) return;
        await onAddAllProjects();
      })
      .catch(() => onOpenCreateAgencySettingModal());
  };

  const onAddAllProjects = async () => {
    await addAllProjects()
      .then(() => {
        handlePaginate(currentPage); // Recall API
      })
      .catch((err: any) => {
        toast.error(err?.message || "Something went wrong");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onOpenCreateAgencySettingModal = () =>
    openModal({
      view: (
        <CreateAgencySetting
          isModalView
          userId={profile?.id || ""}
          onRefetch={() => {}}
        />
      ),
      customSize: "900px",
    });

  const onEditProject = useCallback(
    async (
      id: string | undefined,
      data: {
        upcomingLaunch?: boolean;
        promotion?: boolean;
        featured?: boolean;
      },
      onSuccess: () => void
    ) => {
      await editProject(id, data)
        .then(() => {
          // handleReset(); // Recall API
          refresh();
          onSuccess();
        })
        .catch((err: any) => {
          toast.error(err?.message || "Something went wrong");
        });
    },
    [handleReset]
  );

  const columns = useMemo(
    () =>
      getColumns({
        data: tableData,
        sortConfig,
        onHeaderCellClick,
        onDeleteItem,
        onAddProject,
        onRemoveProject,
        onEditProject,
        role: Roles.ADMIN,
        currentUser: profile?.role,
        landingPageFeature,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onHeaderCellClick,
      sortConfig.key,
      sortConfig.direction,
      onDeleteItem,
      onAddProject,
      tableData,
      onRemoveProject,
      onEditProject,
      landingPageFeature,
    ]
  );

  const { visibleColumns } = useColumn(columns);

  const { openModal } = useModal();

  const rowRefs: { [key: string]: React.RefObject<HTMLDivElement> } =
    tableData?.reduce(
      (acc, item) => {
        acc[item.id] = createRef<HTMLDivElement>();
        return acc;
      },
      {} as { [key: string]: React.RefObject<HTMLDivElement> }
    );

  useEffect(() => {
    const modalShown = Boolean(sessionStorage.getItem(PROJECT_MODAL_SHOWN_KEY));
    if (profile?.role === Roles.AGENCY && !modalShown) {
      openModal({
        view: <DisclaimerNotification />,
        customSize: "500px",
      });
      sessionStorage.setItem(PROJECT_MODAL_SHOWN_KEY, "true");
    }
  }, [profile?.role]);

  useEffect(() => {
    if (!(rowKey && typeof document !== "undefined")) return;
    const elementRef = rowRefs?.[rowKey]?.current;
    // Scroll to the element when the component mounts and rowKey is available
    if (rowKey && elementRef) {
      elementRef.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [rowKey, rowRefs]);

  return (
    <div className="mt-4">
      <FilterElement
        isFiltered={isFiltered}
        filters={filters}
        updateFilter={updateFilter}
        handleReset={handleReset}
        onSearch={handleSearch}
        buttonAddAllProjects={
          profile?.role === Roles.AGENCY && (
            <div className="flex flex-end w-full grow-0 md:w-fit">
              <CustomPopover
                title={`Add all Projects`}
                description={`This action will add all projects to your project listing. Are you sure you want to add?`}
                onAccept={async () => await onFetchAgencySetting()}
                className="ml-auto w-full md:w-fit hover:!border-transparent h-fit border-none"
              >
                <div className="rizzui-button inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-[1.8px] focus-visible:ring-offset-2 ring-offset-background transition-colors duration-200 px-5 py-1.5 text-base h-full rounded-md border border-transparent dark:backdrop-blur bg-primary hover:bg-primary-dark dark:hover:bg-primary/90 focus-visible:ring-muted text-primary-foreground w-full">
                  <PiPlusBold className="me-1.5 h-[17px] w-[17px] " />
                  Add All Projects
                </div>
              </CustomPopover>
            </div>
          )
        }
        searchTerm={searchTerm}
        title={
          profile?.role === Roles.AGENCY ? "Projects Inventory" : "All Projects"
        }
      />

      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={visibleColumns}
        onRow={(record) => {
          return {
            ref: rowRefs?.[record.id], // Attach the ref
          };
        }}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
}
