import { SUFFIX_DOMAIN } from "@/config/constants";
import { z } from "zod";
// form zod validation schema
export const createAgencySettingSchema = z.object({
  domain: z
    .string()
    .refine(
      (value) =>
        /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}?$/.test(
          `${value}${SUFFIX_DOMAIN || ""}`
        ),
      "This is domain field"
    )
    .optional(),
  siteTitle: z.string().optional(),
  siteDescription: z.string().optional(),
});

// generate form types from zod validation schema
export type CreateAgencySettingSchema = z.infer<
  typeof createAgencySettingSchema
>;
