import { Enum } from "./Enum";

export const E_DISCLAIMER = Object.freeze({
  MAIN: "/notification/by-key",
});

export type E_DISCLAIMER = Enum<typeof E_DISCLAIMER>;

export enum EDisclaimerKeys {
  Disclaimer = "disclaimer",
  FooterDisclaimer = "footer-disclaimer",
  FormNotice = "form-notice",
}
