import ImageApp from "@/components/ui/image-app";
import { SelectOption, Tooltip } from "rizzui";

export interface OptionItemLayoutProps {
  option: SelectOption;
}

export default function OptionItemLayout({ option }: OptionItemLayoutProps) {
  if (!option.preview) return <div className="w-full">{option.label}</div>;
  return (
    <Tooltip
      className="z-modal"
      size="sm"
      content={
        <ImageApp
          className="max-w-[500px] h-auto object-contain"
          inApp
          src={option.preview || ""}
        />
      }
      placement="top"
      color="invert"
    >
      <div className="w-full">{option.label}</div>
    </Tooltip>
  );
}
