import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { request } from "@/utilities/libs/request.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { ChatBotDTO, ChatBotItem } from "../types/ChatBot";
import { E_ChatBot } from "../enums/ChatBot";

function getAllChatBot(props: IRequestArgs | void) {
  return request<ListResponseData<ChatBotItem>>({
    method: ERequestMethods.GET,
    url: E_ChatBot.MAIN,
  });
}
function settingChatBot(data: Partial<ChatBotDTO>, id?: string) {
  if (!!id) {
    const { email, ...body } = data;
    return request<IResponseData<ChatBotItem>>({
      method: ERequestMethods.PATCH,
      url: E_ChatBot.MAIN + `/${id}`,
      data: body,
    });
  }

  return request<IResponseData<ChatBotItem>>({
    method: ERequestMethods.POST,
    url: E_ChatBot.MAIN,
    data,
  });
}

const ChatBotRepositories = Object.freeze({
  getAllChatBot,
  settingChatBot,
});

export default ChatBotRepositories;
