import { Enum } from "./Enum";

export const E_PROMOTION_BANNER = Object.freeze({
  MAIN: (sectionId: string = ":sectionId") =>
    `/section/${sectionId}/promotion-banners`,
  LISTING: (sectionId: string = ":sectionId") =>
    `/section/${sectionId}/promotion-banners/listing`,
  PROMOTION_BANNER_ID: (sectionId: string = ":sectionId", id: string = ":id") =>
    `/section/${sectionId}/promotion-banners/${id}`,
});

export type E_PROMOTION_BANNER = Enum<typeof E_PROMOTION_BANNER>;
