import { IUserData } from "@/utilities/types/Users";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import Cookies from "js-cookie";
import { useCallback, useEffect } from "react";
import { useAdvanceFeatures } from "./use-advance-features";
import { EFeatureType } from "@/utilities/types/AdvanceFeature";
const Cookie = Cookies.get();

const initialProfile = Cookie["_auth_state"];

export const profileAtom = atomWithStorage<IUserData | null>(
  "atomProfile",
  initialProfile ? JSON.parse(initialProfile) : null
);
export default function useProfile() {
  const setAuth = useSignIn<IUserData>();
  const authHeader = useAuthHeader();
  const {
    dynamicLayoutMode,
    setEnabledLayout,
    setDynamicLayoutMode,
    setLandingPageFeature,
  } = useAdvanceFeatures();

  const [atomProfile, setAtomProfile] = useAtom(profileAtom);
  const Cookie = Cookies.get();

  const defaultProfile = Cookie["_auth_state"];

  useEffect(() => {
    setAtomProfile(defaultProfile ? JSON.parse(defaultProfile) : null);
  }, [defaultProfile]);

  const updateLayoutState = useCallback(() => {
    if (atomProfile && atomProfile?.userFeatures) {
      const featureLayout = atomProfile?.userFeatures?.find(
        (feature) => feature?.feature?.type === EFeatureType.DynamicLayout
      )?.enabled;
      const advanceLayout = atomProfile?.userFeatures?.find(
        (feature) => feature?.feature?.type === EFeatureType.DynamicLayout
      )?.dynamicLayoutMode;
      const landingPageFeature = atomProfile?.userFeatures?.find(
        (feature) => feature?.feature?.type === EFeatureType.LandingPage
      );

      if (landingPageFeature) {
        setLandingPageFeature(landingPageFeature);
      }

      if (featureLayout) {
        setEnabledLayout(featureLayout ? featureLayout : false);
      }
      if (!dynamicLayoutMode && advanceLayout) {
        setDynamicLayoutMode(advanceLayout);
      }
    }
  }, [atomProfile]);

  useEffect(() => {
    updateLayoutState();
  }, [updateLayoutState]);

  if (!authHeader) {
    return {
      profile: defaultProfile
        ? (JSON.parse(defaultProfile) as IUserData)
        : null,
      setProfile: () => {},
    };
  }

  const [token] = authHeader.split(" ").reverse();

  const setProfile = (newProfile: IUserData) => {
    setAuth({
      auth: {
        token: token,
        type: "Bearer",
      },
      userState: newProfile,
    });
    setAtomProfile(newProfile);
  };

  return { profile: atomProfile, setProfile };
}
