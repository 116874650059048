import CreateEditEmailTemplate from "@/app/app-shared/email-template/create-edit-email-template";
import { EEmailTemplateKeys } from "@/utilities/enums/EmailTemplate";

export interface EmailTemplatePageProps {}

export default function EmailTemplatePage({}: EmailTemplatePageProps) {
  return (
    <div>
      <CreateEditEmailTemplate
        templateKey={EEmailTemplateKeys.WELCOME_PROJECT}
      />
    </div>
  );
}
