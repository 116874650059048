import { useEffect, useState } from "react";
import { Button, Tab, Title } from "rizzui";
import { FormProvider, useForm } from "react-hook-form";
import TelegramConfigurationForm from "./features/TelegramConfigurationFormProps";
import cn from "@/utils/class-names";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  advanceFeatures,
  AdvanceFeaturesSchema,
} from "@/utils/validators/advance-features.schema";
import { IResponseData, ListResponseData } from "@/utilities/types/requests";
import useApi from "@/hooks/useApi";
import AdvanceFeatureRepository from "@/utilities/repositories/AdvanceFeature";
import {
  EFeatureMode,
  EFeatureType,
  IFeature,
  IUserFeature,
} from "@/utilities/types/AdvanceFeature";
import { useParams } from "react-router-dom";
import { findFeatureByType } from "./ultil";
import DynamicLayout from "./features/DynamicLayout";
import toast from "react-hot-toast";
import { useAdvanceFeatures } from "@/hooks/use-advance-features";
import LandingPage from "./features/LandingPage";

export default function AdminSetAdvancedFeaturesPage() {
  const [tabIndex, setTabIndex] = useState(0);
  const { setEnabledLayout } = useAdvanceFeatures();
  const form = useForm({
    resolver: zodResolver(advanceFeatures),
    defaultValues: {
      telegramBot: {
        chatId: "",
        enable: false,
        type: EFeatureType.IpAccessTrackerTelegram,
      },
      dynamicLayout: {
        enable: false,
        dynamicLayoutMode: EFeatureMode.Normal,
        type: EFeatureType.DynamicLayout,
      },
      landingPage: {
        enable: false,
        type: EFeatureType.LandingPage,
      },
    },
  });

  const { id } = useParams();

  const { request: getFeatureByUserId, response } = useApi<
    IResponseData<IUserFeature[]>
  >({
    request: AdvanceFeatureRepository.getUserFeaturesById,
  });

  const { request: getAllFeatureInApp, response: featuresInApp } = useApi<
    ListResponseData<IFeature>
  >({
    request: AdvanceFeatureRepository.getFeaturesInApp,
  });

  const { request: createOrUpdateUserFeature } = useApi({
    request: AdvanceFeatureRepository.createOrUpdateUserFeature,
    enableToast: true,
  });

  const onSubmit = (values: AdvanceFeaturesSchema) => {
    const { telegramBot, dynamicLayout, landingPage } = values;

    const submittedFeature = featuresInApp?.data?.items[tabIndex];
    if (!submittedFeature) return;

    switch (submittedFeature.type) {
      case EFeatureType.IpAccessTrackerTelegram: {
        const featureIpAccessTrackerTelegram = findFeatureByType(
          featuresInApp.data.items,
          EFeatureType.IpAccessTrackerTelegram
        );
        if (id && featureIpAccessTrackerTelegram?.id) {
          createOrUpdateUserFeature({
            userId: id,
            chatId: telegramBot?.chatId,
            enabled: telegramBot?.enable,
            featureId: featureIpAccessTrackerTelegram?.id,
          });
        }

        break;
      }
      case EFeatureType.DynamicLayout: {
        const featureDynamicLayout = findFeatureByType(
          featuresInApp.data.items,
          EFeatureType.DynamicLayout
        );
        if (id && featureDynamicLayout?.id) {
          try {
            createOrUpdateUserFeature({
              userId: id,
              enabled: dynamicLayout?.enable || false,
              featureId: featureDynamicLayout?.id,
            });
            setEnabledLayout(dynamicLayout?.enable || false);
          } catch (error) {
            toast(error?.toString() || "Update failed");
          }
        }
        break;
      }
      case EFeatureType.LandingPage: {
        const featureLandingPage = findFeatureByType(
          featuresInApp.data.items,
          EFeatureType.LandingPage
        );
        if (id && featureLandingPage?.id) {
          try {
            createOrUpdateUserFeature({
              userId: id,
              enabled: landingPage?.enable || false,
              featureId: featureLandingPage?.id,
            });
            setEnabledLayout(landingPage?.enable || false);
          } catch (error) {
            toast(error?.toString() || "Update failed");
          }
        }
        break;
      }
      default: {
        console.error("Feature not found");
        break;
      }
    }
  };

  const renderTabPanelByFeatureType = (featureType: EFeatureType) => {
    switch (featureType) {
      case EFeatureType.IpAccessTrackerTelegram:
        return <TelegramConfigurationForm name="telegramBot" isSuperAdmin />;
      case EFeatureType.DynamicLayout:
        return <DynamicLayout name="dynamicLayout" isSuperAdmin />;
      case EFeatureType.LandingPage:
        return <LandingPage name="landingPage" isSuperAdmin />;

      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (id) {
      getFeatureByUserId(id).then((response) => {
        const userFeature = response?.data?.find(
          (userFeature) =>
            userFeature.feature.type === EFeatureType.IpAccessTrackerTelegram
        );
        const userFeatureDynamicLayout = response?.data?.find(
          (userFeature) =>
            userFeature.feature.type === EFeatureType.DynamicLayout
        );
        const userFeatureLandingPage = response?.data?.find(
          (userFeature) => userFeature.feature.type === EFeatureType.LandingPage
        );
        form.reset({
          telegramBot: {
            chatId: userFeature?.chatId || "",
            enable: userFeature?.enabled || false,
            type: EFeatureType.IpAccessTrackerTelegram,
          },
          dynamicLayout: {
            enable: userFeatureDynamicLayout?.enabled || false,
            dynamicLayoutMode:
              userFeatureDynamicLayout?.dynamicLayoutMode ||
              EFeatureMode.Normal,
            type: EFeatureType.DynamicLayout,
          },
          landingPage: {
            enable: userFeature?.enabled || false,
            type: EFeatureType.LandingPage,
          },
        });
      });
    }

    getAllFeatureInApp({
      limit: 50,
      page: 1,
    });
  }, []);

  return (
    <div className="relative z-50 mb-4 h-full flex flex-wrap items-center justify-between gap-2.5 @container">
      <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
        Advanced Features
      </Title>
      <FormProvider {...form}>
        <form
          className="flex flex-col h-full w-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Tab
            className="col-span-full flex-1"
            selectedIndex={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <Tab.List>
              {featuresInApp &&
                featuresInApp?.data?.items?.map((item) => {
                  return <Tab.ListItem key={item.id}>{item.name}</Tab.ListItem>;
                })}
            </Tab.List>

            <Tab.Panels className={"flex-1 h-full"}>
              {featuresInApp &&
                featuresInApp?.data?.items?.map((item) => {
                  return (
                    <Tab.Panel
                      key={item.id}
                      data-name="ip-access-tracker-with-telegram-alerts"
                      className="flex flex-col gap-6"
                    >
                      {renderTabPanelByFeatureType(item.type)}
                    </Tab.Panel>
                  );
                })}
            </Tab.Panels>
          </Tab>

          <div
            className={cn(
              "col-span-full flex items-center justify-end gap-4 bg-white p-2 sticky bottom-0 left-0 right-0 z-[99]"
            )}
          >
            <Button
              type="submit"
              id={"form-settings"}
              className="w-full @xl:w-auto"
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
