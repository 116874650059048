import AuthWrapperFour from "@/app/shared/auth-layout/auth-wrapper-four";
import SignInForm from "@/components/auth/sign-in-form";

function SignIn() {
  return (
    <AuthWrapperFour title={<></>} isSignIn isSocialLoginActive={false}>
      <SignInForm />
    </AuthWrapperFour>
  );
}

export default SignIn;
