import { routes } from "@/config/routes";
import ForgetPassword from "@/page/ForgetPassword";
import SignIn from "@/page/SignIn";
import VerifyOtpPage from "@/page/verify-otp/VerifyOtpPage";
import { publicRoutesType } from "@/types/routes";

export const publicRoutes: publicRoutesType[] = [
  {
    path: routes.auth.signIn,
    component: SignIn,
    layout: false,
  },
  {
    path: routes.auth.forgotPassword,
    component: ForgetPassword,
    layout: false,
  },

  {
    path: routes.auth.verifyOtp,
    component: VerifyOtpPage,
    layout: false,
  },
];
