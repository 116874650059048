export const checkToHideMenuItem = (
  menuId: string,
  {
    advancedFeatures,
  }: {
    advancedFeatures?: any[];
  }
) => {
  switch (menuId) {
    case "advanced-features":
      return !advancedFeatures?.length;

    default:
      return false;
  }
};
