import cn from "@/utils/class-names";
import { useState } from "react";
import toast from "react-hot-toast";
import { BiEdit } from "react-icons/bi";
import { ActionIcon, Input } from "rizzui";

export interface CellEditProps {
  onSubmit: (value: string) => Promise<void> | undefined;
  onChange?: (value: string) => void;
  defaultValue: string;
  defaultToggle?: boolean;
  error?: string;
  readOnly?: boolean;
  isNumber?: boolean;
  className?: string;
  size?: "sm" | "md" | "lg";
}

export default function CellEdit({
  onSubmit,
  onChange,
  defaultValue,
  defaultToggle = false,
  error,
  readOnly = false,
  isNumber,
  className = "",
  size = "sm",
}: CellEditProps) {
  const [value, setValue] = useState<string>(defaultValue);
  const [isToggleEdit, setIsToggleEdit] = useState<boolean>(defaultToggle);

  // Function to sanitize input and allow only numbers
  const sanitizeInput = (input: string) => {
    return input.replace(/\D/g, ""); // Remove any non-numeric characters
  };

  return (
    <div className={cn("w-full", className)}>
      {isToggleEdit && !readOnly ? (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              await onSubmit(value);
              setIsToggleEdit(false);
            } catch (err: any) {
              toast.error(err?.message || "Somethig");
            }
          }}
          className="w-full"
        >
          <Input
            value={value}
            inputClassName="[&>input]:pe-0"
            onChange={(e) => {
              let targetValue = e.target.value;
              if (isNumber) {
                targetValue = sanitizeInput(e.target.value);
              }
              setValue(targetValue);
              onChange?.(targetValue);
            }}
            size={size}
            error={error}
          />
        </form>
      ) : (
        <div className="min-w-full flex flex-row gap-x-2 items-center">
          {!readOnly && (
            <ActionIcon
              size="sm"
              onClick={() => {
                setIsToggleEdit(true);
              }}
            >
              <BiEdit />
            </ActionIcon>
          )}
          <span>{value}</span>
        </div>
      )}
    </div>
  );
}
