"use client";

import { useModal } from "@/app/shared/modal-views/use-modal";
import EyeIcon from "@/components/icons/eye";
import DateCell from "@/components/ui/date-cell";
import { HeaderCell } from "@/components/ui/table";
import { SortConfig } from "@/hooks/useTableServer";
import { IEnquiryEntry } from "@/utilities/types/EnquiryEntry";
import dayjs from "dayjs";
import { ActionIcon, Checkbox, Text, Tooltip } from "rizzui";
import EnquiryEntryDetail from "../details/enquiry-entries-details";
import DeletePopover from "@/app/shared/delete-popover";
import { COUNTRY_SG_CODE } from "@/config/constants.ts";
import { PiWarningLight } from "react-icons/pi";

type ContactSortBy = "project" | "createdAt" | "name" | "score";

const getScoreColor = (score: number) => {
  if (score > 80) return "green";
  if (score > 50) return "orange";
  if (score > 1) return "red";
  return "red"; // Score of 1, default case
};

export type Columns = {
  data: IEnquiryEntry[];
  sortConfig?: SortConfig<ContactSortBy>;
  onDeleteItem: (id: string) => void;
  onHeaderCellClick?: (value: string) => void;
  isAdmin: boolean;
  handleSelectAll?: any;
  checkedItems?: string[];
  onChecked?: (id: string) => void;
};

export const getColumns = ({
  data,
  sortConfig,
  onDeleteItem,
  onHeaderCellClick,
  isAdmin,
  checkedItems,
  handleSelectAll,
  onChecked,
}: Columns) => {
  const columns = [
    {
      title: (
        <HeaderCell
          // title={
          //   <div className="ps-2">
          //     {/* <Checkbox
          //       title={"Select All"}
          //       onChange={handleSelectAll}
          //       checked={checkedItems?.length === data.length}
          //       className="cursor-pointer"
          //     /> */}
          //     Create at
          //   </div>
          // }
          title={<HeaderCell title="Create At" />}
          sortable
          align="center"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "createdAt"
          }
        />
      ),
      onHeaderCell: () => onHeaderCellClick?.("createdAt"),
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",

      width: 250,
      render: (_: unknown, row: IEnquiryEntry) => {
        if (!dayjs(row?.createdAt).isValid()) return;
        return (
          <div>
            {/* <Checkbox
              className="ps-2"
              aria-label="Select row"
              checked={checkedItems?.includes(row.id)}
              {...(onChecked && { onChange: () => onChecked(row.id) })}
            /> */}
            <DateCell date={new Date(row.createdAt)} />
          </div>
        );
      },
    },
    {
      title: (
        <HeaderCell
          title="Customer"
          sortable
          align="center"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "name"
          }
        />
      ),

      dataIndex: "name",
      key: "name",
      align: "center",
      onHeaderCell: () => onHeaderCellClick?.("name"),

      render: (_: unknown, row: IEnquiryEntry) => {
        const subString = " ";
        let convertPhone = row.phone;
        if (convertPhone) {
          !convertPhone.includes(COUNTRY_SG_CODE) &&
            (convertPhone = COUNTRY_SG_CODE + convertPhone);
          convertPhone =
            convertPhone.substring(0, 3) +
            subString +
            convertPhone.substring(3);
          convertPhone =
            convertPhone.substring(0, 8) +
            subString +
            convertPhone.substring(8);
        }
        return (
          <div>
            <Text className="text-sm font-medium text-gray-900 dark:text-gray-700">
              {row.name}
            </Text>
            {row.email && (
              <Text className="text-[13px] text-gray-500">{row.email}</Text>
            )}
            {convertPhone && (
              <Text className="text-[13px] text-gray-500">{convertPhone}</Text>
            )}
          </div>
        );
      },
    },

    {
      title: <HeaderCell title="Agent" />,
      dataIndex: "user",
      key: "user",
      render: (_: unknown, row: IEnquiryEntry) => {
        if (!row.user) return;

        const { firstName, lastName, email, phone } = row.user;

        const name = firstName + " " + lastName;

        const subString = " ";
        let convertPhone = phone;
        if (convertPhone) {
          !convertPhone.includes(COUNTRY_SG_CODE) &&
            (convertPhone = COUNTRY_SG_CODE + convertPhone);
          convertPhone =
            convertPhone.substring(0, 3) +
            subString +
            convertPhone.substring(3);
          convertPhone =
            convertPhone.substring(0, 8) +
            subString +
            convertPhone.substring(8);
        }

        return (
          <div>
            <Text className="text-sm font-medium text-gray-900 dark:text-gray-700">
              {name}
            </Text>
            {email && (
              <Text className="text-[13px] text-gray-500">{email}</Text>
            )}
            {convertPhone && (
              <Text className="text-[13px] text-gray-500">{convertPhone}</Text>
            )}
          </div>
        );
      },
    },

    {
      dataIndex: "project",
      key: "project",
      title: (
        <HeaderCell
          title={<span className="whitespace-nowrap">Project</span>}
          sortable
          align="center"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "project"
          }
        />
      ),
      onHeaderCell: () => onHeaderCellClick?.("project"),
      render: (_: unknown, row: IEnquiryEntry) => {
        if (!row.user) return;

        return (
          <div>
            <Text className="text-sm font-medium text-gray-900 dark:text-gray-700">
              {typeof row?.project?.name === "object"
                ? row?.project?.name?.en
                : row?.project?.name}
            </Text>
            {row?.project?.address?.en && (
              <Text className="text-[13px] text-gray-500">
                {row?.project?.address?.en}
              </Text>
            )}
          </div>
        );
      },
    },

    {
      dataIndex: "score",
      key: "score",
      title: (
        <HeaderCell
          title={<span className="whitespace-nowrap">Score</span>}
          sortable
          align="center"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "score"
          }
        />
      ),
      onHeaderCell: () => onHeaderCellClick?.("score"),
      render: (_: unknown, row: IEnquiryEntry) => {
        if (!row.user) return;

        return (
          <div>
            <Text
              className="text-sm flex items-center justify-center font-medium text-gray-900 dark:text-gray-700"
              style={{ color: getScoreColor(row?.score) }}
            >
              {row?.score}
              {row?.score === 0 && <PiWarningLight className="h-4 w-4" />}
            </Text>
          </div>
        );
      },
    },

    {
      title: (
        <HeaderCell
          title="Submit at"
          sortable
          align="center"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "createdAt"
          }
        />
      ),
      onHeaderCell: () => onHeaderCellClick?.("createdAt"),

      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (_: string, row: IEnquiryEntry) => {
        return dayjs(row.createdAt).format(" MMMM DD, YYYY");
      },
    },

    {
      title: (
        <HeaderCell
          className="ps-3"
          title={<span className="whitespace-nowrap">Actions</span>}
        />
      ),
      dataIndex: "action",
      key: "action",
      width: 120,
      align: "center",
      render: (_: string, row: IEnquiryEntry) => (
        <RenderAction onDelete={onDeleteItem} row={row} />
      ),
    },
  ];

  return isAdmin ? columns : columns.filter((column) => column.key !== "user");
};

const RenderAction = ({
  onDelete,
  row,
}: {
  onDelete: (id: string) => void;
  row: IEnquiryEntry;
}) => {
  const { openModal } = useModal();
  return (
    <div
      className="flex items-center justify-center gap-3 pe-4"
      onClick={(e) => e.stopPropagation()}
    >
      <Tooltip
        size="sm"
        content={"View Details"}
        placement="top"
        color="invert"
      >
        <ActionIcon
          as="button"
          size="sm"
          variant="outline"
          aria-label={"View Details"}
          className="hover:!border-gray-900 hover:text-gray-700"
          onClick={() =>
            openModal({
              view: <EnquiryEntryDetail data={row} isModalView />,
              customSize: "1200px",
              size: "xl",
            })
          }
        >
          <EyeIcon className="h-4 w-4" />
        </ActionIcon>
      </Tooltip>

      <DeletePopover
        title={`Delete Enquiry`}
        description={`Are you sure you want to delete this enquiry?`}
        onDelete={() => onDelete(row.id)}
      />
    </div>
  );
};
