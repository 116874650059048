"use client";

import { HeaderCell } from "@/components/ui/table";
import { SortConfig } from "@/hooks/useTableServer";
// import { IDeveloper } from "@/utilities/types/Developer";
import { ILocale } from "@/utilities/types/Locale";
import { IProject, IProjectLocale } from "@/utilities/types/Project";
import { Roles } from "@/utilities/types/Users";
import { Tooltip } from "rizzui";
import CustomCheckbox from "@/components/ui/custom-checkbox";

type PropertySortKey =
  | "name"
  | "address"
  | "location"
  | "expectedTop"
  | "tenure"
  | "directCommission";

type Columns = {
  data: IProjectLocale[] | IProject[];
  sortConfig?: SortConfig<PropertySortKey>;
  onDeleteItem?: (id: string) => void;
  onHeaderCellClick: (value: string) => void;
  onRemoveProject?: (id: string) => void;
  onAddProject?: (id: string) => Promise<void>;

  onToggleShowProject?: (
    id: string | undefined,
    data: {
      isShow?: boolean;
      promotion?: boolean;
      featured?: boolean;
    },
    onSuccess: () => void
  ) => Promise<void>;
  currentUser?: Roles;
  role: Roles;
};

export const getColumnsAgentProject = ({
  sortConfig,
  onHeaderCellClick,
  onToggleShowProject,
  role,
}: Columns) => {
  const COLUMNS = [
    {
      title: (
        <HeaderCell
          title="Name"
          sortable
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "name"
          }
          align="left"
        />
      ),
      onHeaderCell: () => onHeaderCellClick("name"),
      dataIndex: "name",

      key: "name",

      render: (field: ILocale, row: IProject) => (
        <>
          <p>{typeof row?.name === "object" ? row?.name?.en : row?.name}</p>
          {/* {row.floorPlanCount === 0 && (
            <small className={"text-xs text-red italic"}>
              Floorplan will be available soon
            </small>
          )} */}
        </>
      ),
    },

    {
      title: "Show Project",
      dataIndex: "showProject",
      key: "showProject",
      align: "center",
      render: (_: string, row: IProject) => {
        return (
          <div className={"flex justify-center"}>
            <Tooltip
              size="sm"
              content={"Togghle show project"}
              placement="top"
              color="invert"
            >
              <div>
                <CustomCheckbox
                  className="m-1"
                  rounded="sm"
                  defaultChecked={!!row?.userProjects?.[0]?.isShow}
                  onChangeValue={(e, onSuccess) => {
                    if (onToggleShowProject) {
                      const data = {
                        isShow: e.target.checked,
                      };
                      onToggleShowProject(row?.id, data, onSuccess);
                    }
                  }}
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return role === Roles.AGENCY
    ? COLUMNS
    : COLUMNS.filter((column) => column?.key !== "featured");
};
