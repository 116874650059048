import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { request } from "@/utilities/libs/request.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { WebhookItem, WebhookSettingDTO } from "../types/Webhook";
import { E_Webhook } from "../enums/Wenhook";

function getWebhookSettings(props: IRequestArgs | void) {
  return request<ListResponseData<WebhookItem>>({
    method: ERequestMethods.GET,
    // params: query,
    url: E_Webhook.MAIN,
  });
}
function settingWebhook(data: WebhookSettingDTO) {
  return request<IResponseData<WebhookItem[]>>({
    method: ERequestMethods.POST,
    url: E_Webhook.MAIN,
    data,
  });
}

const WebhookRepositories = Object.freeze({
  getWebhookSettings,
  settingWebhook,
});

export default WebhookRepositories;
