import { useEffect, useState } from "react";
import { Modal, Button, ActionIcon, Input, Tooltip, Textarea } from "rizzui";
import PencilIcon from "@/components/icons/pencil.tsx";
import useApi from "@/hooks/useApi.tsx";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { IMessage, IMessageDTO } from "@/utilities/types/Message.ts";
import MessageRepository from "@/utilities/repositories/Message.ts";
import { editMessageSchema } from "@/utilities/validators/message.ts";
import { PiPlusBold, PiPlusLight } from "react-icons/pi";

export interface ModalEditProps {
  reset?: () => void;
  row?: IMessage;
  isAgency?: boolean;
  canEdit?: boolean;
}

export default function ModalCreateEditMessage({
  isAgency,
  canEdit = true,
  row,
  reset,
}: ModalEditProps) {
  const form = useForm({
    defaultValues: {
      subject: row?.subject || "",
      content: row?.content || "",
      type: "whats_app",
    },
    resolver: zodResolver(editMessageSchema),
  });
  const [modalState, setModalState] = useState(false);

  const isUpdate: boolean = !!row?.id;
  const title = row?.id ? "Update" : "Create";

  let requestUpdate = MessageRepository.updateMessageByAdmin;
  let requestCreate = MessageRepository.createMessageByAdmin;
  if (isAgency) {
    requestUpdate = MessageRepository.updateMessageByAgency;
    requestCreate = MessageRepository.createMessageByAgent;
  }
  const { loading: loadingUpdate, request: updateMessage } = useApi({
    request: requestUpdate,
  });

  const { request: createMessage } = useApi({
    request: requestCreate,
  });

  useEffect(() => {
    form.reset({
      subject: row?.subject || "",
      content: row?.content || "",
    });
  }, [modalState]);

  const onSubmit = async (formData: IMessageDTO) => {
    const dataToCreateOrUpdate = {
      ...formData,
      type: "whats_app",
    };
    try {
      if (isUpdate && canEdit) {
        await updateMessage(row?.id, dataToCreateOrUpdate);
      } else {
        await createMessage(dataToCreateOrUpdate);
      }
      reset?.();
      toast.success("Successfully");
      setModalState(false);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };
  return (
    <>
      {isUpdate ? (
        <Tooltip
          size="sm"
          content={"Edit Message"}
          placement="top"
          color="invert"
        >
          <ActionIcon
            size="sm"
            variant="outline"
            onClick={() => setModalState(true)}
          >
            <PencilIcon className="h-4 w-4" />
          </ActionIcon>
        </Tooltip>
      ) : (
        <>
          <div className={"flex justify-end items-center gap-2"}>
            <Button
              onClick={() => {
                setModalState(true);
              }}
              className="w-auto mr-0 flex flex-nowrap items-center gap-1"
            >
              <PiPlusBold className="me-1.5 h-[17px] w-[17px]" />
              Add New
            </Button>
          </div>
        </>
      )}

      <Modal
        containerClassName="p-6 modal-message"
        size={"lg"}
        isOpen={modalState}
        onClose={() => setModalState(false)}
      >
        <form
          id={"form-message"}
          className={"gap-4 grid grid-cols-2 form-message"}
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className={"col-span-full"}>
            <Input
              label="Subject"
              placeholder="Subject"
              // value={row?.name?.en || ''}
              {...form.register("subject")}
              error={form?.formState?.errors.subject?.message}
            />
          </div>
          <div className={"col-span-full"}>
            <Textarea
              label="Content"
              placeholder="Content Message"
              // value={row?.name?.en || ''}
              {...form.register("content")}
              error={form?.formState?.errors.content?.message}
            />
          </div>
          <div className="col-span-full">
            <div className="flex flex-col gap-2">
              <div className="rizzui-textarea-label block text-[16px] font-bold">
                Note:
              </div>
              <div className="rizzui-textarea-label text-sm font-medium">
                Please enclose the field names in square brackets []. The code
                will only recognize the following fields on this UI.
              </div>
              <div className="flex gap-2">
                <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                  Customer name:
                </div>
                <div className="rizzui-textarea-label text-sm font-medium">
                  customer_name
                </div>
              </div>
              <div className="flex gap-2">
                <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                  Customer email:
                </div>
                <div className="rizzui-textarea-label text-sm font-medium">
                  customer_email
                </div>
              </div>
              <div className="flex gap-2">
                <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                  Customer phone:
                </div>
                <div className="rizzui-textarea-label text-sm font-medium">
                  customer_phone
                </div>
              </div>
              <div className="flex gap-2">
                <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                  Agent Name:
                </div>
                <div className="rizzui-textarea-label text-sm font-medium">
                  agent_name
                </div>
              </div>
              <div className="flex gap-2">
                <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                  Agent Email:
                </div>
                <div className="rizzui-textarea-label text-sm font-medium">
                  agent_email
                </div>
              </div>
              <div className="flex gap-2">
                <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                  Project name:
                </div>
                <div className="rizzui-textarea-label text-sm font-medium">
                  project_name
                </div>
              </div>
              <div className="flex gap-2">
                <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                  Project address:
                </div>
                <div className="rizzui-textarea-label text-sm font-medium">
                  project_address
                </div>
              </div>
              <div className="flex gap-2">
                <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                  Project area:
                </div>
                <div className="rizzui-textarea-label text-sm font-medium">
                  project_area
                </div>
              </div>
              <div className="flex gap-2">
                <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                  Project tenure:
                </div>
                <div className="rizzui-textarea-label text-sm font-medium">
                  project_tenure
                </div>
              </div>
              <div className="flex gap-2">
                <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                  Project tenure effect from:
                </div>
                <div className="rizzui-textarea-label text-sm font-medium">
                  project_tenure_effect_from
                </div>
              </div>
              <div className="flex gap-2">
                <div className="rizzui-textarea-label text-sm font-medium w-[180px]">
                  Appointment date time:
                </div>
                <div className="rizzui-textarea-label text-sm font-medium">
                  appointment_date_time
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-full flex items-center justify-end gap-4">
            <Button
              variant="outline"
              onClick={() => {
                setModalState(false);
              }}
              className="md:w-auto w-1/2"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              id={"form-message"}
              isLoading={loadingUpdate}
              className="md:w-auto w-1/2"
              disabled={!form?.formState?.isDirty}
            >
              {title}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
