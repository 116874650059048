"use client";

import DeletePopover from "@/app/shared/delete-popover";
import { useModal } from "@/app/shared/modal-views/use-modal";
import { HeaderCell } from "@/components/ui/table";
import { IUserPreference } from "@/utilities/types/UserPreference";
import { formatDate } from "@/utils/format-date";
import { ActionIcon, Tooltip } from "rizzui";
import EyeIcon from "@/components/icons/eye";
import UserPreferenceDetail from "../details/user-preference-details";

type Columns = {
  data: IUserPreference[];
  onDeleteItem: (id: string) => Promise<void>;
};

export const getColumns = ({ onDeleteItem }: Columns) => [
  {
    title: (
      <HeaderCell
        title="Name"
        align="center"
      />
    ),
    key: "name",
    dataIndex: "name",
    align: "center",
  },
  {
    title: (
      <HeaderCell
        title="Phone"
        align="center"
      />
    ),
    key: "phone",
    dataIndex: "phone",
    align: "center",
  },
  {
    title: (
      <HeaderCell
        title="Email"
        align="center"
      />
    ),
    dataIndex: "email",
    key: "email",
    align: "center",
  },
  {
    title: (
      <HeaderCell
        title="Created At"
        align="center"
      />
    ),
    dataIndex: "createdAt",
    key: "createdAt",
    align: "center",
    render: (value: string) =>
      formatDate(new Date(value), "DD/MM/YYYY hh:mm a"),
  },
  {
    title: (
      <HeaderCell
        className="ps-3"
        title={<span className="whitespace-nowrap">Actions</span>}
        align="center"
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: IUserPreference) => (
      <RenderAction
        row={row}
        onDeleteItem={onDeleteItem}
      />
    ),
  },
];

const RenderAction = ({
  row,
  onDeleteItem,
}: {
  row: IUserPreference;
  onDeleteItem: (id: string) => Promise<void>;
}) => {
  const { openModal } = useModal();
  return (
    <div className="flex items-center justify-center gap-3 pe-4">
      <Tooltip
        size="sm"
        content={"View Details"}
        placement="top"
        color="invert"
      >
        <ActionIcon
          as="button"
          size="sm"
          variant="outline"
          aria-label={"View Details"}
          className="hover:!border-gray-900 hover:text-gray-700"
          onClick={() =>
            openModal({
              view: (
                <UserPreferenceDetail
                  data={row}
                  isModalView
                />
              ),
              customSize: "900px",
            })
          }
        >
          <EyeIcon className="h-4 w-4" />
        </ActionIcon>
      </Tooltip>

      <DeletePopover
        title={`Delete user preference`}
        description={`Are you sure you want to delete this user preference?`}
        onDelete={() => onDeleteItem(row?.id || "")}
      />
    </div>
  );
};
