import { request } from "@/utilities/libs/request.ts";
import { IResponseData } from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { IDisclaimer, IDisclaimerDTO } from "../types/Disclaimer";
import { EDisclaimerKeys, E_DISCLAIMER } from "../enums/Disclaimer";

function getDisClaimer(key: EDisclaimerKeys = EDisclaimerKeys.Disclaimer) {
  return request<IResponseData<IDisclaimer>>({
    method: ERequestMethods.GET,
    url: `${E_DISCLAIMER.MAIN}?key=${key}`,
  });
}

function updateDisclaimer(data: Partial<IDisclaimerDTO>) {
  return request<IResponseData<IDisclaimer>>({
    method: ERequestMethods.PUT,
    url: E_DISCLAIMER.MAIN,
    data,
  });
}

const DisclaimerRepository = Object.freeze({
  getDisClaimer,
  updateDisclaimer,
});

export default DisclaimerRepository;
