import { Enum } from "./Enum"


export const FLOORS = Object.freeze(
    {
        GET:`/floor-plan`,
        CREATE:"/floor-plan",
        DETAIL:(id:string) => `/floor-plan/${id}`,
        UPDATE:(id:string) => `/floor-plan/${id}`,
        REMOVE:(id:string) => `/floor-plan/${id}`
    }
)

export type E_FLOORS = Enum<typeof FLOORS>