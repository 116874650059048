import cn from "@/utils/class-names";
import { useState } from "react";
import { PiCaretDownBold } from "react-icons/pi";
import { Collapse, Title } from "rizzui";
import ProjectSetting from "./project-setting";

export interface CollapseProjectSettingProps {
  sectionId: string;
  locationId?: string;
  indexForm: number;
  defaultOpen: boolean;
  previewUrl?: string;
}

export default function CollapseProjectSetting({
  sectionId,
  locationId = "",
  indexForm,
  defaultOpen,
  previewUrl,
}: CollapseProjectSettingProps) {
  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <Collapse
      data-state-open={collapseOpen}
      defaultOpen={defaultOpen}
      header={({ open, toggle }) => (
        <button
          type="button"
          onClick={() => {
            setCollapseOpen(open ? open : true);
            toggle();
          }}
          className={cn(
            "flex w-max mt-4 cursor-pointer items-center  text-start"
          )}
          data-state-open={open}
        >
          <div className="pe-6">
            <Title as="h4" className="text-base font-medium @2xl:mb-2">
              Select projects
            </Title>
          </div>
          <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-gray-100">
            <PiCaretDownBold
              className={cn(
                "h-3.5 w-3.5 transform transition-transform duration-300",
                open && "rotate-180"
              )}
            />
          </div>
        </button>
      )}
    >
      {collapseOpen && (
        <ProjectSetting
          sectionId={sectionId}
          locationId={locationId || ""}
          indexForm={indexForm}
          previewUrl={previewUrl}
        />
      )}
    </Collapse>
  );
}
