"use client";
import { useEffect, useState } from "react";

const useCountdown = (targetDate: any) => {
  if (!targetDate) return null;

  const countDownDate = new Date(targetDate).getTime();
  const diff = countDownDate - new Date().getTime();
  const [countDown, setCountDown] = useState(diff > 0 ? diff : 0);

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = countDownDate - new Date().getTime();
      if (diff < 0) {
        clearInterval(interval);
        setCountDown(0);
        return;
      }
      setCountDown(diff);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: any) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds, countDown };
};

export { useCountdown, getReturnValues };
