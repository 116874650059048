import { z } from "zod";
import { messages } from "@/config/messages"; // form zod validation schema

export const webhookSigninSchema = z.object({
  url: z
    .string()
    .url({ message: messages.urlInvalid })
    .min(1, { message: messages.urlRequired }),
});
// form zod validation schema
export const webhookSettingSchema = z.object({
  webhook: z.array(webhookSigninSchema).refine(
    (items) => {
      return new Set(items.map((item) => item.url)).size === items.length;
    },
    {
      message: "Urls must be unique",
    }
  ),
});

// generate form types from zod validation schema
export type webhookSigninSchema = z.infer<typeof webhookSigninSchema>;
export type webhookSettingSchema = z.infer<typeof webhookSettingSchema>;
