export enum EWebhookType {
  SUBMIT_FORM = "submit-form",
  SIGNIN = "sign-in",
}

export interface WebhookItem {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  name: string; // url
  secretKey: string;
  type: EWebhookType | null;
}

export interface WebhookSettingDTO {
  data: string[]; // URLs string
  type: EWebhookType;
}
