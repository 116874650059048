import CreateEditSiteContent from "@/app/app-shared/site-content/create-edit";
import PageHeader from "@/app/shared/page-header";
import { routes } from "@/config/routes";
import useApi from "@/hooks/useApi";
import { ESiteContentType } from "@/utilities/enums/SiteContent";
import SiteContentRepository from "@/utilities/repositories/SiteContent";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function SiteContentCrUpPage() {
  const { id } = useParams();

  const isUpdate = !!id;

  const { request: getSiteContentById, response } = useApi({
    request: SiteContentRepository.getSiteContentById,
  });

  const title =
    response?.data?.contentType === ESiteContentType.PRIVACT_POLICY
      ? "Privacy Policy"
      : isUpdate
        ? "Edit Site Content"
        : "Create Site Content";

  const pageHeader = {
    title,
    breadcrumb: [
      {
        href: routes.siteContent.listing,
        name: "System Pages",
      },
      {
        name: title,
      },
    ],
  };

  useEffect(() => {
    getSiteContentById(id);
  }, []);

  const renderByType = (type?: ESiteContentType) => {
    if (!type) return <></>;
    if (type === ESiteContentType.PRIVACT_POLICY) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: response?.data?.content || "",
          }}
        />
      );
    }

    return (
      <CreateEditSiteContent
        defaultValues={{
          content: response?.data?.content || "",
          title: response?.data?.title || "",
        }}
      />
    );
  };

  return (
    <div className="@container h-full mt-10">
      <PageHeader
        title={pageHeader.title}
        breadcrumb={pageHeader.breadcrumb}
      ></PageHeader>
      {renderByType(response?.data?.contentType)}
    </div>
  );
}
