import useProfile from "@/hooks/use-profile.ts";

("use client");

import { useCallback, useEffect, useMemo } from "react";
import { useColumn } from "@/hooks/use-column";
import ControlledTable from "@/components/controlled-table";
import { useTableServer } from "@/hooks/useTableServer";
import { getColumns } from "./columns";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import { useModal } from "@/app/shared/modal-views/use-modal";
import { Title } from "rizzui";
import ModalButton from "@/app/shared/modal-button";
import CreateEditUnitTransaction from "../create-edit/create-edit-amenity";
import ProjectRepository from "@/utilities/repositories/Project";
import { useParams } from "react-router-dom";
import { IAmenity } from "@/utilities/types/Amenity";
import AmenityRepository from "@/utilities/repositories/Amenity";
import CreateEditAmenity from "../create-edit/create-edit-amenity";
import { Roles } from "@/utilities/types/Users.ts";
import { Link } from "react-router-dom";
import { backPrev } from "@/utilities/functions";
import { routes } from "@/config/routes.ts";

export default function AmenityTable() {
  const { request: removeUnitType } = useApi<IResponseData<IAmenity>>({
    request: AmenityRepository.deleteAmenity,
    enableToast: true,
  });

  const {
    request: getDetailProjectById,
    response: projectDetailData,
    loading: getDetailProjectByIdLoading,
  } = useApi({
    request: ProjectRepository.getProjectById,
  });
  const { id } = useParams();
  const { profile } = useProfile();
  const {
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handlePaginate,
    pageSize,
    handleReset,
    handleChangePageSize,
    updateFilter,
  } = useTableServer<IAmenity>({
    server: {
      request: projectDetailData?.data?.id
        ? AmenityRepository.getAllAmenity
        : null,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
    initialFilterState: { projectId: projectDetailData?.data?.id || "" },
  });

  const onDeleteItem = useCallback(
    async (id: string) => {
      await removeUnitType(id);
      handlePaginate(currentPage); // Recall API
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlePaginate, currentPage]
  );
  const { openModal } = useModal();
  const onEditItem = (row: IAmenity) => {
    openModal({
      view: (
        <CreateEditAmenity
          refetchData={() => handlePaginate(currentPage)}
          projectId={projectDetailData?.data?.id || ""}
          data={row}
        />
      ),
      customSize: "500px",
    });
  };

  const columns = useMemo(
    () =>
      getColumns({
        data: tableData,
        onEditItem,
        onDeleteItem,
        role: profile?.role ?? "",
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onEditItem, onDeleteItem, JSON.stringify(tableData)]
  );

  useEffect(() => {
    if (projectDetailData?.data?.id) {
      updateFilter("projectId", projectDetailData?.data?.id || "");
    }
  }, [projectDetailData?.data?.id]);

  useEffect(() => {
    getDetailProjectById(id);
  }, []);

  const { visibleColumns } = useColumn(columns);
  //TODO: Uncomment filter and test when BE already filter
  return (
    <div className="mt-4">
      <div className="mb-4 w-full flex-col md:flex-row flex items-center gap-3 justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl mr-auto">
          {typeof projectDetailData?.data?.name === "object"
            ? projectDetailData?.data?.name?.en
            : projectDetailData?.data?.name}
          {projectDetailData?.data ? "'s" : ""} Amenities
        </Title>
        <div className="flex ml-auto justify-end gap-2">
          <Link
            to={backPrev(routes.project.listing)}
            className={"mr-2 ml-auto"}
          >
            <span
              className={
                "px-4 py-2 inline-block border border-solid border-black rounded-md"
              }
            >
              Back
            </span>
          </Link>
          {profile?.role !== Roles.AGENCY && (
            <ModalButton
              view={
                <CreateEditUnitTransaction
                  refetchData={handleReset}
                  projectId={projectDetailData?.data?.id || ""}
                />
              }
              label="Add Project Amenity"
              customSize="500px"
              className="mt-0 w-auto"
            />
          )}
        </div>
      </div>

      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading || getDetailProjectByIdLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={visibleColumns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
}
