import { SECTIONS } from "@/assets/images";

export enum ESectionLayout {
  SectionFeaturedLaunches = "SectionFeaturedLaunches",
  SectionProjectLeftWithGallery = "SectionProjectLeftWithGallery",
  SectionGalleryThree = "SectionGalleryThree",
  SectionProjectTopWithGallery = "SectionProjectTopWithGallery",
  SectionSwiftCards = "SectionSwiftCards",
  SectionProjectWithScrollGallery = "SectionProjectWithScrollGallery",
  SectionSlideCards = "SectionSlideCards",
  SectionGallerySix = "SectionGallerySix",
  SectionHTMLContent = "SectionHTMLContent",
  SectionPromotionBanner = "SectionPromotionBanner",
  SectionCTA = "SectionCTA",
}
export const SectionLayouts: Record<
  string,
  { name: string; preview: string; maxNumOfProject: number }
> = {
  // [ESectionLayout.SectionFeaturedLaunches]: {
  //   name: "SectionFeaturedLaunches",
  //   preview: SECTIONS.FEATURE_LAUNCHES,
  //   maxNumOfProject: 1,
  // },
  [ESectionLayout.SectionProjectLeftWithGallery]: {
    name: "SectionProjectLeftWithGallery",
    preview: SECTIONS.LEFT_WITH_GALLERY,
    maxNumOfProject: 5,
  },
  [ESectionLayout.SectionGalleryThree]: {
    name: "SectionGalleryThree",
    preview: SECTIONS.GALLERY_THREE,
    maxNumOfProject: 3,
  },
  [ESectionLayout.SectionProjectTopWithGallery]: {
    name: "SectionProjectTopWithGallery",
    preview: SECTIONS.TOP_WITH_GALLERY,
    maxNumOfProject: 4,
  },
  [ESectionLayout.SectionSwiftCards]: {
    name: "SectionSwiftCards",
    preview: SECTIONS.SWIFT_CARDS,
    maxNumOfProject: 1,
  },
  [ESectionLayout.SectionProjectWithScrollGallery]: {
    name: "SectionProjectWithScrollGallery",
    preview: SECTIONS.SCROLL_GALLERY,
    maxNumOfProject: 3,
  },
  [ESectionLayout.SectionSlideCards]: {
    name: "SectionSlideCards",
    preview: SECTIONS.SLIDE_CARDS,
    maxNumOfProject: 3,
  },
  [ESectionLayout.SectionGallerySix]: {
    name: "SectionGallerySix",
    preview: SECTIONS.GALLERY_SIX,
    maxNumOfProject: 6,
  },
  [ESectionLayout.SectionHTMLContent]: {
    name: "SectionHTMLContent",
    preview: "",
    maxNumOfProject: 0,
  },
  [ESectionLayout.SectionCTA]: {
    name: "SectionCTA",
    preview: SECTIONS.GALLERY_SIX,
    maxNumOfProject: 0,
  },
  // [ESectionLayout.SectionPromotionBanner]: {
  //   name: "SectionPromotionBanner",
  //   preview: "",
  //   maxNumOfProject: 0,
  // },
};

export const SECTION_PROJECT_ORDER_PREVIEW: Record<string, string> = {
  SectionFeaturedLaunches: SECTIONS.FEATURE_LAUNCHES_PREVIEW,
  SectionProjectLeftWithGallery: SECTIONS.LEFT_WITH_GALLERY_PREVIEW,
  SectionGalleryThree: SECTIONS.GALLERY_THREE_PREVIEW,
  SectionProjectTopWithGallery: SECTIONS.TOP_WITH_GALLERY_PREVIEW,
  SectionSwiftCards: SECTIONS.SWIFT_CARDS_PREVIEW,
  SectionProjectWithScrollGallery: SECTIONS.SCROLL_GALLERY_PREVIEW,
  SectionSlideCards: SECTIONS.SLIDE_CARDS_PREVIEW,
  SectionGallerySix: SECTIONS.GALLERY_SIX_PREVIEW,
  SectionHTMLContent: "",
  SectionCTA: "",
  SectionPromotionBanner: "",
};
