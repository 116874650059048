("use client");

import { useCallback, useMemo } from "react";
import { useColumn } from "@/hooks/use-column";
import ControlledTable from "@/components/controlled-table";
import { useTableServer } from "@/hooks/useTableServer";
import { getColumns } from "./columns";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import { useModal } from "@/app/shared/modal-views/use-modal";
import { Title } from "rizzui";
import ModalButton from "@/app/shared/modal-button";
import { IDeveloper } from "@/utilities/types/Developer";
import DeveloperRepository from "@/utilities/repositories/Developer";
import CreateEditDeveloper from "../create-edit/create-edit-developer";

export default function DeveloperTable() {
  const { request: removeDeveloper } = useApi<IResponseData<IDeveloper>>({
    request: DeveloperRepository.deleteDeveloper,
    enableToast: true,
  });

  const {
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handlePaginate,
    pageSize,
    handleReset,
    handleChangePageSize,
  } = useTableServer<IDeveloper>({
    server: {
      request: DeveloperRepository.getAllDeveloper,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
  });

  const onDeleteItem = useCallback(
    async (id: string) => {
      await removeDeveloper(id);
      handlePaginate(currentPage); // Recall API
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlePaginate, currentPage]
  );
  const { openModal } = useModal();
  const onEditItem = (row: IDeveloper) => {
    openModal({
      view: (
        <CreateEditDeveloper
          refetchData={() => handlePaginate(currentPage)}
          data={row}
        />
      ),
      customSize: "500px",
    });
  };

  const columns = useMemo(
    () =>
      getColumns({
        data: tableData,
        onEditItem,
        onDeleteItem,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onEditItem, onDeleteItem, JSON.stringify(tableData)]
  );

  const { visibleColumns } = useColumn(columns);
  //TODO: Uncomment filter and test when BE already filter
  return (
    <>
      <div className="mb-4 w-full flex items-center justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          Developer
        </Title>

        <ModalButton
          view={<CreateEditDeveloper refetchData={handleReset} />}
          label="Add Developer"
          customSize="500px"
          className="mt-0 w-auto"
        />
      </div>

      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={visibleColumns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </>
  );
}
