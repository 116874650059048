import { Enum } from "./Enum";

export const E_PROJECT_CATEGORY = Object.freeze({
  MAIN: "/category",
  LISTING: "/category/listing",
  PROJECT_CATEGORY_ID: (id: string = ":id") => `/category/${id}`,
});

export type E_PROJECT_CATEGORY = Enum<typeof E_PROJECT_CATEGORY>;

export enum EProjectCategoryTypes {
  RESIDENTIAL = "residential",
  COMMERCIAL = "commercial",
}
