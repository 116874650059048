import { z } from "zod";

// form zod validation schema
export const createAmenitySchema = z.object({
  name: z.string().min(1, "This field is required"),
  type: z.string().min(1, "This field is required"),
  projectId: z.string().min(1, "This field is required"),
  distance: z
    .number()
    .min(0, "This field is numberic")
    .or(
      z
        .string()
        .refine((value) => !isNaN(Number(value)), "This field is numberic")
    ),
  duration: z
    .number()
    .min(0, "This field is numberic")
    .or(
      z
        .string()
        .refine((value) => !isNaN(Number(value)), "This field is numberic")
    ),
});

// generate form types from zod validation schema
export type CreateAmenityInput = z.infer<typeof createAmenitySchema>;
