import { Button, ButtonProps } from "rizzui";
import cn from "@/utils/class-names";
import {siteConfig} from "@/config/site.config.tsx";
import {Link} from "react-router-dom";
import {Roles} from "@/utilities/types/Users.ts";

interface FormFooterProps {
  className?: string;
  altBtnText?: string;
  submitBtnText?: string;
  isLoading?: boolean;
  handleAltBtn?: () => void;
  submitButtonProps?: ButtonProps;
  canSubmit?: boolean;
  redirectPage?: string
}

export const negMargin = "-mx-4 md:-mx-5 lg:-mx-6 3xl:-mx-8 4xl:-mx-10";

export default function FormFooter({
  isLoading,
  altBtnText = "Save as Draft",
  submitBtnText = "Submit",
  className,
  handleAltBtn,
  submitButtonProps,
  canSubmit = true,
  redirectPage
}: FormFooterProps) {
  const search = window.location.search;
  const getParam = new URLSearchParams(search)
  return (
    <div
      className={cn(
        "sticky bottom-0 left-0 right-0 z-10 -mb-8 flex items-center justify-end gap-4 border-t bg-white px-4 py-4 md:px-5 lg:px-6 3xl:px-8 4xl:px-10 dark:bg-gray-50",
        className,
        negMargin
      )}
    >
      <Button
        variant="outline"
        className="w-full @xl:w-auto"
        onClick={handleAltBtn}
      >
        {altBtnText}
      </Button>
      {
        redirectPage && redirectPage !== '' && getParam.get('added-by') == Roles.AGENCY && !canSubmit && <Button
              className="w-full @xl:w-auto"
              onClick={()=>{
                window.open(redirectPage,'_blank')
              }}
          >
              View Page
          </Button>
      }
      {canSubmit && (
        <Button
          type="submit"
          isLoading={isLoading}
          className="w-full @xl:w-auto"
          {...submitButtonProps}
        >
          {submitBtnText}
        </Button>
      )}
    </div>
  );
}
