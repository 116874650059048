import { request } from "@/utilities/libs/request.ts";
import { IRequestArgs, ListResponseData } from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { ILoginActivity } from "../types/LoginActivity";
import dayjs from "dayjs";

function getAll({ queryParams }: IRequestArgs) {
  const filterQuery = Object.assign({}, ...(queryParams?.query?.filter ?? []));
  const endDate = filterQuery?.endDate
    ? dayjs(filterQuery?.endDate).endOf("day").toISOString()
    : undefined;
  const startDate = filterQuery?.startDate
    ? dayjs(filterQuery?.startDate).startOf("day").toISOString()
    : undefined;
  const query = {
    ...queryParams?.pagination,
    ...filterQuery,
    startDate,
    endDate,
  };
  return request<ListResponseData<ILoginActivity>>({
    method: ERequestMethods.GET,
    url: "/login-activity",
    params: query,
  });
}

const LoginActivityRepository = Object.freeze({
  getAll,
});

export default LoginActivityRepository;
