"use client";

import EyeIcon from "@/components/icons/eye";
import { HeaderCell } from "@/components/ui/table";
import {
  EImportTaskStatus,
  EImportType,
  IMPORT_TYPE_LABEL,
  StatusColors,
} from "@/utilities/enums/ImportData";
import { IImportDataTask } from "@/utilities/types/ImportData";
import cn from "@/utils/class-names";
import { formatDate } from "@/utils/format-date";
import { ColumnsType } from "rc-table";
import { ActionIcon, Text, Tooltip } from "rizzui";
import { SortConfig } from "@/hooks/useTableServer";

type ImportTaskSortKey = "name" | "createdAt" | "updatedAt" | "type" | "status";

export function getColumns({
  onDetailClick,
  onHeaderCellClick,
  sortConfig,
}: {
  onDetailClick?: (item: IImportDataTask) => void;
  sortConfig?: SortConfig<ImportTaskSortKey>;
  onHeaderCellClick: (value: string) => void;
}) {
  return [
    {
      title: <HeaderCell title="Filename" align="center" />,
      key: "filename",
      dataIndex: "filename",
      align: "left",
      // onHeaderCell:
    },

    {
      title: <HeaderCell title="Imported By" align="center" />,
      render: (value: IImportDataTask) => value.importedBy.email,
      key: "importedBy",
      align: "center",
    },

    {
      title: (
        <HeaderCell
          title="Date"
          sortable
          align="center"
          isSortedBy={sortConfig?.key === "createdAt"}
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "createdAt"
          }
        />
      ),
      render: (value: Date) => formatDate(value, "HH:mm DD/MM/YYYY"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      onHeaderCell: () => onHeaderCellClick?.("createdAt"),
    },

    {
      title: (
        <HeaderCell
          title="Type"
          align="center"
          sortable
          isSortedBy={sortConfig?.key === "type"}
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "type"
          }
        />
      ),
      key: "type",
      dataIndex: "type",
      align: "center",
      render: (value: EImportType) => IMPORT_TYPE_LABEL[value],
      onHeaderCell: () => onHeaderCellClick?.("type"),
    },
    {
      title: (
        <HeaderCell
          title="Status"
          align="center"
          sortable
          isSortedBy={sortConfig?.key === "status"}
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "status"
          }
        />
      ),
      key: "status",
      dataIndex: "status",
      align: "center",
      onHeaderCell: () => onHeaderCellClick?.("status"),
      render: (value: EImportTaskStatus) => (
        <Text
          className={cn(["font-semibold", "uppercase", StatusColors[value]])}
        >
          {value}
        </Text>
      ),
    },

    {
      title: (
        <HeaderCell
          className="ps-3"
          title={<span className="whitespace-nowrap">Actions</span>}
          align="center"
        />
      ),
      dataIndex: "action",
      key: "action",
      width: 120,
      render: (_: string, row: IImportDataTask) => (
        <div className="flex items-center justify-end gap-3 pe-4">
          <Tooltip size="sm" content={"Detail"} placement="top" color="invert">
            <ActionIcon
              size="sm"
              variant="outline"
              onClick={() => onDetailClick?.(row)}
            >
              <EyeIcon className="h-4 w-4" />
            </ActionIcon>
          </Tooltip>
        </div>
      ),
    },
  ];
}
