import ControlledTable from "@/components/controlled-table";
import { useTableServer } from "@/hooks/useTableServer";
import { useCallback, useMemo } from "react";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import FilterElement from "./filter-element";
import EnquiryEntriesRepository from "@/utilities/repositories/EnquiryEntries";
import { IEnquiryEntry } from "@/utilities/types/EnquiryEntry";
import useProfile from "@/hooks/use-profile";
import { Roles } from "@/utilities/types/Users";
import { useModal } from "@/app/shared/modal-views/use-modal";
import EnquiryEntryDetail from "../details/enquiry-entries-details";
import { Columns, getColumns } from "./columns";

export default function EnquiryEntriesTable() {
  const { request: onDelete } = useApi<IResponseData<IEnquiryEntry>>({
    request: EnquiryEntriesRepository.deleteItem,
    enableToast: true,
  });

  const onHeaderCellClick = (value: string) => ({
    onClick: () => {
      handleSort(value);
    },
  });

  const { profile } = useProfile();

  const {
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handlePaginate,
    pageSize,
    handleChangePageSize,
    searchTerm,
    handleSearch,
    sortConfig,
    handleSort,
    handleReset,
    isFiltered,
    filters,
    updateFilter,
    selectedRowKeys,
    handleRowSelect,
    handleSelectAll,
  } = useTableServer({
    server: {
      request:
        profile?.role === Roles.AGENCY
          ? EnquiryEntriesRepository.getListByAgency
          : EnquiryEntriesRepository.getList,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
  });

  const onDeleteItem = useCallback(
    async (id: string) => {
      await onDelete(id);
      handleReset();

      // Recall API
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [handleReset]
  );

  const columns = useMemo(
    () =>
      getColumns({
        data: tableData,
        sortConfig,
        onHeaderCellClick,
        onDeleteItem,
        isAdmin: profile?.role === Roles.ADMIN,
        checkedItems: selectedRowKeys,
        onChecked: handleRowSelect,
        handleSelectAll,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tableData,
      sortConfig,
      onHeaderCellClick,
      onDeleteItem,
      profile?.role,
      selectedRowKeys,
      handleRowSelect,
      handleSelectAll,
    ]
  );

  const { openModal } = useModal();

  const selectedData = tableData.filter((item) =>
    selectedRowKeys.includes(item.id)
  );
  function handleSendMessage() {
    console.log(selectedData);
  }

  return (
    <div className="mt-4">
      <FilterElement
        isFiltered={isFiltered}
        filters={filters}
        updateFilter={updateFilter}
        handleReset={handleReset}
        onSearch={handleSearch}
        handleSort={handleSort}
        searchTerm={searchTerm}
        onSendMessage={handleSendMessage}
      />
      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        onRow={(record) => ({
          onClick: (e) => {
            openModal({
              view: (
                <EnquiryEntryDetail
                  data={record as IEnquiryEntry}
                  isModalView
                />
              ),
              customSize: "1000px",
            });
          },
        })}
        // @ts-ignore
        columns={columns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        rowClassName="cursor-pointer"
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
}
