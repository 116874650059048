import { request } from "@/utilities/libs/request.ts";
import { IResponseData } from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { IEmailTemplate, IEmailTemplateDTO } from "../types/EmailTemplate";
import { E_EMAIL_TEMPLATE, EEmailTemplateKeys } from "../enums/EmailTemplate";

function getEmailTemplate(params?: { name: E_EMAIL_TEMPLATE }) {
  return request<IResponseData<IEmailTemplate[]>>({
    method: ERequestMethods.GET,
    url: `${E_EMAIL_TEMPLATE.MAIN}`,
    params,
  });
}

function updateEmailTemplate(data: Partial<IEmailTemplateDTO>, id: string) {
  return request<IResponseData<IEmailTemplate>>({
    method: ERequestMethods.PATCH,
    url: E_EMAIL_TEMPLATE.MAIN + `/${id}`,
    data,
  });
}

function createEmailTemplate(data: IEmailTemplateDTO) {
  return request<IResponseData<IEmailTemplate>>({
    method: ERequestMethods.POST,
    url: E_EMAIL_TEMPLATE.MAIN,
    data,
  });
}

const EmailTemplateRepository = Object.freeze({
  getEmailTemplate,
  updateEmailTemplate,
  createEmailTemplate,
});

export default EmailTemplateRepository;
