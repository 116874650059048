export const isGoogleMapsIframe = (str: string) => {
  const iframeRegex =
    /<iframe\s+src="https:\/\/www\.google\.com\/maps\/embed\?pb=[^"]*"(?:\s+[^>]*)?><\/iframe>/;
  return iframeRegex.test(str);
};

export const parseSrcFromIframe = (str: string) => {
  const srcRegex = /<iframe[^>]+src="([^"]+)"[^>]*><\/iframe>/;
  const match = str.match(srcRegex);
  return match ? match[1] : null;
};

export const createWhatsappMessage = (
  template: string,
  variables: { [key: string]: string | number | null | undefined }
): string => {
  return template.replace(/\[([^\]]+)\]/g, (match, p1) => {
    const replacement = variables?.[p1];
    return replacement !== undefined && replacement !== null
      ? replacement?.toString()
      : match;
  });
};
