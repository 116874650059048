import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

interface UseScrollToRowProps {
  rowRefs: { [key: string]: React.RefObject<HTMLElement> };
}

const useScrollToRow = ({ rowRefs }: UseScrollToRowProps) => {
  const [searchParams] = useSearchParams();
  const rowKey = searchParams.get("rowKey");

  useEffect(() => {
    if (!(rowKey && typeof document !== "undefined")) return;
    const elementRef = rowRefs[rowKey]?.current;
    // Scroll to the element when the component mounts and rowKey is available
    if (elementRef) {
      elementRef.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [rowKey, rowRefs]);
};

export default useScrollToRow;
