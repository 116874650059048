import { useModal } from "@/app/shared/modal-views/use-modal";
import { PiXBold } from "react-icons/pi";
import { ActionIcon, Title } from "rizzui";
import { useMemo } from "react";
import {
  IUserPreference,
  IUserPreferenceOption,
} from "@/utilities/types/UserPreference";
import { USER_PREFERENCE_TYPE } from "@/config/constants";
import { EUserReferenceType } from "@/utilities/enums/UserPreference";

export default function UserPreferenceDetail({
  data,
  isModalView,
}: {
  data?: IUserPreference;
  isModalView?: boolean;
}) {
  const { closeModal } = useModal();

  const { options } = useMemo(() => {
    try {
      const options: IUserPreferenceOption[] = JSON.parse(data?.options || "");
      return { options };
    } catch (e) {
      return { options: [] };
    }
  }, [data?.options]);
  return (
    <div className="block">
      <div className="flex items-center justify-between border-b border-gray-200 p-5 md:p-7">
        <Title
          as="h3"
          className="font-lexend text-xl font-semibold md:text-2xl"
        >
          User Preference Detail
        </Title>
        {isModalView && (
          <ActionIcon
            size="sm"
            variant="text"
            onClick={() => closeModal()}
            className="p-0 text-gray-500 hover:!text-gray-900"
          >
            <PiXBold className="h-5 w-5" />
          </ActionIcon>
        )}
      </div>
      {options?.map((option: IUserPreferenceOption, index: number) => (
        <div
          key={`user-preference-option-${index}`}
          className="p-5 md:px-7 md:pb-7 md:pt-6 grid grid-cols-2 gap-y-4 gap-x-6"
        >
          <div className="border border-gray-200 rounded-lg p-4 col-span-full">
            <Title
              as="h3"
              className="mb-3 font-lexend  font-medium text-lg"
            >
              {USER_PREFERENCE_TYPE?.[
                option?.preferenceType as EUserReferenceType
              ]?.label || ""}
            </Title>

            <ul className="space-y-2 text-xs sm:text-sm">
              {Array.isArray(option?.data) ? (
                option?.data?.map((dataItem, dataItemIndex) => (
                  <li
                    key={dataItemIndex}
                    className="flex items-center"
                  >
                    {typeof dataItem?.name === "object"
                      ? dataItem?.name?.en
                      : dataItem?.name}
                  </li>
                ))
              ) : (
                <li className="flex items-center">
                  from: {option?.data?.from} - to: {option?.data?.to}
                </li>
              )}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
}
