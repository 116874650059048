"use client";

import { PiPlusBold } from "react-icons/pi";
import { useModal } from "@/app/shared/modal-views/use-modal";
import { Button, type ButtonProps } from "rizzui";
import cn from "@/utils/class-names";
import { redirect, useNavigate } from "react-router-dom";

interface ModalButtonProps extends ButtonProps {
  label?: string;
  className?: string;
  customSize?: string;
  icon?: React.ReactNode;
  navigateTo: string;
}

export default function AddNewButton({
  label = "Add New",
  className,
  customSize = "500px",
  icon = <PiPlusBold className="me-1.5 h-[17px] w-[17px]" />,
  navigateTo,
  ...rest
}: ModalButtonProps) {
  const navigate = useNavigate();
  return (
    <Button
      className={cn(
        "w-full text-xs capitalize @lg:w-auto sm:text-sm",
        className
      )}
      onClick={() => navigate(navigateTo, { replace: true })}
      {...rest}
    >
      {icon}
      {label}
    </Button>
  );
}
