"use client";

import DeletePopover from "@/app/shared/delete-popover";
import PencilIcon from "@/components/icons/pencil";
import { HeaderCell } from "@/components/ui/table";
import { IAmenity } from "@/utilities/types/Amenity";
import { ActionIcon, Tooltip } from "rizzui";
import {Roles} from "@/utilities/types/Users.ts";

type Columns = {
  data: IAmenity[];
  onDeleteItem: (id: string) => Promise<void>;
  onEditItem: (row: IAmenity) => void;
  role:string
};

export const getColumns = ({ onDeleteItem, onEditItem,role }: Columns) => [
  {
    title: (
      <HeaderCell
        title="Name"
        align="center"
      />
    ),
    key: "name",
    dataIndex: "name",
    align: "center",
    render: (value: string | Record<string, string>) => {
      return typeof value === "string" ? value : value?.en;
    },
  },
  {
    title: (
      <HeaderCell
        title="Type"
        align="center"
      />
    ),
    key: "type",
    dataIndex: "type",
    align: "center",
    render: (value: string | Record<string, string>) => {
      return typeof value === "object" ? value?.en : value;
    },
  },
  {
    title: (
      <HeaderCell
        title="Distance"
        align="center"
      />
    ),
    dataIndex: "distance",
    key: "distance",
    align: "center",
  },
  {
    title: (
      <HeaderCell
        title="Duration"
        align="center"
      />
    ),
    dataIndex: "duration",
    key: "duration",
    align: "center",
  },
  {
    title: (
      <HeaderCell
        className="ps-3"
        title={<span className="whitespace-nowrap">{role !== "" && role !== Roles.AGENCY && ('Actions')}</span>}
        align="center"
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: IAmenity) => (
      <div className="flex items-center justify-end gap-3 pe-4">
        {
            role !== "" && role !== Roles.AGENCY && (<>
              <Tooltip
                  size="sm"
                  content={"Edit Unit Type"}
                  placement="top"
                  color="invert"
              >
                <ActionIcon
                    size="sm"
                    variant="outline"
                    onClick={() => onEditItem(row)}
                >
                  <PencilIcon className="h-4 w-4" />
                </ActionIcon>
              </Tooltip>
              <DeletePopover
                  title={`Delete amenity`}
                  description={`Are you sure you want to delete this amenity?`}
                  onDelete={async () => await onDeleteItem(row.id)}
              />
            </>)
        }
      </div>
    ),
  },
];
