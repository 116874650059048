import { Controller, useFormContext } from "react-hook-form";
import { Input, Switch } from "rizzui";

export interface TelegramConfigurationFormProps {
  enabled?: boolean;
  isSuperAdmin?: boolean;
  name: string;
}

// !IMPORTANT: Please ensure component imported inside FormProvider (react-hook-form)

export default function TelegramConfigurationForm({
  enabled,
  isSuperAdmin,
  name,
}: TelegramConfigurationFormProps) {
  const { control } = useFormContext();

  const botNames = (
    import.meta.env.VITE_BASE_TELEGRAM_BOT_NAME_PROD as string
  )?.split(",");

  return (
    <div className="grid grid-cols-1 gap-2 w-full">
      <div className="grid w-full items-start gap-32grid-cols-1">
        <h6 className="text-foreground md:text-base">Telegram Configuration</h6>
        <p className="my-2">
          <br />
          Step 1: Add the usernames
          {botNames?.map((bot: string, idx) => (
            <span key={bot + idx} className="italic font-bold">
              {" "}
              @{bot}{" "}
              {idx !== botNames.length - 1 && (
                <span className="font-normal"> and </span>
              )}{" "}
            </span>
          ))}
          to your Telegram group. A chatbot ID will be generated for that
          group—copy it.
          <br />
          <br />
          Step 2: Paste that chatbot ID here (including the “-” symbol) and
          click update.
          <br />
          <br />
          Afterward, whenever a lead accesses projects on your website, a
          message will be sent to that group with the following content:
          <br />
          <b>
            “IPXXX is accessing the project [project’s name with URL] at
            [datetime], from [region], via [browser].”
          </b>
        </p>
      </div>
      <div className="grid w-full items-start gap-3 grid-cols-1">
        <Controller
          control={control}
          name={`${name}.chatId`}
          render={({ field, fieldState, formState: { error } }) => (
            <>
              <Input
                label="Chatbot ID"
                size="lg"
                placeholder="-21xxxxx38"
                className="col-span-full md:w-1/2 w-full"
                {...field}
                value={field.value || ""}
                disabled={enabled}
                error={fieldState.error?.message}
                helperText="Please enter chatbot Id provided by the chatbots"
              />
              {error && <span className="text-red-500">{error}</span>}
            </>
          )}
        />

        {isSuperAdmin && (
          <Controller
            control={control}
            name={`${name}.enable`}
            render={({ field, fieldState }) => (
              <Switch
                label="Enable"
                size="lg"
                className=" w-auto"
                {...field}
                checked={field.value || ""}
                error={fieldState.error?.message}
              />
            )}
          />
        )}
      </div>
    </div>
  );
}
