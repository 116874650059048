import LocationRepository from "@/utilities/repositories/Location";
import { atom } from "jotai";
import { MultiSelectOption } from "rizzui";

export const atomLocation = atom<MultiSelectOption[]>([]);
export const atomLocationSelected = atom<Record<string, string[]>>({});

export const atomFetchLocation = atom(
  async (get) => get(atomLocation),
  async (get, set) => {
    try {
      const locations = await LocationRepository.getAllLocation();

      const optionsData = locations?.data?.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      return set(atomLocation, optionsData || []);
    } catch (err) {
      console.log(err);
    }
  }
);

export const atomHandleSelectLocation = atom(
  (get) => get(atomLocationSelected),
  (get, set, payload: Record<string, string[]>) => {
    // SectionId : locaionIds
    const current = get(atomLocationSelected);
    return set(atomLocationSelected, {
      ...current,
      ...payload,
    });
  }
);

export const atomHanleLocationOption = atom(
  (get) => get(atomLocation),
  (get, set, payload: MultiSelectOption[]) => {
    // SectionId : locaionIds
    return set(atomLocation, payload);
  }
);
