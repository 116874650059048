"use client";

import { createRef, useCallback, useEffect, useMemo } from "react";
import dynamic from "next/dynamic";
import { useColumn } from "@/hooks/use-column";
import ControlledTable from "@/components/controlled-table";
import { useTableServer } from "@/hooks/useTableServer";
import { getColumns } from "./columns";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import { IProject } from "@/utilities/types/Project";
import ProjectRepository from "@/utilities/repositories/Project";
import { Roles } from "@/utilities/types/Users";
import toast from "react-hot-toast";
import { useModal } from "@/app/shared/modal-views/use-modal";
import DisclaimerNotification from "../../disclaimer/disclaimer-notifcation";
import useProfile from "@/hooks/use-profile";
import { AGENCY_PROJECT_MODAL_SHOWN_KEY } from "@/config/constants";
import { useSearchParams } from "react-router-dom";
import { useAdvanceFeatures } from "@/hooks/use-advance-features";
const FilterElement = dynamic(
  () => import("@/app/app-shared/project/project-table/filter-element"),
  { ssr: false }
);

export default function AgentProjectsTable() {
  const onHeaderCellClick = (value: string) => ({
    onClick: () => {
      handleSort(value);
    },
  });
  const { landingPageFeature } = useAdvanceFeatures();
  const { profile } = useProfile();
  const [searchParams] = useSearchParams();
  const rowKey = searchParams?.get("rowKey");

  const { request: removeUserProject } = useApi<IResponseData<IProject>>({
    request: ProjectRepository.removeUserProject,
    enableToast: true,
  });
  const { request: updateUpcomgingProject } = useApi<IResponseData<IProject>>({
    request: ProjectRepository.updateUserProject,
    enableToast: true,
  });
  const {
    isLoading,
    isFiltered,
    tableData,
    currentPage,
    totalItems,
    handlePaginate,
    pageSize,
    handleChangePageSize,
    filters,
    updateFilter,
    searchTerm,
    handleSearch,
    sortConfig,
    handleSort,
    handleReset,
    refresh,
  } = useTableServer({
    server: {
      request: ProjectRepository.getAgentProject,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
  });

  const onRemoveProject = useCallback(
    async (id: string) => {
      await removeUserProject(id);
      handleReset(); // Recall API
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleReset]
  );
  const onUpdateUserProjectAgent = useCallback(
    async (
      id: string | undefined,
      data: {
        upcomingLaunch?: boolean;
        promotion?: boolean;
        featured?: boolean;
      },
      onSuccess: () => void
    ) => {
      await updateUpcomgingProject(id, data)
        .then(() => {
          // handleReset(); // Recall API
          refresh();
          onSuccess();
        })
        .catch((err: any) => {
          toast.error(err?.message || "Something went wrong");
        });
    },
    [refresh]
  );
  const columns = useMemo(
    () =>
      getColumns({
        data: tableData,
        sortConfig,
        onHeaderCellClick,
        onRemoveProject,
        onUpdateUserProjectAgent,
        role: Roles.AGENCY,
        landingPageFeature,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onHeaderCellClick,
      sortConfig.key,
      sortConfig.direction,
      onRemoveProject,
      tableData,
    ]
  );

  const { visibleColumns } = useColumn(columns);
  //TODO: Uncomment filter and test when BE already filter

  const { openModal } = useModal();

  const rowRefs: { [key: string]: React.RefObject<HTMLDivElement> } =
    tableData?.reduce(
      (acc, item) => {
        acc[item.id] = createRef<HTMLDivElement>();
        return acc;
      },
      {} as { [key: string]: React.RefObject<HTMLDivElement> }
    );

  useEffect(() => {
    if (!(rowKey && typeof document !== "undefined")) return;
    const elementRef = rowRefs?.[rowKey]?.current;
    // Scroll to the element when the component mounts and rowKey is available
    if (rowKey && elementRef) {
      elementRef.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [rowKey, rowRefs]);

  useEffect(() => {
    const modalShown = Boolean(
      sessionStorage.getItem(AGENCY_PROJECT_MODAL_SHOWN_KEY)
    );
    if (profile?.role === Roles.AGENCY && !modalShown) {
      openModal({
        view: <DisclaimerNotification />,
        customSize: "500px",
      });
      sessionStorage.setItem(AGENCY_PROJECT_MODAL_SHOWN_KEY, "true");
    }
  }, [profile?.role]);

  return (
    <div className="mt-4">
      <FilterElement
        isFiltered={isFiltered}
        filters={filters}
        updateFilter={updateFilter}
        handleReset={handleReset}
        onSearch={handleSearch}
        searchTerm={searchTerm}
        title="My Website’s Projects"
      />
      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={visibleColumns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
}
