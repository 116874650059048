import { z } from "zod";

// form zod validation schema
export const createSiteContentSchema = z.object({
  // contentType: z.string().min(1, { message: "This field is required" }),
  content: z.string().min(1, { message: "This field is required" }),
  title: z.string().min(1, { message: "This field is required" }),
});

// generate form types from zod validation schema
export type CreateSiteContentInput = z.infer<typeof createSiteContentSchema>;
