import { IEnquirySubmissionLog } from "@/utilities/types/EnquiryEntry";
import { IListResponse } from "@/utilities/types/requests";

export const SkeletonSubmissionLogs: IListResponse<IEnquirySubmissionLog> = {
  score: 0,
  submissionLogs: [
    {
      id: "c45039a1-423f-4d5a-bdd2-3d01883bb8ac",
      type: "appointmentDate",
      point: 0,
      reason: "Submit date: N/A - Appointment date: N/A",
    },
    {
      id: "8721e697-ba92-478e-8e3e-296c2f833fc0",
      type: "projectViewCount",
      point: 0,
      reason: "Total count of viewed projects: 0",
    },
    {
      id: "5f90b68c-e442-4964-8c79-b117398db000",
      type: "interested",
      point: 0,
      reason: "Checked interests: N/A",
    },
    {
      id: "ee383b6c-dc77-4541-a2cd-39c7ce450173",
      type: "unitType",
      point: 0,
      reason: "No unit types selected",
    },
    {
      id: "ca54bf0a-1297-4187-af2f-a5accba0137d",
      type: "projectViewDuration",
      point: 0,
      reason: "View duration: 0 minute",
    },
  ],
  total: 0,
  totalPage: 0,
  currentPage: 0,
  limit: 0,
};
