import PromotionBannerTable from "@/app/app-shared/promotion-banner/table";
import { Controller, SubmitHandler } from "react-hook-form";
import { Button } from "rizzui";
import ColorPickerPopover from "@/app/shared/colorpicker-popover.tsx";
import { hexToRgba } from "@/utilities/functions";
import {
  editAgencyPromotion,
  EditAgencyPromotionSchema,
} from "@/utils/validators/edit-agency-setting.schema.ts";
import {
  TAgencySetting,
  TAgencySettingUpdate,
} from "@/utilities/types/Setting.ts";
import SettingsRepository from "@/utilities/repositories/Settings.ts";
import toast from "react-hot-toast";
import { Form } from "@/components/ui/form";
import CreateAgencySetting from "@/page/settings/CreateAgencySetting.tsx";
import { useModal } from "@/app/shared/modal-views/use-modal.ts";
import useProfile from "@/hooks/use-profile.ts";
import { useEffect, useState } from "react";
import useApi from "@/hooks/useApi.tsx";
import { IResponseData } from "@/utilities/types/requests.ts";
import QuillEditor from "@/components/ui/quille-editor/quill-editor.tsx";

export default function PromotionBannerListingPage() {
  const { openModal } = useModal();
  const { profile } = useProfile();
  const DEFAULT_VALUES = {
    id: "",
    promotionHeading: "",
    promotionBgColor: "",
  };
  const [reset, setReset] = useState<EditAgencyPromotionSchema>(DEFAULT_VALUES);
  const { request: getAgencySetting } = useApi<IResponseData<TAgencySetting>>({
    request: SettingsRepository.getAgencySetting,
  });

  const onFetchAgencySetting = () =>
    getAgencySetting()
      .then((res) => {
        if (!res?.data) return;
        const { id, promotionHeading, promotionBgColor } = res.data;

        setReset({
          id,
          promotionHeading: promotionHeading || "",
          promotionBgColor: promotionBgColor || "",
        });
      })
      .catch((e) => onOpenCreateAgencySettingModal());

  const onOpenCreateAgencySettingModal = () =>
    openModal({
      view: (
        <CreateAgencySetting
          isModalView
          userId={profile?.id || ""}
          onRefetch={onFetchAgencySetting}
        />
      ),
      customSize: "900px",
    });
  const onSubmit: SubmitHandler<EditAgencyPromotionSchema> = async (
    formData
  ) => {
    if (!formData?.id) {
      onOpenCreateAgencySettingModal();
      return;
    }
    const dataToUpdate: TAgencySettingUpdate = {
      promotionHeading: formData?.promotionHeading || "",
      promotionBgColor: formData?.promotionBgColor || "",
    };
    try {
      await SettingsRepository.updateAgencySetting(
        formData?.id || "",
        dataToUpdate
      );
      toast.success(`Updated Promotion successfully`);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    onFetchAgencySetting();
  }, []);
  return (
    <div className="mt-4">
      <h3 className={"text-2xl text-black font-semibold mb-3"}>
        Settings Promotion Banner
      </h3>
      <Form<EditAgencyPromotionSchema>
        onSubmit={onSubmit}
        resetValues={reset}
        validationSchema={editAgencyPromotion}
        className="grid gap-6 mb-3 pb-3 grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900 px-0 border-b border-solid border-gray-300"
      >
        {({ control }) => {
          return (
            <>
              <Controller
                control={control}
                name="promotionHeading"
                render={({ field: { onChange, value }, formState }) => (
                  <QuillEditor
                    value={value}
                    onChange={onChange}
                    label="Heading"
                    className="col-span-full [&_.ql-editor]:min-h-[100px]"
                    labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
                    toolbar={[
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ["bold", "italic", "underline", "strike"], // toggled buttons
                      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                      [{ align: [] }],
                    ]}
                    {...formState}
                  />
                )}
              />
              <Controller
                control={control}
                name="promotionBgColor"
                render={({ field }) => (
                  <div className={""}>
                    <p
                      className={
                        "block text-sm mb-1.5 font-medium rizzui-input-labelw"
                      }
                    >
                      Promotion background color
                    </p>
                    <ColorPickerPopover
                      value={field.value || ""}
                      onChange={(value: string) => {
                        field.onChange(hexToRgba(value));
                      }}
                      className="w-[64px] h-[32px] rounded-[6px] border border-solid border-black overflow-hidden"
                    />
                  </div>
                )}
              />
              <div className={"col-span-full"}>
                <Button
                  type="submit"
                  className="w-full @xl:w-auto max-w-[120px] ml-auto mr-0 block"
                >
                  Update
                </Button>
              </div>
            </>
          );
        }}
      </Form>
      <PromotionBannerTable sectionId="" />
    </div>
  );
}
