export default function transformColorVariable(
  variable: string,
  excludedText: string,
): string {
  // Step 1: Remove the `--color-` prefix
  const withoutPrefix = variable.replace("--color-", "");

  // Step 2: Replace `-` with spaces
  const withSpaces = withoutPrefix.replace(/-/g, " ");

  // Step 3: Convert to title case
  const titleCasedWithSpaces = withSpaces.replace(/\b\w/g, (char) =>
    char.toUpperCase(),
  );

  // Step 4: Remove excluded text
  const result = titleCasedWithSpaces
    .replace(new RegExp(`\\b${excludedText}\\b`, "gi"), "")
    .trim();

  return result.charAt(0).toUpperCase() + result.slice(1);
}
