import { z } from "zod";
import { messages } from "@/config/messages"; // form zod validation schema
import { SUFFIX_DOMAIN } from "@/config/constants";

// form zod validation schema
export const editAgencySettingSchema = z.object({
  id: z.string().optional(),
  siteTitle: z.string().optional(),
  siteDescription: z.string().optional(),
  phoneNumber: z.string().optional(),
  whatsapp: z.string().optional(),
  topMessageBackground: z.string().optional(),
  topMessageText: z.string().optional(),
  agencyEmail: z.string().optional(),
  agencyName: z.string().optional(),
  siteLogo: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),
  headerLogo: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),
  footerLogo: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),
  coverImage: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),
  agencyPhoto: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),
  colorScheme: z.record(z.record(z.string())),
  socialLinks: z.array(
    z.object({
      url: z
        .string()
        .url({ message: messages.socialUrlInvalid })
        .min(1, { message: messages.socialUrlReqired }),
      type: z.string().min(1, { message: messages.socialTypeReqired }),
      label: z.string().min(1, { message: messages.socialLabelReqired }),
    })
  ),
  promotionHeading: z.string().optional(),
  promotionBgColor: z.string().optional(),
  promotionSeoImage: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),
  promotionSeoDescription: z.string().optional(),
  promotionSeoTitle: z.string().optional(),
  upcomingSeoImage: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),
  upcomingSeoDescription: z.string().optional(),
  upcomingSeoTitle: z.string().optional(),
  topSeoImage: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),
  topSeoDescription: z.string().optional(),
  topSeoTitle: z.string().optional(),
  seoImage: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),
  seoDescription: z.string().optional(),
  twitterSite: z.string().optional(),
  twitterCreator: z.string().optional(),
  mobileMastheadImage: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),
  floatingButtonEnabled: z.boolean().optional(),
  salesTeamInfo: z.object({
    name: z.string().optional(),
    registrationNumber: z.string().optional(),
    currentEa: z.string().optional(),
    licenseNumber: z.string().optional(),
  }),

  additionalEmail: z.array(
    z.object({
      email: z
        .string()
        .email({ message: messages.invalidEmail })
        .min(1, { message: messages.emailIsRequired }),
    })
  ),
  measuringId: z.string().nullable().optional(),
  chatId: z.string().nullable().optional(),
  domain: z
    .string()
    .refine(
      (value) =>
        /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}?$/.test(`${value}`),
      "This is domain field"
    )
    .optional(),
});
export const editAgencyPromotion = z.object({
  id: z.string().optional(),
  promotionHeading: z.string().optional(),
  promotionBgColor: z.string().optional(),
});
// generate form types from zod validation schema
export type EditAgencySettingSchema = z.infer<typeof editAgencySettingSchema>;
export type EditAgencyPromotionSchema = z.infer<typeof editAgencyPromotion>;
