import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { E_PROMOTION_BANNER } from "../enums/PromotionBanner";
import { IPromotionBaner, IPromotionBannerDTO } from "../types/PromotionBanner";

function getAllPromotionBanner({ queryParams }: IRequestArgs) {
  const query = {
    sortBy: Object.keys(queryParams?.query?.sort || {})?.[0] || "createdAt",
    sort: Object.values(queryParams?.query?.sort || {})?.[0] || "DESC",
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<IPromotionBaner>>({
    method: ERequestMethods.GET,
    url: E_PROMOTION_BANNER.MAIN(
      queryParams?.query?.filter?.[0]?.sectionId || ""
    ),
    params: query,
  });
}

function deletePromotionBanner(sectionId: string, id: string) {
  return request<IResponseData<IPromotionBaner>>({
    method: ERequestMethods.DELETE,
    url: E_PROMOTION_BANNER.PROMOTION_BANNER_ID(sectionId, id),
  });
}

function createPromotionBanner(sectionId: string, data: IPromotionBannerDTO) {
  return request<IResponseData<IPromotionBaner>>({
    method: ERequestMethods.POST,
    url: E_PROMOTION_BANNER.MAIN(sectionId),
    data,
  });
}

function updatePromotionBanner(
  sectionId: string,
  id: string,
  data: Partial<IPromotionBannerDTO>
) {
  return request<IResponseData<IPromotionBaner>>({
    method: ERequestMethods.PATCH,
    url: E_PROMOTION_BANNER.PROMOTION_BANNER_ID(sectionId, id),
    data,
  });
}

const PromotionBannerRepository = Object.freeze({
  getAllPromotionBanner,
  deletePromotionBanner,
  createPromotionBanner,
  updatePromotionBanner,
});

export default PromotionBannerRepository;
