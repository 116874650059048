import { Enum } from "./Enum";

export const E_DEVELOPER = Object.freeze({
  MAIN: "/developer",
  LISTING: "/developer/listing",
  DEVELOPER_ID: (id: string = ":id") => `/developer/${id}`,
});

export type E_DEVELOPER = Enum<typeof E_DEVELOPER>;

export enum EProjectCategoryTypes {
  RESIDENTIAL = "residential",
  COMMERCIAL = "commercial",
}
