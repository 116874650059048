import { z } from "zod";

export const unitSchema = z.object({
  name: z.string().min(1, { message: "This field is required" }),

  price: z
    .string({
      required_error: "This field is required",
    })
    .min(0, { message: "This field is required" }),
  status: z
    .string({
      required_error: "This field is required",
    })
    .min(1, { message: "This field is required" }),
});

export type UnitSchemaInput = z.infer<typeof unitSchema>;
