import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import {
  IDeveloper,
  IDeveloperDTO,
  IDeveloperFromList,
} from "../types/Developer";
import { E_DEVELOPER } from "../enums/Developer";

function getAllDeveloper({ queryParams }: IRequestArgs) {
  const query = {
    filter: queryParams?.query?.filter,
    sortBy: "name",
    sort: "ASC",
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<IDeveloper>>({
    method: ERequestMethods.GET,
    url: E_DEVELOPER.LISTING,
    params: query,
  });
}

function getAllDevelopers() {
  return request<IResponseData<IDeveloperFromList[]>>({
    method: ERequestMethods.GET,
    url: E_DEVELOPER.MAIN,
  });
}

function deleteDeveloper(id: string) {
  return request<IResponseData<IDeveloper>>({
    method: ERequestMethods.DELETE,
    url: E_DEVELOPER.DEVELOPER_ID(id),
  });
}

function createDeveloper(data: IDeveloperDTO) {
  return request<IResponseData<IDeveloper>>({
    method: ERequestMethods.POST,
    url: E_DEVELOPER.MAIN,
    data,
  });
}

function updateDeveloper(id: string, data: Partial<IDeveloperDTO>) {
  return request<IResponseData<IDeveloper>>({
    method: ERequestMethods.PATCH,
    url: E_DEVELOPER.DEVELOPER_ID(id),
    data,
  });
}

const DeveloperRepository = Object.freeze({
  getAllDeveloper,
  deleteDeveloper,
  createDeveloper,
  updateDeveloper,
  getAllDevelopers,
});

export default DeveloperRepository;
