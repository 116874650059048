const E_AUTH_PATHS = {
  SIGN_IN: "/auth/sign-in",
  FORGOT_PASSWORD: "users/forgot-password",
  VERIFICATION_TOKEN: "/users/forgot-password/verify",
  RESET_PASSWORD: "/users/new-password",
  VERIFY_OTP: "/auth/verify-otp",
  ADMIN_SIGNIN_AGENT: "/auth/sign-in-agent/by-admin",
  SIGN_OUT: "/auth/sign-out",
};

export default E_AUTH_PATHS;
