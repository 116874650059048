import { Enum } from "./Enum";

export const E_ENQUIRY_LOG_TYPE = Object.freeze({
  APPOINTMENT_DATE: "appointmentDate",
  PROJECT_VIEW_COUNT: "projectViewCount",
  INTERESTED: "interested",
  UNIT_TYPE: "unitType",
  PROJECT_VIEW_DURATION: "projectViewDuration",
  RED_FLAG: "redFlag",
});

export type E_ENQUIRY_LOG_TYPE = Enum<typeof E_ENQUIRY_LOG_TYPE>;
