import { Enum } from "../enums/Enum";

export enum EUploadType {
  IMAGE = "image",
  VIDEO = "video",
}

export type UploadType = Enum<typeof EUploadType>;

export interface IUrls {
  url: string;
  width?: number;
}

export interface IUploadResponse {
  type: UploadType;
  relation: null;
  relationId: null;
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  urls: IUrls[];
}
