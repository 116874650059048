import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig } from "axios";
import { RequestError } from "../types/requests";
import Cookies from "js-cookie";
import qs from "qs";
import { AUTH_KIT } from "@/config/auth";
import { forEach } from "lodash";
import { routes } from "@/config/routes";
import toast from "react-hot-toast";
import { EStatusCode } from "../enums/StatusCodes";
import i18n from "@/locales/i18n";
import { LANGUAGE_KEY } from "@/config/constants";
import { EErrorId } from "../enums/ECustomizeCodes";
import { EErrorCode } from "../enums/ErrorCodes";

const baseURL = import.meta.env.VITE_BASE_API_URL as string;

const whiteListErrorAuth = [
  EErrorId.EXPIRED_OTP,
  EErrorId.INVALID_OTP,
  EErrorId.INVALID_USER,
];

const blackBlistErrorAuth = [EErrorId.EXPIRED_TOKEN];

export function request<T>(config: AxiosRequestConfig) {
  const cookies = Cookies.get();
  const headers = new AxiosHeaders();
  const defaultConfig: AxiosRequestConfig = {
    baseURL: baseURL,
    withCredentials: true,
    headers,
    paramsSerializer: (param) => {
      return qs.stringify(param, { encode: false });
    },
  };

  if (cookies && cookies[AUTH_KIT.NAME]) {
    defaultConfig.headers = {
      ...defaultConfig.headers,
      Authorization: `Bearer ${cookies[AUTH_KIT.NAME]}`,
    };
  }

  return axios
    .request<T>({
      ...defaultConfig,
      ...config,
      ...{
        headers: {
          ...defaultConfig.headers,
          ...config.headers,
          [LANGUAGE_KEY]: i18n.language,
        },
      },
    })

    .then((res) => res.data)
    .catch((e: AxiosError) => {
      if (e.response) {
        // Auth Error => Clear all cookies related auth => The react-auth-kit will be cleared and auto navigate to the login page

        const errorRes = e?.response?.data as any;

        console.log(errorRes);
        const isUnauthorized =
          e.response?.status === EStatusCode.Unauthorized &&
          blackBlistErrorAuth.includes(errorRes?.errorId) &&
          errorRes?.message === EErrorCode.Unauthorized;

        if (isUnauthorized || e.code == "ERR_NETWORK") {
          forEach(cookies, (_, cookieName) => {
            if (cookieName.startsWith(AUTH_KIT.NAME))
              Cookies.remove(cookieName, { domain: AUTH_KIT.DOMAIN });
          });
          document.location.href = routes.auth.signIn;
          toast.error("Unauthorized");
        }

        const data = e.response.data as
          | {
              error?: string;
              key?: string;
              message?: string;
              msg?: string;
              statusCode: number;
            }
          | undefined;

        throw new RequestError(
          data?.statusCode ?? e.response.status,
          data?.key ?? data?.error ?? e.response.statusText,
          data?.msg ?? data?.message ?? "Unknown error"
        );
      }
      throw new RequestError(e.status ?? 500, e.name, e.message);
    });
}
