import AuthWrapperFour from "@/app/shared/auth-layout/auth-wrapper-four";
import AuthVerifyOtpForm from "@/components/auth/auth-verify-otp-form";
import { routes } from "@/config/routes";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Text } from "rizzui";

export default function VerifyOtpPage() {
  const location = useLocation();

  if (!location?.state) {
    return <Navigate to={routes.auth.signIn} />;
  }
  return (
    <AuthWrapperFour
      title={
        <div className="text-center mt-6">
          <Text className="font-semibold text-gray-900 text-xl">
            OTP Verification
          </Text>
          <div className="flex items-center justify-center flex-wrap gap-1">
            <Text className="text-sm text-gray-500 whitespace-nowrap">
              We have sent a One Time Password (OTP)
            </Text>{" "}
            <Text className="text-sm text-gray-500 whitespace-nowrap">
              via SMS and email.
            </Text>
          </div>
        </div>
      }
      isSignIn
      isSocialLoginActive={false}
    >
      <AuthVerifyOtpForm />
    </AuthWrapperFour>
  );
}
