import { useEffect, useMemo, useState } from "react";
import { Button, Select, Tooltip } from "rizzui";
import { FaPlus } from "react-icons/fa";
import SettingsRepository from "@/utilities/repositories/Settings.ts";
import { IDomainDTO } from "@/utilities/types/Setting.ts";
import { SUFFIX_DOMAIN } from "@/config/constants";
import toast from "react-hot-toast";

export type SelectOptionType = {
  label: string;
  value: string;
  primary: boolean;
  configID: string;
};

// type configID = IUserData

type SelectDomainProps = {
  options: SelectOptionType[];
  defaultOptions: SelectOptionType | null;
  configID: IDomainDTO[];
  reset?: () => void;
};

export default function SelectDomain({
  options,
  defaultOptions,
  configID,
  reset,
}: SelectDomainProps) {
  const [value, setValue] = useState(defaultOptions);
  const [searchValue, setSearchValue] = useState<string>("");
  const mapOptions = options.map((item) => item);
  const onChangeHandler = async (e: SelectOptionType) => {
    setValue(e);
    const updateVariable = {
      primary: true,
    };
    await SettingsRepository.updateDomain(e.value, updateVariable);
  };
  const onSubmitDomain = async () => {
    if (configID) {
      await SettingsRepository.createDomain({
        configId: configID[0].configId,
        name: `${searchValue}`,
        primary: true,
      });
      if (reset) {
        toast.success("Updated User Domain");
        setTimeout(() => {
          reset();
        }, 2000);
      }
    }
  };

  useEffect(() => {
    setValue(defaultOptions);
  }, [defaultOptions]);

  const memoCheckFilter = useMemo(() => {
    const valueFilter = mapOptions.filter((item) => {
      return item.label.toLowerCase().includes(searchValue.toString());
    });
    // console.log(valueFilter)
    return valueFilter;
  }, [searchValue]);
  return (
    <>
      <div className={"flex gap-3 items-center"}>
        <Select
          value={value}
          label=""
          searchable={true}
          options={mapOptions}
          onChange={onChangeHandler}
          onSearchChange={(value) => {
            setSearchValue(value);
          }}
          searchPrefix={
            memoCheckFilter.length === 0 ? (
              <Button
                size={"sm"}
                variant="text"
                className={"h-6 min-w-6 px-0 py-0 text-gray"}
                onClick={onSubmitDomain}
              >
                + Add new domain
              </Button>
            ) : (
              ""
            )
          }
          searchPrefixClassName={
            "translate-y-6 bottom-0 text-xs top-[unset] p-0"
          }
          searchClassName={"mb-5 pl-3"}
        />
      </div>
    </>
  );
}
