import { Enum } from "./Enum";

export const EErrorCode = Object.freeze({
  Unauthorized: "Unauthorized",
  UserNotFound: "user_not_found",
  IncorrectPassword: "incorrect_password",
  TokenNotAccepted: "token_not_accepted",
  RoleNotAccepted: "role_not_accepted",
  UserAlreadyExists: "user_already_exists",
  EmailNotExist: "email_not_exist",
});

export type EErrorCode = Enum<typeof EErrorCode>;
