import { FieldValues } from "react-hook-form";
import _ from "lodash";
import { EUploadType } from "@/utilities/types/Upload";

export const filterChangedFormFields = <T extends FieldValues>(
  allFields: T,
  dirtyFields: Partial<Record<string, any>>,
  options?: {
    nestedArray?: boolean;
    nestedObject?: boolean;
  }
): Partial<T> => {
  const changedFieldValues = Object.keys(dirtyFields).reduce(
    (acc, currentField) => {
      let dirtyValue = allFields[currentField];

      if (Array.isArray(dirtyValue) && options?.nestedArray) {
        dirtyValue = filterChangedFormFields(
          dirtyValue,
          dirtyFields[currentField],
          { ...options }
        );
      }

      if (
        typeof dirtyValue === "object" &&
        !Array.isArray(dirtyValue) &&
        options?.nestedObject
      ) {
        dirtyValue = filterChangedFormFields(
          dirtyValue,
          dirtyFields[currentField],
          { ...options }
        );
      }
      return {
        ...acc,
        [currentField]: dirtyValue,
      };
    },
    {} as Partial<T>
  );

  return changedFieldValues;
};

export function removeUndefinedAndNullNestedObject(obj: Record<string, any>) {
  return _.transform(obj, (result: any, value, key) => {
    if (_.isObject(value)) {
      // Recursively clean nested objects
      const cleanedValue = removeUndefinedAndNullNestedObject(value);
      // Omit key if value is an empty object or array
      if (!(_.isEmpty(cleanedValue) && _.isArray(value))) {
        result[key] = cleanedValue;
      }
    } else if (!_.isUndefined(value) && !_.isNull(value)) {
      // Include key-value pairs if value is not undefined or null
      result[key] = value;
    }
  });
}

export function objectToFormData(obj: Record<string, any>) {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    if (_.isArray(value)) {
      value.forEach((item) => {
        formData.append(`${key}[]`, item);
      });
      return;
    }
    formData.append(key, value);
  });

  return formData;
}

export function getFileType(file: File): EUploadType | null {
  const imageMimeTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/tiff",
    "image/webp",
  ];
  const videoMimeTypes = [
    "video/mp4",
    "video/x-msvideo",
    "video/x-matroska",
    "video/quicktime",
    "video/x-ms-wmv",
    "video/x-flv",
    "video/webm",
  ];

  const mimeType = file.type;

  if (imageMimeTypes.includes(mimeType)) {
    return EUploadType.IMAGE;
  } else if (videoMimeTypes.includes(mimeType)) {
    return EUploadType.VIDEO;
  } else {
    return null;
  }
}
