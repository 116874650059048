"use client";

import { DatePicker } from "@/components/ui/datepicker";
import { PiTrashDuotone } from "react-icons/pi";
import { Button } from "rizzui";

type FilterElementProps = {
  isFiltered: boolean;
  filters: { [key: string]: any };
  updateFilter: (columnId: string, filterValue?: string | any[]) => void;
  handleReset: () => void;
};

export default function FilterElement({
  isFiltered,
  handleReset,
  filters,
  updateFilter,
}: FilterElementProps) {
  return (
    <div className="flex md:flex-row items-end justify-between flex-col w-full flex-wrap gap-4">
      <div className="flex md:flex-row flex-col w-full md:w-fit items-center flex-wrap gap-2">
        <DatePicker
          selected={filters?.["startDate"]}
          onChange={([startDate, endDate]: any) => {
            updateFilter("startDate", startDate || undefined);
            updateFilter("endDate", endDate || undefined);
          }}
          selectsRange
          startDate={filters?.["startDate"]}
          endDate={filters?.["endDate"]}
          dateFormat="dd MMM, yyyy"
          placeholderText="Select date"
          popperPlacement="bottom-end"
          inputProps={{
            variant: "text",
            inputClassName: "p-0 px-1 h-auto [&_input]:text-ellipsis",
            size: "sm",
          }}
          className="rounded  border [&_.rizzui-input-container]:px-3 [&_.rizzui-input-container]:py-1.5 w-full md:w-[280px]"
        />
        {isFiltered && (
          <Button
            className="max-md:w-full"
            size="sm"
            onClick={handleReset}
            variant="flat"
          >
            <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Clear
          </Button>
        )}
      </div>
    </div>
  );
}
