import { IMortgage } from "@/utilities/types/Mortgage.ts";
import { Roles } from "@/utilities/types/Users.ts";
import { HeaderCell } from "@/components/ui/table.tsx";
import useProfile from "@/hooks/use-profile.ts";
import DeletePopover from "@/app/shared/delete-popover.tsx";
import { TImageAsset } from "@/utilities/types/Asset.ts";
import { ActionIcon, Tooltip } from "rizzui";
import PencilIcon from "@/components/icons/pencil";
const ASSET_BASE_URL = import.meta.env.VITE_ASSET_BASE_URL as string;

type Columns = {
  data: IMortgage[];
  onDeleteItem: (id: string) => void;
  onHeaderCellClick: (value: string) => void;
  onEditItem?: (data: IMortgage) => void;
  reset?: () => void;
  role: Roles;
};

export const GetColumns = ({
  onDeleteItem,
  onHeaderCellClick,
  onEditItem,
  role,
}: Columns) => [
  {
    title: (
      <HeaderCell
        title="Name"
        sortable
        align="left"
      />
    ),
    onHeaderCell: () => onHeaderCellClick("bankName"),
    dataIndex: "bankName",
    key: "bankName",
    render: (name: string) => {
      return <>{name}</>;
    },
  },
  {
    title: (
      <HeaderCell
        title="Logo"
        align="left"
      />
    ),
    dataIndex: "bankLogo",
    key: "bankLogo",
    render: (bankLogo: TImageAsset) => {
      return (
        <>
          {bankLogo ? (
            <img
              width="70"
              height="70"
              src={`${ASSET_BASE_URL}${bankLogo.urls[0].url}`}
            />
          ) : (
            <p>Empty Logo</p>
          )}
        </>
      );
    },
  },
  {
    title: (
      <HeaderCell
        title="1st Year"
        align="center"
      />
    ),
    dataIndex: "firstYear",
    key: "firstYear",
    render: (value: { text: string; value: number }) => {
      return (
        <div className="flex flex-col gap-2 text-center">
          <p>{value?.value}</p>
          <p>{value?.text}</p>
        </div>
      );
    },
  },
  {
    title: (
      <HeaderCell
        title="2nd Year"
        align="center"
      />
    ),
    dataIndex: "secondYear",
    key: "secondYear",
    render: (value: { text: string; value: number }) => {
      return (
        <div className="flex flex-col gap-2 text-center">
          <p>{value?.value}</p>
          <p>{value?.text}</p>
        </div>
      );
    },
  },
  {
    title: (
      <HeaderCell
        title="3rd Year"
        align="center"
      />
    ),
    dataIndex: "threeYear",
    key: "threeYear",
    render: (value: { text: string; value: number }) => {
      return (
        <div className="flex flex-col gap-2 text-center">
          <p>{value?.value}</p>
          <p>{value?.text}</p>
        </div>
      );
    },
  },
  {
    title: (
      <HeaderCell
        title="Thereafter"
        align="center"
      />
    ),
    dataIndex: "thereAfter",
    key: "thereAfter",
    render: (value: { text: string; value: number }) => {
      return (
        <div className="flex flex-col gap-2 text-center">
          <p>{value?.value}</p>
          <p>{value?.text}</p>
        </div>
      );
    },
  },
  {
    title: (
      <HeaderCell
        title="Type"
        align="left"
      />
    ),
    dataIndex: "type",
    key: "type",
    render: (type: string) => {
      return <>{type}</>;
    },
  },
  {
    title: (
      <HeaderCell
        className="ps-3"
        title={<span className="whitespace-nowrap">Actions</span>}
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: IMortgage) => {
      if (role === Roles.ADMIN) {
        return (
          <ActionAdmin
            onDeleteItem={() => onDeleteItem?.(row.id)}
            row={row}
            onEditItem={onEditItem}
          />
        );
      }
    },
  },
];

const ActionAdmin = ({
  onDeleteItem,
  row,
  onEditItem,
}: {
  onDeleteItem: () => void;
  onEditItem?: (data: IMortgage) => void;
  row: IMortgage;
}) => {
  const { profile } = useProfile();
  return (
    <div className="flex items-center justify-center gap-3 pe-4">
      {profile?.role === Roles.ADMIN && (
        <>
          <Tooltip
            size="sm"
            content={"Edit Mortgage"}
            placement="top"
            color="invert"
          >
            <ActionIcon
              size="sm"
              variant="outline"
              onClick={() => {
                if (typeof onEditItem === "function") {
                  onEditItem(row);
                }
              }}
            >
              <PencilIcon className="h-4 w-4" />
            </ActionIcon>
          </Tooltip>
          <DeletePopover
            title={`Delete mortgage`}
            description={`Are you sure to delete this mortgage?`}
            onDelete={onDeleteItem}
          />
        </>
      )}
    </div>
  );
};
