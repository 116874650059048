export class RequestError extends Error {
  statusCode: number;
  error: string;
  msg: string;
  constructor(statusCode: number, error: string, message: string) {
    super(message);
    this.statusCode = statusCode;
    this.error = error;
    this.msg = message;
  }
}
export interface IResponseData<T> {
  data: T;
  statusCode?: number;
  message: string;
}

export interface IResponseError {
  error: string;
  message: string;
  statusCode: number;
  success: boolean;
  timestamp: string;
}
export interface IPaginationResponse {
  total: number;
  totalPage: number;
  currentPage: number;
  limit: number;
}

export interface IListResponse<T> extends IPaginationResponse {
  totalCount?: {
    viewsCount: number;
    entriesCount: number;
    unitsSoldCount: number;
  };
  availableUnitCount?: number;
  items?: T[];
  submissionLogs?: T[];
  score?: number;
}

export type ListResponseData<T> = IResponseData<IListResponse<T>>;

//
export interface IRequestPagination {
  limit: number;
  page: number;
}

export interface QueryObject {
  filter: Record<string, string>[];
  sort: Record<string, "desc" | "asc">;
  search: Record<string, string>;
  projectId?: string;
}

export interface IRequestParams {
  query?: QueryObject;
  pagination?: IRequestPagination;
}
export interface IRequestArgs {
  queryParams?: IRequestParams;
  data?: Record<string, any>;
  projectId?: string;
}
