"use client";

import { PiMagnifyingGlassBold } from "react-icons/pi";
import { Title, Input } from "rizzui";

type FilterElementProps = {
  onSearch: (searchTerm: string) => void;
  searchTerm: string;
};

export default function FilterElement({
  onSearch,
  searchTerm,
}: FilterElementProps) {
  return (
    <div className="mb-4">
      <div className="relative z-50 mb-4 flex flex-wrap items-center justify-between gap-2.5 @container ">
        <Title
          as="h5"
          className="-order-6 basis-2/5 @xl:basis-auto"
        >
          User References
        </Title>

        <Input
          type="search"
          placeholder="Search by name..."
          value={searchTerm}
          onClear={() => onSearch("")}
          onChange={(event) => onSearch(event.target.value)}
          prefix={<PiMagnifyingGlassBold className="h-4 w-4" />}
          rounded="lg"
          clearable
          className="-order-4 w-full @xl:-order-5 @xl:ms-auto @xl:w-auto @4xl:-order-2 @4xl:w-[230px] @5xl:w-auto"
        />
      </div>
    </div>
  );
}
