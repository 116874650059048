import { Roles } from "@/utilities/types/Users.ts";
import { IFloor, IFloorName, IUnitTypeFloor } from "@/utilities/types/Floor.ts";
import { HeaderCell } from "@/components/ui/table.tsx";
import useProfile from "@/hooks/use-profile.ts";
import DeletePopover from "@/app/shared/delete-popover.tsx";
import ModalCreateEditFloor from "@/app/app-shared/floor-plan/create-edit/modal-edit.tsx";
import { ActionIcon, Tooltip } from "rizzui";
import { Link } from "react-router-dom";
import { routes } from "@/config/routes";
import { BsBoxes } from "react-icons/bs";
import { getParamsCurrentPage } from "@/utilities/functions";

type Columns = {
  data: IFloor[];
  onDeleteItem?: (id: string) => void;
  onHeaderCellClick: (value: string) => void;
  onUpdateFloor?: (id: string) => Promise<void>;
  reset?: () => void;
  role: Roles;
  projectId: string;
};
export const GetColumns = ({
  data,
  onHeaderCellClick,
  onDeleteItem,
  role,
  reset,
  projectId,
}: Columns) => [
  {
    title: <HeaderCell title="Name" sortable align="left" />,
    onHeaderCell: () => onHeaderCellClick("name"),
    dataIndex: "name",
    key: "name",
    render: (name: IFloorName) => {
      return <>{typeof name === "object" ? name?.en : name}</>;
    },
  },
  {
    title: <HeaderCell title="Unit Type" sortable align="left" />,
    onHeaderCell: () => onHeaderCellClick("name"),
    dataIndex: "unitType",
    key: "unitType",
    render: (unitType: IUnitTypeFloor) => {
      return (
        <>
          {typeof unitType?.title === "object"
            ? unitType?.title?.en
            : unitType?.title}
        </>
      );
    },
  },
  {
    title: <HeaderCell title="Size(sqft)" sortable align="left" />,
    onHeaderCell: () => onHeaderCellClick("name"),
    dataIndex: "area",
    key: "area",
    render: (area: IFloor) => {
      return <>{area}</>;
    },
  },
  {
    title: <HeaderCell title="Min Price" sortable />,
    dataIndex: "minPrice",
    key: "minPrice",
    render: (minPrice: IFloor) => {
      return <>{minPrice}</>;
    },
  },
  {
    title: <HeaderCell title="Max Price" sortable />,
    dataIndex: "maxPrice",
    key: "maxPrice",
    render: (maxPrice: IFloor) => {
      return <>{maxPrice}</>;
    },
  },
  {
    title: (
      <HeaderCell
        className="ps-3"
        title={<span className="whitespace-nowrap">Actions</span>}
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: IFloor) => {
      return (
        <ActionAdmin
          onDeleteItem={() => onDeleteItem?.(row.id)}
          row={row}
          projectId={projectId}
          reset={reset}
        />
      );
    },
  },
];

const ActionAdmin = ({
  onDeleteItem,
  row,
  projectId,
  reset,
}: {
  onDeleteItem: () => void;
  reset?: () => void;
  onUpdateFloor?: () => Promise<void>;
  row: IFloor;
  projectId: string;
}) => {
  const { profile } = useProfile();
  return (
    <div className="flex items-center justify-center gap-3 pe-4">
      {profile?.role === Roles.ADMIN && (
        <>
          <ModalCreateEditFloor row={row} projectId={projectId} reset={reset} />
          <DeletePopover
            title={`Delete Floorplan`}
            description={`Are you sure to delete this Floorplan?`}
            onDelete={onDeleteItem}
          />
        </>
      )}
      <Tooltip size="sm" content={"View Units"} placement="top" color="invert">
        <Link
          to={{
            pathname: `${routes.unit.listing(projectId || "", row?.id)}`,
            search: getParamsCurrentPage({ rowKey: row?.id }),
          }}
        >
          <ActionIcon size="sm" variant="outline">
            <BsBoxes className="h-4 w-4" />
          </ActionIcon>
        </Link>
      </Tooltip>
    </div>
  );
};
