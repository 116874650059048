import { request } from "@/utilities/libs/request.ts";
import { IResponseData } from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { ICategory } from "../types/Project";
import { E_CATEGORIES } from "../enums/Category";

function getAllCategories() {
  return request<IResponseData<ICategory[]>>({
    method: ERequestMethods.GET,
    url: E_CATEGORIES.MAIN,
  });
}

const CategoryRepository = Object.freeze({
  getAllCategories,
});

export default CategoryRepository;
