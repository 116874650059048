import ControlledTable from "@/components/controlled-table";
import { useEffect, useMemo } from "react";
import { getColumns } from "./columns";

import { Title } from "rizzui";
import LoginActivityRepository from "@/utilities/repositories/LoginActivity";
import { useTableServer } from "@/hooks/useTableServer";
import { useLocation, useParams } from "react-router-dom";
import FilterElement from "./filter-element";

export default function LoginActivityTable() {
  const { id } = useParams();

  const {
    isLoading,
    tableData,
    currentPage,
    filters,
    updateFilter,
    handleReset,
    isFiltered,
    totalItems,
    handlePaginate,
    pageSize,
    handleChangePageSize,
  } = useTableServer({
    server: {
      request: LoginActivityRepository.getAll,
    },
    initialFilterState: {
      userId: id,
    },
  });

  const columns = useMemo(
    () =>
      getColumns({
        data: tableData,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData]
  );

  const { state } = useLocation();

  const user = state?.user;
  return (
    <>
      <div className="mb-4 w-full flex items-center justify-between">
        <div className="flex flex-col gap-y-4 w-full">
          <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl ">
            Login activities
          </Title>

          <div className="flex justify-between items-center w-full max-md:flex-col max-md:items-start gap-2">
            <FilterElement
              isFiltered={isFiltered}
              filters={filters}
              updateFilter={updateFilter}
              handleReset={handleReset}
            />

            {user && (
              <div className="w-max">
                <p className="font-bold text-left md:text-right">{`${user?.firstName} ${user?.lastName}`}</p>
                <p className="text-sm text-gray-400 whitespace-nowrap">{`${user?.email}`}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        // @ts-ignore
        columns={columns}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </>
  );
}
