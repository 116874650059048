import { routes } from "@/config/routes";
import { privateRoutesType } from "@/types/routes";
import Users from "@/page/Users.tsx";
import CreateUser from "@/app/shared/roles-permissions/create-user";
import CreateEditProject from "@/app/app-shared/project/create-edit";
import EnquiryEntriesPage from "@/page/enquiry-entries/EnquiryEntriesPage";
import EditAgencySetting from "@/page/settings/EditAgencySetting";
import ProjectPage from "@/page/project/ProjectPage";
import EditUserPage from "@/page/user/edit-user";
import ProfilePage from "@/page/settings/ProfilePage";
import { Roles } from "@/utilities/types/Users";
import Home from "@/page/Home";
import AgentProjectsTable from "@/app/app-shared/project/project-table/agent-projects-table";
import LocationListingPage from "@/page/location/LocationListingPage";
import UnitTypeListingPage from "@/page/unit-types/UnitTypeListingPage";
import ProjectCategoryListingPage from "@/page/project-category/ProjectCategoryListingPage";
import DeveloperListingPage from "@/page/developer/DeveloperListingPage";
import PromotionBannerListingPage from "@/page/promotion-banner/PromotionBannerListingPage";
import UnitTransactionPage from "@/page/unit-transaction/UnitTransactionPage";
import FloorPlanPage from "@/page/floor-plans/FloorPlansPage.tsx";
import UserPreferencePage from "@/page/user-preference/UserPreferencePage";
import AmenityPage from "@/page/amenity/AmenityPage";
import VirtualProjectListingPage from "@/page/virtual-projects/VirutalProjectsListingPage";
import SiteContentListingPage from "@/page/site-content/SiteContentListingPage";
import WhatsappListingPage from "@/page/whatsapp/WhatsappListingPage.tsx";
import ImportTaskListingPage from "@/page/import-data/ImportTaskListingPage";
import DisclaimerListingPage from "@/page/disclaimer";
import MortgagePage from "@/page/mortgage";
import LayoutPage from "@/page/layout";
import FollowUpPage from "@/page/follow-up/FollowUp";
import AgentLocationProjectTable from "@/app/app-shared/agentLocations/table/agent-location-projects-table";
import WebhookSettingPage from "@/page/webhook/WebhookSettingPage";
import EmailTemplatePage from "@/page/email-template/EmailTemplatePage";
import FloorPlanUnitsPage from "@/page/floor-plan-units/FloorPlanUnitsPage";
import AdvancedFeaturesPages from "@/app/app-shared/advanced-features";
import AdminSetAdvancedFeaturesPage from "@/app/app-shared/advanced-features/AdminSetAdvancedFeaturesPage";
import LoginActivityPage from "@/page/login-activity";
import PrivacyPolicyPage from "@/page/privacy-policy/PrivacyPolicyPage";
import SiteContentCrUpPage from "@/page/site-content/SiteContentCrUpPage";
import LandingPages from "@/page/landing-pages";
import ProjectLandingPages from "@/page/project-landing-pages";

export const privateRoutes: privateRoutesType[] = [
  {
    path: "/",
    exact: true,
    component: Home,
    layout: true,
  },
  {
    path: routes.user.create,
    component: CreateUser,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  // User
  {
    path: routes.user.listing,
    component: Users,
    layout: true,
    permissions: [Roles.ADMIN],
  },
  {
    path: routes.user.create,
    component: CreateUser,
    layout: true,
    permissions: [Roles.ADMIN],
  },
  {
    path: routes.user.edit(":id"),
    component: EditUserPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  {
    path: routes.project.listing,
    component: ProjectPage,
    layout: true,
    permissions: [Roles.AGENCY, Roles.ADMIN],
  },
  {
    path: routes.project.create,
    component: CreateEditProject,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  {
    path: routes.project.edit(),
    component: CreateEditProject,
    layout: true,
    permissions: [Roles.ADMIN, Roles.AGENCY],
  },
  // Contact Sale
  {
    path: routes.contacts.listing,
    component: EnquiryEntriesPage,
    layout: true,
    permissions: [Roles.AGENCY, Roles.ADMIN],
  },

  // agency
  {
    path: routes.setting.agency,
    component: EditAgencySetting,
    layout: true,
    permissions: [Roles.AGENCY],
  },

  {
    path: routes.project.agentListing,
    component: AgentProjectsTable,
    layout: true,
    permissions: [Roles.AGENCY],
  },

  //Profile

  {
    path: routes.account.profile,
    component: ProfilePage,
    layout: true,
  },

  // Setting

  // {
  //   path: routes.setting.locaion,
  //   component: SectionConfigPage,
  //   layout: true,
  //   permissions: [Roles.AGENCY],
  // },
  //Location
  {
    path: routes.location.listing,
    component: LocationListingPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  {
    path: routes.agentLocation.listing,
    component: LayoutPage,
    layout: true,
    permissions: [Roles.AGENCY],
  },

  {
    path: routes.agentLocation.projects,
    component: AgentLocationProjectTable,
    layout: true,
    permissions: [Roles.AGENCY],
  },
  {
    path: routes.importData.listing,
    component: ImportTaskListingPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  // unit type
  {
    path: routes.unitType.listing,
    component: UnitTypeListingPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  // project category
  {
    path: routes.projectCategory.listing,
    component: ProjectCategoryListingPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  // developer
  {
    path: routes.developer.listing,
    component: DeveloperListingPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  // promotion banner
  {
    path: routes.promotionBanner.listing,
    component: PromotionBannerListingPage,
    layout: true,
    permissions: [Roles.AGENCY, Roles.ADMIN],
  },

  // unit transaction
  {
    path: routes.unitTransaction.listing(":id"),
    component: UnitTransactionPage,
    layout: true,
    permissions: [Roles.ADMIN, Roles.AGENCY],
  },

  // user preference
  {
    path: routes.userPreference.listing,
    component: UserPreferencePage,
    layout: true,
    permissions: [Roles.AGENCY],
  },
  // amenity
  {
    path: routes.amenity.listing(":id"),
    component: AmenityPage,
    layout: true,
    permissions: [Roles.ADMIN, Roles.AGENCY],
  },

  //Floorplan
  {
    path: routes.floorPlan.listing(":id"),
    component: FloorPlanPage,
    layout: true,
    permissions: [Roles.ADMIN, Roles.AGENCY],
  },

  {
    path: routes.landingPages.listing,
    component: ProjectLandingPages,
    layout: true,
    permissions: [Roles.ADMIN, Roles.AGENCY],
  },

  {
    path: routes.projectLandingPages.listing(":id"),
    component: ProjectLandingPages,
    layout: true,
    permissions: [Roles.ADMIN, Roles.AGENCY],
  },

  {
    path: routes.unit.listing(),
    component: FloorPlanUnitsPage,
    layout: true,
    permissions: [Roles.ADMIN, Roles.AGENCY],
  },

  {
    path: routes.virtual.listing(":id"),
    component: VirtualProjectListingPage,
    layout: true,
    permissions: [Roles.ADMIN, Roles.AGENCY],
  },
  {
    path: routes.siteContent.listing,
    component: SiteContentListingPage,
    layout: true,
    permissions: [Roles.AGENCY],
  },
  // {
  //   path: routes.siteContent.create,
  //   component: CreateEditSiteContent,
  //   layout: true,
  //   permissions: [Roles.AGENCY],
  // },

  {
    path: routes.siteContent.edit(),
    component: SiteContentCrUpPage,
    layout: true,
    permissions: [Roles.AGENCY],
  },
  //Whatsapp
  {
    path: routes.whatsapp.listing,
    component: WhatsappListingPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  //disclaimer
  {
    path: routes.disclaimer.listing,
    component: DisclaimerListingPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },
  //Mortgage
  {
    path: routes.mortgage.listing,
    component: MortgagePage,
    layout: true,
    permissions: [Roles.ADMIN],
  },
  // {
  //   path: routes.layout.listing,
  //   component: LayoutPage,
  //   layout: true,
  //   permissions: [Roles.AGENCY],
  // },
  {
    path: routes.followUp.listing,
    component: FollowUpPage,
    layout: true,
    permissions: [Roles.AGENCY],
  },

  // Webhook
  {
    path: routes.webhook,
    component: WebhookSettingPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  // Email template
  {
    path: routes.emailTemplate,
    component: EmailTemplatePage,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  // Email template
  {
    path: routes.privacyPolicy,
    component: PrivacyPolicyPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },
  // Advance feature
  {
    path: routes.advanceFeatures,
    component: AdvancedFeaturesPages,
    layout: true,
    permissions: [Roles.AGENCY],
  },
  {
    path: routes.user.advanceFeature(":id"),
    component: AdminSetAdvancedFeaturesPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },

  // Login activity
  {
    path: routes.loginActivity,
    component: LoginActivityPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },
  {
    path: routes.user.loginActivity(":id"),
    component: LoginActivityPage,
    layout: true,
    permissions: [Roles.ADMIN],
  },
];
