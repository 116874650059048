import { z } from 'zod';
import {
  validateEmail,
  validatePassword,
} from '@/utils/validators/common-rules';

// form zod validation schema
export const resetPasswordSchema = z
  .object({
    password: validatePassword,
  });

export const submitEmailSchema = z.object({
    email: validateEmail,
})

// generate form types from zod validation schema
export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;
export type SubmitEmailSchema = z.infer<typeof submitEmailSchema>