import ControlledTable from "@/components/controlled-table";
import { getColumns } from "./columns";
import { Title } from "rizzui";
import ModalButton from "@/app/shared/modal-button";
import ImportDataRepository from "@/utilities/repositories/ImportData";
import { PiSignIn } from "react-icons/pi";
import ImportForm from "../import-form";
import { useModal } from "@/app/shared/modal-views/use-modal";
import TaskDetail from "../details/task-details";
import dynamic from "next/dynamic";
import { useTableServer } from "@/hooks/useTableServer";
import { ColumnsType } from "rc-table";
import { IImportDataTask } from "@/utilities/types/ImportData";
import { useEffect, useMemo } from "react";
import { EImportTaskStatus } from "@/utilities/enums/ImportData";

const FilterElement = dynamic(
  () => import("@/app/app-shared/import-data/table/filter-element"),
  { ssr: false }
);

export default function ImportTaskTable() {
  // const {
  //   request: fetchData,
  //   response,
  //   loading: isLoading,
  // } = useApi({
  //   request: ImportDataRepository.getTaskList,
  // });
  const {
    isLoading,
    isFiltered,
    tableData,
    currentPage,
    totalItems,
    pageSize,
    handleChangePageSize,
    filters,
    updateFilter,
    searchTerm,
    handleSearch,
    sortConfig,
    handleSort,
    handleReset,
    handlePaginate,
    refresh,
  } = useTableServer({
    server: {
      request: ImportDataRepository.getTaskList,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
  });
  const onHeaderCellClick = (value: string) => ({
    onClick: () => {
      handleSort(value);
    },
  });

  const { openModal } = useModal();

  const columns = getColumns({
    onDetailClick: (row) => {
      openModal({
        view: <TaskDetail data={row} />,
        customSize: "750px",
      });
    },
    sortConfig,
    onHeaderCellClick,
  }) as ColumnsType<IImportDataTask>;

  // const tableData = response?.data?.items || [];

  // useEffect(() => {
  //   fetchData({
  //     queryParams: {
  //       query: {
  //         sort: "DESC",
  //       },
  //     },
  //   });
  // }, [fetchData]);

  const shouldIntervalRefresh = useMemo(() => {
    return tableData.some(
      (item) =>
        item.status === EImportTaskStatus.Pending ||
        item.status === EImportTaskStatus.Processing
    );
  }, [tableData]);

  useEffect(() => {
    if (shouldIntervalRefresh) {
      const t = window.setInterval(() => {
        refresh();
      }, 10 * 1000);

      return () => {
        window.clearInterval(t);
      };
    }
  }, [shouldIntervalRefresh]);

  return (
    <>
      <div className="mb-4 w-full flex items-center justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          Import Data
        </Title>
        <ModalButton
          view={<ImportForm onSuccess={refresh} />}
          label="Import"
          icon={<PiSignIn className="me-1.5 h-[17px] w-[17px]" />}
          customSize="500px"
          className="mt-0 md:hidden flex w-fit grow-0"
          onBackdrop={() => {}}
        />
      </div>
      <div className="flex md:flex-row w-full justify-between flex-col">
        <FilterElement
          updateFilter={updateFilter}
          filters={filters}
          isFiltered={isFiltered}
          handleReset={handleReset}
          onSearch={handleSearch}
          searchTerm={searchTerm}
        />
        <ModalButton
          view={<ImportForm onSuccess={refresh} />}
          label="Import"
          icon={<PiSignIn className="me-1.5 h-[17px] w-[17px]" />}
          customSize="500px"
          className="mt-0 hidden md:flex w-auto grow-0"
          onBackdrop={() => {}}
        />
      </div>

      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        columns={columns}
        scroll={{ x: 800 }}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </>
  );
}
