import { z } from "zod";

export const editFloorPlanSchema = z.object({
    name: z.string().min(1, { message: "This field is required" }),
    area: z
        .number({
            required_error:"This field is required"
        })
        .min(1, { message: "This field is required" })
        .or(z.string().min(1, { message: "Minimum value is 1" })),
    unitTypeId: z.string().min(1, { message: "This field is required" }),
    minPrice:z
        .number({
            required_error:"This field is required"
        })
        .min(1, { message: "This field is required" })
        .or(z.string().min(1, { message: "Minimum value is 1" })),
    maxPrice:z
        .number({
            required_error:"This field is required"
        })
        .min(1, { message: "This field is required" })
        .or(z.string().min(1, { message: "Minimum value is 1" })),
    totalUnits:z
        .number({
            required_error:"This field is required"
        })
        .min(0, { message: "This field is required" })
        .or(z.string().min(1, { message: "Minimum value is 1" })),
    availableUnits:z
        .number({
            required_error:"This field is required"
        })
        .min(0, { message: "This field is required" })
        .or(z.string().min(1, { message: "Minimum value is 1" })),
})

export type FloorPlanSchemaInput = z.infer<typeof editFloorPlanSchema>;
