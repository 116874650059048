"use client";

import { HeaderCell } from "@/components/ui/table";
import { getReturnValues } from "@/hooks/use-countdown";
import { ILoginActivity } from "@/utilities/types/LoginActivity";
import dayjs from "dayjs";
import { ColumnType } from "rc-table";
import { useParams } from "react-router-dom";
import { Badge } from "rizzui";

type Columns = {
  data: ILoginActivity[];
};

export const getColumns = ({}: Columns): ColumnType<ILoginActivity>[] => {
  const { id } = useParams();

  return [
    {
      title: <HeaderCell title="User" align="center" />,
      key: "user.name",
      dataIndex: "user.name",
      hidden: !!id,
      render: (_: string, row: ILoginActivity) => {
        return (
          <div className="flex flex-col">
            <div className="inline-flex items-center">
              <p className="font-bold">
                {`${row.user.firstName} ${row.user.lastName}`}
              </p>
              <Badge size="sm" className="capitalize ml-2">
                {row.user.role}
              </Badge>
            </div>
            <p className="font-bold text-gray-400">{row.user.email}</p>
          </div>
        );
      },
    },

    {
      title: <HeaderCell title="Status" align="center" />,
      key: "user.status",
      dataIndex: "user.status",
      align: "center",
      render: (_: string, row: ILoginActivity) => {
        if (!row?.status) return <></>;
        const isActive = row?.status === "active";
        return (
          <Badge
            size="sm"
            color={isActive ? "success" : "danger"}
            className="capitalize ml-2 mx-auto"
          >
            {row.status}
          </Badge>
        );
      },
    },

    {
      title: <HeaderCell title="IP" align="center" />,
      dataIndex: "ip",
      key: "ip",
      align: "center",
    },

    {
      title: <HeaderCell title="Country" align="center" />,
      dataIndex: "country",
      key: "country",
      align: "center",
    },

    {
      title: <HeaderCell title="Browser" align="center" />,
      dataIndex: "browser",
      key: "browser",
      align: "center",
    },
    {
      title: <HeaderCell title="Device" align="center" />,
      dataIndex: "device",
      key: "device",
      align: "center",

      render: (_: string, row: ILoginActivity) => {
        return (
          <div className="flex flex-col">
            <p className="font-bold">{row.os}</p>
            <p className="text-gray-400 text-xs">{row.device.model}</p>
            <p className="text-gray-400 text-xs">{row.device.vendor}</p>
          </div>
        );
      },
    },
    {
      title: <HeaderCell title="Login at" align="center" />,
      dataIndex: "created",
      key: "created",
      align: "center",

      render: (_: string, row: ILoginActivity) => {
        return (
          <p className="capitalize">
            {dayjs(row?.createdAt).format("DD MMM YYYY HH:mm A")}
          </p>
        );
      },
    },
    {
      title: <HeaderCell title="Logout at" align="center" />,
      dataIndex: "created",
      key: "created",
      align: "center",
      render: (_: string, row: ILoginActivity) => {
        return (
          row?.loggedOutAt && (
            <p className="capitalize">
              {dayjs(row?.loggedOutAt).format("DD MMM YYYY HH:mm A")}
            </p>
          )
        );
      },
    },

    {
      title: <HeaderCell title="Login duration" align="center" />,
      dataIndex: "duration",
      key: "duration",
      align: "center",
      render: (_: string, row: ILoginActivity) => {
        const { days, hours, minutes, seconds } = getReturnValues(
          dayjs(row?.loggedOutAt).diff(dayjs(row?.createdAt))
        );

        return (
          row?.loggedOutAt && (
            <p className="text-center">
              {!!days && `${days} ${days > 1 ? "days" : "day"} `}
              {!!hours && `${hours} ${hours > 1 ? "hrs" : "hr"} `}
              {!!minutes && `${minutes} ${minutes > 1 ? "mins" : "min"} `}
              {!!seconds && `${seconds} ${seconds > 1 ? "secs" : "sec"} `}
            </p>
          )
        );
      },
    },
  ];
};
