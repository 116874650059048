import React, { createContext, useState, useMemo } from "react";
import { EFeatureMode, IUserFeature } from "../types/AdvanceFeature";
import AdvanceFeatureRepository from "../repositories/AdvanceFeature";
import useApi from "@/hooks/useApi";
import { IResponseData } from "../types/requests";

export type AdvanceFeaturesContextState = {
  dynamicLayoutMode: EFeatureMode | undefined;
  landingPageFeature: IUserFeature | undefined;
  setLandingPageFeature: React.Dispatch<
    React.SetStateAction<IUserFeature | undefined>
  >;
  enabledLayout: boolean;
  setDynamicLayoutMode: React.Dispatch<
    React.SetStateAction<EFeatureMode | undefined>
  >;
  setEnabledLayout: React.Dispatch<React.SetStateAction<boolean>>;
  getAdvanceFeatures: () => void;
};

export const AdvanceFeaturesContext = createContext<
  AdvanceFeaturesContextState | undefined
>(undefined);

export const AdvanceFeaturesProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [dynamicLayoutMode, setDynamicLayoutMode] = useState<
    EFeatureMode | undefined
  >(undefined);
  const [enabledLayout, setEnabledLayout] = useState<boolean>(false);
  const [landingPageFeature, setLandingPageFeature] = useState<
    IUserFeature | undefined
  >(undefined);

  const {
    request: getAdvanceFeaturesByUser,
    response: allAvanceFeaturesByUser,
  } = useApi<IResponseData<IUserFeature[]>>({
    request: AdvanceFeatureRepository.getUserFeaturesById,
  });

  const getAdvanceFeatures = () => {
    // Implementation of the function
  };

  const value = useMemo(
    () => ({
      dynamicLayoutMode,
      enabledLayout,
      setDynamicLayoutMode,
      setEnabledLayout,
      landingPageFeature,
      setLandingPageFeature,
      getAdvanceFeatures,
      getAdvanceFeaturesByUser,
      allAvanceFeaturesByUser,
    }),
    [
      allAvanceFeaturesByUser,
      dynamicLayoutMode,
      enabledLayout,
      getAdvanceFeaturesByUser,
      landingPageFeature,
    ]
  );

  return (
    <AdvanceFeaturesContext.Provider value={value}>
      {children}
    </AdvanceFeaturesContext.Provider>
  );
};

// const updateLayoutState = useCallback(() => {
//   if (atomProfile && atomProfile?.userFeatures) {
//     const featureLayout = atomProfile?.userFeatures?.find(
//       (feature) => feature?.feature?.type === EFeatureType.DynamicLayout
//     )?.enabled;
//     const advanceLayout = atomProfile?.userFeatures?.find(
//       (feature) => feature?.feature?.type === EFeatureType.DynamicLayout
//     )?.dynamicLayoutMode;
//     const landingPageFeature = atomProfile?.userFeatures?.find(
//       (feature) => feature?.feature?.type === EFeatureType.DynamicLayout
//     );

//     if (landingPageFeature) {
//       setLandingPageFeature(landingPageFeature);
//     }

//     if (featureLayout) {
//       setEnabledLayout(featureLayout ? featureLayout : false);
//     }
//     if (!dynamicLayoutMode && advanceLayout) {
//       setDynamicLayoutMode(advanceLayout);
//     }
//   }
// }, [atomProfile]);
