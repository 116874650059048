"use client";

import { useEffect, useMemo } from "react";
import { useColumn } from "@/hooks/use-column";
import ControlledTable from "@/components/controlled-table";
import { getColumns } from "./columns";
import useApi from "@/hooks/useApi";
// import { IResponseData } from "@/utilities/types/requests";
// import { ISiteContent } from "@/utilities/types/SiteContent";
import SiteContentRepository from "@/utilities/repositories/SiteContent";
import { Title } from "rizzui";
// import AddNewButton from "@/app/shared/add-new-button";
// import { routes } from "@/config/routes";

export default function SiteContentTable() {
  // const { request: deleteSiteContent } = useApi<IResponseData<ISiteContent>>({
  //   request: SiteContentRepository.deleteSiteContent,
  //   enableToast: true,
  // });
  const {
    request: fetchData,
    response,
    loading: isLoading,
  } = useApi({
    request: SiteContentRepository.getAllSiteContent,
  });

  const tableData = response?.data || [];

  const columns = useMemo(
    () => getColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData]
  );

  const { visibleColumns } = useColumn(columns);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="mt-4">
      <div className="relative z-50 mb-4 flex flex-wrap items-center justify-between gap-2.5 @container">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          System Pages
        </Title>
      </div>
      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={visibleColumns}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
}
