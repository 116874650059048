import React, { useEffect, useState } from "react";
import { Button } from "rizzui";

interface IColors {
  [key: string]: string;
}

interface ColorSchemaPreviewProps {
  colors: IColors;
  baseUrl: string;
}

const ColorSchemaPreview: React.FC<ColorSchemaPreviewProps> = ({
  colors,
  baseUrl,
}) => {
  const [iframeUrl, setIframeUrl] = useState<string>(baseUrl);
  const [urlError, setUrlError] = useState<string>("");

  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    const updateIframeUrl = (updatedColors: IColors) => {
      if (!isValidUrl(baseUrl)) {
        setUrlError("Invalid URL");
        return;
      }

      const url = new URL(baseUrl);
      const params = new URLSearchParams();

      Object.entries(updatedColors).forEach(([key, value]) => {
        params.append(key, encodeURIComponent(value));
      });

      url.search = params.toString();
      setIframeUrl(url.toString());
      setUrlError(""); // Clear any previous errors
    };

    updateIframeUrl(colors);
  }, [colors, baseUrl]);

  const handleClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div
      className={
        "flex flex-col gap-2 items-center justify-center w-full h-full relative min-h-[220px]"
      }
    >
      {urlError && <p style={{ color: "red" }}>{urlError}</p>}
      <iframe
        src={iframeUrl}
        width="100%"
        height="100%"
        title="Color Preview"
        allow="fullscreen"
        style={{
          background: "#FFFFFF",
          position: "absolute",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
        }}
      />
      <Button variant="outline" onClick={() => handleClick(iframeUrl)}>
        Preview
      </Button>
    </div>
  );
};

export default ColorSchemaPreview;
