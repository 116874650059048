import { Enum } from "./Enum";

export const EErrorId = Object.freeze({
  EXPIRED_TOKEN: "PL000",
  EXPIRED_OTP: "PL001",
  INVALID_OTP: "PL002",
  INVALID_USER: "PL003",
});

export type EErrorId = Enum<typeof EErrorId>;
